<div class="modal-dialog modal-lg" role="document" style="margin-left: 12px; margin-right: 12px;">
  <div class="modal-content">
    <div class="modal-header text-center">
      <h5 class="modal-title w-100" id="exampleModalCenterTitle">{{'ASSIGNED SHIFTS'| translate}}
      </h5>
    </div>
    <div class="modal-body" style="padding: 0px;">
      <div *ngIf="swapShiftList.length" class="row">
        <table class="table table-bordered table-hover dataTable">
          <thead>
            <tr style="background-color: var(--secondary-color);" class="table100-head">
              <th style="text-align:center; width: auto; color:var(--table-header-font-color);">
                Auswahl</th>
              <th style="text-align:center; width: auto; color:var(--table-header-font-color);">
                {{'COLLEAGUE'|translate}}</th>
              <th style="text-align:center; width: auto; color:var(--table-header-font-color);">{{'Date'|translate}}
              </th>
              <th style="text-align:center; width: 100px;color:var(--table-header-font-color);">{{'Time'|translate}}
              </th>

            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let shift of swapShiftList">
              <td style="text-align:center;">
                <input type="radio" name="selectedShift" [value]="shift.Id" [checked]="selectedShiftId === shift.Id"
                  id="{{shift.Id}}" [(ngModel)]="selectedShiftId" id="{{shift.Id}}">
                <label class="checkbox" for="{{shift.Id}}" style="margin-bottom: 15px;"></label>
              </td>
              <td style="text-align:center"> {{shift.EmployeeFullName}}</td>
              <td style="text-align:center">{{shift.Date}}</td>
              <td style="text-align:center; width: auto;">{{shift.StartTime}} - {{shift.EndTime}}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div *ngIf="!swapShiftList.length" class="row">
        <div class="col-md-12">
          <div class="alert alert-warning" role="alert">
            {{'SO FAR NO COLLEAGUE HAS APPLIED FOR THE SHIFT SWAP'|translate}}
          </div>
        </div>
      </div>
      <div class="modal-footer d-flex justify-content-between">
        <button class="btn btn-outline-secondary" (click)="onClose()" type="button"
          data-dismiss="modal">{{'Close'|translate}}</button>
        <button class="btn btn-primary active" (click)="onSubmit()" type="submit" [disabled]="false">{{'ACCEPT'|
          translate}}</button>
      </div>
    </div>
  </div>