import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Observable, tap } from 'rxjs';
import { ApiEndPoints, ApiMethod } from '../../../core/services/const';
import { HttpService } from '../../../core/services/http/http.service';
import {
  EmployeeListByDateDto,
  EmployeeListDto,
} from '../../admin/model/department-dto.model';
import { EmployeePaginationService } from '../component/employee-list/employee-pagination.service';
import { EmployeeDetailsDto } from '../model/employee-details-dto.model';
import { EmployeeEamil } from '../model/employee-email.model';
import { EmployeeList } from '../model/employee-list.model';
import { Employee } from '../model/employee.model';
import { WeekTimeChart } from '../model/week-time-chart.model';
import dayjs from 'dayjs';
import { em } from '@fullcalendar/core/internal-common';

@Injectable({
  providedIn: 'root',
})
export class EmployeeService {
  constructor(
    private httpClient: HttpService,
    public paginationService: EmployeePaginationService,
    private toastr: ToastrService,
    private translateService: TranslateService
  ) {}

  private employeeListSource: BehaviorSubject<Employee[]> = new BehaviorSubject(
    []
  );
  employeeList = this.employeeListSource.asObservable();

  private employeeNameListSource: BehaviorSubject<EmployeeListDto[]> =
    new BehaviorSubject([]);
  employeeNameList = this.employeeNameListSource.asObservable();

  private employeeCountSource: BehaviorSubject<number> = new BehaviorSubject(0);
  employeeCount = this.employeeCountSource.asObservable();

  private getWeekTimeChartSource: BehaviorSubject<WeekTimeChart> =
    new BehaviorSubject(new WeekTimeChart());
  getWeekTimeChart = this.getWeekTimeChartSource.asObservable();

  private getEmployeeDetailsSource: BehaviorSubject<EmployeeDetailsDto> =
    new BehaviorSubject(new EmployeeDetailsDto());
  getEmployeeDetails = this.getEmployeeDetailsSource.asObservable();

  private employeeNameListByDateSource: BehaviorSubject<
    EmployeeListByDateDto[]
  > = new BehaviorSubject([]);
  employeeNameListByDate = this.employeeNameListByDateSource.asObservable();

  addEmployer(employee: Employee): Observable<any> {
    if (employee.StartDate) {
      // add one minute to the date to avoid the date to be the day before
      employee.StartDate = dayjs(employee.StartDate).add(1, 'minute').format();
    }
    employee.Role = +employee.Role;
    if (employee.Id > 0) {
      return this.httpClient
        .requestCall(ApiEndPoints.UpdateEmployee, ApiMethod.POST, employee)
        .pipe(
          tap((res: any) => {
            if (res) {
              this.toastr.success(
                this.translateService.instant('EMPLOYEE UPDATED SUCCESSFULLY'),
                this.translateService.instant('UPDATE EMPLOYEE')
              );
            } else {
              this.toastr.error(
                this.translateService.instant('EMPLOYEE UPDATE FAILED'),
                this.translateService.instant('UPDATE EMPLOYEE')
              );
            }
            this.getEmployeeList();
          })
        );
    }

    return this.httpClient
      .requestCall(ApiEndPoints.AddEmployee, ApiMethod.POST, employee)
      .pipe(
        tap((res: any) => {
          if (res > 0) {
            this.toastr.success(
              this.translateService.instant('EMPLOYEE ADDED SUCCESSFULLY'),
              this.translateService.instant('Add Employer')
            );
          } else {
            this.toastr.error(
              this.translateService.instant('EMPLOYEE ADDED FAILED'),
              this.translateService.instant('Add Employer')
            );
          }
          this.getEmployeeList();
        })
      );
  }

  updateEmployeeEmail(employee: EmployeeEamil): HttpService {
    employee.Role = +employee.Role;
    if (employee.Id > 0) {
      return this.httpClient
        .requestCall(ApiEndPoints.UpdateEmployeeEmail, ApiMethod.POST, employee)
        .subscribe((res: any) => {
          if (res) {
            this.toastr.success(
              this.translateService.instant('EMPLOYEE UPDATED SUCCESSFULLY'),
              this.translateService.instant('UPDATE EMPLOYEE')
            );
          } else {
            this.toastr.error(
              this.translateService.instant('EMPLOYEE UPDATE FAILED'),
              this.translateService.instant('UPDATE EMPLOYEE')
            );
          }
          this.getEmployeeList();
        });
    }
  }

  getEmployeeList(searchKeywords = null, event = null): void {
    const postObject = {
      Pagination: this.paginationService.paginationModel,
      SearchKeywords: searchKeywords,
      SortTable: event?.active,
      SortDirection: event?.direction,
    };

    this.httpClient
      .requestCall(ApiEndPoints.EmployeeList, ApiMethod.POST, postObject)
      .subscribe((res) => {
        const employeeList = res as EmployeeList;
        if (employeeList) {
          // setze die email auf null wenn sie leer ist oder undefined ist
          employeeList.DataList.forEach((element) => {
            if (element.Email === '' || element.Email === undefined) {
              element.Email = null;
            }
          });
          this.employeeListSource.next(employeeList.DataList);
          this.paginationService.setPaginationModel(employeeList.Pagination);
        }
      });
  }

  getEmployeeNameList(): void {
    this.httpClient
      .requestCall(ApiEndPoints.EmployeeNameList, ApiMethod.GET)
      .subscribe((res) => {
        const employeeList = res as EmployeeListDto[];
        this.employeeNameListSource.next(employeeList);
      });
  }

  getEmployeeCount(): void {
    this.httpClient
      .requestCall(ApiEndPoints.EmployeeNameList, ApiMethod.GET)
      .subscribe((res) => {
        const employeeList = res as EmployeeListDto[];
        this.employeeCountSource.next(employeeList.length);
      });
  }

  getEmployeeNameListByDate(dateRange): void {
    this.httpClient
      .requestCall(
        ApiEndPoints.EmployeeNameListByDate,
        ApiMethod.POST,
        dateRange
      )
      .subscribe((res) => {
        const employeeList = res as EmployeeListByDateDto[];
        this.employeeNameListByDateSource.next(employeeList);
      });
  }

  getEmployeeById(id): Employee {
    return this.employeeListSource.value.filter((x) => x.Id === id)[0];
  }

  deleteEmployer(employeeId: number): HttpService {
    if (employeeId > 0) {
      return this.httpClient
        .requestCall(ApiEndPoints.DeleteEmployee, ApiMethod.POST, {
          EmployeeId: employeeId,
        })
        .subscribe((res: any) => {
          this.getEmployeeList();
        });
    }
  }

  activateDeactivateEmployee(
    employeeId: number,
    isActive: boolean,
    activeUntil: Date
  ): HttpService {
    if (employeeId > 0) {
      return this.httpClient
        .requestCall(ApiEndPoints.ActivateDeactivateEmployee, ApiMethod.POST, {
          EmployeeId: employeeId,
          IsActive: isActive,
          ActiveUntil: activeUntil,
        })
        .subscribe((res: any) => {
          this.getEmployeeList();
        });
    }
  }

  // GetEmployeeDetails
  GetEmployeeDetails(): void {
    return this.httpClient
      .requestCall(ApiEndPoints.GetEmployeeDetails, ApiMethod.GET)
      .subscribe((result: EmployeeDetailsDto) => {
        this.getEmployeeDetailsSource.next(result);
      });
  }

  GetInvitationInfo(id) {
    const invitation = {
      InvitationId: id,
    };
    return this.httpClient.requestCall(
      ApiEndPoints.GetInvitationInfo,
      ApiMethod.POST,
      invitation
    );
  }
  GetWeekTimeChart(): void {
    return this.httpClient
      .requestCall(ApiEndPoints.GetWeekTimeChart, ApiMethod.GET)
      .subscribe((result: WeekTimeChart) => {
        this.getWeekTimeChartSource.next(result);
      });
  }
}
