import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import * as dayjs from 'dayjs';
import { Subscription } from 'rxjs';
import { EventType } from 'src/app/core/services/const';
import { CalendarService } from '../../services/calendar.service';
import { OpenShiftsService } from '../../services/open-shifts.service';

@Component({
  selector: 'app-show-work-shift-details',
  templateUrl: './show-work-shift-details.component.html',
  styleUrls: ['./show-work-shift-details.component.css'],
})
export class ShowWorkShiftDetailsComponent implements OnInit, OnDestroy {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<ShowWorkShiftDetailsComponent>,
    private openShiftService: OpenShiftsService,
    private workShiftService: CalendarService
  ) {}
  private subscriptionOpenShiftDetails: Subscription;
  private subscriptionWorkShiftDetails: Subscription;
  shiftDetails: any;
  shiftTime: any;
  dialogTitle: string;
  duration: string;
  break: string;
  employeeDetails: any;
  ngOnInit(): void {
    if (
      this.data &&
      this.data.eventId &&
      this.data.eventType === EventType.OpenShift
    ) {
      this.openShiftService.getOpenShiftDetails(this.data.eventId);
      this.subscriptionOpenShiftDetails =
        this.openShiftService.openShiftDetails.subscribe((details) => {
          this.formatAndSet(details);
          if (details.EmployeeDetails) {
            this.employeeDetails = details.EmployeeDetails;

            this.employeeDetails.forEach((element) => {
              let color = '#008a5db3';
              if (element.TimeAvailablePercent >= 80) {
                color = '#e39e21ba';
              }
              if (element.TimeAvailablePercent > 99) {
                color = '#8d265ab5';
              }

              element.Color = color;
            });
          }
        });
    }

    if (
      this.data &&
      this.data.eventId &&
      this.data.eventType === EventType.WorkShift
    ) {
      this.workShiftService.getWorkShiftDetails(this.data.eventId);
      this.subscriptionWorkShiftDetails =
        this.workShiftService.workShiftDetails.subscribe((details) => {
          this.formatAndSet(details);
        });
    }
  }
  ngOnDestroy(): void {
    if (this.data.eventType === EventType.OpenShift) {
      this.subscriptionOpenShiftDetails.unsubscribe();
    }
    if (this.data.eventType === EventType.WorkShift) {
      this.subscriptionWorkShiftDetails.unsubscribe();
    }
  }

  formatAndSet(details): void {
    this.shiftDetails = {
      EmployeeName: details.EmployeeName,
      Department: details.Department,
      IconCode: details.IconCode,
      EventId: details.EventId,
      Break: details.Break,
    };
    const StarTime = new Date(details.StarTime);
    this.dialogTitle = StarTime.toLocaleDateString(navigator.language, {
      month: '2-digit',
      day: '2-digit',
    });

    details.StarTime = StarTime.toLocaleTimeString(navigator.language, {
      hour: '2-digit',
      minute: '2-digit',
    });
    const EndTime = new Date(details.EndTime);
    details.EndTime = EndTime.toLocaleTimeString(navigator.language, {
      hour: '2-digit',
      minute: '2-digit',
    });

    // Calculate duration between StartTime and EndTime
    const duration = dayjs.duration(dayjs(EndTime).diff(dayjs(StarTime)));

    // Assuming breakDuration represents the duration of the break in minutes
    const breakDuration = details.Break; // Example: 60 minutes
    let netDuration = duration;
    if (breakDuration) {
      netDuration = duration.subtract(breakDuration, 'minutes');
    }

    // Calculate hours and minutes from net duration
    const netHours = Math.floor(netDuration.asHours());
    const netMinutes = Math.round(netDuration.asMinutes() % 60);

    // Format hours and minutes
    const formattedHours = netHours < 10 ? '0' + netHours : netHours;
    const formattedMinutes = netMinutes < 10 ? '0' + netMinutes : netMinutes;

    // Combine formatted hours and minutes
    this.duration = `${formattedHours}:${formattedMinutes}`;

    this.break = this.convertMinutesToTime(details.Break);

    this.shiftTime = { EndTime: details.EndTime, StarTime: details.StarTime };
  }

  convertMinutesToTime(minutes: number): string {
    if (isNaN(minutes) || minutes < 0) {
      return '00:00';
    }

    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;

    const hoursStr = hours < 10 ? '0' + hours : hours;
    const minutesStr =
      remainingMinutes < 10 ? '0' + remainingMinutes : remainingMinutes;

    return `${hoursStr}:${minutesStr}`;
  }

  adoptOpenShiftInWorkShift(openShiftId, employeeId): void {
    this.openShiftService.adoptOpenShiftInWorkShift(openShiftId, employeeId);
  }

  onSubmit(): void {}

  onClose(): void {
    this.dialogRef.close();
  }
}
