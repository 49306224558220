export class HolidaysChart {
  CurrentEntitlement: number; // Aktueller Anspruch
  RemainingLeaveLastYear: number; // Resturlaub Vorjahr
  TotalEntitlement: number; // Aktueller Anspruch + Resturlaub
  Approved: number; // Genehmigte Urlaubstage
  Planned: number; // Geplante Urlaubstage
  BookedCurrentYear: number; // Gebuchte Urlaubstage im aktuellen Jahr (Genehmigt)
  RemainingLeaveCurrentYear: number; // Resturlaub aktuelles Jahr
  SickDays: number; // Krankheitstage
}
