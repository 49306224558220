<div class="container">
  <form [formGroup]="filterForm" (ngSubmit)="applyFilter()">
    <div class="d-flex flex-nowrap">
      <div class="form-group flex-fill mb-3">
        <mat-label>{{ "Employee" | translate }}</mat-label>
        <mat-select
          formControlName="employee"
          class="form-control"
          (selectionChange)="onInputChange('employee')"
        >
          <mat-option>
            <ngx-mat-select-search
              [formControl]="employeeFilterCtrl"
            ></ngx-mat-select-search>
          </mat-option>
          <mat-option [value]="null">{{
            "NO SELECTION" | translate
          }}</mat-option>
          <ng-container *ngFor="let employee of filteredEmployees | async">
            <mat-option [value]="employee.Id">{{ employee.Name }}</mat-option>
          </ng-container>
        </mat-select>
      </div>
      <div class="form-group flex-fill mb-3">
        <mat-label>{{ "Department" | translate }}</mat-label>
        <mat-select
          class="form-control"
          formControlName="department"
          multiple
          (selectionChange)="onInputChange('department')"
        >
          <mat-option
            *ngFor="let department of departmentList"
            [value]="department.Id"
            >{{ department.Name }}
          </mat-option>
        </mat-select>
      </div>
      <div class="form-group flex-fill mb-3">
        <mat-label>{{ "Start Date" | translate }}</mat-label>
        <div class="input-group mb-3">
          <div class="input-group-prepend">
            <span
              (click)="startPicker.open()"
              class="input-group-text"
              id="start-time-icon"
              style="height: 40px"
            >
              <i class="fa-light fa-calendar-days"></i>
            </span>
          </div>
          <input
            matInput
            class="form-control"
            [matDatepicker]="startPicker"
            formControlName="startDate"
            (click)="startPicker.open()"
            (dateChange)="onInputChange('startDate')"
          />
          <mat-datepicker #startPicker></mat-datepicker>
        </div>
      </div>
      <div class="form-group flex-fill mb-3">
        <mat-label>{{ "End Date" | translate }}</mat-label>
        <div class="input-group mb-3">
          <div class="input-group-prepend">
            <span
              (click)="endPicker.open()"
              class="input-group-text"
              id="end-time-icon"
              style="height: 40px"
            >
              <i class="fa-light fa-calendar-days"></i>
            </span>
          </div>
          <input
            matInput
            class="form-control"
            [matDatepicker]="endPicker"
            formControlName="endDate"
            (click)="endPicker.open()"
            (dateChange)="onInputChange('endDate')"
          />
          <mat-datepicker #endPicker></mat-datepicker>
        </div>
      </div>
    </div>
    <div
      *ngIf="filterForm.hasError('dates')"
      class="alert alert-danger col-12"
      role="alert"
    >
      {{ "Start date should be less than end date" | translate }}
    </div>
  </form>
</div>
