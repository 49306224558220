<div class="container content-center">
  <div class="row">
    <div class="col-sm-8 col-md-6 col-lg-4 mx-auto">
      <div class="card border-0 shadow rounded-3 my-5">
        <div class="card-body">
          <img class="center" style="padding-top: 30px;" src="../../../../../assets/img/Logo.PNG" alt="" width="152"
            height="80">
          <form #form='ngForm' (submit)="onSubmit(form)">
            <div style="padding-top: 30px;" class="form-group mb-3">
              <label for="floatingInput" style="color: var(--font-color)">{{'Email'| translate}}</label>
              <input type="email" class="form-control" id="floatingInput" placeholder="name@example.com"
                #Email="ngModel" name="Email" [(ngModel)]="formModel.Email">
            </div>
            <div style="padding-top: 50px; font-size: small;" class="mb-3 row">
              <div class="d-grid">
                <button class="btn btn-primary btn-lg btn-login bi bi-lock fa-lg" type="submit">
                  {{'RESET PASSWORD'| translate}}</button>
              </div>
            </div>
            <div class="text-center">
              <a style="color: var(--font-color); font-size: small; text-align: center;" [routerLink]="['/login']">
                {{'BACK TO LOGIN'| translate}}
              </a>
            </div>
            <div *ngIf="errorStatus.showError" class="alert alert-danger" role="alert">
              {{errorStatus.errorMessage}}
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>