<div class="modal-lg" role="document">
  <div class="modal-content">
    <div class="modal-header text-center">
      <h5 class="modal-title w-100" id="exampleModalCenterTitle">{{'SHIFT SWAP REQUEST' | translate}}</h5>
    </div>
    <div class="modal-body">
      <!-- <div class="text-center mb-2">
        <mat-radio-group [(ngModel)]="swapType" (change)="onSwapTypeChange($event)">
          <mat-radio-button value="open" class="custom-radio-button">
            {{'OPEN SWAP REQUEST' | translate}}
          </mat-radio-button>
          <mat-radio-button value="targeted" class="ml-3 custom-radio-button">
            {{'TARGETED REQUEST' | translate}}
          </mat-radio-button>
        </mat-radio-group>
      </div> -->
      <div class="modal-lg" role="document">
        <div class="modal-content">
          <div class="text-center p-2">
            <span>{{'LIST OF AVAILABLE SHIFTS FOR SWAPPING' | translate}}</span>
          </div>
          <div class="modal-body" style="max-height: 60vh; overflow-y: auto; padding: 10px;">
            <div *ngFor="let shift of potentialShiftSwappersList" class="event-animation-scale">
              <app-item-list [Title]="shift.EmplyeeFullName"
                [Content]="shift.DateString + ' ' + shift.StartTime + ' - ' + shift.EndTime" [ContentColor]="'#0A4D68'"
                [BadgeContent]="'SWAP' | translate" [Icon]="shift.IconCode" [buttonClass]="'btn btn-outline-success'"
                [buttonIcon]="'fal fa-sync-alt'" (onClick)="selectShiftSwap(shift.Id)"></app-item-list>
            </div>
          </div>
        </div>
      </div>

      <!-- <div *ngIf="swapType === 'open'" class="text-center mb-3">
        <div class="text-center p-2">
          <span>{{'BY SELECTING OPEN REQUEST' | translate}}</span>
        </div>
      </div> -->
      <div class="modal-footer d-flex justify-content-between" style="padding: 15px;">
        <button class="btn btn-outline-secondary" (click)="onClose()" type="button" data-dismiss="modal">
          {{'Close' | translate}}
        </button>
        <!-- <button class="btn btn-primary" form="addEmployee" type="submit" (click)="onSubmit()"
          [disabled]="swapType === 'targeted' && !EventIdForSwap">
          {{ swapType === 'open' ? ('SEND OPEN REQUEST' | translate) : ('SEND TARGETED REQUEST' | translate) }}
        </button> -->
        <button class="btn btn-primary" form="addEmployee" type="submit" (click)="onSubmit()"
          [disabled]="!EventIdForSwap">
          {{ ('SEND TARGETED REQUEST' | translate) }}
        </button>
      </div>
    </div>
  </div>