import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, filter, finalize, switchMap, take } from 'rxjs/operators';
import { LoginErrorType } from 'src/app/core/services/const';
import { LoaderService } from 'src/app/core/services/loader/loader.service';
import { AuthService } from '../auth/auth.service';
import { AuthResponseDto } from '../auth/response/authResponseDto.model';
// https://www.jerriepelser.com/blog/share-translations-between-aspnet-mvc-and-angularjs/
@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );
  constructor(
    private router: Router,
    public loaderService: LoaderService,
    private authService: AuthService
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let authReq = req;
    const token = localStorage.getItem('token');
    if (token != null) {
      authReq = this.addTokenHeader(req, token);
    }

    this.loaderService.isLoading.next(
      this.loaderService.isLoading.getValue() + 1
    );

    return next.handle(authReq).pipe(
      finalize(() =>
        this.loaderService.isLoading.next(
          this.loaderService.isLoading.getValue() - 1
        )
      ),
      catchError<HttpEvent<any>, Observable<HttpEvent<any>>>((error) => {
        if (error.error?.ErrorType === LoginErrorType.RefreshTokenExpired) {
          localStorage.removeItem('token');
          this.router.navigateByUrl('/login');
          return throwError(error);
        }

        if (
          error instanceof HttpErrorResponse &&
          !authReq.url.includes('/ApplicationUser/Login') &&
          error.status === 401
        ) {
          return this.handle401Error(authReq, next);
        }

        return throwError(error);
      })
    );
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);
      const token = localStorage.getItem('token');

      if (token) {
        return this.authService.tryRefreshingTokens(token).pipe(
          switchMap((token: AuthResponseDto) => {
            this.isRefreshing = false;

            this.refreshTokenSubject.next(token.Token);

            return next.handle(this.addTokenHeader(request, token.Token));
          }),
          catchError((err) => {
            this.isRefreshing = false;
            localStorage.removeItem('token');
            this.router.navigateByUrl('/login');
            return throwError(err);
          })
        );
      } else {
        this.isRefreshing = false;
        this.router.navigateByUrl('/login');
      }
    }

    return this.refreshTokenSubject.pipe(
      filter((token) => token !== null),
      take(1),
      switchMap((token) =>
        next.handle(this.addTokenHeader(request, token as string))
      )
    );
  }

  private addTokenHeader(request: HttpRequest<any>, token: string) {
    return request.clone({
      headers: request.headers.set('Authorization', 'Bearer ' + token),
    });
  }
}
