<div class="container content-center">
  <div class="row">
    <div class="col-sm-8 col-md-6 col-lg-4 mx-auto">
      <div class="card shadow-lg">
        <div class="card-body">
          <img class="center" style="padding-top: 30px;" src="../../../../../assets/img/Logo.PNG" alt="" width="152"
            height="80">
          <h5 class="card-title text-center mb-5 fw-light fs-5" style="padding-top: 15px;">
            {{'RESET PASSWORD'| translate}}
          </h5>
          <form [formGroup]="resetPasswordForm" autocomplete="off" novalidate
            (ngSubmit)="resetPassword(resetPasswordForm.value)">

            <div class="form-group mb-3">
              <label for="password" style="color: var(--font-color)">{{'New Password'| translate}}</label>
              <input autocomplete="new-password" type="password" id="password" formControlName="password"
                class="form-control" />
              <em *ngIf="validateControl('password') && hasError('password', 'required')">
                {{'PASSWORD IS REQUIRED'| translate}}</em>
            </div>
            <div class="form-group mb-3">
              <label for="confirm" style="color: var(--font-color)">{{'Confirm New Password'| translate}}</label>
              <input autocomplete="new-password" type="password" id="confirm" formControlName="confirm"
                class="form-control" />
              <em *ngIf="validateControl('confirm') && hasError('confirm', 'required')">
                {{'CONFIRMATION IS REQUIRED'| translate}}</em>
              <em *ngIf="hasError('confirm', 'mustMatch')">{{'PASSWORDS MUST MATCH'| translate}}</em>
            </div>
            <div class="mb-3 row">
              <div class="d-grid">
                <button type="submit" class="btn btn-primary btn-login bi bi-info-square fa-lg"
                  [disabled]="!resetPasswordForm.valid"> {{'SUBMIT'| translate}}</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>