<div class="row" style="padding-top: 15px;">
  <div id="section-to-print">
    <div class="row justify-content-md-center">
      <div class="hidden-mobile col-xl-1" style="padding-right: 0px; padding-left: 0px;">
        <!-- <app-employee-list-calender></app-employee-list-calender> -->
        <app-calendar-week-list-view></app-calendar-week-list-view>
      </div>
      <div class="show-full-width col-xl-11">
        <app-work-shifts-planner-timeline></app-work-shifts-planner-timeline>
      </div>
    </div>
  </div>
</div>