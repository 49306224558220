import {
  Component,
  Input,
  OnInit,
  ElementRef,
  ViewChild,
  Output,
  EventEmitter,
  OnDestroy,
} from '@angular/core';
import { OnboardingStep } from './onboarding-step.model';
import { OnboardingAnchorDirective } from './onboarding-anchor.directive';

@Component({
  selector: 'app-onboarding',
  templateUrl: './onboarding.component.html',
  styleUrls: ['./onboarding.component.css'],
})
export class OnboardingComponent implements OnInit, OnDestroy {
  @Input() step: OnboardingStep;
  @Output() next = new EventEmitter<void>();
  @ViewChild('tooltip') tooltip: ElementRef;

  private targetElement: HTMLElement;

  ngOnInit(): void {
    this.targetElement = document.querySelector(
      `[onboardingAnchor="${this.step.anchorId}"]`
    ) as HTMLElement;
    if (this.targetElement) {
      this.positionTooltip();
    } else {
      console.error(
        `Onboarding anchor with id '${this.step.anchorId}' not found.`
      );
      this.next.emit();
    }
  }

  ngOnDestroy(): void {
    // Optional: Bereinigung bei Bedarf
  }

  positionTooltip(): void {
    const targetRect = this.targetElement.getBoundingClientRect();
    const tooltipElem = this.tooltip.nativeElement as HTMLElement;

    // Tooltip erstmal unsichtbar machen, um genaue Maße zu erhalten
    tooltipElem.style.visibility = 'hidden';
    tooltipElem.style.display = 'block';

    const tooltipRect = tooltipElem.getBoundingClientRect();

    // Positionierung basierend auf dem gewünschten Placement
    let top = 0;
    let left = 0;

    switch (this.step.placement) {
      case 'left':
        top =
          targetRect.top +
          window.scrollY +
          (targetRect.height - tooltipRect.height) / 2;
        left = targetRect.left + window.scrollX - tooltipRect.width - 10; // 10px Abstand
        break;
      case 'right':
        top =
          targetRect.top +
          window.scrollY +
          (targetRect.height - tooltipRect.height) / 2;
        left = targetRect.right + window.scrollX + 10;
        break;
      case 'top':
        top = targetRect.top + window.scrollY - tooltipRect.height - 10;
        left =
          targetRect.left +
          window.scrollX +
          (targetRect.width - tooltipRect.width) / 2;
        break;
      case 'bottom':
      default:
        top = targetRect.bottom + window.scrollY + 10;
        left =
          targetRect.left +
          window.scrollX +
          (targetRect.width - tooltipRect.width) / 2;
        break;
    }

    tooltipElem.style.top = `${top}px`;
    tooltipElem.style.left = `${left}px`;
    tooltipElem.style.visibility = 'visible';
  }

  onNext(): void {
    this.next.emit();
  }
}
