import { Component, Input, OnInit } from '@angular/core';
import { EChartsOption } from 'echarts';
import { CalendarService } from 'src/app/modules/admin/services/calendar.service';
import { CalendarWeek } from '../../../modules/admin/model/CalendarWeek';

@Component({
  selector: 'app-calendar-week-view',
  templateUrl: './calendar-week-view.component.html',
  styleUrls: ['./calendar-week-view.component.css'],
})
export class CalendarWeekViewComponent implements OnInit {
  chartOption: EChartsOption;
  @Input() fromParent: CalendarWeek;
  @Input() isHorizontal: boolean = false;
  constructor(private service: CalendarService) {}

  ngOnInit(): void {
    const gaugeData = [];
    const colorPalette = [];

    if (this.fromParent.PublishedOrUnpublishedShiftPercent !== 0) {
      gaugeData.push({
        value: this.fromParent.PublishedOrUnpublishedShiftPercent,
        detail: {
          valueAnimation: true,
          offsetCenter: ['0%', '-15%'],
        },
      });
      colorPalette.push('#6bbaf7');
    }

    if (this.fromParent.AcceptedFromEmployee !== 0) {
      gaugeData.push({
        value: this.fromParent.AcceptedFromEmployee,
        detail: {
          valueAnimation: true,
          offsetCenter: ['0%', '15%'],
        },
      });
      colorPalette.push('#00695c');
    }

    if (this.fromParent.RejectedFromEmployee !== 0) {
      gaugeData.push({
        value: this.fromParent.RejectedFromEmployee,
        detail: {
          valueAnimation: true,
          offsetCenter: ['0%', '50%'],
        },
      });
      colorPalette.push('#882709');
    }

    this.chartOption = {
      color: colorPalette,
      grid: {
        left: 0,
        top: 30,
        right: 0,
        bottom: 0,
      },
      series: [
        {
          radius: '100%',
          type: 'gauge',
          startAngle: 90,
          endAngle: -270,
          pointer: {
            show: false,
          },
          progress: {
            show: true,
            overlap: false,
            roundCap: true,
            clip: false,
            itemStyle: {
              borderWidth: 1,
              borderColor: '#fff',
            },
          },
          axisLine: {
            lineStyle: {
              width: 22,
            },
          },
          splitLine: {
            show: false,
            distance: 0,
            length: 10,
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            show: false,
            distance: 50,
          },
          data: gaugeData,
          detail: {
            width: 50,
            height: 14,
            fontSize: 13,
            color: 'auto',
            borderColor: 'auto',
            formatter: '{value}%',
          },
        },
      ],
    };
    if (gaugeData.length === 1) {
      this.chartOption.series[0].axisLine.lineStyle.width = 10;
    }
    if (gaugeData.length === 2) {
      this.chartOption.series[0].axisLine.lineStyle.width = 20;
    }
    if (gaugeData.length === 3) {
      this.chartOption.series[0].axisLine.lineStyle.width = 30;
    }
    if (gaugeData.length === 4) {
      this.chartOption.series[0].axisLine.lineStyle.width = 35;
    }
  }

  calenderWeekClick(): void {
    this.service.setCurrentCalenderWeek(
      this.fromParent.StartTime,
      this.isHorizontal
    );
  }
}
