import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { ConfirmationDialogComponent } from 'src/app/core/component/confirmation-dialog/confirmation-dialog.component';
import { BottomNavigationEmployeeService } from 'src/app/navigation/bottom-navigation-employee/bottom-navigation-employee.service';
import { OpenShiftsService } from '../../services/open-shifts.service';

@Component({
  selector: 'app-open-work-shift-list',
  templateUrl: './open-work-shift-list.component.html',
  styleUrls: ['./open-work-shift-list.component.css'],
})
export class OpenWorkShiftListComponent implements OnInit {
  constructor(
    private service: OpenShiftsService,
    private dialog: MatDialog,
    private translate: TranslateService,
    private bottomNavigationEmployeeService: BottomNavigationEmployeeService
  ) {}
  private subscriptionOpenShiftList: Subscription;

  @Output() showListInDashbord = new EventEmitter<boolean>(false);

  dialogRef: MatDialogRef<ConfirmationDialogComponent>;

  openShiftList: any[];
  ngOnInit(): void {
    this.service.getOpenShiftsForEmployee();
    this.showListInDashbord.emit(false);
    this.subscriptionOpenShiftList =
      this.service.employeeOpenShiftList.subscribe((list) => {
        const cloned = list.map((x) => Object.assign({}, x));
        cloned.forEach((entry) => {
          const StarTimeTitle = new Date(entry.StarTime);
          //entry.Title =
          entry.Date = StarTimeTitle.toLocaleDateString(navigator.language, {
            month: 'long',
            day: '2-digit',
            weekday: 'short',
          });
          const options: any = {
            hour: '2-digit',
            minute: '2-digit',
          };
          const StarTime = new Date(entry.StarTime);
          entry.StarTime = StarTime.toLocaleTimeString(
            navigator.language,
            options
          );

          const EndTime = new Date(entry.EndTime);
          entry.EndTime = EndTime.toLocaleTimeString(
            navigator.language,
            options
          );
        });
        if (cloned.length) {
          this.showListInDashbord.emit(true);
        } else {
          this.showListInDashbord.emit(false);
        }
        this.openShiftList = cloned;
        this.bottomNavigationEmployeeService.setNotificationBadge(
          this.openShiftList.length > 0
        );
      });
  }

  addEmployeeToOpenShift(id): void {
    this.dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      disableClose: false,
      panelClass: 'custom-dialog-container',
    });
    this.dialogRef.componentInstance.confirmMessage = this.translate.instant(
      'Would-you-like-to-apply-for-this-shift'
    );

    this.dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.service.addEmployeeToOpenShift(id);
      }
      this.dialogRef = null;
    });
  }
}
