import {
  Directive,
  Input,
  ElementRef,
  OnInit,
  OnDestroy,
  HostListener,
} from '@angular/core';
import { OnboardingService } from './onboarding.service';
import { Subscription } from 'rxjs';
import {
  Overlay,
  OverlayRef,
  ConnectedPosition,
  OverlayPositionBuilder,
} from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { OnboardingTooltipComponent } from './onboarding-tooltip.component';
import { OnboardingStep } from './onboarding-step.model';

@Directive({
  selector: '[onboardingAnchor]',
})
export class OnboardingTooltipDirective implements OnInit, OnDestroy {
  @Input('onboardingAnchor') anchorId: string;

  private overlayRef: OverlayRef;
  private subscription: Subscription;

  constructor(
    private elementRef: ElementRef,
    private onboardingService: OnboardingService,
    private overlay: Overlay,
    private overlayPositionBuilder: OverlayPositionBuilder
  ) {}

  ngOnInit(): void {
    this.onboardingService.registerAnchor(
      this.anchorId,
      this.elementRef.nativeElement
    );

    this.subscription = this.onboardingService.showTooltip$.subscribe(
      (step: OnboardingStep) => {
        if (step.anchorId === this.anchorId) {
          this.showTooltip(step);
        }
      }
    );
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    this.hideTooltip();
  }

  @HostListener('focusin')
  onFocus() {
    this.onboardingService.triggerStep(this.anchorId);
  }

  @HostListener('click')
  onInteraction() {
    this.onboardingService.triggerStep(this.anchorId);
  }

  @HostListener('focusout')
  onBlur(target) {
    this.hideTooltip();
  }

  private showTooltip(step: OnboardingStep): void {
    this.hideTooltip();

    const positionStrategy = this.getPositionStrategy(step);

    this.overlayRef = this.overlay.create({ positionStrategy });

    const tooltipPortal = new ComponentPortal(OnboardingTooltipComponent);
    const tooltipRef = this.overlayRef.attach(tooltipPortal);
    tooltipRef.instance.title = step.title;
    tooltipRef.instance.content = step.content;
    tooltipRef.instance.placement = step.placement; // Platzierung übergeben

    // Subscribe to the close event
    const closeSub = tooltipRef.instance.closeTooltip.subscribe(() => {
      this.hideTooltip();
      // Optionally remove focus from the input field
      this.elementRef.nativeElement.blur();
      closeSub.unsubscribe();
    });
  }

  private hideTooltip(): void {
    if (this.overlayRef) {
      this.overlayRef.detach();
      this.overlayRef = null;
    }
  }

  private getPositionStrategy(step: OnboardingStep) {
    const positions: ConnectedPosition[] = this.getConnectedPositions(
      step.placement
    );
    return this.overlayPositionBuilder
      .flexibleConnectedTo(this.elementRef)
      .withPositions(positions)
      .withPush(false);
  }

  private getConnectedPositions(
    placement: 'left' | 'right' | 'top' | 'bottom' = 'right'
  ): ConnectedPosition[] {
    switch (placement) {
      case 'left':
        return [
          {
            originX: 'start',
            originY: 'center',
            overlayX: 'end',
            overlayY: 'center',
            offsetX: -10,
          },
        ];
      case 'right':
        return [
          {
            originX: 'end',
            originY: 'center',
            overlayX: 'start',
            overlayY: 'center',
            offsetX: 10,
          },
        ];
      case 'top':
        return [
          {
            originX: 'center',
            originY: 'top',
            overlayX: 'center',
            overlayY: 'bottom',
            offsetY: -10,
          },
        ];
      case 'bottom':
      default:
        return [
          {
            originX: 'center',
            originY: 'bottom',
            overlayX: 'center',
            overlayY: 'top',
            offsetY: 10,
          },
        ];
    }
  }
}
