import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { BottomNavigationEmployeeService } from 'src/app/navigation/bottom-navigation-employee/bottom-navigation-employee.service';
import { EmployeeShiftSwap } from '../../models/employee-shift-swap';
import { ShiftSwapService } from '../../services/shift-swap.service';
import { ShowTargetedSwapStatusComponent } from '../show-targeted-swap-status/show-targeted-swap-status.component';

@Component({
  selector: 'app-admin-show-shifts-in-swapping-process',
  templateUrl: './admin-show-shifts-in-swapping-process.component.html',
  styleUrls: ['./admin-show-shifts-in-swapping-process.component.css'],
})
export class AdminShowShiftSInSwappingProcessComponent
  implements OnInit, OnDestroy
{
  private subscriptionEmployeeSwapShiftsList: Subscription;
  public swapShiftList = new Array<EmployeeShiftSwap>();

  constructor(
    private shiftSwapService: ShiftSwapService,
    private dialog: MatDialog,
    private bottomNavigationEmployeeService: BottomNavigationEmployeeService
  ) {}

  @Output() showListInDashbord = new EventEmitter<boolean>(false);

  ngOnInit(): void {
    this.showListInDashbord.emit(false);
    this.subscriptionEmployeeSwapShiftsList =
      this.shiftSwapService.employeeSwapProcessShiftList.subscribe(
        (list: EmployeeShiftSwap[]) => {
          list.forEach((entry) => {
            const StarTimeTitle = new Date(entry.StartTime);
            //entry.Title =
            entry.DateString = StarTimeTitle.toLocaleDateString(
              navigator.language,
              {
                month: 'long',
                day: '2-digit',
                weekday: 'short',
              }
            );
            const options: any = {
              hour: '2-digit',
              minute: '2-digit',
            };
            const StarTime = new Date(entry.StartTime);
            entry.StartTime = StarTime.toLocaleTimeString(
              navigator.language,
              options
            );

            const EndTime = new Date(entry.EndTime);
            entry.EndTime = EndTime.toLocaleTimeString(
              navigator.language,
              options
            );
          });

          this.swapShiftList = list;
          if (this.swapShiftList.length > 0) {
            this.showListInDashbord.emit(true);
          }
          this.bottomNavigationEmployeeService.setNotificationBadge(
            this.swapShiftList.length > 0
          );
        }
      );

    this.shiftSwapService.GetAllEmplyeesSwappingProcessQuery();
  }

  ngOnDestroy(): void {
    this.subscriptionEmployeeSwapShiftsList.unsubscribe();
  }

  showResponsedSwap(swapShiftId): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.panelClass = 'custom-dialog-container';
    dialogConfig.data = { swapShiftId };
    this.dialog.open(ShowTargetedSwapStatusComponent, dialogConfig);
  }
}
