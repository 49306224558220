import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ApiEndPoints, ApiMethod } from 'src/app/core/services/const';
import { HttpService } from 'src/app/core/services/http/http.service';
import { CompanySettings } from '../model/company-settings.model';

@Injectable({
  providedIn: 'root',
})
export class CompanySettingsService {
  CompanySettings: CompanySettings = new CompanySettings();
  private CompanySettingsSource: BehaviorSubject<CompanySettings> =
    new BehaviorSubject(new CompanySettings());
  CompanySettingsObservable = this.CompanySettingsSource.asObservable();

  constructor(private httpClient: HttpService) {
    this.CompanySettings = new CompanySettings();
  }

  getCompanySettings(): void {
    const settings = localStorage.getItem('CompanySettings');
    if (
      settings !== null &&
      settings !== undefined &&
      settings !== '' &&
      settings !== 'undefined' &&
      settings !== 'null'
    ) {
      this.CompanySettings = CompanySettings.fromStorage(JSON.parse(settings));
      this.CompanySettingsSource.next(this.CompanySettings);
    }

    if (!localStorage.getItem('token')) {
      return;
    }

    this.httpClient
      .requestCall(ApiEndPoints.GetCompanySettings, ApiMethod.GET)
      .subscribe((settings: CompanySettings) => {
        this.CompanySettings = CompanySettings.fromStorage(settings);
        localStorage.setItem(
          'CompanySettings',
          JSON.stringify(this.CompanySettings)
        );
        this.CompanySettingsSource.next(this.CompanySettings);
      });
  }

  initGetCompanySettings(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.httpClient
        .requestCall(ApiEndPoints.GetCompanySettings, ApiMethod.GET)
        .subscribe((settings: CompanySettings) => {
          this.CompanySettings = CompanySettings.fromStorage(settings);
          localStorage.setItem(
            'CompanySettings',
            JSON.stringify(this.CompanySettings)
          );
          this.CompanySettingsSource.next(this.CompanySettings);
          resolve(true);
        });
    });
  }

  updateCompanySettings(settings: CompanySettings): void {
    this.CompanySettings = settings;
    this.httpClient
      .requestCall(ApiEndPoints.UpdateCompanySettings, ApiMethod.PUT, {
        CompanySettings: settings,
      })
      .subscribe(() => {
        this.CompanySettingsSource.next(settings);
      });
  }
}
