import { Directive, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[onboardingAnchor]',
})
export class OnboardingAnchorDirective {
  @Input('onboardingAnchor') anchorId: string;

  constructor(public elementRef: ElementRef) {}
}
