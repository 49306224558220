import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { FormatString } from 'src/app/core/_helpers';
import { ConfirmationDialogComponent } from 'src/app/core/component/confirmation-dialog/confirmation-dialog.component';
import { CalendarService } from 'src/app/modules/admin/services/calendar.service';
import { BottomNavigationEmployeeService } from 'src/app/navigation/bottom-navigation-employee/bottom-navigation-employee.service';

export interface WorkShift {
  dateLong: any;
  endTime: any;
  startTime: any;
  eventId: string;
  skillIcons: string;
  hour: string;
  date: string;
  timeRange: string;
  accepted: boolean | null;
}

@Component({
  selector: 'app-show-not-accepted-events',
  templateUrl: './show-not-accepted-events.component.html',
  styleUrls: ['./show-not-accepted-events.component.css'],
})
export class ShowNotAcceptedEventsComponent implements OnInit, OnDestroy {
  displayedColumns: string[] = ['select', 'skill', 'date', 'timeRange', 'hour'];
  selection = new SelectionModel<WorkShift>(true, []);
  private subscriptionnotAcceptedEventList: Subscription;

  requestList = [];
  showAnimation = true;
  dialogRef: MatDialogRef<ConfirmationDialogComponent>;

  constructor(
    public calendarService: CalendarService,
    private dialog: MatDialog,
    private translate: TranslateService,
    private bottomNavigationEmployeeService: BottomNavigationEmployeeService
  ) {}

  ngOnInit(): void {
    this.showAnimation = true;
    this.calendarService.getNotAcceptedEventFromEmployee();
    this.subscriptionnotAcceptedEventList =
      this.calendarService.notAcceptedEventList.subscribe((list) => {
        this.requestList = list.map(
          (x) =>
            ({
              eventId: x.eventId,
              skillIcons: x.icon,
              accepted: null,
              hour: this.getHours(x.start, x.end),
              date: x.start.toLocaleDateString(navigator.language, {
                month: 'long',
                day: '2-digit',
                weekday: 'short',
              }),
              dateLong: x.start.toLocaleDateString(navigator.language, {
                month: 'long',
                day: '2-digit',
                weekday: 'long',
              }),
              startTime: x.start.toLocaleTimeString(navigator.language, {
                hour: '2-digit',
                minute: '2-digit',
              }),
              endTime: x.end.toLocaleTimeString(navigator.language, {
                hour: '2-digit',
                minute: '2-digit',
              }),
              timeRange:
                x.start.toLocaleTimeString(navigator.language, {
                  hour: '2-digit',
                  minute: '2-digit',
                }) +
                ' -> ' +
                x.end.toLocaleTimeString(navigator.language, {
                  hour: '2-digit',
                  minute: '2-digit',
                }),
            } as WorkShift)
        );
        this.bottomNavigationEmployeeService.setNotificationBadge(
          this.requestList.length > 0
        );
      });
  }

  ngOnDestroy(): void {
    this.subscriptionnotAcceptedEventList.unsubscribe();
  }

  onSubmit(): void {}
  getHours(from: Date, to: Date): string {
    let diff = to.getTime() - from.getTime();

    const hours = Math.floor(diff / (1000 * 60 * 60));
    diff -= hours * (1000 * 60 * 60);

    const mins = Math.floor(diff / (1000 * 60));
    diff -= mins * (1000 * 60);

    return hours + ':' + mins;
  }

  acceptEvent(event: WorkShift): void {
    this.dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      disableClose: false,
      panelClass: 'custom-dialog-container',
    });
    this.dialogRef.componentInstance.confirmMessage = FormatString(
      this.translate.instant(
        'CAN YOU CONFIRM THAT YOU WILL BE ABLE TO WORK BETWEEN'
      ),
      [event.dateLong, event.startTime, event.endTime]
    );

    this.dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        event.accepted = true;
        this.showAnimation = false;
        this.calendarService.workShiftListRequest([
          { EventId: event.eventId, AcceptedStatus: true },
        ]);
      }
      this.dialogRef = null;
    });
  }

  rejectEvent(event: WorkShift): void {
    this.dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      disableClose: false,
      panelClass: 'custom-dialog-container',
    });
    this.dialogRef.componentInstance.confirmMessage = FormatString(
      this.translate.instant(
        'CAN YOU CONFIRM THAT YOU WILL NOT BE ABLE TO WORK ON'
      ),
      [event.dateLong, event.startTime, event.endTime]
    );

    this.dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        event.accepted = false;
        this.showAnimation = false;
        this.calendarService.workShiftListRequest([
          { EventId: event.eventId, AcceptedStatus: false },
        ]);
      }
      this.dialogRef = null;
    });
  }
}
