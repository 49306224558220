export const environment = {
  imagesURL: 'https://api-test.fixplaner.com/Resources/Images/',
  apiURL: 'https://api-test.fixplaner.com/api',
  production: true,
  firebase: {
    apiKey: 'AIzaSyDX2Jl1827loptDPY1gL4-T2kGcSGMWSuQ',
    authDomain: 'fixplaner.firebaseapp.com',
    projectId: 'fixplaner',
    storageBucket: 'fixplaner.appspot.com',
    messagingSenderId: '414473334369',
    appId: '1:414473334369:web:595e351ab22f2307b88420',
  },
  matomo: {
    url: 'https://analytics.fixplaner.com/',
    siteId: 1,
  },
};
// apiURL: 'https://xplanner-dev.azurewebsites.net/api',
// apiURL: 'https://localhost:44362/api',
