import { Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';
import { SwPush } from '@angular/service-worker';
import { mergeMapTo } from 'rxjs/operators';
import { ApiEndPoints, ApiMethod } from '../const';
import { HttpService } from '../http/http.service';
@Injectable({
  providedIn: 'root',
})
export class PushNotificationsService {
  constructor(
    private afMessaging: AngularFireMessaging,
    private httpClient: HttpService,
    private swPush: SwPush
  ) {
    try {
      if (this.swPush.isEnabled) {
        this.swPush.notificationClicks.subscribe(
          (event) => {
            window.focus();
            window.open(event.notification.data.url, '_self');
          },
          (error) => {
            // handle error
          }
        );
      }
    } catch (err) {
      // handle error
    }
  }

  requestPermission(): void {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.ready
        .then((registration) => {
          this.afMessaging.requestPermission
            .pipe(mergeMapTo(this.afMessaging.tokenChanges))
            .subscribe(
              (token) => {
                this.AddAccessToken(token);
              },
              (error) => {
                console.error('Error requesting permission:', error);
              }
            );
        })
        .catch((err) => {
          console.error('Service Worker not ready:', err);
        });
    } else {
      console.error('Service Worker not supported in this browser');
    }
  }

  AddAccessToken(accessToken): void {
    const token = localStorage.getItem('token');

    if (token === null) {
      return;
    }

    return this.httpClient
      .requestCall(ApiEndPoints.AddAccessToken, ApiMethod.POST, {
        AccessToken: accessToken,
      })
      .subscribe((res: any) => {});
  }
}
