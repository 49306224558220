<div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
        <div class="modal-header text-center">
            <h5 class="modal-title w-100">
                {{ "SUBSCRIPTION_OPTIONS" | translate }}
            </h5>
        </div>
        <div class="modal-body">
            <div class="row" *ngIf="availableSubscriptions$ | async as subscriptions">
                <div *ngFor="let subscription of subscriptions" class="col-md-4 d-flex">
                    <div class="subscription-box d-flex flex-column" [ngClass]="{
              'current-subscription':
                subscription.SubscriptionPlanName ===
                currentSubscriptionPlanName,
              'canceled-subscription':
                cancelAtPeriodEnd &&
                subscription.SubscriptionPlanName ===
                  currentSubscriptionPlanName
            }">
                        <h3 class="plan-name">{{ subscription.SubscriptionPlanName }}</h3>
                        <ul class="features-list flex-grow-1">
                            <li *ngFor="let feature of subscription.Features">
                                <i class="fa-light fa-check fa-lg feature-icon"></i> {{ feature.Name | translate }}
                            </li>
                        </ul>
                        <div class="mt-auto">
                            <p class="total-cost" *ngIf="isTrial; else regularPrice">
                                <span class="strikethrough">
                  {{ subscription.PricePerUserPerMonth | currency }} /
                  {{ "USER_MONTH" | translate }}
                </span>
                                <br />
                                <span class="free-until">
                  {{ "FREE UNTIL" | translate }}
                  {{ formatTrialEndDate(trialEnd) }}
                </span>
                            </p>
                            <ng-template #regularPrice>
                                <p class="total-cost">
                                    {{ subscription.PricePerUserPerMonth | currency }} / {{ "USER_MONTH" | translate }}
                                </p>
                                <p class="total-cost">
                                    {{ "TOTAL_COST" | translate }} {{ employeeCount }} {{ "EMPLOYEES" | translate }}: {{ subscription.PricePerUserPerMonth * employeeCount | currency }}
                                </p>
                            </ng-template>

                            <!-- Zeige das Enddatum, wenn das Abo gekündigt wurde -->
                            <div *ngIf="
                  cancelAtPeriodEnd &&
                  subscription.SubscriptionPlanName ===
                    currentSubscriptionPlanName
                " class="subscription-end-date">
                                {{ "ENDS ON" | translate }} {{ formatTrialEndDate(currentPeriodEnd) }}
                            </div>
                            <button (click)="selectSubscription(subscription)" class="btn btn-primary">
                {{
                  subscription.SubscriptionPlanName ===
                  this.currentSubscriptionPlanName
                    ? ("MANAGE PLAN" | translate)
                    : ("SWITCH PLAN" | translate)
                }}
              </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer d-flex justify-content-between">
            <button class="btn btn-outline-secondary" (click)="onClose()" type="button">
        {{ "CLOSE" | translate }}
      </button>
        </div>
    </div>
</div>