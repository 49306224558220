import { Injectable } from '@angular/core';
import { IonContent } from '@ionic/angular';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BottomNavigationEmployeeService {
  // show nofication badge on bottom navigation
  public showNotificationBadge = false;

  private scrollEventSubject = new Subject<number>();
  public scrollEvent$ = this.scrollEventSubject.asObservable();
  constructor() {}

  private scrollableContentElement: IonContent;
  private showNotificationBadgeSubject = new Subject<boolean>();
  public showNotificationBadge$ =
    this.showNotificationBadgeSubject.asObservable();

  checkComponentList(component: any[]): boolean {
    return component.some((item) => item.Title && item.Content);
  }

  setNotificationBadge(show: boolean) {
    if (show) {
      this.showNotificationBadgeSubject.next(show);
    }
  }

  setScrollableContentElement(element: IonContent) {
    this.scrollableContentElement = element;
  }

  getScrollableContentElement(): IonContent {
    return this.scrollableContentElement;
  }

  emitScrollEvent(scrollY: number) {
    this.scrollEventSubject.next(scrollY);
  }
}
