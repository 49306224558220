import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { UserRoles } from 'src/app/core/services/const';
import { AuthService } from 'src/app/modules/auth/services/auth/auth.service';
import { EmployeeEamil } from '../../model/employee-email.model';
import { EmployeeService } from '../../services/employee.service';

@Component({
  selector: 'app-invite-employee',
  templateUrl: './invite-employee.component.html',
  styleUrls: ['./invite-employee.component.css'],
})
export class InviteEmployeeComponent implements OnInit {
  userRoles = UserRoles;
  form: UntypedFormGroup;
  userRolesList: string[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<InviteEmployeeComponent>,
    public service: EmployeeService,
    private translate: TranslateService,
    public authService: AuthService
  ) {
    this.userRolesList = Object.keys(this.userRoles).filter(
      (f) => !isNaN(Number(f))
    );
  }

  formModel: EmployeeEamil = {
    Id: 0,
    Role: 2,
    Email: '',
  };

  userRole = [
    { id: 2, label: 'Employee' },
    { id: 3, label: 'Team Leader' },
  ];

  ngOnInit(): void {
    if (this.data && this.data.employee && this.data.employee.Id) {
      this.formModel = {
        Id: this.data.employee.Id,
        Role: this.data.employee.Role,
        Email: this.data.employee.Email,
      };
    } else {
      this.formModel = {
        Id: 0,
        Role: 2,
        Email: '',
      };
    }
  }

  onSubmit(form: any): void {
    if (form) {
      this.service.updateEmployeeEmail(form as EmployeeEamil);
    }
    this.onClose();
  }

  onClose(): void {
    this.dialogRef.close();
  }
}
