import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Observable, tap } from 'rxjs';
import { ApiEndPoints, ApiMethod } from 'src/app/core/services/const';
import { HttpService } from 'src/app/core/services/http/http.service';
import { EmployeeShiftSwap } from '../models/employee-shift-swap';
import { ShiftForSwap } from '../models/shift-for-swap';
import { SwappingStatus } from '../models/swapping-status';
@Injectable({
  providedIn: 'root',
})
export class ShiftSwapService {
  private employeeSwapShiftListSource: BehaviorSubject<EmployeeShiftSwap[]> =
    new BehaviorSubject([] as EmployeeShiftSwap[]);
  employeeSwapShiftList = this.employeeSwapShiftListSource.asObservable();

  private swappingStatusSource: BehaviorSubject<SwappingStatus> =
    new BehaviorSubject(new SwappingStatus());
  swappingStatus = this.swappingStatusSource.asObservable();

  private employeeShiftSwappingProcessListSource: BehaviorSubject<
    EmployeeShiftSwap[]
  > = new BehaviorSubject([] as EmployeeShiftSwap[]);
  employeeSwapProcessShiftList =
    this.employeeShiftSwappingProcessListSource.asObservable();

  private potentialShiftSwappersListSource: BehaviorSubject<ShiftForSwap[]> =
    new BehaviorSubject([] as ShiftForSwap[]);
  potentialShiftSwappersList =
    this.potentialShiftSwappersListSource.asObservable();
  private shiftForSwapListSource: BehaviorSubject<EmployeeShiftSwap[]> =
    new BehaviorSubject([] as EmployeeShiftSwap[]);
  shiftForSwapList = this.shiftForSwapListSource.asObservable();

  private shiftForPossibleWorkShiftListSource: BehaviorSubject<ShiftForSwap[]> =
    new BehaviorSubject([] as ShiftForSwap[]);
  shiftForPossibleWorkShift =
    this.shiftForPossibleWorkShiftListSource.asObservable();

  private shiftsSwapCandidateListSource: BehaviorSubject<ShiftForSwap[]> =
    new BehaviorSubject([] as ShiftForSwap[]);
  shiftsSwapCandidate = this.shiftsSwapCandidateListSource.asObservable();

  constructor(
    private httpClient: HttpService,
    private toastr: ToastrService,
    private translate: TranslateService
  ) {}

  PutShiftForSwap(eventId): HttpService {
    return this.httpClient
      .requestCall(ApiEndPoints.PutShiftForSwap, ApiMethod.PUT, {
        EventId: eventId,
      })
      .subscribe((details: any) => {
        this.GetEmployeeSwapShiftsList();
      });
  }

  AddTargetedRequestToSwapRequest(
    employeeEventId,
    targetedEventId
  ): HttpService {
    return this.httpClient
      .requestCall(
        ApiEndPoints.AddTargetedRequestToSwapRequest,
        ApiMethod.POST,
        {
          WorkShiftEventId: employeeEventId,
          TargetedWorkShiftId: targetedEventId,
        }
      )
      .subscribe((details: any) => {
        if (details > 0) {
          this.toastr.success(
            this.translate.instant(
              'YOUR SHIFT SWAP REQUEST HAS BEEN SUCCESSFULLY REGISTERED'
            ),
            this.translate.instant('SWAP REQUEST SUCCESSFUL')
          );
          this.GetEmployeeSwapShiftsList();
        }
      });
  }

  GetEmployeeSwapShiftsList(): HttpService {
    return this.httpClient
      .requestCall(ApiEndPoints.GetEmployeeSwapShiftsList, ApiMethod.GET)
      .subscribe((details: EmployeeShiftSwap[]) => {
        return this.employeeSwapShiftListSource.next(details);
      });
  }

  GetEmployeeSwapShiftsListAsync(): Observable<EmployeeShiftSwap[]> {
    return this.httpClient
      .requestCall(ApiEndPoints.GetEmployeeSwapShiftsList, ApiMethod.GET)
      .pipe(
        tap((details: EmployeeShiftSwap[]) => {
          this.employeeSwapShiftListSource.next(details);
        })
      );
  }

  GetShiftSwapStatus(swapingId: number): HttpService {
    return this.httpClient
      .requestCall(
        ApiEndPoints.GetShiftSwapStatusQuery + '/' + swapingId,
        ApiMethod.GET
      )
      .subscribe((details: SwappingStatus) => {
        return this.swappingStatusSource.next(details);
      });
  }

  GetAllEmplyeesSwappingProcessQuery(): HttpService {
    return this.httpClient
      .requestCall(
        ApiEndPoints.GetAllEmplyeesSwappingProcessQuery,
        ApiMethod.GET
      )
      .subscribe((details: EmployeeShiftSwap[]) => {
        return this.employeeShiftSwappingProcessListSource.next(details);
      });
  }

  GetPotentialShiftSwappersList(eventId: string): HttpService {
    return this.httpClient
      .requestCall(
        ApiEndPoints.GetPotentialShiftSwappersQuery + '/' + eventId,
        ApiMethod.GET
      )
      .subscribe((details: ShiftForSwap[]) => {
        return this.potentialShiftSwappersListSource.next(details);
      });
  }

  GetShiftsForSwapQuery(): HttpService {
    return this.httpClient
      .requestCall(ApiEndPoints.GetShiftsForSwapQuery, ApiMethod.GET)
      .subscribe((details: EmployeeShiftSwap[]) => {
        return this.shiftForSwapListSource.next(details);
      });
  }

  GetShiftsForSwapQueryAsync(): Observable<EmployeeShiftSwap[]> {
    return this.httpClient
      .requestCall(ApiEndPoints.GetShiftsForSwapQuery, ApiMethod.GET)
      .pipe(
        tap((details: EmployeeShiftSwap[]) => {
          this.shiftForSwapListSource.next(details);
        })
      );
  }

  GetEmployeeWorkShiftsForSwapingQuery(shittId: any, EmployeeId: any) {
    return this.httpClient
      .requestCall(
        ApiEndPoints.GetEmployeeWorkShiftsForSwapingQuery +
          '/' +
          shittId +
          '/' +
          EmployeeId,
        ApiMethod.GET
      )
      .subscribe((details: ShiftForSwap[]) => {
        return this.shiftForPossibleWorkShiftListSource.next(details);
      });
  }

  proposedOptionList(accepted: number[], shiftId: number): HttpService {
    return this.httpClient
      .requestCall(
        ApiEndPoints.AddProposedOptionToSwapRequestCommand,
        ApiMethod.POST,
        {
          ProposedOptions: accepted,
          WorkShiftId: shiftId,
        }
      )
      .subscribe((details: any) => {});
  }

  GetShiftsSwapCandidateQuery(shittId: any) {
    return this.httpClient
      .requestCall(
        ApiEndPoints.GetShiftsSwapCandidateQuery + '/' + shittId,
        ApiMethod.GET
      )
      .subscribe((details: ShiftForSwap[]) => {
        return this.shiftsSwapCandidateListSource.next(details);
      });
  }

  SwapEmployeeBetweenShifts(swapShiftId: any, ShiftId: any) {
    return this.httpClient
      .requestCall(
        ApiEndPoints.SwapEmployeeBetweenShiftsCommand,
        ApiMethod.PUT,
        {
          SwapShiftId: swapShiftId,
          ShiftId: ShiftId,
        }
      )
      .subscribe((details: ShiftForSwap[]) => {
        return this.shiftsSwapCandidateListSource.next(details);
      });
  }

  SwapEmployeeBetweenTargetedShifts(swapShiftId: any) {
    return this.httpClient
      .requestCall(
        ApiEndPoints.SwapEmployeeBetweenTargetedShiftsCommand,
        ApiMethod.PUT,
        {
          SwapShiftId: swapShiftId,
        }
      )
      .subscribe((details: number) => {
        if (details == 1) {
          this.toastr.success(
            this.translate.instant(
              'THE SHIFTS HAVE BEEN SUCCESSFULLY EXCHANGED'
            ),
            this.translate.instant('SHIFT EXCHANGE SUCCESSFUL')
          );
          this.GetAllEmplyeesSwappingProcessQuery();
        }
      });
  }

  EmployeeAcceptShiftSwapCommand(swapShiftId: any) {
    return this.httpClient
      .requestCall(
        ApiEndPoints.EmployeeAcceptShiftSwapCommand,
        ApiMethod.POST,
        {
          ShiftSwapId: swapShiftId,
        }
      )
      .subscribe((result: any) => {
        if (result == 1) {
          this.GetEmployeeSwapShiftsList();
          this.toastr.success(
            this.translate.instant('EXCHANGE REQUEST ACCEPTED'),
            this.translate.instant('EXCHANGE REQUEST CONFIRMATION')
          );
        }
      });
  }
}
