import { Injectable } from '@angular/core';
import { HttpService } from 'src/app/core/services/http/http.service';
import { ApiEndPoints, ApiMethod } from 'src/app/core/services/const';
import { BehaviorSubject, map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LocationService {
  private countriesSource = new BehaviorSubject<Country[]>([]);
  countries$ = this.countriesSource.asObservable();

  private regionsSource = new BehaviorSubject<Region[]>([]);
  regions$ = this.regionsSource.asObservable();

  constructor(private httpClient: HttpService) {}

  getAllCountries(): void {
    this.httpClient
      .requestCall(ApiEndPoints.GetAllCountries, ApiMethod.GET)
      .subscribe((countries: Country[]) => {
        this.countriesSource.next(countries);
      });
  }

  getRegionsByCountry(isoCountryCode: string): void {
    this.httpClient
      .requestCall(
        `${ApiEndPoints.GetRegions}?isoCountryCode=${isoCountryCode}`,
        ApiMethod.GET
      )
      .subscribe((regions: Region[]) => {
        this.regionsSource.next(regions);
      });
  }

  getRegionNameByIsoRegionCode(
    isoRegionCode: string
  ): Observable<string | undefined> {
    return this.regions$.pipe(
      map((regions: Region[]) => {
        const region = regions.find((r) => r.IsoRegionCode === isoRegionCode);
        return region ? region.RegionName : undefined;
      })
    );
  }
}

export interface Country {
  CountryId: number;
  CountryName: string;
  IsoCountryCode: string;
}

export interface Region {
  RegionId: number;
  RegionName: string;
  IsoRegionCode: string;
  CountryId: number;
}
