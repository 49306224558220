<div class="table-container">
    <mat-table [dataSource]="listData" matSort (matSortChange)="sortEmployeeData($event)">
        <ng-container matColumnDef="EmployeeName">
            <mat-header-cell *matHeaderCellDef mat-sort-header style="font-size: 16px; color: var(--font-color)">
                {{ "EMPLOYEE NAME" | translate }}
            </mat-header-cell>
            <mat-cell *matCellDef="let element" style="color: var(--primary-color-blue); font-weight: bold">
                <h6>
                    <i class="fa-light fa-circle-user fg-lg" style="font-size: x-large"></i> {{ element.EmployeeName }}
                </h6>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="Department">
            <mat-header-cell *matHeaderCellDef mat-sort-header style="font-size: 16px; color: var(--font-color)">
                {{ "Department" | translate }}
            </mat-header-cell>
            <mat-cell *matCellDef="let element">{{ element.Department }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="Diagram">
            <mat-header-cell *matHeaderCellDef mat-sort-header style="font-size: 16px; color: var(--font-color)">
                {{ "Diagram" | translate }}
            </mat-header-cell>
            <mat-cell *matCellDef="let element">
                <div style="padding: 0px; width: 300px; height: 50px">
                    <app-employee-time-bar-chart [chartId]="element.Id + '-' + element.DepartmentId" [chartData]="element.TimeLine"></app-employee-time-bar-chart>
                </div>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="TotalWorkTime">
            <mat-header-cell *matHeaderCellDef mat-sort-header style="font-size: 16px; color: var(--font-color)">
                {{ "WORKING HOURS" | translate }}
            </mat-header-cell>
            <mat-cell *matCellDef="let element">{{ element.totalWorkTime }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="TotalOvertimeTime">
            <mat-header-cell *matHeaderCellDef mat-sort-header style="font-size: 16px; color: var(--font-color)">
                {{ "OVERTIME" | translate }}
            </mat-header-cell>
            <mat-cell *matCellDef="let element">{{ element.totalOvertimeTime }}
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="Actions">
            <mat-header-cell *matHeaderCellDef></mat-header-cell>
            <mat-cell style="display: flex; justify-content: flex-end; padding: 0px;" *matCellDef="let element">
                <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
          <mat-icon>more_vert</mat-icon>
        </button>
                <mat-menu #menu="matMenu">
                    <button mat-menu-item (click)="getEmployeeWorkHoursReportPdfQuery(element.Id, element.DepartmentId)">
            <i class="fa-solid fa-file-pdf"></i>
            <span> {{ "PDF REPORT" | translate }}</span>
          </button>



                </mat-menu>
            </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" style="font-weight: 500; background-color: white; font: bold"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    </mat-table>
    <mat-paginator [length]="paginationService.totalCount" [pageSize]="paginationService.pageCount" [pageSizeOptions]="[5, 10, 15, 25, 100]" (page)="onPageSwitch($event)" class="mat-paginator-sticky">
    </mat-paginator>
</div>