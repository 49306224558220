<div class="modal-dialog modal-lg" role="document">
  <div class="modal-content">
    <div class="modal-header text-center">
      <h5 class="modal-title w-100">{{confirmHeader}}</h5>
    </div>
    <div class="modal-body">
      <div [innerHTML]="confirmMessage"></div>
    </div>
    <div class="modal-footer d-flex justify-content-between">
      <button class="btn btn-outline-secondary" (click)="dialogRef.close(false)">{{cancelButtonText}}</button>
      <button class="btn btn-primary active" (click)="dialogRef.close(true)">{{confirmButtonText}}</button>
    </div>
  </div>
</div>