import {
  Component,
  ElementRef,
  Input,
  OnInit,
  Renderer2,
  SimpleChanges,
} from '@angular/core';
import * as echarts from 'echarts';
import { TimeLineDto } from '../../model/employee-work-hours-dto';

@Component({
  selector: 'app-employee-time-bar-chart',
  templateUrl: './employee-time-bar-chart.component.html',
  styleUrls: ['./employee-time-bar-chart.component.css'],
})
export class EmployeeTimeBarChartComponent implements OnInit {
  @Input() chartId: string;
  @Input() chartData: TimeLineDto = new TimeLineDto();

  private chartInstance: any;
  private tooltipElement: HTMLElement;

  constructor(private renderer: Renderer2, private el: ElementRef) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.chartData) {
      this.initChart();
    }
  }

  ngOnInit(): void {
    // call this after the view has been initialized
    setTimeout(() => {
      const chartDom = document.getElementById(this.chartId)!;
      if (chartDom) {
        this.chartInstance = echarts.init(chartDom);
        this.initChart();
      }
    }, 300);
  }

  initChart(): void {
    if (!this.chartInstance) {
      return;
    }

    const series: echarts.BarSeriesOption[] = [
      {
        name: 'Regular Hours',
        type: 'bar',
        stack: 'total',
        barWidth: '60%',
        data: this.chartData.WorkTime,
        itemStyle: {
          color: '#1976D2',
        },
      },
      {
        name: 'Overtime Hours',
        type: 'bar',
        stack: 'total',
        barWidth: '60%',
        data: this.chartData.Overtime,
        itemStyle: {
          color: '#1A237E',
        },
      },
    ];

    const option = {
      grid: {
        left: 5,
        right: 5,
        top: 5,
        bottom: 5,
        containLabel: true,
      },
      yAxis: {
        type: 'value',
        axisLabel: {
          show: false,
        },
        axisLine: {
          show: false,
        },
        axisTick: {
          show: false,
        },
        splitLine: {
          show: false,
        },
      },
      xAxis: {
        type: 'category',
        data: this.chartData.Days.map((dateStr) => {
          const date = new Date(dateStr);
          return isNaN(date.getTime())
            ? ''
            : new Intl.DateTimeFormat('de-DE', { weekday: 'long' }).format(
                date
              );
        }),
        axisLabel: {
          show: false,
        },
        axisLine: {
          show: false,
        },
        axisTick: {
          show: false,
        },
        splitLine: {
          show: false,
        },
      },
      tooltip: {
        show: false, // Disable the built-in tooltip
      },
      series,
      barCategoryGap: '1px', // Abstand zwischen den Balken-Kategorien
      barGap: '1px', // Abstand zwischen den gestapelten Balken
    };

    this.chartInstance.setOption(option);

    // Create a custom tooltip element
    this.tooltipElement = this.renderer.createElement('div');
    this.renderer.addClass(this.tooltipElement, 'custom-tooltip');
    this.renderer.appendChild(this.el.nativeElement, this.tooltipElement);

    this.chartInstance.on('mouseover', (params: any) => {
      if (params.componentType === 'series') {
        const dateStr = this.chartData.Days[params.dataIndex];
        const date = new Date(dateStr);
        const formattedDay = isNaN(date.getTime())
          ? 'Kein Datum'
          : new Intl.DateTimeFormat('de-DE', {
              weekday: 'long',
              day: '2-digit',
              month: '2-digit',
            }).format(date);
        const regularHours = this.convertMinutesToHoursAndMinutes(
          this.chartData.WorkTime[params.dataIndex] || 0
        );
        const overtimeHours = this.convertMinutesToHoursAndMinutes(
          this.chartData.Overtime[params.dataIndex] || 0
        );
        let tooltipText = `${formattedDay}<br/>Arbeitsstunden: ${regularHours}`;
        if (overtimeHours !== '0h 0m') {
          tooltipText += `<br/>Überstunden: ${overtimeHours}`;
        }
        this.renderer.setProperty(
          this.tooltipElement,
          'innerHTML',
          tooltipText
        );
        this.renderer.setStyle(this.tooltipElement, 'display', 'block');
        this.updateTooltipPosition(params.event);
      }
    });

    this.chartInstance.on('mousemove', (params: any) => {
      if (params.componentType === 'series') {
        this.updateTooltipPosition(params.event);
      }
    });

    this.chartInstance.on('mouseout', () => {
      this.renderer.setStyle(this.tooltipElement, 'display', 'none');
    });
  }

  convertMinutesToHoursAndMinutes(minutes: number): string {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    return `${hours}h ${remainingMinutes}m`;
  }

  updateTooltipPosition(event: any): void {
    const chartRect = this.el.nativeElement.getBoundingClientRect();
    const x = event.offsetX + chartRect.left + 10;
    const y = event.offsetY + chartRect.top + 10;
    this.renderer.setStyle(this.tooltipElement, 'left', `${x}px`);
    this.renderer.setStyle(this.tooltipElement, 'top', `${y}px`);
  }
}
