<div class="row">
  <div class="col-9 float-right" style="padding-right: 1px; padding-left: 1px">
    <!-- Anzeige des aktuellen Wertes im Ansichtsmodus -->
    <label
      style="float: right; padding-top: 10px"
      *ngIf="this.currentIcon === 'edit' && !showDropdownInput"
      class="form-label float-right"
      >{{ value }}</label
    >
    <label
      style="float: right; padding-top: 10px"
      *ngIf="this.currentIcon === 'edit' && showDropdownInput"
      class="form-label float-right"
      >{{ getDropdownValue(value) }}</label
    >

    <!-- Eingabefelder im Bearbeitungsmodus -->

    <!-- Normaler Input -->
    <input
      [id]="inputId"
      style="float: right"
      class="form-control"
      *ngIf="this.currentIcon === 'check' && showNormalInput"
      [ngModel]="value"
      (ngModelChange)="
        value = $event; onChange(value); adjustInputWidth($event)
      "
    />

    <!-- Timepicker-Feld -->
    <input
      style="float: right; width: 50%"
      *ngIf="this.currentIcon === 'check' && showTimepicker"
      #Start="ngModel"
      name="Start"
      id="Start"
      class="form-control"
      [format]="24"
      [ngxMatTimepicker]="pickerStart"
      [(ngModel)]="value"
      required
      inputmode="none"
      (ngModelChange)="value = $event; onChange(value)"
    />
    <ngx-mat-timepicker #pickerStart [minutesGap]="5"></ngx-mat-timepicker>

    <!-- Autocomplete Input -->
    <input
      type="text"
      id="timeInput"
      [matAutocomplete]="auto"
      *ngIf="this.currentIcon === 'check' && showAutocomplete"
      class="form-control"
      [(ngModel)]="value"
      (input)="onInputChange($event)"
      [formControl]="timeInputControl"
    />
    <mat-autocomplete #auto="matAutocomplete">
      <mat-option
        *ngFor="let suggestion of filteredSuggestions"
        [value]="suggestion"
        (onSelectionChange)="selectSuggestion($event, suggestion)"
      >
        {{ suggestion }}
      </mat-option>
    </mat-autocomplete>

    <!-- Dropdown Input -->
    <mat-select
      (ngModelChange)="value = $event; onChange(value)"
      *ngIf="this.currentIcon === 'check' && showDropdownInput"
      #Role="ngModel"
      name="Role"
      id="Role"
      class="form-control"
      [(ngModel)]="value"
      [multiple]="multiple"
      Bindung
      der
      Mehrfachauswahl
    >
      <mat-option *ngFor="let item of dropdownListe" [value]="item.key">
        {{ item.value }}
      </mat-option>
    </mat-select>
  </div>

  <!-- Buttons für Editieren und Abbrechen -->
  <div class="col-3" style="padding-left: 0px; display: flex; gap: 10px">
    <button
      class="card-header cancel"
      (click)="changeButtonStatus()"
      mat-icon-button
    >
      <mat-icon>{{ currentIcon }}</mat-icon>
    </button>
    <!-- Nur anzeigen, wenn der Benutzer gerade bearbeitet -->
    <button
      *ngIf="editing"
      class="card-header"
      (click)="cancelEdit()"
      mat-icon-button
    >
      <mat-icon>close</mat-icon>
      <!-- Abbrechen-Button -->
    </button>
  </div>
</div>
