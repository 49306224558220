import { ShiftSwapStatus } from 'src/app/core/services/const';

export class ShiftForSwap {
  Id: number;
  EmployeeFullName: string;
  StartTime: Date | string;
  EndTime: Date | string;
  ShiftId: number;
  SkillName: string;
  IconCode: string;
  EmployeeId: number;
  EventId: string;
  DateString: string;
  Status: ShiftSwapStatus;
}
