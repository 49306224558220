<!-- ======= Header ======= -->
<header id="header" class="header fixed-top d-flex align-items-center">
  <div class="d-flex align-items-center justify-content-between">
    <i class="bi bi-list toggle-sidebar-btn" (click)="toggleSidebar()"></i>
    <a
      class="navbar-brand"
      href="javascript:void(0)"
      (click)="onDashboardClickImage()"
    >
      <img
        src="../../../../../assets/img/logo-no-name.png"
        alt=""
        width="40"
        height="40"
      />
      <img
        src="../../../../../assets/img/Logo.PNG"
        alt=""
        width="110"
        height="30"
      />
    </a>
  </div>
  <!-- End Logo -->

  <nav class="header-nav ms-auto">
    <ul class="d-flex align-items-center">
      <li style="list-style-type: none" class="nav-item dropdown">
        <a
          style="width: 130px; text-align: right"
          class="nav-link dropdown-toggle"
          href="#"
          id="navbarDropdown"
          role="button"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <i class="bi bi-person-circle"></i> {{ authService.firstName }}
        </a>
        <ul
          class="dropdown-menu col-xs-12"
          aria-labelledby="navbarDropdownMenuLink"
          style="white-space: nowrap"
        >
          <li class="divider"></li>
          <li>
            <a
              class="dropdown-item w-100"
              (click)="onChangePassword()"
              href="javascript:void(0)"
              style="padding-left: 5px"
            >
              <i class="bi bi-key"></i> {{ "CHANGE PASSWORD" | translate }}
            </a>
          </li>
          <hr class="mt-1 mb-1" />
          <li>
            <a
              class="dropdown-item w-100"
              (click)="onLogoutClick()"
              href="javascript:void(0)"
              style="padding-left: 5px"
            >
              <i class="bi bi-box-arrow-right"></i> {{ "Logout" | translate }}
            </a>
          </li>
        </ul>
      </li>
      <!-- End Profile Nav -->
    </ul>
  </nav>
</header>
<!-- End Header -->

<!-- ======= Sidebar ======= -->
<aside
  [@slideIn]
  id="sidebar"
  class="sidebar"
  [ngClass]="{ closed: !showSidebar }"
>
  <ul class="sidebar-nav" id="sidebar-nav">
    <li *ngIf="authService.isManegeOrTeamLeader" class="nav-item">
      <a
        class="nav-link"
        [ngClass]="{ collapsed: router.url !== '/dashboard' }"
        (click)="onDashboardClick()"
        href="javascript:void(0)"
        ><i class="fas fa-desktop" style="margin-right: 10px"></i>
        Dashboard<span class="sr-only">(current)</span></a
      >
    </li>
    <li *ngIf="!authService.isManegeOrTeamLeader" class="nav-item">
      <a
        class="nav-link"
        (click)="onEmployeeDashboardClick()"
        href="javascript:void(0)"
        ><i class="fas fa-desktop" style="margin-right: 10px"></i>
        Dashboard
        <span class="sr-only">(current)</span></a
      >
    </li>
    <li *ngIf="authService.isManegeOrTeamLeader" class="nav-item">
      <a
        class="nav-link"
        [ngClass]="{ collapsed: router.url !== '/planner' }"
        (click)="onPlannerClick()"
        href="javascript:void(0)"
        ><i class="fas fa-tasks" style="margin-right: 10px"></i>
        {{ "Planner" | translate }}</a
      >
    </li>

    <li *ngIf="authService.isManegeOrTeamLeader" class="nav-item">
      <a
        class="nav-link"
        [ngClass]="{ collapsed: router.url !== '/employee' }"
        (click)="onEmployeeClick()"
        href="javascript:void(0)"
        ><i class="fas fa-users" style="margin-right: 10px"></i>
        {{ "Employee" | translate }}</a
      >
    </li>
    <li
      *ngIf="
        authService.isManegeOrTeamLeader && subscriptionService.hasFeature(9)
      "
      class="nav-item"
    >
      <a
        class="nav-link"
        [ngClass]="{ collapsed: router.url !== '/time-recording' }"
        (click)="onTimeRecordingClick()"
        href="javascript:void(0)"
        ><i class="fa-light fa-calendar-clock" style="margin-right: 10px"></i>
        {{ "TIME TRACKING" | translate }}</a
      >
    </li>
    <li *ngIf="authService.isManegeOrTeamLeader" class="nav-item">
      <a
        class="nav-link"
        [ngClass]="{ collapsed: router.url !== '/absence' }"
        (click)="onAbsenceClick()"
        href="javascript:void(0)"
        ><i class="bi bi-calendar-x" style="margin-right: 10px"></i>
        {{ "Absence" | translate }}</a
      >
    </li>
    <li *ngIf="authService.isManegeOrTeamLeader" class="nav-item">
      <a
        class="nav-link"
        [ngClass]="{ collapsed: router.url !== '/resource-plan' }"
        (click)="onResourcePlanClick()"
        href="javascript:void(0)"
      >
        <i class="fa-light fa-people-group" style="margin-right: 10px"></i>
        {{ "RESOURCE PLAN" | translate }}</a
      >
    </li>
    <li *ngIf="authService.isManegeOrTeamLeader" class="nav-item">
      <a
        class="nav-link"
        [ngClass]="{ collapsed: router.url !== '/settings' }"
        (click)="onSettingsClick()"
        href="javascript:void(0)"
      >
        <i class="fa-light fa-gears" style="margin-right: 10px"></i>
        {{ "SETTINGS" | translate }}</a
      >
    </li>
  </ul>
</aside>
<!-- End Sidebar-->
