<div class="modal-dialog modal-lg custom-modal" role="document">
  <div class="modal-content">
    <div class="modal-header text-center">
      <h5 class="modal-title w-100" id="exampleModalCenterTitle">
        {{
          formModel.Id > 0
            ? ("Edit employee data" | translate)
            : ("Add New Employer" | translate)
        }}
      </h5>
      <app-toggle-button
        [isActive]="isOnboardingActive"
        (toggled)="toggleOnboarding()"
        *ngIf="!showOnboarding"
      ></app-toggle-button>
    </div>
    <div class="modal-body">
      <form
        class="user"
        id="addEmployeeForm"
        #addEmployeeForm="ngForm"
        autocomplete="off"
        (submit)="onSubmit(addEmployeeForm)"
      >
        <input type="hidden" name="Id" [(ngModel)]="formModel.Id" />

        <!-- Basic Information -->
        <div class="row">
          <div class="form-group col-md-6 mb-4">
            <label for="InputFirstName" class="required-label">{{
              "First Name" | translate
            }}</label>
            <input
              autocomplete="off"
              type="text"
              class="form-control"
              id="InputFirstName"
              aria-describedby="emailHelp"
              #FirstName="ngModel"
              placeholder="{{ 'Enter First Name' | translate }}"
              name="FirstName"
              [(ngModel)]="formModel.FirstName"
              required
              onboardingAnchor="first-name-add-employee"
            />
          </div>
          <div class="form-group col-md-6 mb-4">
            <label for="InputLastName">{{ "Last Name" | translate }}</label>
            <input
              autocomplete="off"
              type="text"
              class="form-control"
              id="InputLastName"
              placeholder="{{ 'Enter Last Name' | translate }}"
              #LastName="ngModel"
              name="LastName"
              [(ngModel)]="formModel.LastName"
              onboardingAnchor="last-name-add-employee"
            />
          </div>
        </div>

        <!-- Department and Skill Selection -->
        <div class="row">
          <div class="form-group col-md-6 mb-4">
            <label for="Department">
              {{
                structure === workStructureEnum.Departments
                  ? "Department"
                  : ((structure === workStructureEnum.Projects
                      ? "Project"
                      : "DEPARTMENT/PROJECT"
                    ) | translate)
              }}
            </label>
            <mat-select
              id="Department"
              class="form-control"
              placeholder="{{ 'Select Department' | translate }}"
              [formControl]="departmentValue"
              multiple
              [(ngModel)]="formModel.DepartmentList"
              name="DepartmentList"
              required
              onboardingAnchor="department-add-employee"
            >
              <mat-option
                *ngFor="let department of departmentList"
                [value]="department.Id"
              >
                {{ department.Name }}
              </mat-option>
            </mat-select>
          </div>
          <div class="form-group col-md-6 mb-4">
            <label for="Skill">{{ "ABILITY/COMPETENCE" | translate }}</label>
            <mat-select
              id="Skill"
              class="form-control"
              placeholder="{{ 'CHOOSE A QUALIFICATION' | translate }}"
              [formControl]="skillValue"
              multiple
              [(ngModel)]="formModel.SkillList"
              name="SkillList"
              onboardingAnchor="skill-add-employee"
            >
              <mat-option *ngFor="let skill of skillList" [value]="skill.Id">
                <i [ngClass]="skill.IconCode"></i> {{ skill.Name }}
              </mat-option>
            </mat-select>
          </div>
        </div>

        <!-- Email and Role -->
        <div class="row">
          <div class="form-group col-md-6 mb-4">
            <label for="Email">{{ "Email" | translate }}</label>
            <input
              type="email"
              class="form-control form-control-user"
              id="Email"
              [disabled]="employeeHaseEmail"
              placeholder="{{ 'Enter Email' | translate }}"
              #Email="ngModel"
              name="Email"
              [(ngModel)]="formModel.Email"
              [required]="formModel.Role === 3"
              email
              onboardingAnchor="email-add-employee"
            />
            <div
              *ngIf="Email.invalid && (Email.dirty || Email.touched)"
              class="text-danger"
            >
              <div *ngIf="Email.errors?.required">
                {{
                  "TO SELECT A TEAM LEADER YOU MUST HAVE AN EMAIL ADDRESS"
                    | translate
                }}
              </div>
              <div *ngIf="Email.errors?.email">
                {{ "Please enter a valid email address." | translate }}
              </div>
            </div>
          </div>
          <div class="form-group col-md-6 mb-4">
            <label for="Role">{{ "Role" | translate }}</label>
            <mat-select
              *ngIf="formModel.Role !== 1"
              #Role="ngModel"
              name="Role"
              id="Role"
              class="form-control"
              [(ngModel)]="formModel.Role"
              [disabled]="authService.myRole !== 1"
              required
              onboardingAnchor="role-add-employee"
            >
              <mat-option *ngFor="let role of userRole" [value]="role.id">
                {{ role.label }}
              </mat-option>
            </mat-select>
            <ng-container *ngIf="formModel.Role === 1">
              <br />
              <label for="Role">{{ "MANAGER" | translate }}</label>
            </ng-container>
          </div>
        </div>

        <!-- Vacation and Job Type -->
        <div class="row">
          <div class="form-group col-md-6 mb-4">
            <label for="Holidays">{{ "VACATION DAYS" | translate }}</label>
            <input
              type="number"
              class="form-control form-control-user"
              id="Holidays"
              placeholder="{{ 'Enter number of Holidays' | translate }}"
              #Holidays="ngModel"
              name="Holidays"
              [(ngModel)]="formModel.Holidays"
              required
              min="0"
              onboardingAnchor="holidays-add-employee"
            />
          </div>
          <div class="form-group col-md-6 mb-4">
            <label for="JobType">{{ "TYPES OF EMPLOYMENT" | translate }}</label>
            <mat-select
              id="JobType"
              class="form-control"
              placeholder="{{ 'Select Job Type' | translate }}"
              name="JobType"
              [(ngModel)]="formModel.JobType"
              required
              onboardingAnchor="job-type-add-employee"
            >
              <mat-option *ngFor="let jobType of jobTypes" [value]="jobType.id">
                {{ jobType.label | translate }}
              </mat-option>
            </mat-select>
          </div>
        </div>

        <!-- Work Days and Hours -->
        <div class="row" *ngIf="formModel.JobType !== JobType.MiniJob">
          <div class="form-group col-md-6 mb-4">
            <label for="WorkDaysPerWeek">{{
              "WORK DAYS PER WEEK" | translate
            }}</label>
            <mat-select
              id="WorkDaysPerWeek"
              class="form-control"
              name="WorkDaysPerWeek"
              placeholder="{{ 'SELECT WORK DAYS PER WEEK' | translate }}"
              [(ngModel)]="formModel.WorkDaysPerWeek"
              required
              onboardingAnchor="work-days-per-week-add-employee"
            >
              <mat-option
                *ngFor="let day of [1, 2, 3, 4, 5, 6, 7]"
                [value]="day"
              >
                {{ day }}
              </mat-option>
            </mat-select>
          </div>

          <div class="form-group col-md-6 mb-4">
            <label for="WorkHours">{{
              "WORK HOURS PER DAY" | translate
            }}</label>
            <input
              #WorkHours="ngModel"
              name="WorkHours"
              [min]="minEndTime"
              [max]="maxEndTime"
              id="WorkHours"
              class="form-control"
              [ngxMatTimepicker]="pickerEnd"
              [(ngModel)]="hour.hoursPerDay"
              required
              inputmode="none"
              aria-describedby="end-time-icon"
              onboardingAnchor="work-hours-per-day-add-employee"
            />
            <ngx-mat-timepicker
              #pickerEnd
              [minutesGap]="30"
            ></ngx-mat-timepicker>
          </div>
        </div>

        <div class="row">
          <div class="form-group col-md-6 mb-4">
            <div class="form-group flex-fill mb-3">
              <mat-label>{{ "EMPLOYMENT START DATE" | translate }}</mat-label>
              <div class="input-group mb-3">
                <div class="input-group-prepend">
                  <span
                    (click)="startPicker.open()"
                    class="input-group-text"
                    id="start-time-icon"
                    style="height: 40px"
                  >
                    <i class="fa-light fa-calendar-days"></i>
                  </span>
                </div>
                <input
                  matInput
                  id="StartDate"
                  class="form-control"
                  [matDatepicker]="startPicker"
                  (click)="startPicker.open()"
                  [(ngModel)]="formModel.StartDate"
                  name="StartDate"
                  onboardingAnchor="start-date-add-employee"
                />
                <mat-datepicker #startPicker></mat-datepicker>
              </div>
            </div>
          </div>

          <div class="row" *ngIf="formModel.JobType === JobType.MiniJob">
            <div class="form-group col-md-6 mb-4">
              <label for="WorkingTime">{{
                "WORK HOURS PER WEEK" | translate
              }}</label>
              <input
                type="number"
                class="form-control form-control-user"
                id="WorkingTime"
                placeholder="{{ 'Enter Working Time' | translate }}"
                #WorkingTime="ngModel"
                name="WorkingTime"
                [(ngModel)]="formModel.WorkingTime"
                required
                min="0"
                onboardingAnchor="working-time-add-employee"
              />
            </div>
          </div>
        </div>

        <!-- Mehr Informationen (Expansion Panel) -->
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            {{ "MORE EMPLOYEE INFORMATION" | translate }}
          </mat-expansion-panel-header>
          <ng-template matExpansionPanelContent>
            <div class="row">
              <div class="form-group col-md-6 mb-4">
                <label for="InputHomePhoneNo">{{
                  "Home Phone" | translate
                }}</label>
                <input
                  type="text"
                  class="form-control form-control-user"
                  id="InputHomePhoneNo"
                  placeholder="{{ 'Enter HomePhone Number' | translate }}"
                  #HomePhoneNo="ngModel"
                  name="HomePhoneNo"
                  [(ngModel)]="formModel.HomePhoneNo"
                />
              </div>
              <div class="form-group col-md-6 mb-4">
                <label for="InputMobileNo">{{
                  "Mobile Number" | translate
                }}</label>
                <input
                  type="text"
                  class="form-control form-control-user"
                  id="InputMobileNo"
                  placeholder="{{ 'Mobile Number' | translate }}"
                  #MobileNo="ngModel"
                  name="MobileNo"
                  [(ngModel)]="formModel.MobileNo"
                />
              </div>
            </div>
            <div class="row">
              <div class="form-group col-md-6 mb-4">
                <label for="InputZipCode">{{ "ZipCode" | translate }}</label>
                <input
                  type="text"
                  class="form-control form-control-user"
                  id="InputZipCode"
                  placeholder="{{ 'Enter ZipCode' | translate }}"
                  #ZipCode="ngModel"
                  name="ZipCode"
                  [(ngModel)]="formModel.ZipCode"
                />
              </div>
              <div class="form-group col-md-6 mb-4">
                <label for="City">{{ "City" | translate }}</label>
                <input
                  type="text"
                  class="form-control form-control-user"
                  id="InputCity"
                  placeholder="{{ 'City' | translate }}"
                  #City="ngModel"
                  name="City"
                  [(ngModel)]="formModel.City"
                />
              </div>
            </div>
            <div class="row">
              <div class="form-outline col-md-6 mb-4">
                <label for="InputStreet1">{{ "Street" | translate }}</label>
                <input
                  type="text"
                  class="form-control form-control-user"
                  id="InputStreet1"
                  placeholder="{{ 'Street' | translate }}"
                  #Street1="ngModel"
                  name="Street1"
                  [(ngModel)]="formModel.Street1"
                />
              </div>
            </div>
          </ng-template>
        </mat-expansion-panel>
      </form>
    </div>
    <div
      class="modal-footer d-flex justify-content-between"
      *ngIf="showButtons"
    >
      <button
        class="btn btn-outline-secondary"
        (click)="onClose()"
        type="button"
        data-dismiss="modal"
      >
        {{ "Close" | translate }}
      </button>
      <button
        class="btn btn-primary active"
        form="addEmployeeForm"
        type="submit"
        [disabled]="!addEmployeeForm.valid"
      >
        {{ "Save changes" | translate }}
      </button>
    </div>
  </div>
</div>
