import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, lastValueFrom, Observable } from 'rxjs';
import { ApiEndPoints, ApiMethod } from '../../../core/services/const';
import { HttpService } from '../../../core/services/http/http.service';
import { AuthService } from './auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export class OnboardingProcessService {
  constructor(
    private httpClient: HttpService,
    private toastr: ToastrService,
    private translate: TranslateService
  ) {}

  // BehaviorSubject zur Verwaltung des Onboarding-Status
  private onboardingCompletedSource: BehaviorSubject<boolean> =
    new BehaviorSubject(false);
  onboardingCompleted$ = this.onboardingCompletedSource.asObservable();

  /**
   * Fügt einen neuen Onboarding-Schritt hinzu oder aktualisiert den Status eines bestehenden Schritts auf true.
   * @param companyId Die ID des Unternehmens.
   * @param page Die Seite, zu der der Schritt gehört.
   * @param stepId Die ID des Schritts.
   */
  addOrUpdateStep(companyId: number, page: string, stepId: string): void {
    const payload = {
      companyId: companyId,
      page: page,
      stepId: stepId,
    };

    this.httpClient
      .requestCall(ApiEndPoints.AddOrUpdateStep, ApiMethod.POST, payload)
      .subscribe({
        next: () => {
          this.toastr.success(
            this.translate.instant('ONBOARDING_STEP_UPDATED_SUCCESS'),
            this.translate.instant('SUCCESS')
          );
        },
        error: (error) => {
          this.toastr.error(
            this.translate.instant('ONBOARDING_STEP_UPDATED_FAILED'),
            this.translate.instant('ERROR')
          );
          console.error('AddOrUpdateStep Error:', error);
        },
      });
  }

  /**
   * Setzt den Status aller Onboarding-Schritte einer bestimmten Seite auf false.
   * @param companyId Die ID des Unternehmens.
   * @param page Die Seite, deren Schritte zurückgesetzt werden sollen.
   */
  resetSteps(companyId: number, page: string): void {
    const payload = {
      companyId: companyId,
      page: page,
    };

    this.httpClient
      .requestCall(ApiEndPoints.ResetSteps, ApiMethod.POST, payload)
      .subscribe({
        next: () => {
          this.toastr.success(
            this.translate.instant('ONBOARDING_STEPS_RESET_SUCCESS'),
            this.translate.instant('SUCCESS')
          );
        },
        error: (error) => {
          this.toastr.error(
            this.translate.instant('ONBOARDING_STEPS_RESET_FAILED'),
            this.translate.instant('ERROR')
          );
          console.error('ResetSteps Error:', error);
        },
      });
  }

  /**
   * Aktualisiert den CurrentRegisteredStepper und setzt CurrentRegisteredStepperIsCompleted auf true, wenn der Schwellenwert erreicht ist.
   * @param companyId Die ID des Unternehmens.
   * @param newStepperValue Der neue Wert für den Stepper.
   * @param completionThreshold Der Schwellenwert, ab dem das Onboarding als abgeschlossen gilt (Standard: 5).
   */
  updateCurrentStepper(
    newStepperValue: number,
    completionThreshold: number = 5
  ): void {
    const payload = {
      newStepperValue: newStepperValue,
    };

    this.httpClient
      .requestCall(ApiEndPoints.UpdateCurrentStepper, ApiMethod.POST, payload)
      .subscribe({
        next: () => {
          if (newStepperValue > completionThreshold) {
            this.onboardingCompletedSource.next(true);
            this.toastr.success(
              this.translate.instant('YOUR ONBOARDING IS COMPLETE'),
              this.translate.instant('SUCCESS')
            );
          }
        },
        error: (error) => {
          console.error('UpdateCurrentStepper Error:', error);
        },
      });
  }

  /**
   * Überprüft, ob das Onboarding für ein Unternehmen abgeschlossen ist.
   * @param companyId Die ID des Unternehmens.
   */
  async isOnboardingCompleted(): Promise<boolean> {
    try {
      const isCompleted: boolean = await lastValueFrom(
        this.httpClient.requestCall(
          ApiEndPoints.IsOnboardingCompleted,
          ApiMethod.GET
        )
      );
      this.onboardingCompletedSource.next(isCompleted);
      return isCompleted;
    } catch (error) {
      console.error('IsOnboardingCompleted Error:', error);
      return false;
    }
  }

  /**
   * Ruft den aktuellen Onboarding-Stepper-Wert ab.
   * @returns Die aktuelle Stepper-Stufe.
   */
  getCurrentStepper(): Observable<number> {
    return this.httpClient.requestCall(
      ApiEndPoints.GetCurrentStepper,
      ApiMethod.GET
    );
  }
}
