<div class="subscription-box" [ngClass]="{ 'canceled-subscription': showWarning }">
    <h3 class="plan-name">{{ subscriptionDetails.SubscriptionPlanName }}</h3>
    <ul class="features-list">
        <li *ngFor="let feature of subscriptionDetails.Features">
            <i class="fa-light fa-check fa-lg feature-icon"></i> {{ feature.Name | translate }}
        </li>
    </ul>

    <!-- Zeige das Enddatum nur, wenn das Abo gekündigt wurde -->
    <div *ngIf="showWarning" class="subscription-end-date">

        {{ "ENDS ON" | translate }} {{ formatTrialEndDate(subscriptionDetails.CurrentPeriodEnd) }}
    </div>

    <button (click)="changeSubscription()" class="btn btn-primary">
    {{ "MANAGE PLAN" | translate }}
  </button>
</div>