import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-toggle-button',
  templateUrl: './toggle-button.component.html',
  styleUrls: ['./toggle-button.component.css'],
})
export class ToggleButtonComponent {
  @Input() isActive: boolean = false;
  @Output() toggled = new EventEmitter<boolean>();

  toggle(): void {
    this.isActive = !this.isActive;
    this.toggled.emit(this.isActive);
  }
}
