import {
  HttpClient,
  HttpErrorResponse,
  HttpParams,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ApiMethod } from '../const';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { throwError } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class HttpService {
  constructor(
    private http: HttpClient,
    private translate: TranslateService,
    private toastService: ToastrService
  ) {}

  requestCall(
    api: any,
    method: ApiMethod,
    data?: any,
    params?: HttpParams,
    responseType: 'json' | 'blob' = 'json'
  ) {
    let response;
    switch (method) {
      case ApiMethod.GET:
        response = this.http
          .get(`${environment.apiURL}${api}`, { params })
          .pipe(catchError(async (err) => this.handleError(err, this)));
        break;
      case ApiMethod.POST:
        const options =
          responseType === 'blob' ? { responseType: 'blob' as 'json' } : {};
        response = this.http
          .post(`${environment.apiURL}${api}`, data, options)
          .pipe(catchError(async (err) => this.handleError(err, this)));
        break;
      case ApiMethod.PUT:
        response = this.http
          .put(`${environment.apiURL}${api}`, data)
          .pipe(catchError(async (err) => this.handleError(err, this)));
        break;
      case ApiMethod.DELETE:
        response = this.http
          .delete(`${environment.apiURL}${api}`, { body: data })
          .pipe(catchError(async (err) => this.handleError(err, this)));
        break;
    }
    return response;
  }

  handleError(err: HttpErrorResponse, context: any) {
    let errorMessage = '';
    let showToast = false;

    if (err.error instanceof ErrorEvent) {
      // Client-Side Error
      errorMessage = `Client error: ${err.error.message}`;
    } else {
      // Server-Side Error
      errorMessage = err.error.message || 'An unexpected error occurred';
      showToast = err.error.ShowToUser; // Prüfen, ob die Nachricht als Toast angezeigt werden soll
    }

    // Zeige die Fehlermeldung als Toast, wenn das Flag gesetzt ist
    if (showToast) {
      this.toastService.error(
        this.translate.instant(errorMessage),
        this.translate.instant('ERROR'),
        {
          timeOut: 10000, // Der Toast wird für 10 Sekunden angezeigt
          extendedTimeOut: 2000, // Nach Hover wird der Toast 2 Sekunden länger angezeigt
          closeButton: true, // Zeigt einen Schließen-Button
          tapToDismiss: true, // Benutzer kann den Toast wegklicken
        }
      ); // Nutze ngx-translate und ngx-toastr
    }

    // Logge den Fehler für weitere Analysen
    // console.error(`Error: ${errorMessage}`);
    // return throwError(errorMessage);
  }
}
