<div class="container content-center">
  <div class="row">
    <div class="col-sm-8 col-md-6 col-lg-4 mx-auto">
      <div class="card shadow-lg">
        <div class="card-body">
          <img class="center" style="padding-top: 30px;" src="../../../../../assets/img/Logo.PNG" alt="" width="152"
            height="80">
          <div class="confirmation-message" style="padding-top: 30px;">
            <h4 class="text-center" style="color: var(--font-color)">
              {{ message }}
            </h4>
          </div>
          <div *ngIf="!errorStatus.showError" class="text-center" style="padding-top: 30px;">
            <a class="btn btn-primary btn-lg" [routerLink]="['/login']">Log In</a>
          </div>
          <div *ngIf="errorStatus.showError" class="alert alert-danger" role="alert" style="margin-top: 30px;">
            {{ errorStatus.errorMessage }}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>