import { Component, OnInit } from '@angular/core';
import { EmployeeService } from '../../services/employee.service';

@Component({
  selector: 'app-search-employee',
  templateUrl: './search-employee.component.html',
  styleUrls: ['./search-employee.component.css'],
})
export class SearchEmployeeComponent implements OnInit {
  constructor(public service: EmployeeService) {}
  searchKeywords = null;
  ngOnInit(): void {}

  onSearchKeywordsChange(keyWord): void {
    this.searchKeywords = keyWord;
    this.service.getEmployeeList(keyWord);
  }
}
