import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormBuilder, Validators, UntypedFormGroup } from '@angular/forms';
import { AuthService } from '../../services/auth/auth.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css'],
})
export class ChangePasswordComponent implements OnInit {
  constructor(private fb: UntypedFormBuilder, private service: AuthService) {}
  @Input() public invitation = null;
  @Input() public oldPassword = null;
  isOldPasswordHidden = false;
  showNewPass = false;
  newPassword;
  @Input() set email(value: string) {
    if (value) {
      if (this.oldPassword) {
        this.isOldPasswordHidden = true;
      }
      this.newPasswordForm = this.fb.group(
        {
          email: [value, Validators.required],
          oldPassword: [this.oldPassword, Validators.required],
          password: ['', [Validators.required, Validators.minLength(6)]],
          confirmPassword: [''],
        },
        { validator: this.checkPasswords }
      );
    }
  }

  newPasswordForm: UntypedFormGroup;

  checkPasswords(group: UntypedFormGroup): any {
    const pass = group.get('password').value;
    const confirmPass = group.get('confirmPassword').value;

    return pass === confirmPass ? null : { notSame: true };
  }

  ngOnInit(): void {
    this.newPassword = 'password';
    this.newPasswordForm = this.fb.group(
      {
        oldPassword: ['', Validators.required],
        password: ['', [Validators.required, Validators.minLength(4)]],
        confirmPassword: [''],
      },
      { validator: this.checkPasswords }
    );
  }

  onSubmit(): void {
    if (this.newPasswordForm.valid) {
      this.service.updateNewPassword(this.newPasswordForm.value);
    }
  }
  onClickNewPassword() {
    if (this.newPassword === 'password') {
      this.newPassword = 'text';
      this.showNewPass = true;
    } else {
      this.newPassword = 'password';
      this.showNewPass = false;
    }
  }
}
