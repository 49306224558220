import { Component, Inject, LOCALE_ID, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { EmployeeService } from 'src/app/modules/employee/services/employee.service';
import { AvailableSubscriptionDto } from '../../model/available-subscription-dto.model';
import { CompanySubscriptionDetailsDto } from '../../model/company-subscription-details-dto.model';
import { SubscriptionService } from '../../services/subscription.service';
import { TranslateService } from '@ngx-translate/core';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import 'dayjs/locale/de';
import 'dayjs/locale/en';
dayjs.extend(localizedFormat);

@Component({
  selector: 'app-subscription-options',
  templateUrl: './subscription-options.component.html',
  styleUrls: ['./subscription-options.component.css'],
})
export class SubscriptionOptionsComponent implements OnInit, OnDestroy {
  availableSubscriptions$: Observable<AvailableSubscriptionDto[]>;
  employeeCount: number = 25;
  currentSubscriptionPlanName: string;
  SubscriptionPlanId: number = 0;
  cancelAtPeriodEnd: boolean;
  currentPeriodEnd: Date;
  trialEnd: Date;
  isTrial: boolean = false;
  currentLocale: string = 'de';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<SubscriptionOptionsComponent>,
    private subscriptionService: SubscriptionService,
    public router: Router,
    private employeeService: EmployeeService,
    private translate: TranslateService,
  ) {
  }

  ngOnInit(): void {
    this.loadCurrentSubscription();
    // Get current language ('en' or 'de') from translate service
    this.currentLocale = this.translate.currentLang || 'en';

    // Set dayjs locale
    dayjs.locale(this.currentLocale);

    // Lade verfügbare Abonnements
    this.loadAvailableSubscriptions();

    this.employeeService.employeeCount.subscribe((count) => {
      this.employeeCount = count;
    });

    this.employeeService.getEmployeeCount();

    // Aktualisiere die Liste der Abonnements, wenn sich die Daten ändern
    this.subscriptionService
      .getAvailableSubscriptionsObservable()
      .subscribe(() => {
        this.loadAvailableSubscriptions();
      });
  }

  formatTrialEndDate(date: Date): string {
    return dayjs(date).locale(this.currentLocale).format('LL');
  }

  loadCurrentSubscription(): void {
    this.subscriptionService.companySubscriptionDetails.subscribe(
      (data: CompanySubscriptionDetailsDto) => {
        this.currentSubscriptionPlanName = data.SubscriptionPlanName;
        this.cancelAtPeriodEnd = data.CancelAtPeriodEnd;
        this.currentPeriodEnd = data.CurrentPeriodEnd;
        this.trialEnd = data.TrialEnd;
        this.isTrial = data.Status === 'Trial';
        if (data.SubscriptionPlanId > 0) {
          this.SubscriptionPlanId = data.SubscriptionPlanId;
        }
      }
    );
  }

  loadAvailableSubscriptions(): void {
    this.availableSubscriptions$ =
      this.subscriptionService.loadAvailableSubscriptions();
  }

  selectSubscription(subscription: AvailableSubscriptionDto): void {
    if (
      this.SubscriptionPlanId &&
      subscription.Id === this.SubscriptionPlanId
    ) {
      this.createCustomerPortalSession();
    } else if (this.SubscriptionPlanId > 0) {
      // Abonnement ändern
      this.subscriptionService
        .changeSubscriptionPlan(subscription.Id)
        .subscribe(() => {
          this.loadCurrentSubscription(); // Aktualisiere das aktuelle Abonnement
        });
    } else {
      console.log('Ausgewähltes Abonnement:', subscription);
      this.router.navigate(['/payment-confirmation', { id: subscription.Id }]); // Die ID des ausgewählten Abonnements wird hier übergeben
      this.onClose();
    }
  }

  createCustomerPortalSession(): void {
    this.subscriptionService.createCustomerPortalSession();
  }

  onClose(): void {
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    // Optionale Bereinigung, falls benötigt
  }
}
