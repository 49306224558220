import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import * as dayjs from 'dayjs';
import { Duration } from 'dayjs/plugin/duration';
import { Subscription } from 'rxjs';
import { ClockEventType } from 'src/app/core/services/const';
import { ClockEventOneDayDto } from '../../model/clock-event-dto.model';
import { TimeRecordingService } from '../../services/time-recording.service';

@Component({
  selector: 'app-show-time-recording-details',
  templateUrl: './show-time-recording-details.component.html',
  styleUrls: ['./show-time-recording-details.component.css'],
})
export class ShowTimeRecordingDetailsComponent implements OnInit, OnDestroy {
  clockEvent: ClockEventOneDayDto | null = null;
  shiftTime: any = {};
  dialogTitle: string = '';
  duration: string = '00:00';
  break: string = '00:00';
  overTime: string = null;
  private subscriptions: Subscription[] = [];
  ClockEventType = ClockEventType;
  employeeName = '';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ShowTimeRecordingDetailsComponent>,
    private timeRecordingService: TimeRecordingService,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.timeRecordingService.resetClockEvent(); // Reset the clockEvent if in addModus
    if (this.data && !this.data.addModus) {
      this.timeRecordingService.getAllClockEventsForDay(
        this.data.startDate,
        this.data.employeeId,
        this.data.departmentId
      );
      this.employeeName = this.data.employeeName;
    } else {
      this.employeeName = this.data.employeeName;
      const eventDate = new Date(this.data.startDate);
      this.dialogTitle = eventDate.toLocaleDateString(navigator.language, {
        month: '2-digit',
        day: '2-digit',
      });
      this.shiftTime = { StarTime: '00:00', EndTime: '00:00' };
      this.duration = '00:00';
      this.break = '00:00';
      this.overTime = null;
      this.clockEvent = {
        EventDate: eventDate,
        ClockEvents: [],
        TotalWorkTimeDay: 0,
        TotalBreakTimeDay: 0,
        TotalOvertime: 0,
        FristClockIn: new Date(), // dummy date
        LastClockOut: new Date(), // dummy date,
        ComplianceMessages: [],
      };
    }

    const clockEventSub = this.timeRecordingService.clockEvent.subscribe(
      (details: ClockEventOneDayDto) => {
        if (details) {
          this.clockEvent = details;
          this.formatAndSet(this.clockEvent);
        }
      }
    );
    this.subscriptions.push(clockEventSub);
  }

  handleClockEvent(event: any): void {
    this.timeRecordingService.getAllClockEventsForDay(
      new Date(this.data.startDate).toDateString(),
      this.data.employeeId,
      this.data.departmentId
    );

    this.timeRecordingService.getTimeRecordingTimelineForCompany(
      this.data.calendarInfo.currentWeekStart,
      this.data.calendarInfo.oneDay,
      this.data.calendarInfo.searchKeywords
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  formatAndSet(details: ClockEventOneDayDto): void {
    if (details && details.ClockEvents && details.ClockEvents.length > 0) {
      const eventDate = new Date(details.EventDate);
      this.dialogTitle = eventDate.toLocaleDateString(navigator.language, {
        month: '2-digit',
        day: '2-digit',
      });

      this.shiftTime = this.getShiftTime(details);

      const totalWorkTime = dayjs.duration(details.TotalWorkTimeDay, 'minutes');
      this.duration = this.formatDuration(totalWorkTime);

      this.break = this.convertMinutesToTime(details.TotalBreakTimeDay);

      if (details.TotalOvertime > 0) {
        const totalOvertime = dayjs.duration(details.TotalOvertime, 'minutes');
        this.overTime = this.formatDuration(totalOvertime);
      } else {
        this.overTime = null;
      }
    } else {
      this.dialogTitle = '';
      this.shiftTime = { StarTime: '', EndTime: '' };
      this.duration = '00:00';
      this.break = '00:00';
    }
  }

  getShiftTime(details): any {
    const firstEvent = new Date(details.FristClockIn);
    const lastEvent = new Date(details.LastClockOut);
    let shiftTime = {
      StarTime: firstEvent.toLocaleTimeString(navigator.language, {
        hour: '2-digit',
        minute: '2-digit',
      }),
      EndTime: lastEvent.toLocaleTimeString(navigator.language, {
        hour: '2-digit',
        minute: '2-digit',
      }),
    };

    // check if the details.LastClockOut is today and this.clockEvent.ClockEvents last event contain EventEndId == 0 then employee is still clocked in
    if (
      new Date(details.LastClockOut).toDateString() ===
        new Date().toDateString() &&
      details.ClockEvents &&
      details.ClockEvents.length > 0 &&
      details.ClockEvents[details.ClockEvents.length - 1].EventEndId === 0
    ) {
      shiftTime.EndTime = this.translate.instant('NOW');
    }

    return shiftTime;
  }

  convertMinutesToTime(minutes: number): string {
    if (isNaN(minutes) || minutes < 0) {
      return '00:00';
    }

    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;

    const hoursStr = hours < 10 ? '0' + hours : hours;
    const minutesStr =
      remainingMinutes < 10 ? '0' + remainingMinutes : remainingMinutes;

    return `${hoursStr}:${minutesStr}`;
  }

  formatDuration(duration: Duration): string {
    const hours = Math.floor(duration.asHours());
    const minutes = Math.floor(duration.minutes());

    const formattedHours = hours < 10 ? '0' + hours : hours;
    const formattedMinutes = minutes < 10 ? '0' + minutes : minutes;

    return `${formattedHours}:${formattedMinutes}`;
  }

  onClose(): void {
    this.dialogRef.close();
  }
}
