<div class="modal-dialog modal-lg" role="document" style="margin-left: 12px; margin-right: 12px;">
  <div class="modal-content">
    <div class="modal-header text-center">
      <h5 class="modal-title w-100" id="exampleModalCenterTitle">{{'SHIFT SWAP'| translate}}
      </h5>
    </div>
    <div class="modal-body" style="padding: 0px;">
      <app-targeted-swap-status [SwapId]="swapShiftId" [isEmployee]="true"
        [hideEmployeeAcceptButton]="hideEmployeeAcceptButton"
        (employeeAccepted)="employeeAccepted($event)"></app-targeted-swap-status>
      <div class="modal-footer d-flex justify-content-between">
        <button class="btn btn-outline-secondary" (click)="onClose()" type="button"
          data-dismiss="modal">{{'Close'|translate}}</button>
        <button *ngIf="!hideEmployeeAcceptButton" class="btn btn-primary active" (click)="onSubmit()" type="submit"
          [disabled]="!swapShiftIsAccepted">{{'ACCEPT'|
          translate}}</button>
      </div>
    </div>
  </div>