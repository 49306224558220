import { Injectable } from '@angular/core';
import * as dayjs from 'dayjs';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject } from 'rxjs';
import { ApiEndPoints, ApiMethod } from '../../../core/services/const';
import { HttpService } from '../../../core/services/http/http.service';
import { AuthService } from '../../auth/services/auth/auth.service';
import { ScheduleRequirementEvent } from '../model/schedule-requirement-event';
import { data } from 'jquery';

@Injectable({
  providedIn: 'root',
})
export class ScheduleRequirementService {
  constructor(
    private httpClient: HttpService,
    public authService: AuthService,
    private toastr: ToastrService
  ) {}

  private employeeTimelineEventListSource: BehaviorSubject<any> =
    new BehaviorSubject(null);
  employeeTimelineEventList =
    this.employeeTimelineEventListSource.asObservable();
  public selectedDepartementList = [];

  public calendarCurrentStart = null;
  addEvent(event: ScheduleRequirementEvent): HttpService {
    event.StarTime = new Date(event.StarTime);
    if (event.EndTime) {
      event.EndTime = new Date(event.EndTime);
    }
    return this.httpClient
      .requestCall(ApiEndPoints.AddScheduleRequirement, ApiMethod.POST, event)
      .subscribe((res: number) => {
        if (res === 0) {
          this.toastr.error(
            'Add Neu Shift',
            'The Employee has vacation on This Day'
          );
        }
        this.getTimelineEvent();
      });
  }
  updatePlanTemplateImage(imageId: string, templateId: number): void {
    return this.httpClient
      .requestCall(ApiEndPoints.UpdateTemplateImage, ApiMethod.POST, {
        ImageId: imageId,
        TemplateId: templateId,
      })
      .subscribe((res: number) => {
        if (res) {
        }
      });
  }

  updateEvent(event: ScheduleRequirementEvent): HttpService {
    event.StarTime = new Date(event.StarTime);
    if (event.EndTime) {
      event.EndTime = new Date(event.EndTime);
    }

    return this.httpClient
      .requestCall(
        ApiEndPoints.UpdateScheduleRequirement,
        ApiMethod.POST,
        event
      )
      .subscribe((res: number) => {
        if (res === 0) {
          this.toastr.error(
            'Add Neu Shift',
            'The Employee has vacation on This Day'
          );
        }
        this.getTimelineEvent();
      });
  }

  getTimelineEvent(): void {
    this.httpClient
      .requestCall(ApiEndPoints.GetScheduleRequirementEvents, ApiMethod.GET)
      .subscribe((result: any) => {
        const clonedList = result.CalenderEvents.map((entry) => {
          this.setColorAndClassForEvent(entry);
          return entry;
        });

        clonedList.forEach((entry) => {
          entry.start = new Date(entry.start);
          entry.end = new Date(entry.end);
        });

        this.employeeTimelineEventListSource.next({
          calenderEvents: clonedList,
          calenderResources: result.CalenderResources,
        } as any);
      });
  }

  deleteTimelineWorkPlan(startTime, endTime): HttpService {
    endTime = dayjs(endTime).format('YYYY-MM-DD');
    return this.httpClient
      .requestCall(ApiEndPoints.DeleteWorkPlan, ApiMethod.POST, {
        StarTime: startTime,
        EndTime: endTime,
      })
      .subscribe((details: any) => {
        this.getTimelineEvent();
      });
  }

  deleteEventTimeline(eventId): HttpService {
    return this.httpClient
      .requestCall(ApiEndPoints.DeleteScheduleRequirement, ApiMethod.POST, {
        EventId: eventId,
      })
      .subscribe((details: any) => {
        this.getTimelineEvent();
      });
  }

  setColorAndClassForEvent(event: any): void {
    event.className =
      'vertical-line-schedule-requirement ' + 'event-animation-scale';
    event.color = '#0d48a13a';
    event.textColor = '#000000';
  }
}
