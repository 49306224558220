<div class="modal-dialog modal-lg custom-modal" role="document">
  <div class="modal-content">
    <div class="modal-header text-center" style="text-align: center">
      <h5
        class="modal-title w-100"
        *ngIf="formModel.Id > 0"
        id="exampleModalCenterTitle-add-department"
      >
        {{
          isProject()
            ? ("ADD_DEPARTMENT_EDIT_PROJECT" | translate)
            : ("ADD_DEPARTMENT_EDIT_DEPARTMENT" | translate)
        }}
      </h5>
      <h5
        class="modal-title w-100"
        *ngIf="formModel.Id === 0"
        id="exampleModalCenterTitle-add-department"
      >
        {{
          isProject()
            ? ("ADD_DEPARTMENT_ADD_NEW_PROJECT" | translate)
            : ("ADD_DEPARTMENT_ADD_NEW_DEPARTMENT" | translate)
        }}
      </h5>
      <app-toggle-button
        [isActive]="isOnboardingActive"
        (toggled)="toggleOnboarding()"
        *ngIf="!showOnboarding"
      ></app-toggle-button>
    </div>
    <div class="modal-body">
      <form
        class="user"
        id="addDepartment-add-department"
        #addDepartmentForm="ngForm"
        autocomplete="off"
        (submit)="onSubmit(addDepartmentForm)"
      >
        <input type="hidden" name="Id" [(ngModel)]="formModel.Id" />

        <!-- Name und Typ -->
        <div class="row">
          <!-- Name -->
          <div class="form-group col-md-6 mb-4">
            <label for="Name-add-department" class="required-label">{{
              "ADD_DEPARTMENT_NAME_LABEL" | translate
            }}</label>
            <input
              type="text"
              class="form-control"
              id="Name-add-department"
              name="Name"
              [(ngModel)]="formModel.Name"
              required
              placeholder="{{
                'ADD_DEPARTMENT_ENTER_NAME_PLACEHOLDER' | translate
              }}"
              onboardingAnchor="name-add-department"
            />
          </div>

          <!-- Typ auswählen, wenn WorkStructure auf Both steht -->
          <div
            class="form-group col-md-6 mb-4"
            *ngIf="workStructure === workStructureEnum.Both"
          >
            <label for="Type-add-department">{{ "Type" | translate }}</label>
            <div
              class="btn-group flex-wrap col-md-12"
              role="group"
              aria-label="Type Toggle"
              id="Type-add-department"
            >
              <input
                type="radio"
                class="btn-check"
                [value]="departmentTypeEnum.Department"
                [(ngModel)]="formModel.Type"
                name="Type-add-department"
                id="btnradioDepartment-add-department"
                autocomplete="off"
                required
                (change)="onDepartmentTypeChange()"
              />
              <label
                class="btn btn-outline-primary"
                for="btnradioDepartment-add-department"
              >
                <img
                  src="./assets/icons/department.png"
                  style="width: 25px; height: 25px; margin-right: 5px"
                  alt="{{ 'DEPARTMENT_ICON_ALT' | translate }}"
                />
                {{ "Department" | translate }}
              </label>

              <input
                type="radio"
                class="btn-check"
                [value]="departmentTypeEnum.Project"
                [(ngModel)]="formModel.Type"
                name="Type-add-department"
                id="btnradioProject-add-department"
                autocomplete="off"
                required
                onboardingAnchor="type-add-department"
                (change)="onDepartmentTypeChange()"
              />
              <label
                class="btn btn-outline-primary"
                for="btnradioProject-add-department"
              >
                <img
                  src="./assets/icons/project-management.png"
                  style="width: 25px; height: 25px; margin-right: 5px"
                  alt="{{ 'PROJECT_ICON_ALT' | translate }}"
                />
                {{ "Project" | translate }}
              </label>
            </div>
          </div>
        </div>

        <!-- Parent Department und Skills -->
        <div class="row">
          <!-- Parent Department -->
          <div class="form-group col-md-6 mb-4">
            <label for="ParentDepartmentId-add-department">{{
              "ADD_DEPARTMENT_PARENT_DEPARTMENT_LABEL" | translate
            }}</label>
            <mat-select
              id="ParentDepartmentId-add-department"
              class="form-control"
              placeholder="{{
                'ADD_DEPARTMENT_SELECT_PARENT_DEPARTMENT_PLACEHOLDER'
                  | translate
              }}"
              name="ParentDepartmentId"
              [(ngModel)]="formModel.ParentDepartmentId"
              onboardingAnchor="parent-department-add-department"
            >
              <mat-option [value]="null">
                {{
                  "ADD_DEPARTMENT_SELECT_PARENT_DEPARTMENT_PLACEHOLDER"
                    | translate
                }}
              </mat-option>
              <mat-option
                *ngFor="let dept of departmentList"
                [value]="dept.Id"
                [disabled]="
                  dept.Id === formModel.Id ||
                  (formModel.Type === departmentTypeEnum.Department &&
                    dept.Type === departmentTypeEnum.Project)
                "
              >
                {{ dept.Name }}
              </mat-option>
            </mat-select>
          </div>

          <!-- Skills (Multiple Auswahl) -->
          <div class="form-group col-md-6 mb-4">
            <label for="SkillIds-add-department">{{
              "ADD_DEPARTMENT_ABILITY_COMPETENCE_LABEL" | translate
            }}</label>
            <mat-select
              id="SkillIds-add-department"
              class="form-control"
              placeholder="{{
                'ADD_DEPARTMENT_SELECT_SKILLS_PLACEHOLDER' | translate
              }}"
              [formControl]="skillValue"
              [(ngModel)]="formModel.SkillIds"
              multiple
              onboardingAnchor="skill-ids-add-department"
            >
              <mat-option *ngFor="let skill of skillList" [value]="skill.Id">
                {{ skill.Name }}
              </mat-option>
            </mat-select>
          </div>
        </div>

        <!-- Projekt-spezifische Felder -->
        <div *ngIf="isProject()">
          <div class="row">
            <!-- Projektleiter (nur für Projekte) -->
            <div class="form-group col-md-6 mb-4">
              <label for="ProjectManagerId-add-department">{{
                "ADD_DEPARTMENT_PROJECT_MANAGER_LABEL" | translate
              }}</label>
              <mat-select
                [formControl]="projectManagerCtrl"
                id="ProjectManagerId-add-department"
                class="form-control"
                placeholder="{{
                  'ADD_DEPARTMENT_SELECT_PROJECT_MANAGER_PLACEHOLDER'
                    | translate
                }}"
                onboardingAnchor="project-manager-add-department"
              >
                <mat-option>
                  <ngx-mat-select-search
                    [formControl]="projectManagerFilterCtrl"
                    placeholderLabel="{{ 'Search' | translate }}"
                  ></ngx-mat-select-search>
                </mat-option>
                <mat-option [value]="null">
                  {{
                    "ADD_DEPARTMENT_SELECT_PROJECT_MANAGER_PLACEHOLDER"
                      | translate
                  }}
                </mat-option>
                <mat-option
                  *ngFor="let emp of filteredProjectManagers | async"
                  [value]="emp"
                >
                  {{ emp.FirstName }} {{ emp.LastName }}
                </mat-option>
              </mat-select>
            </div>
            <!-- Beschreibung -->
            <div class="form-group mb-4">
              <label for="Description-add-department">{{
                "ADD_DEPARTMENT_DESCRIPTION_LABEL" | translate
              }}</label>
              <textarea
                class="form-control"
                id="Description-add-department"
                name="Description"
                [(ngModel)]="formModel.Description"
                onboardingAnchor="description-add-department"
              ></textarea>
            </div>
          </div>

          <div class="row">
            <!-- Projektstart und -ende -->
            <div class="form-group col-md-6 mb-4">
              <mat-label class="required-label">{{
                "ADD_DEPARTMENT_PROJECT_START_DATE_LABEL" | translate
              }}</mat-label>
              <div class="input-group mb-3">
                <div class="input-group-prepend">
                  <span
                    (click)="startPicker.open()"
                    class="input-group-text"
                    id="start-time-icon-add-department"
                    style="height: 40px"
                  >
                    <i class="fa-light fa-calendar-days"></i>
                  </span>
                </div>
                <input
                  matInput
                  [matDatepicker]="startPicker"
                  class="form-control"
                  id="ProjectStart-add-department"
                  name="ProjectStart"
                  (click)="startPicker.open()"
                  [(ngModel)]="formModel.ProjectStart"
                  required
                  placeholder="{{
                    'ADD_DEPARTMENT_SELECT_START_DATE_PLACEHOLDER' | translate
                  }}"
                  onboardingAnchor="project-start-date-add-department"
                />
                <mat-datepicker #startPicker></mat-datepicker>
              </div>
            </div>

            <div class="form-group col-md-6 mb-4">
              <mat-label>{{
                "ADD_DEPARTMENT_PROJECT_END_DATE_LABEL" | translate
              }}</mat-label>
              <div class="input-group mb-3">
                <div class="input-group-prepend">
                  <span
                    (click)="endPicker.open()"
                    class="input-group-text"
                    id="end-time-icon-add-department"
                    style="height: 40px"
                  >
                    <i class="fa-light fa-calendar-days"></i>
                  </span>
                </div>
                <input
                  matInput
                  [matDatepicker]="endPicker"
                  class="form-control"
                  id="ProjectEnd-add-department"
                  name="ProjectEnd"
                  (click)="endPicker.open()"
                  [(ngModel)]="formModel.ProjectEnd"
                  placeholder="{{
                    'ADD_DEPARTMENT_SELECT_END_DATE_PLACEHOLDER' | translate
                  }}"
                  onboardingAnchor="project-end-date-add-department"
                />
                <mat-datepicker #endPicker></mat-datepicker>
              </div>
            </div>
          </div>

          <div class="row">
            <!-- Projektstatus und Abschluss -->
            <div class="form-group col-md-6 mb-4">
              <label for="Status-add-department">{{
                "ADD_DEPARTMENT_PROJECT_STATUS_LABEL" | translate
              }}</label>
              <mat-select
                id="Status-add-department"
                class="form-control"
                placeholder="{{
                  'ADD_DEPARTMENT_SELECT_PROJECT_STATUS_PLACEHOLDER' | translate
                }}"
                name="Status"
                [(ngModel)]="formModel.Status"
                required
                onboardingAnchor="project-status-add-department"
              >
                <mat-option
                  *ngFor="let status of projectStatusList"
                  [value]="status.value"
                >
                  {{ status.label | translate }}
                </mat-option>
              </mat-select>
            </div>

            <div class="form-group col-md-6 mb-4">
              <mat-checkbox
                [(ngModel)]="formModel.IsProjectCompleted"
                name="IsProjectCompleted"
                onboardingAnchor="project-completed-add-department"
              >
                {{ "ADD_DEPARTMENT_PROJECT_COMPLETED_LABEL" | translate }}
              </mat-checkbox>
            </div>
          </div>
        </div>

        <!-- Unsichtbare Anker für die neuen Onboarding-Schritte -->
        <div
          id="manage-qualifications-add-department"
          style="display: none"
        ></div>
        <div id="manage-projects-add-department" style="display: none"></div>
      </form>

      <!-- Bedingt interne Buttons anzeigen -->
      <div
        *ngIf="showButtons"
        class="modal-footer d-flex justify-content-between"
      >
        <button
          class="btn btn-outline-secondary"
          (click)="onClose()"
          type="button"
          data-dismiss="modal"
        >
          {{ "ADD_DEPARTMENT_CLOSE_BUTTON" | translate }}
        </button>
        <button
          class="btn btn-primary active"
          form="addDepartment-add-department"
          type="submit"
          [disabled]="!addDepartmentForm.valid"
        >
          {{ "ADD_DEPARTMENT_SAVE_CHANGES_BUTTON" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
