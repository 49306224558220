<div class="modal-dialog modal-lg" role="document">
  <div class="modal-content">
    <div class="modal-header text-center">
      <h5 class="modal-title w-100" id="exampleModalCenterTitle">{{heder}}</h5>
    </div>
    <div class="modal-body">
      <form class="user" id="addEmployee" #form='ngForm' autocomplete="off" (submit)="onSubmit(form)">
        <input type="hidden" #StarTime="ngModel" name="StarTime" [(ngModel)]="formModel.StarTime" />
        <input type="hidden" #EndTime="ngModel" name="EndTime" [(ngModel)]="formModel.EndTime" />
        <div class="row">
          <div class="form-group col-md-6 mb-3">
            <mat-label>{{'START OF WORK'| translate}}</mat-label>
            <input #Start="ngModel" name="Start" [min]="minTime" id="Start" class="form-control" [format]="24"
              [ngxMatTimepicker]="pickerStart" [(ngModel)]="hour.StarTime" required>
            <ngx-mat-timepicker #pickerStart [minutesGap]="5" (timeSet)="onTimeset($event)"></ngx-mat-timepicker>
          </div>
          <div class="form-group col-md-6 mb-3">
            <mat-label>{{'END OF WORK'| translate}}</mat-label>
            <input #End="ngModel" name="End" id="End" [min]="minEndTime" class="form-control" [format]="24"
              [ngxMatTimepicker]="pickerEnd" [(ngModel)]="hour.EndTime" required>
            <ngx-mat-timepicker #pickerEnd [minutesGap]="5"></ngx-mat-timepicker>
          </div>
        </div>
        <div class="row">
          <div class="form-group col-6">
            <mat-label>{{'QUALIFICATION'|translate}}</mat-label>
            <mat-select class="form-control" name="SkillId" placeholder="Skill" [(ngModel)]="formModel.SkillId"
              (selectionChange)="onIconChange($event)">
              <mat-select-trigger>
                <span *ngIf="selectedValue">
                  <i [ngClass]="selectedValue.IconCode + ' fa-lg'"></i>
                  {{selectedValue.Name}}
                </span>
              </mat-select-trigger>
              <mat-option *ngFor="let skill of skillList; let i = index" [value]="skill.Id" [attr.selected]="!i"><i
                  [ngClass]="skill.IconCode + ' fa-lg'"></i>{{skill.Name}}
              </mat-option>
            </mat-select>
          </div>
          <div class="form-group col-6 mb-4">
            <label for="NumberOfStaff">{{'NUMBER OF STAFF'|translate}}</label>
            <input type="number" class="form-control form-control-user" id="NumberOfStaff"
              placeholder="{{'Enter Email'|translate}}" #Email="ngModel" name="NumberOfStaff"
              [(ngModel)]="formModel.NumberOfStaff">
          </div>
        </div>
      </form>
    </div>
    <div class="modal-footer d-flex justify-content-between">
      <button class="btn btn-outline-secondary" (click)="onClose()" type="button"
        data-dismiss="modal">{{'Close'|translate}}</button>
      <button class="btn btn-primary" form="addEmployee" type="submit"
        [disabled]="form.invalid || form.value.Start=='01:00' || form.value.End=='01:00'">{{'Save'|translate}}</button>
    </div>
  </div>
</div>