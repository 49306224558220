import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AbsenceComponent } from './modules/absence/page/absence/absence.component';
import { DashboardComponent } from './modules/admin/pages/dashboard/dashboard.component';
import { ScheduleRequirementComponent } from './modules/admin/pages/schedule-requirement/schedule-requirement.component';
import { ShiftsPlannerComponent } from './modules/admin/pages/shifts-planner/shifts-planner.component';
import { AcceptInvitationComponent } from './modules/auth/pages/accept-invitation/accept-invitation.component';
import { ChangePasswordComponent } from './modules/auth/pages/change-password/change-password.component';
import { EmailConfirmationComponent } from './modules/auth/pages/email-confirmation/email-confirmation.component';
import { ForbiddenComponent } from './modules/auth/pages/forbidden/forbidden.component';
import { ForgotPasswordComponent } from './modules/auth/pages/forgot-password/forgot-password.component';
import { LoginComponent } from './modules/auth/pages/login/login.component';
import { RegisterUserComponent } from './modules/auth/pages/register-user/register-user.component';
import { RegisteredStepperComponent } from './modules/auth/pages/registered-stepper/registered-stepper.component';
import { ResetPasswordComponent } from './modules/auth/pages/reset-password/reset-password.component';
import { AuthGuard } from './modules/auth/services/guard/auth.guard';
import { AvailabilityPageComponent } from './modules/availability/availability-page/availability-page.component';
import { EmployeeDashboardComponent } from './modules/employee/page/employee-dashboard/employee-dashboard.component';
import { EmployeeComponent } from './modules/employee/page/employee/employee.component';
import { PaymentConfirmationComponent } from './modules/feature-toggle/component/payment-confirmation/payment-confirmation.component';
import { ReportsPageComponent } from './modules/reports/reports-page/reports-page.component';
import { ResourcePlanPageComponent } from './modules/resource-plan/resource-plan-page/resource-plan-page.component';
import { SettingsPageComponent } from './modules/settings/settings-page/settings-page.component';
import { AdminTimeRecordingPageComponent } from './modules/time-recording/admin-time-recording-page/admin-time-recording-page.component';
const routes: Routes = [
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  { path: 'forgotpassword', component: ForgotPasswordComponent },
  { path: 'registration', component: RegisterUserComponent },
  { path: 'forbidden', component: ForbiddenComponent },
  { path: 'email-confirmation', component: EmailConfirmationComponent },
  { path: 'accept-invitation', component: AcceptInvitationComponent },
  { path: 'resetpassword', component: ResetPasswordComponent },
  { path: 'change-password', component: ChangePasswordComponent },
  {
    path: 'registered-stepper',
    component: RegisteredStepperComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'employeedashboard',
    component: EmployeeDashboardComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [AuthGuard],
    data: { permittedRoles: ['MANAGER', 'TeamLeader'] },
  },
  {
    path: 'planner',
    component: ShiftsPlannerComponent,
    canActivate: [AuthGuard],
    data: { permittedRoles: ['MANAGER', 'TeamLeader'] },
  },
  {
    path: 'availability',
    component: AvailabilityPageComponent,
    canActivate: [AuthGuard],
    data: { permittedRoles: ['MANAGER', 'TeamLeader'] },
  },
  {
    path: 'settings',
    component: SettingsPageComponent,
    canActivate: [AuthGuard],
    data: { permittedRoles: ['MANAGER', 'TeamLeader'] },
  },
  {
    path: 'requirement-planer',
    component: ScheduleRequirementComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'employee',
    component: EmployeeComponent,
    canActivate: [AuthGuard],
    data: { permittedRoles: ['MANAGER', 'TeamLeader'] },
  },
  {
    path: 'resource-plan',
    component: ResourcePlanPageComponent,
    canActivate: [AuthGuard],
    data: { permittedRoles: ['MANAGER', 'TeamLeader'] },
  },
  {
    path: 'absence',
    component: AbsenceComponent,
    canActivate: [AuthGuard],
    data: { permittedRoles: ['MANAGER', 'TeamLeader'] },
  },
  {
    path: 'time-recording',
    component: AdminTimeRecordingPageComponent,
    canActivate: [AuthGuard],
    data: { permittedRoles: ['MANAGER', 'TeamLeader'] },
  },
  {
    path: 'reports',
    component: ReportsPageComponent,
    canActivate: [AuthGuard],
    data: { permittedRoles: ['MANAGER', 'TeamLeader'] },
  },
  {
    path: 'payment-confirmation',
    component: PaymentConfirmationComponent,
    canActivate: [AuthGuard],
    data: { permittedRoles: ['MANAGER', 'TeamLeader'] },
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
