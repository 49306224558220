import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ShiftSwapStatus } from 'src/app/core/services/const';
import { ShiftForSwap } from '../../models/shift-for-swap';
import { ShiftSwapService } from '../../services/shift-swap.service';

@Component({
  selector: 'app-targeted-swap-status',
  templateUrl: './targeted-swap-status.component.html',
  styleUrls: ['./targeted-swap-status.component.css'],
})
export class TargetedSwapStatusComponent implements OnInit {
  @Input() SwapId: number = 0;
  @Input() isEmployee = false;
  @Input() hideEmployeeAcceptButton = false;

  swappingStatussubscribe: any;
  Proposer: ShiftForSwap = new ShiftForSwap();
  RequestedShift: ShiftForSwap = new ShiftForSwap();
  buttonActive: boolean = false;
  employeeButtonActive: boolean = false;

  RequestedShiftBadgeContentText: string = '';
  RequestedShiftBadgeContentColor: string = '#0A4D68';
  acceptButtonColor: string = '#609260';
  AcceptButtonDisabled: boolean = true;
  @Output() adminAccepted = new EventEmitter<boolean>(false);
  @Output() employeeAccepted = new EventEmitter<boolean>(false);
  constructor(
    private translate: TranslateService,
    private shiftSwapService: ShiftSwapService
  ) {}

  ngOnInit(): void {
    this.swappingStatussubscribe =
      this.shiftSwapService.swappingStatus.subscribe((status) => {
        if (status.Proposer && status.RequestedShift) {
          const StarTimeTitle = new Date(status.Proposer.StartTime);

          status.Proposer.DateString = StarTimeTitle.toLocaleDateString(
            navigator.language,
            {
              month: 'long',
              day: '2-digit',
              weekday: 'short',
            }
          );
          const options: any = {
            hour: '2-digit',
            minute: '2-digit',
          };
          const StarTime = new Date(status.Proposer.StartTime);
          status.Proposer.StartTime = StarTime.toLocaleTimeString(
            navigator.language,
            options
          );

          const EndTime = new Date(status.Proposer.EndTime);
          status.Proposer.EndTime = EndTime.toLocaleTimeString(
            navigator.language,
            options
          );

          this.Proposer = status.Proposer;

          const StarTimeTitleRequested = new Date(
            status.RequestedShift.StartTime
          );
          //entry.Title =
          status.RequestedShift.DateString =
            StarTimeTitleRequested.toLocaleDateString(navigator.language, {
              month: 'long',
              day: '2-digit',
              weekday: 'short',
            });
          const optionsRequested: any = {
            hour: '2-digit',
            minute: '2-digit',
          };
          const StarTimeRequested = new Date(status.RequestedShift.StartTime);
          status.RequestedShift.StartTime =
            StarTimeRequested.toLocaleTimeString(
              navigator.language,
              optionsRequested
            );

          const EndTimeRequested = new Date(status.RequestedShift.EndTime);
          status.RequestedShift.EndTime = EndTimeRequested.toLocaleTimeString(
            navigator.language,
            optionsRequested
          );

          this.RequestedShift = status.RequestedShift;

          switch (status.RequestedShift.Status) {
            case ShiftSwapStatus.AcceptedFromEmployee:
              this.RequestedShiftBadgeContentText =
                this.translate.instant('ACCEPTED');
              this.RequestedShiftBadgeContentColor = this.acceptButtonColor;
              this.AcceptButtonDisabled = false;
              this.hideEmployeeAcceptButton = true;
              break;
            case ShiftSwapStatus.Created:
              this.RequestedShiftBadgeContentText =
                this.translate.instant('PENDING');
              this.RequestedShiftBadgeContentColor = '#e8c355';
              break;
            case ShiftSwapStatus.Rejected:
              this.RequestedShiftBadgeContentText =
                this.translate.instant('REJECTED');
              this.RequestedShiftBadgeContentColor = '#e37373';
              break;
          }
        }
      });
    if (this.SwapId > 0) {
      this.shiftSwapService.GetShiftSwapStatus(this.SwapId);
    }
  }

  ngOnDestroy(): void {
    this.swappingStatussubscribe.unsubscribe();
  }

  buttonAClick() {
    console.log('buttonAClick');
  }

  adminAcceptedButton() {
    this.buttonActive = !this.buttonActive;
    this.adminAccepted.emit(this.buttonActive);
  }

  employeeAcceptedButton() {
    this.employeeButtonActive = !this.employeeButtonActive;
    this.employeeAccepted.emit(this.employeeButtonActive);
    if (this.employeeButtonActive) {
      this.RequestedShiftBadgeContentText = this.translate.instant('ACCEPTED');
      this.RequestedShiftBadgeContentColor = this.acceptButtonColor;
    } else {
      this.RequestedShiftBadgeContentText = this.translate.instant('PENDING');
      this.RequestedShiftBadgeContentColor = '#e8c355';
    }
  }
}
