import {
  Component,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  EventEmitter,
  ViewChild,
} from '@angular/core';
import { NgForm } from '@angular/forms';
import { UntypedFormControl } from '@angular/forms';
import { Subscription, ReplaySubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DepartementService } from '../../services/departement.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EmployeeService } from '../../../employee/services/employee.service';
import { SkillService } from 'src/app/modules/skill/services/skill.service';
import {
  AddDepartement,
  DepartmentType,
  ProjectStatus,
} from '../../model/add-departement';
import { CompanySettingsService } from 'src/app/modules/settings/services/company-settings.service';
import {
  CompanySettings,
  WorkStructure,
} from 'src/app/modules/settings/model/company-settings.model';
import { Employee } from 'src/app/modules/employee/model/employee.model';
import { TranslateService } from '@ngx-translate/core';
import { Skill } from 'src/app/modules/skill/model/departement';
import { OnboardingService } from 'src/app/core/component/onboarding/onboarding.service';
import { OnboardingStep } from 'src/app/core/component/onboarding/onboarding-step.model';

@Component({
  selector: 'app-add-departement',
  templateUrl: './add-departement.component.html',
  styleUrls: ['./add-departement.component.css'],
})
export class AddDepartementComponent implements OnInit, OnDestroy {
  @Input() formModel: AddDepartement = new AddDepartement();
  @Input() showButtons: boolean = true; // Steuerung der internen Buttons
  @Input() showOnboarding: boolean = false; // Steuerung der Onboarding-Anzeige
  @Output() onSave = new EventEmitter<AddDepartement>(); // Ereignis zum Emittieren der gespeicherten Daten

  @ViewChild('addDepartmentForm') form: NgForm; // Zugriff auf das Formular

  projectStatusList = [];
  workStructure: WorkStructure = WorkStructure.Departments;
  workStructureEnum = WorkStructure;
  departmentList = [];
  employeeList: Employee[] = [];
  skillList: Skill[] = [];
  isOnboardingActive: boolean;

  // FormControls für die Suchfunktionalität
  projectManagerCtrl: UntypedFormControl = new UntypedFormControl();
  projectManagerFilterCtrl: UntypedFormControl = new UntypedFormControl();
  skillValue: UntypedFormControl = new UntypedFormControl();

  // Gefilterte Liste für Projektmanager
  filteredProjectManagers: ReplaySubject<Employee[]> = new ReplaySubject<
    Employee[]
  >(1);

  protected _onDestroy = new Subject<void>();

  departmentTypeEnum = DepartmentType;

  // Definieren der Onboarding-Schritte mit eindeutigen IDs
  onboardingSteps: OnboardingStep[] = [
    {
      anchorId: 'name-add-department',
      title: this.translate.instant('ADD_DEPARTMENT_NAME_LABEL'),
      content: this.isProject()
        ? this.translate.instant('ADD_PROJEKT_ENTER_NAME_PLACEHOLDER')
        : this.translate.instant('ADD_DEPARTMENT_ENTER_NAME_PLACEHOLDER'),
      placement: 'left',
    },
    {
      anchorId: 'parent-department-add-department',
      title: this.translate.instant('ADD_DEPARTMENT_PARENT_DEPARTMENT_LABEL'),
      content: this.translate.instant(
        'ADD_DEPARTMENT_PARENT_DEPARTMENT_DESCRIPTION'
      ),
      placement: 'left',
    },
    {
      anchorId: 'skill-ids-add-department',
      title: this.translate.instant('ADD_DEPARTMENT_ABILITY_COMPETENCE_LABEL'),
      content: this.translate.instant(
        'ADD_DEPARTMENT_ABILITY_COMPETENCE_DESCRIPTION'
      ),
      placement: 'right',
    },
    {
      anchorId: 'project-manager-add-department',
      title: this.translate.instant('ADD_DEPARTMENT_PROJECT_MANAGER_LABEL'),
      content: this.translate.instant(
        'ADD_DEPARTMENT_PROJECT_MANAGER_DESCRIPTION'
      ),
      placement: 'left',
    },
    {
      anchorId: 'description-add-department',
      title: this.translate.instant('ADD_DEPARTMENT_DESCRIPTION_LABEL'),
      content: this.translate.instant('ADD_DEPARTMENT_DESCRIPTION_DESCRIPTION'),
      placement: 'left',
    },
    {
      anchorId: 'project-start-date-add-department',
      title: this.translate.instant('ADD_DEPARTMENT_PROJECT_START_DATE_LABEL'),
      content: this.translate.instant(
        'ADD_DEPARTMENT_PROJECT_START_DATE_DESCRIPTION'
      ),
      placement: 'left',
    },
    {
      anchorId: 'project-end-date-add-department',
      title: this.translate.instant('ADD_DEPARTMENT_PROJECT_END_DATE_LABEL'),
      content: this.translate.instant(
        'ADD_DEPARTMENT_PROJECT_END_DATE_DESCRIPTION'
      ),
      placement: 'right',
    },
    {
      anchorId: 'project-status-add-department',
      title: this.translate.instant('ADD_DEPARTMENT_PROJECT_STATUS_LABEL'),
      content: this.translate.instant(
        'ADD_DEPARTMENT_PROJECT_STATUS_DESCRIPTION'
      ),
      placement: 'left',
    },
    {
      anchorId: 'project-completed-add-department',
      title: this.translate.instant('ADD_DEPARTMENT_PROJECT_COMPLETED_LABEL'),
      content: this.translate.instant(
        'ADD_DEPARTMENT_PROJECT_COMPLETED_DESCRIPTION'
      ),
      placement: 'right',
    },
    {
      anchorId: 'manage-qualifications-add-department',
      title: this.translate.instant(
        'ADD_DEPARTMENT_MANAGE_QUALIFICATIONS_TITLE'
      ),
      content: this.translate.instant(
        'ADD_DEPARTMENT_EXPLANATION_CATEGORY_DEPARTMENT'
      ),
      placement: 'bottom',
    },
    {
      anchorId: 'manage-projects-add-department',
      title: this.translate.instant('ADD_DEPARTMENT_MANAGE_PROJECTS_TITLE'),
      content: this.translate.instant(
        'ADD_DEPARTMENT_EXPLANATION_CATEGORY_PROJECT'
      ),
      placement: 'bottom',
    },
  ];

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    @Optional() public dialogRef: MatDialogRef<AddDepartementComponent>,
    public service: DepartementService,
    private companySettingsService: CompanySettingsService,
    private employeeService: EmployeeService,
    private skillService: SkillService,
    private translate: TranslateService,
    private onboardingService: OnboardingService
  ) {
    // Initialisierung der projectStatusList mit Übersetzungsschlüsseln
    this.projectStatusList = [
      {
        value: ProjectStatus.Planned,
        label: this.translate.instant('PLANNED'),
      },
      {
        value: ProjectStatus.InProgress,
        label: this.translate.instant('IN_PROGRESS'),
      },
      {
        value: ProjectStatus.Completed,
        label: this.translate.instant('COMPLETED'),
      },
      { value: ProjectStatus.OnHold, label: this.translate.instant('ON_HOLD') },
      {
        value: ProjectStatus.Cancelled,
        label: this.translate.instant('CANCELLED'),
      },
    ];
  }

  // Getter zur Überprüfung der Formvalidität
  get isValid(): boolean {
    return this.form?.valid;
  }

  ngOnInit(): void {
    // Abonnieren der CompanySettings
    this.companySettingsService.CompanySettingsObservable.subscribe(
      (settings: CompanySettings) => {
        this.workStructure = settings.WorkStructure;
      }
    );

    // Laden der Abteilungsliste für ParentDepartmentId
    this.service.getDepartementList();
    this.service.departementList.subscribe((departments) => {
      this.departmentList = departments;
    });

    // Laden der Mitarbeiterliste für ProjectManagerId
    this.employeeService.getEmployeeList();
    this.employeeService.employeeList.subscribe((employees) => {
      this.employeeList = employees;
      // Initiale gefilterte Liste setzen
      this.filteredProjectManagers.next(this.employeeList.slice());

      // Initiale Daten laden
      if (this.formModel.ProjectManagerId) {
        const selectedManager = this.employeeList.find(
          (emp) => emp.Id === this.formModel.ProjectManagerId
        );
        this.projectManagerCtrl.setValue(selectedManager);
      }
    });

    // Laden der Skillliste
    this.skillService.getAllSkillList(); // Skills abrufen
    this.skillService.AllSkillList.subscribe((skills) => {
      this.skillList = skills as Skill[];
    });

    // Einrichten der Suchfunktionalität
    this.projectManagerFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterProjectManagers();
      });

    // Initialisierung des Formulars
    if (this.data && this.data.departement && this.data.departement.Id) {
      this.formModel = { ...this.data.departement };

      // Setzen der Skill-IDs, falls vorhanden
      if (this.formModel.SkillIds && this.formModel.SkillIds.length > 0) {
        this.skillValue.setValue(this.formModel.SkillIds);
      }
    }

    // Initialen Wert für ProjectManagerId setzen
    if (this.formModel.ProjectManagerId) {
      const selectedManager = this.employeeList.find(
        (emp) => emp.Id === this.formModel.ProjectManagerId
      );
      this.projectManagerCtrl.setValue(selectedManager);
    }

    if (this.showOnboarding) {
      this.onboardingService.setSteps(this.onboardingSteps);
    }
  }

  // Methode zum Filtern der Projektmanager
  protected filterProjectManagers() {
    if (!this.employeeList) {
      return;
    }
    // Suchbegriff erhalten
    let search = this.projectManagerFilterCtrl.value;
    if (!search) {
      this.filteredProjectManagers.next(this.employeeList.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // Mitarbeiter filtern
    this.filteredProjectManagers.next(
      this.employeeList.filter((employee) =>
        (employee.FirstName + ' ' + employee.LastName)
          .toLowerCase()
          .includes(search)
      )
    );
  }

  // Überprüfung, ob es sich um ein Projekt handelt
  isProject(): boolean {
    return (
      this.workStructure === WorkStructure.Projects ||
      (this.workStructure === WorkStructure.Both &&
        this.formModel.Type === DepartmentType.Project)
    );
  }

  // Externe Methode zum Speichern, aufrufbar von der Elternkomponente
  save(): void {
    this.onSubmit(this.form);
  }

  onDepartmentTypeChange(): void {
    console.log('Department Type Changed' + this.formModel.Type);
    this.onboardingService.updateStepContentById(
      'name-add-department',
      this.isProject()
        ? this.translate.instant('ADD_PROJEKT_ENTER_NAME_PLACEHOLDER')
        : this.translate.instant('ADD_DEPARTMENT_ENTER_NAME_PLACEHOLDER')
    );
  }

  // Methode zum Speichern des Formulars
  onSubmit(form: NgForm): void {
    if (form.valid) {
      // Setzen des Typs basierend auf WorkStructure
      if (this.workStructure === WorkStructure.Departments) {
        this.formModel.Type = DepartmentType.Department;
      } else if (this.workStructure === WorkStructure.Projects) {
        this.formModel.Type = DepartmentType.Project;
      }

      // Setzen der ProjectManagerId aus dem ausgewählten Mitarbeiter
      if (this.isProject() && this.projectManagerCtrl.value) {
        this.formModel.ProjectManagerId = this.projectManagerCtrl.value.Id;
      } else {
        this.formModel.ProjectManagerId = null;
      }

      // Setzen der ausgewählten Skills
      this.formModel.SkillIds = this.skillValue.value;

      // Übergeben des gesamten formModel an den Service
      this.service.submitDepartement(this.formModel).subscribe({
        next: () => {
          if (this.dialogRef) {
            // Wenn als Dialog verwendet, schließen und Daten zurückgeben
            this.dialogRef.close(this.formModel);
          } else {
            // Wenn eingebettet, das onSave Ereignis emittieren
            this.onSave.emit(this.formModel);
          }
        },
        error: (error) => {
          console.error('Fehler beim Speichern des Departements', error);
          // Optional: Fehlerbehandlung hinzufügen
        },
      });
    }
  }

  // Methode zum Schließen des Dialogs
  onClose(): void {
    if (this.dialogRef) {
      this.dialogRef.close();
    }
  }
  toggleOnboarding(): void {
    this.isOnboardingActive = !this.isOnboardingActive;
    if (this.isOnboardingActive) {
      this.onboardingService.setSteps(this.onboardingSteps);
    } else {
      this.onboardingService.removeSteps(this.onboardingSteps);
    }
  }

  ngOnDestroy(): void {
    this._onDestroy.next();
    this._onDestroy.complete();
    this.onboardingService.removeSteps(this.onboardingSteps);
  }
}
