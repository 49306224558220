import { Injectable } from '@angular/core';
import { OnboardingStep } from './onboarding-step.model';
import { OnboardingComponent } from './onboarding.component';
import { ConnectedPosition, Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class OnboardingService {
  private steps: OnboardingStep[] = [];
  private currentStepIndex: number = -1;
  private overlayRef: OverlayRef;

  private showTooltipSubject = new Subject<OnboardingStep>();
  showTooltip$ = this.showTooltipSubject.asObservable();
  private anchors = new Map<string, HTMLElement>();

  constructor(private overlay: Overlay) {}

  setSteps(steps: OnboardingStep[]): void {
    this.steps = [...this.steps, ...steps];
  }

  start(): void {
    this.currentStepIndex = -1;
    this.nextStep();
  }

  // Methode zum Entfernen mehrerer Schritte basierend auf einer Liste von Schritten
  removeSteps(stepsToRemove: OnboardingStep[]): void {
    stepsToRemove.forEach((stepToRemove) => {
      this.steps = this.steps.filter(
        (step) => step.anchorId !== stepToRemove.anchorId
      );
    });
  }

  updateStepContentById(anchorId: string, newContent: string): void {
    const step = this.steps.find((s) => s.anchorId === anchorId);
    if (step) {
      step.content = newContent;
    } else {
      console.warn(`Step with anchorId ${anchorId} not found.`);
    }
  }

  nextStep(): void {
    this.currentStepIndex++;
    if (this.currentStepIndex < this.steps.length) {
      const step = this.steps[this.currentStepIndex];
      this.showStep(step);
    } else {
      this.end();
    }
  }

  showStep(step: OnboardingStep): void {
    this.removeOverlay();

    const targetElement = document.querySelector(
      `[onboardingAnchor="${step.anchorId}"]`
    ) as HTMLElement;
    if (!targetElement) {
      console.error(`Onboarding anchor with id '${step.anchorId}' not found.`);
      this.nextStep();
      return;
    }

    const positionStrategy = this.overlay
      .position()
      .flexibleConnectedTo(targetElement)
      .withPositions(this.getPosition(step.placement));

    this.overlayRef = this.overlay.create({
      positionStrategy,
      hasBackdrop: false,
      scrollStrategy: this.overlay.scrollStrategies.reposition(),
    });

    const tooltipPortal = new ComponentPortal(OnboardingComponent);
    const componentRef = this.overlayRef.attach(tooltipPortal);

    componentRef.instance.step = step;
    componentRef.instance.next.subscribe(() => this.nextStep());
  }

  end(): void {
    this.currentStepIndex = -1;
    this.removeOverlay();
  }

  private removeOverlay(): void {
    if (this.overlayRef) {
      this.overlayRef.dispose();
      this.overlayRef = null;
    }
  }

  private getPosition(
    placement: 'left' | 'right' | 'top' | 'bottom' | undefined
  ): ConnectedPosition[] {
    switch (placement) {
      case 'left':
        return [
          {
            originX: 'start',
            originY: 'center',
            overlayX: 'end',
            overlayY: 'center',
            offsetX: -10,
          },
        ];
      case 'right':
        return [
          {
            originX: 'end',
            originY: 'center',
            overlayX: 'start',
            overlayY: 'center',
            offsetX: 10,
          },
        ];
      case 'top':
        return [
          {
            originX: 'center',
            originY: 'top',
            overlayX: 'center',
            overlayY: 'bottom',
            offsetY: -10,
          },
        ];
      case 'bottom':
      default:
        return [
          {
            originX: 'center',
            originY: 'bottom',
            overlayX: 'center',
            overlayY: 'top',
            offsetY: 10,
          },
        ];
    }
  }

  // Optional: Methode zum Anzeigen eines Schritts basierend auf anchorId
  showStepById(anchorId: string): void {
    const stepIndex = this.steps.findIndex((s) => s.anchorId === anchorId);
    if (stepIndex !== -1) {
      this.currentStepIndex = stepIndex - 1; // Setzen Sie den Index zurück
      this.nextStep();
    } else {
      console.error(`No onboarding step found for anchorId '${anchorId}'`);
    }
  }
  triggerStep(anchorId: string): void {
    const step = this.steps.find((s) => s.anchorId === anchorId);
    if (step) {
      const element = this.anchors.get(anchorId);
      if (element) {
        step.element = element;
        this.showTooltipSubject.next(step);
      } else {
        console.error(`Element für anchorId '${anchorId}' nicht registriert.`);
      }
    }
  }

  registerAnchor(anchorId: string, element: HTMLElement): void {
    this.anchors.set(anchorId, element);
  }
}
