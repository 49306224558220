import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { ShiftForSwap } from '../../models/shift-for-swap';
import { ShiftSwapService } from '../../services/shift-swap.service';

@Component({
  selector: 'app-add-swap-request',
  templateUrl: './add-swap-request.component.html',
  styleUrls: ['./add-swap-request.component.css'],
})
export class AddSwapRequestComponent implements OnInit {
  buttonTitle = this.translate.instant('SWAPING');
  potentialShiftSwappersList: ShiftForSwap[] = [];
  private subscriptionpotentialShiftSwappersList: Subscription;
  EventIdForSwap: string | null = null;
  swapType: string = 'open'; // Default to open request

  constructor(
    public dialogRef: MatDialogRef<AddSwapRequestComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private translate: TranslateService,
    private shiftSwapService: ShiftSwapService
  ) {}

  ngOnInit(): void {
    this.subscriptionpotentialShiftSwappersList =
      this.shiftSwapService.potentialShiftSwappersList.subscribe(
        (list: ShiftForSwap[]) => {
          list.forEach((entry) => {
            const StarTimeTitle = new Date(entry.StartTime);
            //entry.Title =
            entry.DateString = StarTimeTitle.toLocaleDateString(
              navigator.language,
              {
                month: '2-digit',
                day: '2-digit',
              }
            );

            const options: any = {
              hour: '2-digit',
              minute: '2-digit',
            };

            const StarTime = new Date(entry.StartTime);
            entry.StartTime = StarTime.toLocaleTimeString(
              navigator.language,
              options
            );

            const EndTime = new Date(entry.EndTime);
            entry.EndTime = EndTime.toLocaleTimeString(
              navigator.language,
              options
            );
          });

          this.potentialShiftSwappersList = list;
        }
      );
    if (this.data?.EventId) {
      this.shiftSwapService.GetPotentialShiftSwappersList(this.data.EventId);
    }
  }

  onClose(): void {
    this.dialogRef.close();
  }
  onSubmit(): void {
    this.shiftSwapService.AddTargetedRequestToSwapRequest(
      this.data.EventId,
      this.EventIdForSwap
    );

    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    this.subscriptionpotentialShiftSwappersList.unsubscribe();
  }
  selectShiftSwap(id): void {
    this.EventIdForSwap = id;
  }

  // Method to handle change in swapType
  onSwapTypeChange(event: any) {
    if (event.value === 'open') {
      this.EventIdForSwap = null;
    }
  }
}
