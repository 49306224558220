<div class="row" style="padding-top: 8px">
  <div class="col-4"></div>
  <div class="col-8">
    <mat-form-field>
      <mat-label>{{ "RECORDED TIMES" | translate }}</mat-label>
      <mat-date-range-input
        [rangePicker]="picker"
        (click)="picker.open()"
        (dateChange)="onDateChange($event)"
        (dateInput)="onDateChange($event)"
      >
        <input
          inputmode="none"
          matStartDate
          name="StarTime"
          [(ngModel)]="timeRecordingService.timeRange.StarTime"
          placeholder="Start date"
          required
        />
        <input
          inputmode="none"
          matEndDate
          name="EndTime"
          [(ngModel)]="timeRecordingService.timeRange.EndTime"
          placeholder="End date"
          required
        />
      </mat-date-range-input>
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-date-range-picker
        #picker
        (closed)="onDatePickerClose()"
        touchUi
      ></mat-date-range-picker>
    </mat-form-field>
  </div>
</div>

<div style="max-height: 40vh; overflow-y: auto">
  <app-time-recording-accordion
    (clockEvent)="handleClockEvent($event)"
    [eventListAccordion]="eventListAccordion"
  >
  </app-time-recording-accordion>
  <p *ngIf="eventListAccordion.length === 0" class="text-center">
    {{ "THERE ARE NO ENTRIES AVAILABLE FOR THE SPECIFIED PERIOD" | translate }}
  </p>
</div>
