import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { EventType } from 'src/app/core/services/const';
import { OpenShiftsService } from '../../services/open-shifts.service';
import { ShowWorkShiftDetailsComponent } from '../show-work-shift-details/show-work-shift-details.component';

@Component({
  selector: 'app-open-work-shift-list-admin',
  templateUrl: './open-work-shift-list-admin.component.html',
  styleUrls: ['./open-work-shift-list-admin.component.css'],
})
export class OpenWorkShiftListAdminComponent implements OnInit {
  constructor(private service: OpenShiftsService, private dialog: MatDialog) {}

  private subscriptionOpenShiftList: Subscription;

  @Output() showOpenWorkShiftList = new EventEmitter<boolean>(false);

  openShiftList: any[];
  ngOnInit(): void {
    this.service.getOpenShiftListForAdmin();
    this.showOpenWorkShiftList.emit(false);
    this.subscriptionOpenShiftList =
      this.service.employeeOpenShiftList.subscribe((list) => {
        const cloned = list.map((x) => Object.assign({}, x));
        cloned.forEach((entry) => {
          const StarTimeTitle = new Date(entry.StarTime);
          //entry.Title =
          entry.Date = StarTimeTitle.toLocaleDateString(navigator.language, {
            month: 'long',
            day: '2-digit',
            weekday: 'short',
          });
          const options: any = {
            hour: '2-digit',
            minute: '2-digit',
          };
          const StarTime = new Date(entry.StarTime);
          entry.StarTime = StarTime.toLocaleTimeString(
            navigator.language,
            options
          );

          const EndTime = new Date(entry.EndTime);
          entry.EndTime = EndTime.toLocaleTimeString(
            navigator.language,
            options
          );
        });
        if (cloned.length) {
          this.showOpenWorkShiftList.emit(true);
        } else {
          this.showOpenWorkShiftList.emit(false);
        }
        this.openShiftList = cloned;
      });
  }

  openShiftDetails(EventId): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;

    dialogConfig.data = { eventId: EventId, eventType: EventType.OpenShift };
    dialogConfig.panelClass = 'custom-dialog-container';
    this.dialog.open(ShowWorkShiftDetailsComponent, dialogConfig);
  }
}
