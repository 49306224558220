// add-custom-holiday.component.ts

import {
  Component,
  Inject,
  OnDestroy,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  Optional,
} from '@angular/core';
import { NgForm } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  CustomHolidayDto,
  HolidayDto,
  HolidayService,
} from '../../services/holiday.service';

@Component({
  selector: 'app-add-custom-holiday',
  templateUrl: './add-custom-holiday.component.html',
  styleUrls: ['./add-custom-holiday.component.css'],
})
export class AddCustomHolidayComponent implements OnInit, OnDestroy {
  isEditMode: boolean = false;

  formModel: CustomHolidayDto = {
    Date: new Date(),
    HolidayName: '',
    IsRecurring: false,
    RecurrencePattern: '',
    IsHalfDay: false,
    HolidayId: '', // Add this property
  };

  @Input() showButtons: boolean = true; // Steuerung der internen Buttons
  @Output() onSave = new EventEmitter<CustomHolidayDto>(); // Ereignis zum Emittieren der gespeicherten Daten

  @ViewChild('addCustomHolidayForm') form: NgForm; // Zugriff auf das Formular

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    @Optional() public dialogRef: MatDialogRef<AddCustomHolidayComponent>,
    private holidayService: HolidayService
  ) {}

  ngOnInit(): void {
    if (this.data && this.data.holiday) {
      this.isEditMode = true;
      const holiday = this.data.holiday as HolidayDto;
      this.formModel = {
        Date: new Date(holiday.StartDate),
        HolidayName: this.getHolidayName(holiday),
        IsRecurring: holiday.IsRecurring,
        RecurrencePattern: holiday.RecurrencePattern,
        IsHalfDay: holiday.DurationInDays < 1,
        HolidayId: holiday.Id, // New property to track existing holiday
      };
    } else {
      this.isEditMode = false;
      // Initialize formModel with default values
    }
  }

  onSubmit(form: NgForm): void {
    if (form.valid) {
      if (this.isEditMode) {
        // Call update method
        this.holidayService.updateCustomHoliday(this.formModel).subscribe({
          next: () => {
            if (this.dialogRef) {
              this.dialogRef.close(this.formModel);
            } else {
              this.onSave.emit(this.formModel);
            }
          },
          error: (error) => {
            console.error('Fehler beim Aktualisieren des Feiertags', error);
          },
        });
      } else {
        // Call add method
        this.holidayService.addCustomHoliday(this.formModel).subscribe({
          next: () => {
            if (this.dialogRef) {
              this.dialogRef.close(this.formModel);
            } else {
              this.onSave.emit(this.formModel);
            }
          },
          error: (error) => {
            console.error('Fehler beim Hinzufügen des Feiertags', error);
          },
        });
      }
    }
  }

  onClose(): void {
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    // Ressourcenbereinigung, falls erforderlich
  }

  // Methode zum Speichern, aufrufbar von der Elternkomponente
  save(): void {
    this.onSubmit(this.form);
  }

  // Getter zur Überprüfung der Formvalidität
  get isValid(): boolean {
    return this.form?.valid;
  }

  getHolidayName(holiday: HolidayDto): string {
    const preferredLanguage = 'DE';
    const nameEntry = holiday.Name.find(
      (n) => n.Language === preferredLanguage
    );
    return nameEntry ? nameEntry.Text : holiday.Name[0]?.Text || '';
  }
}
