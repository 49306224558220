import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FullCalendarComponent } from '@fullcalendar/angular';
import { CalendarOptions } from '@fullcalendar/core';
import interaction from '@fullcalendar/interaction';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import { TranslateService } from '@ngx-translate/core';
import * as dayjs from 'dayjs';
import { Subscription } from 'rxjs';
import { SortShiftBy } from 'src/app/core/services/const';
import { CompanySettingsService } from 'src/app/modules/settings/services/company-settings.service';
import { CalendarService } from '../../services/calendar.service';
@Component({
  selector: 'app-work-shifts-timeline-dashboard',
  templateUrl: './work-shifts-timeline-dashboard.component.html',
  styleUrls: ['./work-shifts-timeline-dashboard.component.css'],
})
export class workShiftsTimelineDashboardComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  /*.initOptions..*/
  currentCalendarData: any;
  calendarApi: any;

  calendarOptions: CalendarOptions = {
    plugins: [resourceTimelinePlugin, interaction],
    schedulerLicenseKey: 'GPL-My-Project-Is-Open-Source',
    locale: this.translate.currentLang,
    firstDay: this.companyService.CompanySettings.CalendarOptions.FirstDay,
    initialView: 'customDay',
    slotEventOverlap: false,
    slotMinTime:
      this.companyService.CompanySettings.CalendarOptions
        .BusinessHoursStartTime,
    slotMaxTime:
      this.companyService.CompanySettings.CalendarOptions.BusinessHoursEndTime,
    slotDuration: '24:00:00',
    resourcesInitiallyExpanded: true,
    weekNumbers: true,
    resourceAreaWidth: '15%',
    height: 'auto',
    // scrollTime to actual time - 1 hour on init calendar
    scrollTime: dayjs().subtract(1, 'hours').format('HH:mm:ss'),
    resourceAreaColumns: [
      {
        field: 'title',
      },
    ],
    resourceGroupField: 'departement',
    headerToolbar: {
      left: '',
      center: '',
      right: '',
    },
    views: {
      customDay: {
        type: 'resourceTimeline',
        duration: { day: 1 },
        slotDuration: { hour: 0.5 },
        buttonText: this.translate.instant('Day'),
        titleFormat: {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
          weekday: 'long',
        },
      },
    },
    businessHours: {
      startTime:
        this.companyService.CompanySettings.CalendarOptions
          .BusinessHoursStartTime,
      endTime:
        this.companyService.CompanySettings.CalendarOptions
          .BusinessHoursEndTime,

      daysOfWeek: [0, 1, 2, 3, 4, 5, 6],
      // days of week. an array of zero-based day of week integers (0=Sunday)
      // (Monday-Thursday in this example)
    },
    weekText: this.translate.instant('calendar week'),
    resourceOrder: 'sortOrder',
    aspectRatio: 1.6,
    displayEventTime: true,
    nowIndicator: true,
    weekends: true,
    editable: false,
    selectable: false,
    selectMirror: true,
    dayMaxEvents: true,
    handleWindowResize: true,
    eventContent: this.handleEventContent.bind(this),
  };

  @ViewChild('calendar') calendarComponent: FullCalendarComponent;

  constructor(
    private service: CalendarService,
    private translate: TranslateService,
    private companyService: CompanySettingsService
  ) {}
  private subscriptionEmployeeEventList: Subscription;

  ngOnInit(): void {
    this.subscriptionEmployeeEventList =
      this.service.employeeTimelineEventList.subscribe(
        async (timelineEvents) => {
          if (timelineEvents == null) {
            return;
          }

          timelineEvents.calenderEvents.forEach((event) => {
            event.editable = false;
          });

          this.calendarOptions.events = timelineEvents.calenderEvents;
          this.calendarOptions.resources = timelineEvents.calenderResources;
        }
      );

    this.service.currentFilter = SortShiftBy.ByShiftStart;
    this.service.getTimelineEvent(true, Date.now(), true, false, true, '*');
  }
  ngAfterViewInit(): void {
    this.calendarApi = this.calendarComponent.getApi();
    if (this.service.calendarCurrentStart && this.calendarApi) {
      this.calendarApi.gotoDate(this.service.calendarCurrentStart);
    }
    this.currentCalendarData = this.calendarApi.getCurrentData();
  }

  ngOnDestroy(): void {
    this.subscriptionEmployeeEventList.unsubscribe();
  }

  handleEventContent(event): any {
    // return {
    //   html:
    //     "<em class='fal fa-" +
    //     event.event._def.extendedProps.icon +
    //     "'></em> " +
    //     event.event._def.title,
    // };

    return {
      html:
        '<div class="fc-event-time">' +
        event.timeText +
        ' <em class="' +
        event.event._def.extendedProps.icon +
        ' fa-lg' +
        '"></em> ' +
        event.event._def.title +
        '</div>',
    };
    return true;
  }
}
