import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { FormControl, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';

@Component({
  selector: 'app-input-editable',
  templateUrl: './input-editable.component.html',
  styleUrls: ['./input-editable.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputEditableComponent),
      multi: true,
    },
  ],
})
export class InputEditableComponent implements OnInit {
  onChange: (val: any) => void;
  onTouched: () => void;

  @Output() onSave = new EventEmitter<boolean>();

  @Input() showTimepicker: boolean = false;
  @Input() showNormalInput: boolean = false;
  @Input() showDropdownInput: boolean = false;
  @Input() showAutocomplete: boolean = false;
  @Input() dropdownListe: { key: string | number; value: string }[] = [];
  @Input() multiple: boolean = false; // Neue Eingabe für Mehrfachauswahl

  selectedTime: string = '';
  @Input() validatorsPattern: string = '';
  @Input() suggestions: string[] = [];
  timeInputControl = new FormControl('', [
    Validators.required,
    Validators.pattern(this.validatorsPattern),
  ]);
  filteredSuggestions: string[] = [];

  hour = {
    StarTime: '',
    EndTime: '',
  };

  // random html id
  inputId = Math.random().toString(36).substring(7);

  value: any;
  originalValue: any; // Speichern des ursprünglichen Werts, um bei Abbruch darauf zurückzusetzen

  dropdownValue: string;
  currentIcon = 'edit';
  editing: boolean = false; // Status, ob gerade bearbeitet wird

  constructor() {}

  ngOnInit(): void {}

  getDropdownValue(value: any): string {
    if (this.multiple && Array.isArray(value)) {
      const selectedItems = this.dropdownListe.filter((item) =>
        value.includes(item.key)
      );
      return selectedItems.map((item) => item.value).join(', ');
    } else {
      return this.getDropdownSingleValue(value);
    }
  }

  private getDropdownSingleValue(key: string | number): string {
    // Prüfen, ob die Dropdown-Liste nicht leer ist
    if (this.dropdownListe.length > 0) {
      // Suchen des Eintrags mit passendem Key
      const dropdownItem = this.dropdownListe.find(
        (dropdown) => dropdown.key === key
      );
      return dropdownItem ? dropdownItem.value : '';
    }
    return '';
  }

  writeValue(value: any): void {
    if (this.multiple) {
      this.value = Array.isArray(value) ? value : [];
      this.originalValue = [...this.value]; // Kopie des Arrays
    } else {
      this.value =
        value || (typeof value === 'number' && value >= 0) ? value : '';
      this.originalValue = this.value; // Speichern des ursprünglichen Werts
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  changeButtonStatus(): void {
    if (this.currentIcon === 'check') {
      this.onSave.emit(true);
    }
    this.currentIcon = this.currentIcon === 'edit' ? 'check' : 'edit';
    this.editing = !this.editing;

    this.onChange(this.value);
    // call after 100ms to make sure the input field is rendered
    setTimeout(() => {
      this.adjustInputWidth(this.value);
    }, 50);
  }

  adjustInputWidth(value: string | number | any[]) {
    // Ermitteln Sie das Eingabefeld-Element
    const inputElement = document.getElementById(
      this.inputId
    ) as HTMLInputElement;

    if (inputElement) {
      let displayValue = '';

      if (this.multiple && Array.isArray(value)) {
        displayValue = value
          .map((val) => this.getDropdownSingleValue(val))
          .join(', ');
      } else {
        displayValue = value.toString();
      }

      // Setzen Sie eine Basisbreite für das Eingabefeld und multiplizieren Sie sie mit der Länge des Inhalts
      const minWidth = 50; // Minimalbreite des Feldes
      const maxWidth = 200; // Maximalbreite des Feldes
      const calculatedWidth = Math.max(
        minWidth,
        Math.min(
          (displayValue.length + 3) * 8, // Anpassung der Breitenberechnung für bessere Darstellung
          maxWidth
        )
      );

      // Setzen Sie die neue Breite für das Eingabefeld
      inputElement.style.width = `${calculatedWidth}px`;
    }
  }

  cancelEdit(): void {
    if (this.multiple) {
      this.value = [...this.originalValue]; // Zurücksetzen auf den ursprünglichen Wert
    } else {
      this.value = this.originalValue; // Zurücksetzen auf den ursprünglichen Wert
    }
    this.currentIcon = 'edit';
    this.editing = false;
  }

  onInputChange(event: any) {
    const inputValue = event.target.value.toLowerCase();
    this.filteredSuggestions = this.suggestions.filter((suggestion) =>
      suggestion.toLowerCase().includes(inputValue)
    );
  }

  selectSuggestion(event: MatAutocompleteSelectedEvent, suggestion: string) {
    this.value = suggestion;
  }
}
