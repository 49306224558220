import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { BottomNavigationEmployeeService } from 'src/app/navigation/bottom-navigation-employee/bottom-navigation-employee.service';
import { EmployeeShiftSwap } from '../../models/employee-shift-swap';
import { ShiftForSwap } from '../../models/shift-for-swap';
import { ShiftSwapService } from '../../services/shift-swap.service';
import { ShowResponsedWorkShiftForSwapComponent } from '../show-responsed-work-shift-for-swap/show-responsed-work-shift-for-swap.component';
import { ShowTargetedSwapShiftComponent } from '../show-targeted-swap-shift/show-targeted-swap-shift.component';

@Component({
  selector: 'app-show-my-shift-swap',
  templateUrl: './show-my-shift-swap.component.html',
  styleUrls: ['./show-my-shift-swap.component.css'],
})
export class ShowMyShiftSwapComponent implements OnInit, OnDestroy {
  private subscriptionEmployeeSwapShiftsList: Subscription;
  public swapShiftList = new Array<EmployeeShiftSwap>();

  constructor(
    private shiftSwapService: ShiftSwapService,
    private dialog: MatDialog,
    private bottomNavigationEmployeeService: BottomNavigationEmployeeService
  ) {}

  @Output() showListInDashbord = new EventEmitter<boolean>(false);

  ngOnInit(): void {
    this.showListInDashbord.emit(false);
    this.subscriptionEmployeeSwapShiftsList =
      this.shiftSwapService.employeeSwapShiftList.subscribe((list: any) => {
        list.forEach((entry) => {
          const StarTimeTitle = new Date(entry.StartTime);
          //entry.Title =
          entry.Date = StarTimeTitle.toLocaleDateString(navigator.language, {
            month: 'long',
            day: '2-digit',
            weekday: 'short',
          });
          const options: any = {
            hour: '2-digit',
            minute: '2-digit',
          };
          const StarTime = new Date(entry.StartTime);
          entry.StartTime = StarTime.toLocaleTimeString(
            navigator.language,
            options
          );

          const EndTime = new Date(entry.EndTime);
          entry.EndTime = EndTime.toLocaleTimeString(
            navigator.language,
            options
          );
        });

        this.swapShiftList = list;
        if (this.swapShiftList.length > 0) {
          this.showListInDashbord.emit(true);
        }
        this.bottomNavigationEmployeeService.setNotificationBadge(
          this.swapShiftList.length > 0
        );
      });

    this.shiftSwapService.GetEmployeeSwapShiftsList();
  }

  ngOnDestroy(): void {
    this.subscriptionEmployeeSwapShiftsList.unsubscribe();
  }

  showResponsedSwap(swapShiftId, RequestsShifts: ShiftForSwap): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.panelClass = 'custom-dialog-container';

    if (swapShiftId > 0) {
      dialogConfig.data = {
        swapShiftId: swapShiftId,
        hideEmployeeAcceptButton: true,
      };
      this.dialog.open(ShowTargetedSwapShiftComponent, dialogConfig);
    } else {
      dialogConfig.data = { swapShiftId };
      this.dialog.open(ShowResponsedWorkShiftForSwapComponent, dialogConfig);
    }
  }
}
