import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DialogService {
  private dialogOpenSubject = new BehaviorSubject<boolean>(false);
  dialogOpen$ = this.dialogOpenSubject.asObservable();

  setDialogOpen(isOpen: boolean): void {
    this.dialogOpenSubject.next(isOpen);
  }
}
