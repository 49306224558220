import { Component, OnInit } from '@angular/core';
@Component({
  selector: 'app-schedule-requirement',
  templateUrl: './schedule-requirement.component.html',
  styleUrls: ['./schedule-requirement.component.css'],
})
export class ScheduleRequirementComponent implements OnInit {
  constructor() {}
  showTimeline = true;
  ngOnInit(): void {}
  clickPayOption(selectedChoice: boolean) {
    this.showTimeline = selectedChoice;
  }
}
