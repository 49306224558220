import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { TimeRecordingService } from 'src/app/modules/time-recording/services/time-recording.service';

enum MitarbeiterStatus {
  Aktiv = 1,
  Pause,
  Fertig,
  NichtEingeloggt,
}

interface Mitarbeiter {
  name: string;
  status: MitarbeiterStatus;
  schichtStart: string;
  schichtEnde: string;
  progress?: number;
  department: string;
  skillName: string;
  skillIcons: string;
}

@Component({
  selector: 'app-live-status',
  templateUrl: './live-status.component.html',
  styleUrls: ['./live-status.component.css'],
})
export class LiveStatusComponent implements OnInit, OnDestroy {
  // Mache das Enum für das Template verfügbar
  public MitarbeiterStatus = MitarbeiterStatus;

  mitarbeiterListe: Mitarbeiter[] = [];
  statusGruppen = {
    [MitarbeiterStatus.Aktiv]: [],
    [MitarbeiterStatus.Pause]: [],
    [MitarbeiterStatus.Fertig]: [],
    [MitarbeiterStatus.NichtEingeloggt]: [],
  };
  skillGruppen: { [key: string]: Mitarbeiter[] } = {};

  hoveredStatus: MitarbeiterStatus | null = null;
  private liveStatusSubscription: Subscription;

  constructor(private timeRecordingService: TimeRecordingService) {}

  ngOnInit(): void {
    // Subscribe to live status data and group them by status
    this.liveStatusSubscription =
      this.timeRecordingService.employeeLiveStatus$.subscribe(
        (statusList: any[]) => {
          this.mitarbeiterListe = statusList.map((status) => ({
            name: status.Name,
            status: this.mapStatus(status.Status),
            schichtStart: status.SchichtStart,
            schichtEnde: status.SchichtEnde,
            progress: status.Progress,
            department: status.Department,
            skillName: status.SkillName,
            skillIcons: status.SkillIcons,
          }));
          this.gruppiereMitarbeiterNachStatus();
          this.gruppiereMitarbeiterNachSkills();
        }
      );

    this.timeRecordingService.getLiveStatus();

    // Start auto-refresh for every 60 seconds
    this.timeRecordingService.startAutoRefresh(60000);
  }

  ngOnDestroy(): void {
    // Unsubscribe to avoid memory leaks
    if (this.liveStatusSubscription) {
      this.liveStatusSubscription.unsubscribe();
    }
    this.timeRecordingService.stopAutoRefresh();
  }

  skillGruppenByStatus: { [key: number]: { [key: string]: Mitarbeiter[] } } =
    {};

  gruppiereMitarbeiterNachSkills(): void {
    this.skillGruppenByStatus = {};
    this.mitarbeiterListe.forEach((mitarbeiter) => {
      if (!this.skillGruppenByStatus[mitarbeiter.status]) {
        this.skillGruppenByStatus[mitarbeiter.status] = {};
      }

      const skill = mitarbeiter.skillName;
      if (!this.skillGruppenByStatus[mitarbeiter.status][skill]) {
        this.skillGruppenByStatus[mitarbeiter.status][skill] = [];
      }

      this.skillGruppenByStatus[mitarbeiter.status][skill].push(mitarbeiter);
    });
  }

  getSkillsByStatus(status: MitarbeiterStatus): string[] {
    return this.skillGruppenByStatus[status]
      ? Object.keys(this.skillGruppenByStatus[status])
      : [];
  }

  getMitarbeiterAnzahlBySkill(
    status: MitarbeiterStatus,
    skill: string
  ): number {
    return this.skillGruppenByStatus[status] &&
      this.skillGruppenByStatus[status][skill]
      ? this.skillGruppenByStatus[status][skill].length
      : 0;
  }

  getSkillIcon(skill: string, status: MitarbeiterStatus): string {
    const skillGroup =
      this.skillGruppenByStatus[status] &&
      this.skillGruppenByStatus[status][skill];
    return skillGroup && skillGroup.length > 0 ? skillGroup[0].skillIcons : '';
  }

  // Map backend status to the enum
  mapStatus(status: number): MitarbeiterStatus {
    return status in MitarbeiterStatus
      ? (status as MitarbeiterStatus)
      : MitarbeiterStatus.NichtEingeloggt;
  }

  gruppiereMitarbeiterNachStatus(): void {
    this.statusGruppen[MitarbeiterStatus.Aktiv] = this.mitarbeiterListe.filter(
      (m) => m.status === MitarbeiterStatus.Aktiv
    );
    this.statusGruppen[MitarbeiterStatus.Pause] = this.mitarbeiterListe.filter(
      (m) => m.status === MitarbeiterStatus.Pause
    );
    this.statusGruppen[MitarbeiterStatus.Fertig] = this.mitarbeiterListe.filter(
      (m) => m.status === MitarbeiterStatus.Fertig
    );
    this.statusGruppen[MitarbeiterStatus.NichtEingeloggt] =
      this.mitarbeiterListe.filter(
        (m) => m.status === MitarbeiterStatus.NichtEingeloggt
      );
  }

  showList(status: MitarbeiterStatus): void {
    this.hoveredStatus = status;
  }

  hideList(): void {
    this.hoveredStatus = null;
  }

  getStatusIcon(status: MitarbeiterStatus): string {
    switch (status) {
      case MitarbeiterStatus.Aktiv:
        return 'check_circle';
      case MitarbeiterStatus.Pause:
        return 'pause_circle';
      case MitarbeiterStatus.Fertig:
        return 'done';
      case MitarbeiterStatus.NichtEingeloggt:
        return 'cancel';
      default:
        return 'help_outline';
    }
  }

  getMitarbeiterByStatus(status: MitarbeiterStatus): Mitarbeiter[] {
    return this.statusGruppen[status];
  }

  getMitarbeiterBySkill(skill: string): Mitarbeiter[] {
    return this.skillGruppen[skill] || [];
  }
}
