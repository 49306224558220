export class TotalWorkHoursReportDto {
  public TotalWorkMinutes: number;
  public TotalOvertimeMinutes: number;
  public TotalEmployees: number;
  public TotalDepartments: number;
  public TimeLine: TimeLineDto;
  totalWorkTime: string;
  totalOvertimeTime: string;
}

export class TimeLineDto {
  public Days: Date[] = [];
  public WorkTime: number[] = [];
  public Overtime: number[] = [];
}
