<div class="row">
  <div class="form-group col-9">
    <label class="form-label">{{'ALLOW SHIFT SWAP'| translate}}</label>
  </div>
  <div class="form-group col-3">
    <label class="form-label"
      style="padding-left: 30px; padding-right: 10px;">{{companySettings.ShiftSwapSettings.AllowShiftSwap ? ('YES' |
      translate) : ('NO' | translate) }}</label>
    <mat-slide-toggle color="primary" [checked]="companySettings.ShiftSwapSettings.AllowShiftSwap" (change)="onUpdate()"
      name="AllDay" [(ngModel)]="companySettings.ShiftSwapSettings.AllowShiftSwap">
    </mat-slide-toggle>
  </div>
</div>
<hr class="mt-2 mb-3" />
<div class="row" [class.disabled]="!companySettings.ShiftSwapSettings.AllowShiftSwap">
  <div class="form-group col-8">
    <label class="form-label">{{'ADVANCE NOTICE FOR SHIFT SWAP'| translate}}</label>
  </div>
  <div class="form-group col-4">
    <app-input-editable showDropdownInput="true" [dropdownListe]="daysInAdvance" (onSave)="onUpdate()"
      [(ngModel)]="companySettings.ShiftSwapSettings.DaysInAdvance"></app-input-editable>
  </div>
</div>