<div class="row">
  <div *ngIf="showDay && dayWeekMonth.Day.Percentage>-1" class="col">
    <app-time-recording-donut-chart [timeChart]="dayWeekMonth.Day"></app-time-recording-donut-chart>
  </div>
  <div *ngIf="showWeek && dayWeekMonth.Week.Percentage>-1" class="col">
    <app-time-recording-donut-chart [timeChart]="dayWeekMonth.Week"></app-time-recording-donut-chart>
  </div>
  <div *ngIf="showMonth && dayWeekMonth.Month.Percentage>-1" class="col">
    <app-time-recording-donut-chart [timeChart]="dayWeekMonth.Month"></app-time-recording-donut-chart>
  </div>
</div>