import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-confirmation-dialog-input',
  templateUrl: './confirmation-dialog-input.component.html',
  styleUrls: ['./confirmation-dialog-input.component.css'],
})
export class ConfirmationDialogInputComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<ConfirmationDialogInputComponent>,
    private translate: TranslateService
  ) {}
  public confirmMessage: string;
  public confirmHeader = this.translate.instant('CONFIRMATION');
  public confirmButtonText = this.translate.instant('CONFIRM');
  public cancelButtonText = this.translate.instant('Close');
  public inputLabel: string;
  public inputValue: string;
  public inputType: 'text' | 'date' = 'text';
  ngOnInit(): void {}
}
