import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CalendarService } from '../../services/calendar.service';

@Component({
  selector: 'app-select-plan-template',
  templateUrl: './select-plan-template.component.html',
  styleUrls: ['./select-plan-template.component.css'],
})
export class SelectPlanTemplateComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private service: CalendarService,
    public dialogRef: MatDialogRef<SelectPlanTemplateComponent>
  ) {}
  private subscriptionPlanTemplateList: Subscription;
  planTemplateList = [];
  formModel = {
    StarTime: null,
    EndTime: null,
    Id: null,
  };
  imageId = null;
  ngOnInit(): void {
    if (this.data && this.data.timeSpan) {
      this.formModel = {
        StarTime: this.data.timeSpan.starTime,
        EndTime: this.data.timeSpan.endTime,
        Id: null,
      };
    }
    this.subscriptionPlanTemplateList = this.service.planTemplateList.subscribe(
      (list) => {
        this.planTemplateList = list as any[];
      }
    );
    this.service.getPlanTemplateList();
  }

  onSubmitPlan(id: any): void {
    this.service.applyPlanTemplate({
      StarTime: this.data.timeSpan.starTime,
      EndTime: this.data.timeSpan.endTime,
      Id: id,
    });
    this.onClose();
  }
  selectTemplate(imageId): void {
    this.imageId = imageId;
  }

  getTime(startTime, endTime): string {
    const options: any = {
      month: '2-digit',
      day: '2-digit',
    };

    const StartTime = new Date(startTime);
    let returnString = StartTime.toLocaleDateString(
      navigator.language,
      options
    );

    const EndTime = new Date(endTime);
    EndTime.setSeconds(EndTime.getSeconds() - 1);

    return (
      returnString +
      ' - ' +
      EndTime.toLocaleDateString(navigator.language, options)
    );
  }

  onClose(): void {
    this.dialogRef.close();
  }

  onDelete(id: number, imageId: string): void {
    this.service.deletePlanTemplate(id, imageId);
  }
  getImgPath(imageId): string {
    return environment.imagesURL + imageId + '.jpg';
  }
}
