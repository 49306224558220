<mat-accordion multi="false">
  <mat-expansion-panel
    class="expansion-panel"
    *ngFor="let clockEvent of eventListAccordion; let i = index"
    (opened)="panelOpened(i)"
    (closed)="panelClosed(i)"
    [expanded]="expandedIndex === i"
  >
    <mat-expansion-panel-header class="panel-header">
      <mat-panel-title class="panel-title">
        {{ formattedEventDates[i] }}
      </mat-panel-title>
      <mat-panel-description class="panel-description">
        <span class="time-range">
          <span class="start-time">{{
            formattedTimeRanges[i]?.split(" - ")[0]
          }}</span>
          <span class="separator"> - </span>
          <span class="end-time">{{
            formattedTimeRanges[i]?.split(" - ")[1]
          }}</span>
        </span>
        <span class="duration">
          <i class="fa-solid fa-clock"></i>
          {{ formattedDurations[i] }}
        </span>
      </mat-panel-description>
    </mat-expansion-panel-header>
    <div *ngIf="clockEvent?.ClockEvents?.length > 0">
      <app-time-recording-table
        (clockEvent)="handleClockEvent($event)"
        [clockEventList]="clockEvent.ClockEvents"
        [employeeId]="0"
        [startDate]="clockEvent.EventDate"
      ></app-time-recording-table>
    </div>
  </mat-expansion-panel>
</mat-accordion>
