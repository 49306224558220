import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css'],
})
export class FooterComponent {
  version: string = '0.0.0'; // Setzen Sie assets/version.txt Ihre Versionsnummer

  constructor(private http: HttpClient) {}

  ngOnInit(): void {
    this.http
      .get('assets/version.txt', { responseType: 'text' })
      .subscribe((data) => {
        this.version = data.trim();
      });
  }
}
