<div class="modal-dialog modal-lg" role="document" style="margin: 20px;">
  <div class="modal-content">
    <div class="modal-header text-center" style="padding-bottom: 0px;">
      <h5 class="modal-title w-100" id="exampleModalCenterTitle">{{Title}} {{this.data.Title}}</h5>
    </div>
    <div class="modal-body" style="width: auto;">
      <div class="row">
        <div class="col-lg-6 list-container" *ngIf="holidaysConflictsList.length>0"
          style="padding-left: 0px; padding-right: 0px;">
          <p class="h5">{{'SHIFTS ALREADY ASSIGNED'| translate}} ({{holidaysConflictsList.length}})</p>
          <span *ngFor="let conflicts of holidaysConflictsList; let i = index" class="event-animation-scale">
            <app-item-list [Title]="getDate(conflicts.StarTime)"
              [Content]="getTime(conflicts.StarTime, conflicts.EndTime)" [ContentColor]="'#34568B'"
              [isSecondButton]="true" [buttonIcon]="'bi-check-circle'" [secondButtonIcon]="'bi-trash'"
              [Icon]="conflicts.Icon"
              [buttonClass]="'btn btn-outline-success btn-sm ' + (conflictsAsOpenShift[i] === true ? 'active' : '')"
              [secondButtonClass]="'btn btn-outline-danger btn-sm ' + (conflictsAsOpenShift[i] === false ? 'active' : '')"
              [BadgeContent]="'SHIFT' | translate" (onClick)="acceptEvent(i)"
              (onSecondClick)="rejectEvent(i)"></app-item-list>
          </span>
          <p>{{openShiftNumberText}}<br>
            {{deletedShifNumberText}}
          </p>

        </div>
        <div class="col-lg-6 chart-container">
          <app-absence-holiday-chart style="justify-content: center; align-items: center;"
            [employeeId]="this.data.EmployeeId"></app-absence-holiday-chart>
        </div>
      </div>
      <div class="row">
        <div class="alert alert-primary" role="alert" style="padding: 5px;" [innerHTML]="alertMessage">

        </div>

        <div *ngIf="Comment" class="alert alert-secondary" role="alert" style="padding: 5px;">
          {{Comment}}
        </div>
      </div>
    </div>
    <div class="modal-footer d-flex justify-content-between">
      <button class="btn btn-outline-secondary" (click)="onClose()" type="button">{{'Close'|
        translate}}</button>

      <div>
        <!-- Added padding here -->
        <button class="btn btn-danger" (click)="onReject()" type="button" style="margin-right: 10px;"><i
            class="bi bi-calendar-x">
            {{'REJECT'| translate}}</i></button>
        <button class="btn btn-primary active" form="addEmployee" (click)="onSubmit()" type="submit"><i
            class="bi bi-calendar-check"> {{'ACCEPT REQUEST'| translate}}</i></button>
      </div>
    </div>
  </div>
</div>