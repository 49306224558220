export class EmployeeWorkHoursDto {
  Id: number;
  EmployeeName: string;
  Department: string;
  DepartmentId: number;
  TotalWorkMinutes: number;
  TotalOvertimeMinutes: number;
  totalWorkTime: string;
  totalOvertimeTime: string;
  TimeLine: TimeLineDto;
}

export class TimeLineDto {
  WorkTime: number[]; // time in minutes for each day
  Overtime: number[]; // over time in minutes for each day
  Days: Date[]; // days of the week
}
