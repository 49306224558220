<div class="row pull-to-refresh">
  <ion-content class="ion-padding">
    <p
      *ngIf="showRefreshText"
      class="bg-light text-center py-2"
      style="height: 20px; border-radius: 20px"
    >
      <i class="bi bi-arrow-clockwise"></i>
      {{ "PULL DOWN TO REFRESH" | translate }}
    </p>
    <ion-refresher slot="fixed" (ionRefresh)="handleRefresh($event)">
      <ion-refresher-content
        pullingText="Pull to refresh"
        refreshingSpinner="circles"
        refreshingText="Refreshing..."
      >
      </ion-refresher-content>
    </ion-refresher>
  </ion-content>
</div>
<div class="row" style="height: 30px">
  <h6
    *ngIf="showWelcomeMessage"
    [ngClass]="{ 'fade-out': fadeOut }"
    style="
      display: inline-block;
      color: var(--font-color);
      padding-left: 5px;
      padding-top: 10px;
    "
  >
    Welcome Back {{ authService.firstName }}
  </h6>
</div>

<div *ngIf="employeeCount > 1" style="padding-top: 15px">
  <div class="row">
    <div class="col-lg-8 order-last order-lg-first">
      <app-calendar-week-horizontal-view></app-calendar-week-horizontal-view>
      <div class="card shadow mb-4">
        <app-work-shifts-timeline-dashboard></app-work-shifts-timeline-dashboard>
      </div>
    </div>
    <div class="col-lg-4 mb-4 order-first order-lg-last">
      <div>
        <app-live-status></app-live-status>
      </div>
      <div>
        <app-open-work-shift-list-admin></app-open-work-shift-list-admin>
      </div>
      <div>
        <app-admin-show-shifts-in-swapping-process></app-admin-show-shifts-in-swapping-process>
      </div>
      <div>
        <app-absence-requests></app-absence-requests>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-8">
      <div class="card shadow mb-4">
        <app-absence-calendar></app-absence-calendar>
      </div>
    </div>
    <div class="col-lg-4">
      <div class="card shadow mb-4">
        <app-absence-summary-chart></app-absence-summary-chart>
      </div>
    </div>
  </div>
  <div *ngIf="employeeCount === 1" class="alert alert-info" role="alert">
    {{ alertMessage }}
  </div>
</div>
