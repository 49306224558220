import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../../services/auth/auth.service';

import { NgForm } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css'],
})
export class ForgotPasswordComponent implements OnInit {
  imageUrl: string = '/assets/images/bg-register.jpeg';

  formModel = {
    Email: '',
  };
  errorStatus: any;
  constructor(
    private service: AuthService,
    private fb: UntypedFormBuilder,
    private toasterService: ToastrService
  ) {
    this.errorStatus = this.service.error;
  }

  ngOnInit(): void {}

  onSubmit(form: NgForm) {
    this.service.forgotPassword(form.value.Email);
  }
}
