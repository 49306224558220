<div class="modal-dialog modal-lg" role="document">
  <div class="modal-content">
    <div class="modal-header text-center">
      <h5 class="modal-title w-100" id="exampleModalCenterTitle">
        {{ Title }}
      </h5>
    </div>
    <div class="modal-body">
      <form
        class="user row"
        id="addAvailability"
        #form="ngForm"
        autocomplete="off"
        (submit)="onSubmit(form)"
      >
        <input
          type="hidden"
          #Id="ngModel"
          name="Id"
          [(ngModel)]="formModel.Id"
        />
        <div
          *ngIf="formModel.isWeekendVisible"
          class="form-group col-md-6 mb-3"
        >
          <div
            class="btn-group flex-wrap"
            role="group"
            aria-label="Basic radio toggle button group"
            required
          >
            <input
              type="radio"
              class="btn-check"
              value="1"
              [(ngModel)]="formModel.Weekday"
              name="weekday"
              id="btnradio2"
              autocomplete="off"
            />
            <label class="btn btn-outline-primary" for="btnradio2">{{
              "MONDAY" | translate
            }}</label>

            <input
              type="radio"
              class="btn-check"
              value="2"
              [(ngModel)]="formModel.Weekday"
              name="weekday"
              id="btnradio3"
              autocomplete="off"
            />
            <label class="btn btn-outline-primary" for="btnradio3">{{
              "TUESDAY" | translate
            }}</label>

            <input
              type="radio"
              class="btn-check"
              value="3"
              [(ngModel)]="formModel.Weekday"
              name="weekday"
              id="btnradio4"
              autocomplete="off"
            />
            <label class="btn btn-outline-primary" for="btnradio4">{{
              "WEDNESDAY" | translate
            }}</label>

            <input
              type="radio"
              class="btn-check"
              value="4"
              [(ngModel)]="formModel.Weekday"
              name="weekday"
              id="btnradio5"
              autocomplete="off"
            />
            <label class="btn btn-outline-primary" for="btnradio5">{{
              "THURSDAY" | translate
            }}</label>

            <input
              type="radio"
              class="btn-check"
              value="5"
              [(ngModel)]="formModel.Weekday"
              name="weekday"
              id="btnradio6"
              autocomplete="off"
            />
            <label class="btn btn-outline-primary" for="btnradio6">{{
              "FRIDAY" | translate
            }}</label>

            <input
              type="radio"
              class="btn-check"
              value="6"
              [(ngModel)]="formModel.Weekday"
              name="weekday"
              id="btnradio7"
              autocomplete="off"
            />
            <label class="btn btn-outline-primary" for="btnradio7">{{
              "SATURDAY" | translate
            }}</label>
            <input
              type="radio"
              class="btn-check"
              value="0"
              [(ngModel)]="formModel.Weekday"
              name="weekday"
              id="btnradio1"
              autocomplete="off"
            />
            <label class="btn btn-outline-primary" for="btnradio1">{{
              "SUNDAY" | translate
            }}</label>
          </div>
        </div>
        <div *ngIf="true" class="form-group col-md-6 mb-2">
          <mat-label class="required-label">{{
            "AVAILABILITY FROM" | translate
          }}</mat-label>
          <input
            inputmode="none"
            #Start="ngModel"
            name="Start"
            id="Start"
            class="form-control"
            [format]="24"
            [ngxTimepicker]="with5Gap"
            [ngxMatTimepicker]="pickerStart"
            [min]="minTime"
            [(ngModel)]="formModel.Start"
            required
          />
          <ngx-mat-timepicker
            #pickerStart
            [minutesGap]="5"
            (timeSet)="onTimeset($event)"
          ></ngx-mat-timepicker>
        </div>
        <div *ngIf="true" class="form-group col-md-6 mb-2">
          <mat-label class="required-label">{{
            "AVAILABILITY UNTIL" | translate
          }}</mat-label>
          <input
            inputmode="none"
            #End="ngModel"
            name="End"
            id="End"
            class="form-control"
            [format]="24"
            [ngxTimepicker]="with5Gap"
            [ngxMatTimepicker]="pickerEnd"
            [min]="minEndTime"
            [(ngModel)]="formModel.End"
            required
          />
          <ngx-mat-timepicker #pickerEnd [minutesGap]="5"></ngx-mat-timepicker>
        </div>
      </form>
      <div class="modal-footer d-flex justify-content-between">
        <button
          class="btn btn-outline-secondary"
          (click)="onClose()"
          type="button"
          data-dismiss="modal"
        >
          {{ "Close" | translate }}
        </button>
        <button
          class="btn btn-primary"
          form="addEmployee"
          (click)="onSubmit(form)"
          type="submit"
          [disabled]="form.invalid || form.value.weekday == -1"
        >
          {{ buttonTitle }}
        </button>
      </div>
    </div>
  </div>
</div>
