import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  NgZone,
  OnDestroy,
  OnInit,
  Renderer2,
  RendererStyleFlags2,
  ViewChild,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import { FullCalendarComponent } from '@fullcalendar/angular';
import { CalendarOptions, DateSelectArg } from '@fullcalendar/core';
import interactionPlugin from '@fullcalendar/interaction';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import { TranslateService } from '@ngx-translate/core';
import * as dayjs from 'dayjs';
import dayOfYear from 'dayjs/plugin/dayOfYear';

dayjs.extend(dayOfYear);

import { EChartsOption } from 'echarts';
import { NgxSpinnerService } from 'ngx-spinner';
import { ReplaySubject, Subject, Subscription, debounceTime, tap } from 'rxjs';
import { FormatString } from 'src/app/core/_helpers';
import { ConfirmationDialogComponent } from 'src/app/core/component/confirmation-dialog/confirmation-dialog.component';
import {
  EventType,
  SortShiftBy,
  WorkShiftStatus,
} from 'src/app/core/services/const';
import { DialogService } from 'src/app/core/services/dialog/dialog.service';
import { ImageService } from 'src/app/core/services/upload/Image.service';
import { AvailabilityService } from 'src/app/modules/availability/services/availability.service';
import { EmployeeService } from 'src/app/modules/employee/services/employee.service';
import { CompanySettingsService } from 'src/app/modules/settings/services/company-settings.service';
import tippy, {
  followCursor,
  hideAll,
  inlinePositioning,
  roundArrow,
} from 'tippy.js';
import { v4 as uuidv4 } from 'uuid';
import { CalenderEvent } from '../../model/calendar-event.model';
import { EmployeeListByDateDto } from '../../model/department-dto.model';
import { ResourceXY } from '../../model/resource-x-y.model';
import { TimeEvent } from '../../model/time-event.model';
import { CalendarService } from '../../services/calendar.service';
import { AddEmployeeInWorkShiftComponent } from '../add-employee-in-work-shift/add-employee-in-work-shift.component';
import { AddPlanTemplateComponent } from '../add-plan-template/add-plan-template.component';
import { SelectPlanTemplateComponent } from '../select-plan-template/select-plan-template.component';
import { ShowWorkShiftDetailsComponent } from '../show-work-shift-details/show-work-shift-details.component';
@Component({
  selector: 'app-work-shifts-planner-timeline',
  templateUrl: './work-shifts-planner-timeline.component.html',
  styleUrls: ['./work-shifts-planner-timeline.component.css'],
})
export class WorkShiftsPlannerTimelineComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  /*.initOptions..*/
  currentCalendarData: any;
  isAdding = false;
  isCopy = false;
  queriedEmployee = 0;
  queriedPositionId = 0;
  calenderPosition: any = null;
  currentEventId: null;
  resourceListXY: ResourceXY[] = [];
  IncomeTypeId = '';
  byShiftStart =
    this.service.currentFilter === SortShiftBy.ByShiftStart ? true : false;
  byAvailableTime =
    this.service.currentFilter === SortShiftBy.ByAvailableTime ? true : false;
  translatedTextOnlyDayView = this.translate.instant(
    'THIS OPTION IS ONLY AVAILABLE IN DAY VIEW'
  );

  eventsAndScheduleRequirementList: { [key: number]: [] } = {};
  private subscriptionAvailabilityEventList: Subscription;
  chartOption2: EChartsOption;

  dialogRef: MatDialogRef<ConfirmationDialogComponent>;
  calendarApi: any;
  HTMLElement: HTMLImageElement;
  EmployeeSearchId: number;
  EmployeeSearchText: string;
  eventNumber = 1;

  alertMessage: string = this.translate.instant(
    'BEFORE PROCEEDING WITH THIS VIEW WE WOULD LIKE TO REMIND YOU THAT YOU '
  );
  public filteredCategory: ReplaySubject<EmployeeListByDateDto[]> =
    new ReplaySubject<EmployeeListByDateDto[]>(1);
  public categoryFilterCtrl: UntypedFormControl = new UntypedFormControl();
  employeeCategory: EmployeeListByDateDto[];
  protected _onDestroy = new Subject<void>();
  @ViewChild('EmployeeSearch', { static: true }) categorySelect: MatSelect;

  start =
    dayjs().day() > 5
      ? dayjs().add(1, 'week').format('YYYY-MM-DD')
      : dayjs().format('YYYY-MM-DD');

  calendarOptions: CalendarOptions = {
    plugins: [resourceTimelinePlugin, interactionPlugin],
    schedulerLicenseKey: 'GPL-My-Project-Is-Open-Source',
    locale: this.translate.currentLang,
    firstDay: this.companyService.CompanySettings.CalendarOptions.FirstDay,
    initialView: 'customWeek',
    slotEventOverlap: false,
    slotMinTime:
      this.companyService.CompanySettings.CalendarOptions
        .BusinessHoursStartTime,
    slotMaxTime:
      this.companyService.CompanySettings.CalendarOptions.BusinessHoursEndTime,
    slotDuration: '24:00:00',
    weekNumbers: true,
    resourceAreaWidth: this.getResourceAreaWidth(),
    height: 'auto',
    resourcesInitiallyExpanded: true,
    resourceAreaColumns: [
      {
        field: 'title',
      },
    ],
    resourceGroupField: 'departement',
    customButtons: {
      savePlanTemplateButton: {
        text: this.translate.instant('Plan as Template'),
        click: () => this.savePlanTemplate(),
      },
      selectTemplate: {
        text: this.translate.instant('TEMPLATES'),
        click: () => this.selectTemplate(),
      },
      downloadPlan: {
        text: this.translate.instant('DOWNLOAD'),
        click: () => this.downloadPlan(),
      },
      downloadCSVPlan: {
        text: 'CSV',
        click: () => this.downloadCSVPlan(),
      },
      publishPlanButton: {
        text: this.translate.instant('PUBLISH'),
        click: () => this.openPublishConfirmationDialog(),
      },
      autoFillPlanButton: {
        text: 'Autofill',
        click: () => this.openAutoFillConfirmationDialog(),
      },
      deletePlanButton: {
        text: this.translate.instant('DELETE'),
        click: () => this.openDeleteConfirmationDialog(),
      },
      prev: {
        click: () => this.selectPrevButton(),
      },
      today: {
        text: this.translate.instant('TODAY'),
        click: () => this.selectTodayButton(),
      },
      next: {
        click: () => this.selectNextButton(),
      },
      customWeekButton: {
        text: this.translate.instant('Week'),
        click: () => this.selectCustomWeekButton(),
      },
      customDayButton: {
        text: this.translate.instant('Day'),
        click: () => this.selectCustomDayButton(),
      },
    },
    headerToolbar: {
      left: 'prev,today,next,customDayButton,customWeekButton',
      center: 'title',
      right:
        'autoFillPlanButton,downloadPlan,savePlanTemplateButton,selectTemplate,deletePlanButton,publishPlanButton',
    },
    views: {
      customDay: {
        type: 'resourceTimeline',
        duration: { day: 1 },
        slotDuration: { hour: 0.5 },
        buttonText: this.translate.instant('Day'),
        titleFormat: {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
          weekday: 'long',
        },
        slotLabelFormat: [
          { weekday: 'long' }, // top level of text
          { hour: '2-digit' }, // lower level of text
        ],
      },
      customWeek: {
        type: 'resourceTimeline',
        duration: { weeks: 1 },
        slotDuration: { days: 1 },
        buttonText: this.translate.instant('Week'),
        slotLabelFormat: [
          { week: 'short' },
          {
            weekday: 'long',
            month: 'numeric',
            day: 'numeric',
            omitCommas: true,
          },
        ],
        weekText: this.translate.instant('calendar week'),
      },
      customMonth: {
        type: 'resourceTimeline',
        duration: { month: 1 },
        slotDuration: { days: 1 },
        buttonText: this.translate.instant('Month'),
      },
    },
    businessHours: {
      startTime:
        this.companyService.CompanySettings.CalendarOptions
          .BusinessHoursStartTime,
      endTime:
        this.companyService.CompanySettings.CalendarOptions
          .BusinessHoursEndTime,

      daysOfWeek: [0, 1, 2, 3, 4, 5, 6],
      // days of week. an array of zero-based day of week integers (0=Sunday)
      // (Monday-Thursday in this example)
    },
    weekText: this.translate.instant('calendar week'),
    resourceOrder: 'sortOrder',
    aspectRatio: 1.6,
    displayEventTime: true,
    weekends: true,
    editable: true,
    selectable: true,
    selectMirror: true,
    dayMaxEvents: true,
    handleWindowResize: true,
    eventReceive: this.handleDropInfo.bind(this),
    eventChange: this.handleEventChange.bind(this),
    eventResizeStart: this.handleEventResize.bind(this),
    eventContent: this.handleEventContent.bind(this),
    select: this.handleDateSelect.bind(this),
    eventDidMount: this.handleEventDidMount.bind(this),
    resourceLabelContent: this.handleResourceLabelContent.bind(this),
    slotLabelContent: this.handleSlotLabelContent.bind(this),
    slotLaneClassNames: this.handleDayCellClassNames.bind(this),
    eventConstraint: {
      start: dayjs().format('YYYY-MM-DD'),
      end: '2100-01-01', // hard coded goodness unfortunately
    },
    selectConstraint: {
      start: dayjs().format('YYYY-MM-DD'),
      end: '2100-01-01',
    },
  };

  @ViewChild('calendar') calendarComponent: FullCalendarComponent;
  @ViewChild('calendar') calendarComponentPostition: ElementRef;

  @ViewChild('EmployeeSearchInput') EmployeeSearch: ElementRef;

  @ViewChild('canvas') canvas: ElementRef;
  @ViewChild('downloadLink') downloadLink: ElementRef;
  publishButtonColor: string;
  searchKeywords: string;
  holidays: string[] = [];
  constructor(
    private service: CalendarService,
    private cdr: ChangeDetectorRef,
    public employeeService: EmployeeService,
    private dialog: MatDialog,
    private availabilityService: AvailabilityService,
    private el: ElementRef,
    private translate: TranslateService,
    private uploadService: ImageService,
    private spinner: NgxSpinnerService,
    private zone: NgZone,
    private renderer: Renderer2,
    private companyService: CompanySettingsService,
    private changeDetectorRef: ChangeDetectorRef,
    private companySetting: CompanySettingsService,
    private dialogService: DialogService
  ) {
    Window['WorkShiftsPlannerTimelineComponent'] = this;
  }
  private subscriptionEmployeeEventList: Subscription;

  private subscriptioneventsAndScheduleRequirementStatusList: Subscription;

  getResourceAreaWidth(): string {
    const width = window.innerWidth;

    if (width < 576) {
      // Mobiltelefone
      return '130px';
    } else if (width >= 576 && width < 768) {
      // Kleine Tablets
      return '150px';
    } else if (width >= 768 && width < 992) {
      // Große Tablets
      return '200px';
    } else {
      // Desktop
      return '250px';
    }
  }

  @HostListener('window:resize', ['$event'])
  onWindowResize(event: Event) {
    this.calendarOptions.resourceAreaWidth = this.getResourceAreaWidth();
    // Falls erforderlich, können Sie den Kalender neu rendern
    // this.calendarComponent.getApi().updateSize();
  }

  ngOnInit(): void {
    this.byAvailableTime = true;
    this.byShiftStart = false;
    this.service.currentFilter = SortShiftBy.ByAvailableTime;

    this.subscriptionEmployeeEventList = this.service.employeeTimelineEventList
      .pipe(
        tap((timelineEvents) => {
          if (timelineEvents == null) {
            return;
          }

          this.holidays = timelineEvents.holidays;

          if (this.calendarComponent) {
            const calendar = this.calendarComponent.getApi();
            if (timelineEvents.calendarWeekStart) {
              calendar.changeView('customWeek');
              calendar.gotoDate(timelineEvents.calendarWeekStart);
            }
            if (
              calendar &&
              JSON.stringify(this.calendarOptions.events) !==
                JSON.stringify(timelineEvents.calenderEvents)
            ) {
              calendar.removeAllEvents();
            }
            this.eventNumber = timelineEvents.calenderResources.length;

            this.calendarOptions.events = timelineEvents.calenderEvents;
            this.calendarOptions.resources = timelineEvents.calenderResources;
            calendar.refetchEvents();
            this.cdr.detectChanges();
          }

          // eterate over all events and check

          const viewApi = this.currentCalendarData?.viewApi;
          const notPublished =
            Array.isArray(this.calendarOptions.events) &&
            this.calendarOptions.events.some(
              (event) =>
                viewApi &&
                event.start > viewApi.currentStart &&
                event.end < viewApi.currentEnd &&
                event.eventType === 1 &&
                event.shiftStatus === WorkShiftStatus.Draft
            );

          if (notPublished) {
            this.setPublishPlanButtonColorRed();
          } else {
            this.setPublishPlanButtonColorNormal();
          }
        })
      )
      .subscribe();

    this.subscriptionAvailabilityEventList =
      this.availabilityService.availabilityEventList.subscribe((list: []) => {
        if (list.length && this.calendarComponent) {
          const calendar = this.calendarComponent.getApi();
          list.forEach((entry: CalenderEvent) => {
            entry.display = 'inverse-background';
            entry.groupId = 'available';
            calendar.addEvent(JSON.parse(JSON.stringify(entry)));
          });
        }
      });

    this.subscriptioneventsAndScheduleRequirementStatusList =
      this.service.eventsAndScheduleRequirementStatusList
        .pipe(
          debounceTime(500) // debounce for 500ms
        )
        .subscribe((scheduleList) => {
          this.eventsAndScheduleRequirementList = scheduleList;
          const calendar = this.calendarComponent?.getApi();
          if (calendar) {
            calendar.render();
            this.cdr.detectChanges();
          }
        });

    // this.subscriptionMousemove = fromEvent(
    //   document.body,
    //   'mousemove'
    // ).subscribe((e: any) => {
    //   this.calenderPosition = (
    //     this.calendarComponentPostition as any
    //   ).element.nativeElement.getBoundingClientRect();

    //   if (
    //     this.queriedEmployee > 0 &&
    //     !(
    //       e.clientX > this.calenderPosition.left &&
    //       e.clientX < this.calenderPosition.right &&
    //       e.clientY > this.calenderPosition.top + 50
    //     )
    //   ) {
    //     this.queriedEmployee = 0;
    //     this.service.getTimelineEvent(true);
    //   }
    //   for (const res of this.resourceListXY) {
    //     if (
    //       res.resourceId !== this.queriedPositionId &&
    //       this.currentCalendarData.currentViewType === 'customDay' &&
    //       e.clientY > res.top &&
    //       e.clientY < res.bottom &&
    //       e.clientX > res.left &&
    //       e.clientX < res.right
    //     ) {
    //       const calendar = this.calendarComponent?.getApi();
    //       calendar?.getEvents().forEach((event) => {
    //         if (event.groupId === 'available') {
    //           event.remove();
    //         }
    //       });
    //       this.getAvailabilityPositionId(res.resourceId);
    //     }
    //   }
    // });

    // this.subscriptionEmployeeCategory =
    //   this.employeeService.employeeNameListByDate.subscribe((list) => {
    //     this.employeeCategory = list as EmployeeListByDateDto[];
    //     this.filteredCategory.next(this.employeeCategory.slice());
    //   });

    // this.categoryFilterCtrl.valueChanges
    //   .pipe(takeUntil(this._onDestroy))
    //   .subscribe(() => {
    //     this.filterCategory();
    //   });
  }
  isMobileView() {
    return window.innerWidth <= 1168;
  }
  ngAfterViewInit(): void {
    this.calendarApi = this.calendarComponent.getApi();
    if (this.service.calendarCurrentStart && this.calendarApi) {
      this.calendarApi.gotoDate(this.service.calendarCurrentStart);
    }

    if (this.calendarApi) {
      this.calendarApi.next();
    }

    this.currentCalendarData = this.calendarApi.getCurrentData();
    if (this.service.calendarCurrentStartFromHorizontalCharts) {
      this.calendarApi.gotoDate(
        this.service.calendarCurrentStartFromHorizontalCharts
      );
      this.service.getTimelineEvent(
        true,
        this.service.calendarCurrentStartFromHorizontalCharts,
        this.currentCalendarData.currentViewType === 'customDay',
        false,
        false,
        '*'
      );
    } else {
      this.service.getTimelineEvent(
        true,
        this.currentCalendarData.viewApi.currentStart,
        this.currentCalendarData.currentViewType === 'customDay',
        false,
        false,
        '*'
      );
    }

    this.HTMLElement = this.el.nativeElement.querySelector(
      '.fc-datagrid-cell-frame'
    );

    this.renderer.appendChild(
      // Employee Search
      this.HTMLElement,
      this.EmployeeSearch.nativeElement
    );
  }

  onSearchKeywordsChange(keyWord): void {
    this.searchKeywords = keyWord;
    this.service.getTimelineEvent(
      true,
      this.currentCalendarData.viewApi.currentStart,
      this.currentCalendarData.currentViewType === 'customDay',
      false,
      false,
      keyWord
    );
  }

  setPublishPlanButtonColorRed(): void {
    let ppbutton = this.el.nativeElement.querySelector(
      'button.fc-publishPlanButton-button'
    );
    // set style as important
    this.renderer.setStyle(
      ppbutton,
      'color',
      '#f23a90',
      RendererStyleFlags2.Important
    );

    // set font to bold
    this.renderer.setStyle(ppbutton, 'font-weight', 'bold');
  }

  setPublishPlanButtonColorNormal(): void {
    const ppbutton = this.el.nativeElement.querySelector(
      'button.fc-publishPlanButton-button'
    );
    if (ppbutton) {
      this.renderer.setStyle(ppbutton, 'color', 'white');
      // set font to bold
      this.renderer.setStyle(ppbutton, 'font-weight', 'normal');
    }
  }

  ngOnDestroy(): void {
    this.subscriptionEmployeeEventList.unsubscribe();
    this.subscriptioneventsAndScheduleRequirementStatusList.unsubscribe();
    this.subscriptionAvailabilityEventList.unsubscribe();
  }
  savePlanTemplate(): void {
    const calendar = this.calendarComponent.getApi();
    this.currentCalendarData = calendar.getCurrentData();
    const timeSpan = {
      starTime: this.currentCalendarData.viewApi.currentStart,
      endTime: this.currentCalendarData.viewApi.currentEnd,
    };

    if (this.currentCalendarData.currentViewType !== 'customWeek') {
      calendar.changeView('customWeek');
      this.service.getTimelineEvent(
        true,
        this.currentCalendarData.viewApi.currentStart,
        this.currentCalendarData.currentViewType === 'customDay',
        false,
        false,
        '*'
      );
    }

    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;

    dialogConfig.panelClass = 'custom-dialog-container';
    dialogConfig.data = { timeSpan };
    this.dialog
      .open(AddPlanTemplateComponent, dialogConfig)
      .afterClosed()
      .subscribe((planId) => {
        if (planId) {
          this.uploadPlanAsImage(planId);
        }
      });
  }
  selectTemplate(): void {
    const calendar = this.calendarComponent.getApi();
    this.currentCalendarData = calendar.getCurrentData();
    const timeSpan = {
      starTime: this.currentCalendarData.viewApi.currentStart,
      endTime: this.currentCalendarData.viewApi.currentEnd,
    };
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;

    dialogConfig.panelClass = 'custom-dialog-container';
    dialogConfig.data = { timeSpan };
    this.dialog.open(SelectPlanTemplateComponent, dialogConfig);
  }
  async downloadPlan(): Promise<void> {
    await this.spinner.show();
    this.service.getTimelineEvent(
      true,
      this.currentCalendarData.viewApi.currentStart,
      this.currentCalendarData.currentViewType === 'customDay',
      false,
      false,
      '*',
      false
    );
    this.HTMLElement = this.el.nativeElement.querySelector(
      'table.fc-scrollgrid'
    );
    setTimeout(async () => {
      const html2canvas = (await import('html2canvas')).default;
      html2canvas(this.HTMLElement).then((canvas) => {
        this.canvas.nativeElement.src = canvas.toDataURL();
        this.downloadLink.nativeElement.href = canvas.toDataURL('image/png');
        this.downloadLink.nativeElement.download = 'weekly-plan.png';
        this.spinner.hide();
        this.downloadLink.nativeElement.click();
      });
    }, 500);
  }

  async downloadCSVPlan(): Promise<void> {
    var eventsArray = this.calendarOptions.events;
    this.convertArrayToCSV(eventsArray as any[]);
  }
  convertArrayToCSV2(data) {
    // Filtern von eventType: 3 Einträgen
    data = data.filter((item) => item.eventType !== 3);

    // Erstellen einer Map, um Aktivitäten für jeden Mitarbeiter für jeden Tag zu speichern
    let employeeActivitiesByDay = new Map();

    // Durchlaufen der Daten und Befüllen der Map
    data.forEach((item) => {
      let employee = item.title;
      let date = new Date(item.start).toLocaleDateString();
      let startTime = new Date(item.start);
      let endTime = new Date(item.end);
      let activity = item.description;

      // Erstellen des Schlüssels für die Mitarbeiteraktivitäten für diesen Tag
      let key = employee + '-' + date;

      if (!employeeActivitiesByDay.has(key)) {
        employeeActivitiesByDay.set(key, []);
      }

      employeeActivitiesByDay.get(key).push({ startTime, endTime, activity });
    });

    // Sortieren der Aktivitäten nach Startzeit für jeden Tag
    employeeActivitiesByDay.forEach((activities, key) => {
      activities.sort((a, b) => a.startTime - b.startTime);
    });

    // Extrahieren aller eindeutigen Datumszeichenfolgen
    let uniqueDates = [
      ...new Set(data.map((item) => new Date(item.start).toLocaleDateString())),
    ];
    uniqueDates.sort(
      (a: Date, b: Date) => new Date(a).getDate() - new Date(b).getDate()
    );
    // Create CSV content
    let csvContent = 'sep=;\n'; // Excel-Separator
    // Erstellen des CSV-Inhalts
    csvContent += '';

    // Hinzufügen der Spaltenköpfe
    csvContent += 'Name;';
    uniqueDates.forEach((date) => {
      csvContent += date + ';';
      csvContent += ',';
    });
    csvContent += '\n';

    // Hinzufügen der Mitarbeiteraktivitäten für jeden Tag
    employeeActivitiesByDay.forEach((activities, key) => {
      let [employee, date] = key.split('-');
      csvContent += '"' + employee + '";';
      let index = 0;
      uniqueDates.forEach((date) => {
        let activitiesForDate = activities.filter(
          (activity) => activity.startTime.toLocaleDateString() === date
        );
        if (activitiesForDate.length > 0) {
          activitiesForDate.forEach((activity) => {
            csvContent +=
              '"' +
              activity.startTime.toLocaleTimeString([], {
                hour: '2-digit',
                minute: '2-digit',
              }) +
              ' - ' +
              activity.endTime.toLocaleTimeString([], {
                hour: '2-digit',
                minute: '2-digit',
              }) +
              ' ' +
              activity.activity +
              '",';
          });
        } else {
          csvContent += ';';
        }
        index++;
        if (index < uniqueDates.length) {
          csvContent += ';';
        }
      });
      csvContent += '\n';
    });

    // Erstellen von Blob und Download-Link
    let blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    let link = document.createElement('a');
    if (link.download !== undefined) {
      let url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', 'schedule.csv');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
  convertArrayToCSV(data: any[]) {
    // Filter entries with eventType: 3
    data = data.filter((item) => item.eventType !== 3);
    // Create CSV content
    let csvContent = 'sep=;\n'; // Excel-Separator
    // Create CSV content
    csvContent += '';

    // Add header row with dates
    let uniqueDates = [
      ...new Set(data.map((item) => new Date(item.start).toLocaleDateString())),
    ];
    uniqueDates.sort((a, b) => {
      // Custom sorting based on day part of the date
      let dayA = parseInt(a.split('.')[0]);
      let dayB = parseInt(b.split('.')[0]);
      return dayA - dayB;
    });
    csvContent += 'Name;';
    csvContent +=
      uniqueDates.map((date) => date.split('.').reverse().join('.') + ';') +
      '\n';

    // Create a map to store activities for each employee for each date
    let employeeActivities = new Map();

    // Populate the map
    data.forEach((item) => {
      let employee = item.title;
      let date = new Date(item.start).toLocaleDateString();
      let time =
        new Date(item.start).toLocaleTimeString([], {
          hour: '2-digit',
          minute: '2-digit',
        }) +
        ' - ' +
        new Date(item.end).toLocaleTimeString([], {
          hour: '2-digit',
          minute: '2-digit',
        });
      let activity = item.description;

      if (!employeeActivities.has(employee)) {
        employeeActivities.set(employee, new Map());
      }

      if (!employeeActivities.get(employee).has(date)) {
        employeeActivities.get(employee).set(date, []);
      }

      employeeActivities
        .get(employee)
        .get(date)
        .push(time + ' ' + activity);
    });

    // Populate CSV rows
    employeeActivities.forEach((activities, employee) => {
      csvContent += employee + ';';
      uniqueDates.forEach((date) => {
        csvContent += (activities.get(date) || []).join('\n') + ';';
      });
      csvContent += '\n';
    });

    // Create Blob and download link
    let blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    let link = document.createElement('a');
    if (link.download !== undefined) {
      let url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', 'schedule.csv');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
  openPublishConfirmationDialog(): void {
    let textDialog = '';
    const calendar = this.calendarComponent.getApi();
    this.currentCalendarData = calendar.getCurrentData();
    if (this.currentCalendarData.currentViewType !== 'customWeek') {
      calendar.changeView('customWeek');
      this.service.getTimelineEvent(
        true,
        this.currentCalendarData.viewApi.currentStart,
        this.currentCalendarData.currentViewType === 'customDay',
        false,
        false,
        '*'
      );
    }
    this.dialogService.setDialogOpen(true); // Dialog geöffnet
    this.dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      disableClose: false,
      panelClass: 'custom-dialog-container',
    });

    const startDate = dayjs(
      this.currentCalendarData.viewApi.currentStart
    ).format('DD.MM');
    // Subtract one second from currentEnd
    const endDate = dayjs(this.currentCalendarData.viewApi.currentEnd)
      .subtract(1, 'seconds')
      .format('DD.MM');

    textDialog += FormatString(
      this.translate.instant('WOULD YOU LIKE TO PUBLISH THE PLAN'),
      [startDate, endDate]
    );
    this.dialogRef.componentInstance.confirmHeader = this.translate.instant(
      'PUBLISH WEEKLY SCHEDULE'
    );
    this.dialogRef.componentInstance.confirmMessage = textDialog;
    this.dialogRef.componentInstance.confirmButtonText =
      this.translate.instant('PUBLISH');
    this.dialogRef.componentInstance.cancelButtonText =
      this.translate.instant('ABORT');

    this.dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.publishPlanButton();
      }
      this.dialogService.setDialogOpen(false); // Dialog geschlossen
      this.dialogRef = null;
    });
  }

  openAutoFillConfirmationDialog(): void {
    let textDialog = '';
    const calendar = this.calendarComponent.getApi();
    this.currentCalendarData = calendar.getCurrentData();

    if (this.currentCalendarData.currentViewType !== 'customWeek') {
      calendar.changeView('customWeek');
      this.service.getTimelineEvent(
        true,
        this.currentCalendarData.viewApi.currentStart,
        this.currentCalendarData.currentViewType === 'customDay',
        false,
        false,
        '*'
      );
    }

    const options: any = { month: '2-digit', day: '2-digit' };
    const startDate = new Date(
      this.currentCalendarData.viewApi.currentStart
    ).toLocaleDateString(navigator.language, options);

    // Subtract one second from currentEnd
    let endDateObj = new Date(this.currentCalendarData.viewApi.currentEnd);
    endDateObj.setSeconds(endDateObj.getSeconds() - 1);
    const endDate = endDateObj.toLocaleDateString(navigator.language, options);

    textDialog +=
      FormatString(
        this.translate.instant(
          'WOULD YOU LIKE TO AUTOMATICALLY FILL IN THE WEEKLY SCHEDULE'
        ),
        [startDate, endDate]
      ) +
      '<br><br><b>' +
      this.translate.instant('THE FOLLOWING POINTS ARE TAKEN INTO ACCOUNT') +
      ': </b><br>' +
      '- ' +
      this.translate.instant(
        'THE AUTOMATIC FILLING IS BASED ON THE REQUIREMENTS SCHEDULE'
      ) +
      '<br>' +
      '- ' +
      this.translate.instant('EMPLOYEE ABSENCES ARE TAKEN INTO ACCOUNT') +
      '<br>' +
      '- ' +
      this.translate.instant('AVAILABLE WORKING HOURS ARE EVENLY DISTRIBUTED') +
      '<br>' +
      '- ' +
      this.translate.instant('A PRIORITY LIST IS CONSIDERED') +
      '<br>' +
      '- ' +
      this.translate.instant(
        'ALREADY CAPTURED SHIFTS ARE CONSIDERED, BUT NOT DELETED'
      );
    this.dialogService.setDialogOpen(true); // Dialog geöffnet
    this.dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      disableClose: false,
      panelClass: 'custom-dialog-container',
    });

    this.dialogRef.componentInstance.confirmHeader = this.translate.instant(
      'FILL PLAN AUTOMATICALLY'
    );
    this.dialogRef.componentInstance.confirmMessage = textDialog;
    this.dialogRef.componentInstance.confirmButtonText =
      this.translate.instant('FILL IN');
    this.dialogRef.componentInstance.cancelButtonText =
      this.translate.instant('ABORT');

    this.dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.callAutoFill(0, true);
      }
      this.dialogService.setDialogOpen(false); // Dialog geschlossen
      this.dialogRef = null;
    });
  }

  async uploadPlanAsImage(planId: number): Promise<void> {
    this.service.getTimelineEvent(
      true,
      this.currentCalendarData.viewApi.currentStart,
      this.currentCalendarData.currentViewType === 'customDay',
      false,
      false,
      '*',
      false
    );
    this.HTMLElement = this.el.nativeElement.querySelector(
      'table.fc-scrollgrid'
    );
    setTimeout(async () => {
      const html2canvas = (await import('html2canvas')).default;
      html2canvas(this.HTMLElement).then((canvas) => {
        canvas.toBlob(async (blob) => {
          const file = new File([blob], 'fileName.jpg', { type: 'image/jpeg' });
          const fileId = await this.uploadFile([file]);
          this.service.updatePlanTemplateImage(fileId, planId);
          this.spinner.hide();
        }, 'image/jpeg');
      });
    }, 500);
  }

  openDeleteConfirmationDialog(): void {
    let textDialog = '';
    const calendar = this.calendarComponent.getApi();
    this.currentCalendarData = calendar.getCurrentData();
    if (this.currentCalendarData.currentViewType !== 'customWeek') {
      textDialog +=
        '<b>Es kann nur einen Wochenplan gelöscht werden. </b><br/>';
      calendar.changeView('customWeek');
      this.service.getTimelineEvent(
        true,
        this.currentCalendarData.viewApi.currentStart,
        this.currentCalendarData.currentViewType === 'customDay',
        false,
        false,
        '*'
      );
    }
    this.dialogService.setDialogOpen(true); // Dialog geöffnet
    this.dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      disableClose: false,
      panelClass: 'custom-dialog-container',
    });

    const startDate = dayjs(
      this.currentCalendarData.viewApi.currentStart
    ).format('DD.MM');
    // Subtract one second from currentEnd
    const endDate = dayjs(this.currentCalendarData.viewApi.currentEnd)
      .subtract(1, 'seconds')
      .format('DD.MM');

    textDialog += FormatString(
      this.translate.instant('DO YOU WANT TO DELETE THE PLAN'),
      [startDate, endDate]
    );

    this.dialogRef.componentInstance.confirmMessage = textDialog;
    this.dialogRef.componentInstance.confirmHeader = this.translate.instant(
      'DELETE WEEKLY SCHEDULE'
    );
    this.dialogRef.componentInstance.confirmButtonText =
      this.translate.instant('DELETE');
    this.dialogRef.componentInstance.cancelButtonText =
      this.translate.instant('ABORT');

    this.dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.deletePlanButton();
      }
      this.dialogService.setDialogOpen(false); // Dialog geöffnet
      this.dialogRef = null;
    });
  }

  deletePlanButton(): void {
    const calendar = this.calendarComponent.getApi();
    const currentData = calendar.getCurrentData();
    this.service.deleteTimelineWorkPlan(
      currentData.viewApi.currentStart,
      currentData.viewApi.currentEnd
    );
  }
  selectPrevButton(): void {
    this.calendarApi.prev();
    this.currentCalendarData = this.calendarApi.getCurrentData();
    this.service.getTimelineEvent(
      true,
      this.currentCalendarData.viewApi.currentStart,
      this.currentCalendarData.currentViewType === 'customDay',
      false,
      false,
      '*'
    );
  }
  selectTodayButton(): void {
    this.calendarApi.today();
    this.currentCalendarData = this.calendarApi.getCurrentData();
    this.service.getTimelineEvent(
      true,
      this.currentCalendarData.viewApi.currentStart,
      this.currentCalendarData.currentViewType === 'customDay',
      false,
      false,
      '*'
    );
  }
  selectNextButton(): void {
    this.currentCalendarData = this.calendarApi.getCurrentData();
    this.calendarApi.next();
    this.service.getTimelineEvent(
      true,
      this.currentCalendarData.viewApi.currentStart,
      this.currentCalendarData.currentViewType === 'customDay',
      false,
      false,
      '*'
    );
  }

  selectCustomWeekButton(): void {
    const calendar = this.calendarComponent.getApi();
    calendar.changeView('customWeek');
    this.currentCalendarData = calendar.getCurrentData();
    this.service.currentFilter = SortShiftBy.ByAvailableTime;
    this.byAvailableTime = true;
    this.byShiftStart = false;
    this.service.getTimelineEvent(
      true,
      this.currentCalendarData.viewApi.currentStart,
      this.currentCalendarData.currentViewType === 'customDay',
      false,
      false,
      '*'
    );
  }
  selectCustomDayButton(): void {
    const calendar = this.calendarComponent.getApi();
    calendar.changeView('customDay');
    this.currentCalendarData = calendar.getCurrentData();
    this.service.currentFilter = SortShiftBy.ByAvailableTime;
    this.byAvailableTime = true;
    this.byShiftStart = false;
    this.service.getTimelineEvent(
      true,
      this.currentCalendarData.viewApi.currentStart,
      this.currentCalendarData.currentViewType === 'customDay',
      false,
      false,
      '*'
    );
  }
  editWorkShift(eventId: string): void {
    setTimeout(() => {
      hideAll();
    }, 100);
    const list = this.calendarOptions.events;
    this.currentCalendarData = this.calendarApi.getCurrentData();
    const shift = (list as []).find((x: any) => x.eventId === eventId) as any;
    this.addWorkShift({
      StarTime: shift.start,
      EndTime: shift.end,
      DepartementId: shift.departmentId,
      EmployeeId: shift.employeeId,
      EventId: eventId,
      calendarViewDay: this.currentCalendarData.currentViewType === 'customDay',
      isOpenShift: shift.eventType === EventType.OpenShift,
      addWorkShift: false,
      NumberOfStaff: shift.numberOfStaff,
      SkillId: shift.skillId,
      Break: shift.break,
    });
  }
  handleDateSelect(selectInfo: DateSelectArg): void {
    this.currentCalendarData = this.calendarApi.getCurrentData();
    const startTime = dayjs(selectInfo.startStr).format('YYYY-MM-DD HH:mm:ss');
    const endTime = dayjs(selectInfo.endStr).format('YYYY-MM-DD HH:mm:ss');

    this.addWorkShift({
      StarTime: startTime,
      EndTime: endTime,
      DepartementId: selectInfo.resource._resource.extendedProps.departementId,
      EmployeeId: selectInfo.resource._resource.extendedProps.employeeId,
      SkillId: selectInfo.resource._resource.extendedProps.skillId,
      calendarViewDay: this.currentCalendarData.currentViewType === 'customDay',
      isOpenShift: selectInfo.resource._resource.extendedProps.employeeId === 0,
      addWorkShift: true,
      NumberOfStaff: selectInfo.resource._resource.extendedProps.numberOfStaff,
      Break: this.companySetting.CompanySettings.TimeTrackingSettings.BreakTime,
    });
  }

  addWorkShift(workShift: any): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.panelClass = 'custom-dialog-container';
    // make scrollable for mobile devices (small screens)
    dialogConfig.maxHeight = '100vh';
    dialogConfig.maxWidth = '80vw';
    dialogConfig.autoFocus = false; // Ändern Sie dies auf false
    dialogConfig.restoreFocus = false; // Fügen Sie diese Zeile hinzu
    workShift.calendarViewDay =
      this.currentCalendarData.currentViewType === 'customDay';
    dialogConfig.data = { workShift };
    this.zone.run(() => {
      this.dialog.open(AddEmployeeInWorkShiftComponent, dialogConfig);
    });
  }

  handleDropInfo(events: any): void {
    if (events.event.extendedProps.eventId) {
      return;
    }

    this.isAdding = true;
    const event = new TimeEvent();
    event.EmployeeId = +events.event.id;
    event.StarTime = events.event.startStr;
    event.EventId = uuidv4();
    if (events.draggedEl.event) {
      event.DepartementId = +JSON.parse(events.draggedEl.event).departementId;
    }

    events.event.setExtendedProp('eventId', event.EventId);
    this.queriedEmployee = 0;
    this.service.addEvent(
      event,
      true,
      this.currentCalendarData.currentViewType === 'customDay'
    );
    this.cdr.detectChanges();
  }

  handleEventChange(events: any): void {
    if (this.isCopy) {
      const event = new TimeEvent();
      event.EmployeeId = +events.event.id;
      event.StarTime = events.event.startStr;
      if (events.event.endStr !== '') {
        event.EndTime = events.event.endStr;
      }
      event.EventId = uuidv4();
      event.DepartementId = events.event.extendedProps.departementId;
      this.queriedEmployee = 0;
      this.service.addEvent(
        event,
        true,
        this.currentCalendarData.currentViewType === 'customDay'
      );
    }
    if (!this.isAdding && !this.isCopy) {
      const event = new TimeEvent();
      event.EmployeeId = +events.event.id;
      event.StarTime = events.event.startStr;
      if (events.event.endStr !== '') {
        event.EndTime = events.event.endStr;
      }
      event.EventId = events.event.extendedProps.eventId;
      event.SkillId = events.event.extendedProps.skillId;
      this.queriedEmployee = 0;
      this.queriedPositionId = 0;
      this.service.updateEvent(
        event,
        true,
        this.currentCalendarData.currentViewType === 'customDay'
      );
    }
    this.isAdding = false;
    this.isCopy = false;
    this.cdr.detectChanges();
  }

  publishPlanButton(): void {
    const calendar = this.calendarComponent.getApi();
    this.currentCalendarData = calendar.getCurrentData();

    this.service.publishTimelineWorkPlan(
      this.currentCalendarData.viewApi.currentStart,
      this.currentCalendarData.viewApi.currentEnd
    );
  }

  handleEventResize(event: any): void {
    if (parseInt(event.event.id) !== this.queriedEmployee) {
      this.currentEventId = event.event.extendedProps.eventId;
      this.getEmployeeAvailability(event.event.id);
    }
  }
  handleEventContent(event): any {
    if (
      event.isDraggable &&
      event.isDragging &&
      parseInt(event.event.id) !== this.queriedEmployee
    ) {
      this.currentEventId = event.event.extendedProps.eventId;
      this.getEmployeeAvailability(event.event.id);
    }

    if (event.event.extendedProps.absenceType === 3) {
      return {
        html:
          event.event._def.title +
          ' ' +
          this.translate.instant('IS NOT AVAILABLE IN THE AREA MARKED IN RED'),
      };
    }

    // get hours and minutes from the event start and end time and show in HH:MM format
    const start = dayjs(event.event.start);
    const end = dayjs(event.event.end);
    const hours = end.diff(start, 'hours');
    // calculate the remaining time after subtracting the break time from the total time in minutes
    // break time is in minutes
    const minutes = end.diff(start, 'minutes') - hours * 60;
    const remainingTime =
      hours * 60 + minutes - event.event.extendedProps.break;

    // convert remaining time to HH:MM format
    let remainingHours = Math.floor(remainingTime / 60);
    let remainingMinutes = remainingTime % 60;

    let remainingHoursString = remainingHours.toString();
    let remainingMinutesString = remainingMinutes.toString();

    if (remainingHours < 10) {
      remainingHoursString = '0' + remainingHours;
    }
    if (remainingMinutes < 10) {
      remainingMinutesString = '0' + remainingMinutes;
    }

    let breakTime = this.convertMinutesToTime(event.event.extendedProps.break);

    let shiftStautsIcon = this.service.shiftStatusMapping(
      event.event.extendedProps.shiftStatus
    );

    if (event.event.extendedProps.eventType === EventType.OpenShift) {
      shiftStautsIcon = '';
    }
    const icon =
      '<i style="position: absolute; bottom: 0; right: 0;" class="fa-lg ' +
      shiftStautsIcon +
      '"></i>';

    return {
      html:
        '<div class="fc-event-time">' +
        event.timeText +
        '  ' +
        // show the icon in the right side of the event
        '<div class="a hidden-mobile">' +
        '<i  class="b fa-solid fa-clock"> ' +
        remainingHoursString +
        ':' +
        remainingMinutesString +
        '</i>' +
        '<i  class="c fa-light fa-mug-hot"> ' +
        breakTime +
        '</i>' +
        '</div>' +
        '</div>' +
        event.event._def.title +
        ' <em class="' +
        event.event._def.extendedProps.icon +
        ' fa-lg' +
        '"></em>' +
        // show the check icon on the bottom right
        icon,
    };
  }

  convertMinutesToTime(minutes: number): string {
    if (isNaN(minutes) || minutes < 0) {
      return '00:00';
    }

    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;

    const hoursStr = hours < 10 ? '0' + hours : hours;
    const minutesStr =
      remainingMinutes < 10 ? '0' + remainingMinutes : remainingMinutes;

    return `${hoursStr}:${minutesStr}`;
  }

  getEmployeeAvailability(id: string): void {
    this.queriedEmployee = parseInt(id);
    this.availabilityService.getAvailabilityEvent(false, this.queriedEmployee);
  }

  getAvailabilityPositionId(id: number): void {
    this.queriedPositionId = id;
    this.currentEventId = null;
    this.availabilityService.getAvailabilityPositionId(
      false,
      this.queriedPositionId
    );
  }
  showWorkShiftDetails(eventId, eventType): void {
    setTimeout(() => {
      hideAll();
    }, 100);
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;

    dialogConfig.data = { eventId, eventType };
    dialogConfig.panelClass = 'custom-dialog-container';
    this.zone.run(() => {
      this.dialog.open(ShowWorkShiftDetailsComponent, dialogConfig);
    });
  }

  handleEventDidMount(event): any {
    tippy(event.el, {
      content:
        '<div>' +
        '<span onclick="Window.WorkShiftsPlannerTimelineComponent.showWorkShiftDetails(\'' +
        event.event.extendedProps.eventId +
        "'," +
        event.event.extendedProps.eventType +
        ')" ><i class="bi bi-info-circle fa-lg fa-clickable">' +
        '</i></span>' +
        ' <span onclick="Window.WorkShiftsPlannerTimelineComponent.editWorkShift(\'' +
        event.event.extendedProps.eventId +
        '\')"><i class="bi bi-pen fa-lg fa-clickable"></i></span>' +
        '<span onclick="Window.WorkShiftsPlannerTimelineComponent.deleteEvent(\'' +
        event.event.extendedProps.eventId +
        "'," +
        event.event.extendedProps.eventType +
        ')" ><i class="bi bi-trash fa-lg fa-clickable">' +
        '</i></span> </div>',
      allowHTML: true,
      plugins: [inlinePositioning, followCursor],
      theme: 'light',
      trigger: 'click',
      followCursor: 'initial',
      arrow: roundArrow,
      popperOptions: { strategy: 'fixed' },
      interactive: true,
      interactiveBorder: 30,
      interactiveDebounce: 0,
      appendTo: document.body,
      animation: 'scale-extreme',
      onMount(instance): void {
        const box = instance.popper.firstElementChild;
        requestAnimationFrame(() => {
          box.classList.add('animated');
          box.classList.add('wobble');
        });
      },
    });
  }
  showWarning(dayOfYear: number): boolean {
    return (
      this.eventsAndScheduleRequirementList &&
      this.eventsAndScheduleRequirementList[dayOfYear] &&
      this.eventsAndScheduleRequirementList[dayOfYear].length > 0
    );
  }

  callAutoFill(dayNumber: number, fillWeekDays: boolean = false): void {
    setTimeout(() => {
      hideAll();
    }, 100);
    this.service.callAutomaticFill(
      dayNumber,
      this.currentCalendarData.viewApi.currentStart,
      fillWeekDays
    );
  }

  showWarningText(dayOfYear: number): string {
    const button = `<div class="row" style="margin: 5px;">
      <button style="float: right;" class="btn btn-primary btn-sm" onclick="Window.WorkShiftsPlannerTimelineComponent.callAutoFill(${dayOfYear})">
        Automatisch befüllen
      </button>
      </div>
    `;

    const requirements =
      (this.eventsAndScheduleRequirementList?.[dayOfYear] as any) ?? [];
    const elements = [];

    const startTimes = requirements.map((element) =>
      dayjs(element.StartTime).format('HH:mm')
    );
    const endTimes = requirements.map((element) =>
      dayjs(element.EndTime).format('HH:mm')
    );

    for (let i = 0; i < requirements.length; i++) {
      const element = requirements[i];
      const startTime = startTimes[i];
      const endTime = endTimes[i];

      elements.push(`
        <li>
          ${element.NumberOfStaff} -mal
          <i class="${element.Icon}"></i>
          ${element.SkillName}
          von <b>${startTime}</b> Uhr bis <b>${endTime}</b> Uhr
        </li>
      `);
    }

    const html = `

      <div class="row" style="margin: 5px;"> Es fehlt:
        <br>
        <ul>${elements.join('')}</ul>
        <br>
      </div>${button}
    `;

    return html;
  }

  handleDayClick(clickedDay): any {
    clickedDay = dayjs(clickedDay).toDate();
    const calendar = this.calendarComponent.getApi();
    calendar.gotoDate(clickedDay);
    calendar.changeView('customDay');
    if (calendar) {
      this.currentCalendarData = calendar.getCurrentData();
      this.service.getTimelineEvent(
        true,
        this.currentCalendarData.viewApi.currentStart,
        this.currentCalendarData.currentViewType === 'customDay',
        false,
        false,
        '*'
      );
    }
  }

  addEmployeeToShift(clickedDay): any {
    clickedDay = dayjs(clickedDay).toDate();

    const startTime = dayjs(clickedDay).format('YYYY-MM-DD HH:mm:ss');
    const endTime = dayjs(clickedDay).format('YYYY-MM-DD HH:mm:ss');

    this.addWorkShift({
      StarTime: startTime,
      EndTime: endTime,
      DepartementId: 0,
      EmployeeId: 0,
      calendarViewDay: this.currentCalendarData.currentViewType === 'customDay',
      isOpenShift: false,
      addWorkShift: true,
      SkillId: 0,
      NumberOfStaff: 0,
      Break: this.companySetting.CompanySettings.TimeTrackingSettings.BreakTime,
    });
  }

  handleSlotLabelContent(event): any {
    if (
      (event.level === 1 &&
        this.currentCalendarData?.currentViewType !== 'customDay') ||
      (event.level === 0 &&
        this.currentCalendarData?.currentViewType === 'customDay')
    ) {
      // string between space and first . in the string
      const regex = /(?<=\s)(.*?)(?=\.)|(?<=\s)(.*?)(?=\s)/g;
      const dayIndex = event.text.match(regex);
      const dayOfYear = dayjs(event.date).dayOfYear();

      // if event.date is in the past, do not show the warning
      if (dayjs(event.date).isBefore(dayjs(), 'day')) {
        return {
          html:
            '<span class="time-line-header-day" onclick="Window.WorkShiftsPlannerTimelineComponent.handleDayClick(\'' +
            dayjs(event.date).format('YYYY-MM-DD') +
            '\')">' +
            event.text +
            '</span>',
        };
      }
      const WarningId = 't' + dayIndex;

      let htmlWarning = '';

      if (this.showWarning(dayOfYear)) {
        // remove old tippy instance if exists

        setTimeout(() => {
          tippy('#' + WarningId, {
            content: this.showWarningText(dayOfYear),
            allowHTML: true,
            plugins: [inlinePositioning, followCursor],
            theme: 'light',
            trigger: 'click',
            followCursor: 'initial',
            arrow: roundArrow,
            popperOptions: { strategy: 'fixed' },
            interactive: true,
            interactiveBorder: 30,
            interactiveDebounce: 0,
            appendTo: document.body,
            animation: 'scale-extreme',
            onMount(instance): void {
              const box = instance.popper.firstElementChild;
              requestAnimationFrame(() => {
                box.classList.add('animated');
                box.classList.add('wobble');
              });
            },
          });
        }, 400);

        htmlWarning =
          '<i id="' +
          WarningId +
          '" class="bi bi-exclamation-triangle fa-clickable time-line-header-day-warning"></i>   ';
      }

      let addShift =
        '<span class="time-line-header-day-add-employee" onclick="Window.WorkShiftsPlannerTimelineComponent.addEmployeeToShift(\'' +
        dayjs(event.date).format('YYYY-MM-DD') +
        '\')">' +
        '<i class="fa-solid fa-user-plus"></i>' +
        '</span>';

      return {
        html:
          htmlWarning +
          '<span class="time-line-header-day" onclick="Window.WorkShiftsPlannerTimelineComponent.handleDayClick(\'' +
          dayjs(event.date).format('YYYY-MM-DD') +
          '\')">' +
          event.text +
          '</span>' +
          addShift,
      };
    }
    return true;
  }

  handleResourceLabelContent(event): any {
    const extendedProps = event.resource._resource.extendedProps;

    if (extendedProps.sortOrder === -99) {
      return {
        html: `
          <div class="row">
            <div style="padding-left: 0px;padding-right: 0px;" class="col-md-3 mb-2">
              <div style="position: relative;display: flex;justify-content: center;align-items: center;">
                <div id="progress-spinner"></div>
                <div style="padding-left: 0px;padding-right: 0px; font-size: medium; margin-bottom: 0px !important;" class="col-md-9 mb-4">
                  ${this.translate.instant('OPEN WORK SHIFT')}
                </div>
              </div>
            </div>
          </div>
        `,
      };
    }

    const { employeeId, timeAvailablePercent, timeAvailable, skillIcons } =
      extendedProps;
    const colorPriority = '#4ccaf1';
    let color = '#1b5e1f8f';

    if (employeeId > 0) {
      if (timeAvailablePercent >= 80) {
        color = '#F9A8258f';
      }
      if (timeAvailablePercent > 99) {
        color = '#B71C1C8f';
      }
      return {
        html: `
          <div class="row">
            <div style="padding-left: 0px;padding-right: 0px;" class="col-md-3 mb-2">
              <div class="hidden-small-mobile" style="position: relative;display: flex;justify-content: center;align-items: center;">
                <div id="middle-circle">${timeAvailablePercent}%</div>
                <div id="progress-spinner" style="background: conic-gradient(${color} ${timeAvailablePercent}%, rgb(242, 242, 242) ${timeAvailablePercent}%);"></div>
              </div>
            </div>
            <div style="padding-left: 0px;padding-right: 0px; font-size: medium; margin-bottom: 0px !important; padding-left: 5px;" class="col-md-9 mb-4">
              ${event.resource._resource.title}<br/>
              <div class="badge badge-pill badge-primary" style="font-size: small; background-color: ${colorPriority}">
                ${timeAvailable}
              </div>
              ${skillIcons}
            </div>
          </div>
        `,
      };
    }
  }

  deleteEvent(id, eventType): void {
    setTimeout(() => {
      hideAll();
    }, 100);
    this.zone.run(() => {
      this.dialogService.setDialogOpen(true); // Dialog geöffnet
      this.dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        disableClose: false,
        panelClass: 'custom-dialog-container',
      });
    });
    this.dialogRef.componentInstance.confirmMessage = this.translate.instant(
      'DO YOU WANT TO DELETE THE WORK SHIFT'
    );
    this.dialogRef.componentInstance.confirmButtonText =
      this.translate.instant('DELETE');
    this.dialogRef.componentInstance.cancelButtonText =
      this.translate.instant('ABORT');

    this.dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        if (!this.currentCalendarData) {
          const calendar = this.calendarComponent.getApi();
          this.currentCalendarData = calendar.getCurrentData();
        }
        if (eventType === EventType.WorkShift) {
          this.service.deleteEventTimeline(
            id,
            this.currentCalendarData.viewApi.currentStart,
            this.currentCalendarData.currentViewType === 'customDay'
          );
        }
        if (eventType === EventType.OpenShift) {
          this.service.deleteOpenShift(
            id,
            this.currentCalendarData.viewApi.currentStart
          );
        }
      }
      this.dialogService.setDialogOpen(false); // Dialog geschlossen
      this.dialogRef = null;
    });
  }

  uploadFile(files): Promise<string> {
    return this.uploadService.uploadFile(files);
  }

  fillterEventsShiftStart(): void {
    if (this.byShiftStart) {
      this.byAvailableTime = false;
      this.service.currentFilter = SortShiftBy.ByShiftStart;
      this.currentCalendarData = this.calendarApi.getCurrentData();
      this.service.getTimelineEvent(
        true,
        this.currentCalendarData.viewApi.currentStart,
        this.currentCalendarData.currentViewType === 'customDay',
        false,
        false,
        '*'
      );
    } else {
      this.service.currentFilter = SortShiftBy.Nothing;
      this.currentCalendarData = this.calendarApi.getCurrentData();
      this.service.getTimelineEvent(
        true,
        this.currentCalendarData.viewApi.currentStart,
        this.currentCalendarData.currentViewType === 'customDay',
        false,
        false,
        '*'
      );
    }
  }

  fillterEventsAvailableTime(): void {
    if (this.byAvailableTime) {
      this.byShiftStart = false;
      this.service.currentFilter = SortShiftBy.ByAvailableTime;
      this.currentCalendarData = this.calendarApi.getCurrentData();
      this.service.getTimelineEvent(
        true,
        this.currentCalendarData.viewApi.currentStart,
        this.currentCalendarData.currentViewType === 'customDay',
        false,
        false,
        '*'
      );
    } else {
      this.service.currentFilter = SortShiftBy.Nothing;
      this.currentCalendarData = this.calendarApi.getCurrentData();
      this.service.getTimelineEvent(
        true,
        this.currentCalendarData.viewApi.currentStart,
        this.currentCalendarData.currentViewType === 'customDay',
        false,
        false,
        '*'
      );
    }
  }
  handleDayCellClassNames(arg: any): any {
    const day = arg.date.getDay(); // 0 (Sonntag) bis 6 (Samstag)
    const weekendDays =
      this.companySetting.CompanySettings.CalendarOptions.WeekendDays;

    // Wenn der Tag ein Wochenende ist, CSS-Klasse hinzufügen
    if (weekendDays.includes(day)) {
      return ['weekend-day'];
    }

    // wenn der Tag arg.date in der Liste der Feiertage enthalten ist, CSS-Klasse hinzufügen
    if (
      this.holidays &&
      this.holidays.includes(dayjs(arg.date).format('YYYY-MM-DD'))
    ) {
      return ['holiday-day'];
    }
  }
}
