import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Observable, of, tap } from 'rxjs';
import { ApiEndPoints, ApiMethod } from '../../../core/services/const';
import { HttpService } from '../../../core/services/http/http.service';
import { AvailableSubscriptionDto } from '../model/available-subscription-dto.model';
import { CheckoutSession } from '../model/checkout-session.model';
import { CompanySubscriptionDetailsDto } from '../model/company-subscription-details-dto.model';
import { FeatureDto } from '../model/feature-dto.model';
import { PaymentMethod } from '../model/payment-method.model';

declare const Stripe;
@Injectable({
  providedIn: 'root',
})
export class SubscriptionService {
  private featuresSubject = new BehaviorSubject<FeatureDto[]>([]);
  private featuresLoaded: boolean = false;
  private availableSubscriptionsSubject = new BehaviorSubject<
    AvailableSubscriptionDto[]
  >([]);
  private subscriptionsLoaded: boolean = false;
  private storageKey = 'company_features';

  companySubscriptionDetails =
    new BehaviorSubject<CompanySubscriptionDetailsDto>(
      new CompanySubscriptionDetailsDto()
    );

  getAvailableSubscriptionsObservable(): Observable<
    AvailableSubscriptionDto[]
  > {
    return this.availableSubscriptionsSubject.asObservable();
  }

  private paymentMethods: PaymentMethod[] = [
    {
      name: 'Kreditkarte',
      icon: 'assets/icons/credit-card.png',
    },
    {
      name: 'PayPal',
      icon: 'assets/icons/paypal.png',
    },
    {
      name: 'Lastschrift',
      icon: 'assets/icons/mobile-banking.png',
    },
  ];

  constructor(
    private httpClient: HttpService,
    private toastr: ToastrService,
    private translateService: TranslateService
  ) {
    this.loadFeaturesFromLocalStorage();
  }

  private loadFeaturesFromLocalStorage(): void {
    const storedFeatures = localStorage.getItem(this.storageKey);
    if (storedFeatures && storedFeatures!== 'undefined') {
      const features: FeatureDto[] = JSON.parse(storedFeatures);
      this.featuresSubject.next(features);
    }
  }

  private saveFeaturesToLocalStorage(features: FeatureDto[]): void {
    // check if features are not undefined
    if (!features) {
      return;
    }
    localStorage.setItem(this.storageKey, JSON.stringify(features));
  }

  loadCompanyFeatures(): Observable<FeatureDto[]> {
    if (!this.featuresLoaded) {
      // Features are not loaded from the server, initiate server call
      this.httpClient
        .requestCall(
          ApiEndPoints.GetCompanySubscriptionDetailsQuery,
          ApiMethod.GET
        )
        .pipe(
          tap((data: CompanySubscriptionDetailsDto) => {
            if (data) {
              this.featuresSubject.next(data.Features);
              this.companySubscriptionDetails.next(data);
              this.featuresLoaded = true;
              this.saveFeaturesToLocalStorage(data.Features); // Save features to local storage
            }
          })
        )
        .subscribe();
    }
    return this.featuresSubject.asObservable();
  }

  loadAvailableSubscriptions(): Observable<AvailableSubscriptionDto[]> {
    if (!this.subscriptionsLoaded) {
      this.httpClient
        .requestCall(ApiEndPoints.GetAvailableSubscriptionsQuery, ApiMethod.GET)
        .pipe(
          tap((data: AvailableSubscriptionDto[]) => {
            this.availableSubscriptionsSubject.next(data);
            this.subscriptionsLoaded = true;
          })
        )
        .subscribe();
    }
    return this.availableSubscriptionsSubject.asObservable();
  }

  hasFeature(featureId: number): boolean {
    return this.featuresSubject.value.some((f) => f.Id === featureId);
  }

  getPaymentMethods(): PaymentMethod[] {
    return this.paymentMethods;
  }

  processPayment(
    method: PaymentMethod,
    plan: CompanySubscriptionDetailsDto
  ): Observable<any> {
    return of({ success: true });
  }

  createCheckoutSession(checkoutSession: CheckoutSession): void {
    return this.httpClient
      .requestCall(
        ApiEndPoints.CreateCheckoutSessionCommand,
        ApiMethod.POST,
        checkoutSession
      )
      .subscribe((session) => {
        if (session) {
          this.redirectToCheckout(session);
        }
      });
  }

  changeSubscriptionPlan(SubscriptionId: number): Observable<boolean> {
    return this.httpClient
      .requestCall(ApiEndPoints.ChangeSubscriptionPlanCommand, ApiMethod.POST, {
        SubscriptionId,
      })
      .pipe(
        tap((success: boolean) => {
          if (success) {
            this.toastr.success(
              this.translateService.instant('subscription-changedSuccess'),
              this.translateService.instant('subscription-title')
            );
            this.loadAvailableSubscriptions();
            this.loadCompanySubscriptionDetails(); // Aktuelles Abonnement neu laden
          } else {
            this.toastr.error(
              this.translateService.instant('subscription-changeFailed') +
                ' ' +
                this.translateService.instant(
                  'subscription-pleaseTryAgainLater'
                ),
              this.translateService.instant('subscription-title')
            );
          }
        })
      );
  }

  loadCompanySubscriptionDetails(): void {
    this.httpClient
      .requestCall(
        ApiEndPoints.GetCompanySubscriptionDetailsQuery,
        ApiMethod.GET
      )
      .pipe(
        tap((data: CompanySubscriptionDetailsDto) => {
          if (data) {
            this.companySubscriptionDetails.next(data);
            this.saveFeaturesToLocalStorage(data.Features); // Update the local storage with new features
          }
        })
      )
      .subscribe();
  }

  redirectToCheckout(session: any) {
    const stripe = Stripe(session.publicKey);

    stripe.redirectToCheckout({
      sessionId: session.sessionId,
    });
  }

  createCustomerPortalSession() {
    const returnUrl = window.location.href;
    const command = {
      ReturnUrl: returnUrl,
    };

    this.httpClient
      .requestCall(
        ApiEndPoints.CreateCustomerPortalSession,
        ApiMethod.POST,
        command
      )
      .subscribe((response: any) => {
        if (response && response.Url) {
          window.location.href = response.Url;
        }
      });
  }
}
