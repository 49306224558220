import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
  FormControl,
  NgForm,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import { TranslateService } from '@ngx-translate/core';
import * as dayjs from 'dayjs';
import { ReplaySubject, Subject, Subscription } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { FormatString } from 'src/app/core/_helpers/stringFormater';
import { Departement } from 'src/app/modules/departement/model/departement';
import { DepartementService } from 'src/app/modules/departement/services/departement.service';
import { CompanySettingsService } from 'src/app/modules/settings/services/company-settings.service';
import { Skill } from 'src/app/modules/skill/model/departement';
import { SkillService } from 'src/app/modules/skill/services/skill.service';
import { v4 as uuidv4 } from 'uuid';
import { EmployeeService } from '../../../employee/services/employee.service';
import { EmployeeAvailability } from '../../model/EmployeeAvailability';
import {
  EmployeeListByDateDto,
  EmployeeListDto,
} from '../../model/department-dto.model';
import { TimeEvent } from '../../model/time-event.model';
import { CalendarService } from '../../services/calendar.service';
import { OpenShiftsService } from '../../services/open-shifts.service';

@Component({
  selector: 'app-add-employee-in-work-shift',
  templateUrl: './add-employee-in-work-shift.component.html',
  styleUrls: ['./add-employee-in-work-shift.component.css'],
})
export class AddEmployeeInWorkShiftComponent implements OnInit, OnDestroy {
  public filteredCategory: ReplaySubject<EmployeeListByDateDto[]> =
    new ReplaySubject<EmployeeListByDateDto[]>(1);
  public categoryFilterCtrl: UntypedFormControl = new UntypedFormControl();
  employeeCategory: EmployeeListByDateDto[] = [];
  protected _onDestroy = new Subject<void>();
  @ViewChild('EmployeeId', { static: true }) categorySelect: MatSelect;
  private _subscriptionEmployeeCategory: Subscription;
  IncomeTypeId = '';

  private subscriptionDepartmentList: Subscription;
  private subscriptionSkillList: Subscription;
  departmentList = [];
  skillList = [];
  selected: string[];
  departmentValue = new UntypedFormControl();
  employeeAvailability: EmployeeAvailability = new EmployeeAvailability();

  suggestions: string[] = [
    '10',
    '15',
    '20',
    '25',
    '30',
    '40',
    '45',
    '50',
    '60',
  ];
  timeInputControl = new FormControl('', [
    Validators.required,
    Validators.pattern('^(0|[1-9][0-9]?|120)$'),
  ]);
  filteredSuggestions: string[] = [];
  requiredBreakTimeText: any;
  dailyWorkingHoursText: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public employeeService: EmployeeService,
    public dialogRef: MatDialogRef<AddEmployeeInWorkShiftComponent>,
    private service: CalendarService,
    public departementService: DepartementService,
    private translate: TranslateService,
    private skillService: SkillService,
    private openShiftService: OpenShiftsService,
    private companySetting: CompanySettingsService
  ) {}
  employeeName = '';

  formModel: TimeEvent = {
    EventId: null,
    StarTime: null,
    EndTime: null,
    EmployeeId: 0,
    DepartementId: 0,
    OpenShift: false,
    SkillId: 0,
    RequiredStaff: 0,
    Break:
      this.companySetting.CompanySettings.TimeTrackingSettings.BreakTime,
  };
  heder = null;
  employeeSelectionDisabled = false;
  selectedValue: {};
  hour = {
    StarTime: '',
    EndTime: '',
  };
  assignEmployeeForOpenShift = false;

  minEndTime = dayjs().startOf('day').set('h', 8).format('HH:mm');

  minTime = dayjs().startOf('day').set('h', 8).format('HH:mm');

  ngOnInit(): void {
    if (this.data && this.data.workShift) {
      this.formModel = {
        StarTime: this.data.workShift.StarTime,
        EndTime: this.data.workShift.EndTime,
        EventId: null,
        EmployeeId: this.data.workShift.EmployeeId,
        DepartementId: this.data.workShift.DepartementId,
        OpenShift: this.data.workShift.isOpenShift,
        SkillId: this.data.workShift.SkillId,
        RequiredStaff: this.data.workShift.NumberOfStaff,
        Break: this.data.workShift.Break,
      };
      if (this.data.workShift.EmployeeId) {
        this.GetEmployeeAvailability(
          this.data.workShift.EmployeeId,
          this.data.workShift.StarTime
        );
        this.employeeSelectionDisabled = true;
      }

      if (!this.data.workShift.isOpenShift) {
        this.heder = this.data.workShift.addWorkShift
          ? this.translate.instant('Add New Work Shift')
          : this.translate.instant('Edit Work Shift');
      } else {
        this.heder = this.data.workShift.addWorkShift
          ? this.translate.instant('ADD OPEN SHIFT')
          : this.translate.instant('EDIT OPEN SHIFT');
      }

      this.hour.StarTime = this.getDateHours(this.data.workShift.StarTime);
      this.hour.EndTime = this.getDateHours(this.data.workShift.EndTime);

      this.onTimeset(this.hour.StarTime);
      const StarTime = new Date(this.data.workShift.StarTime);
      this.heder =
        this.heder +
        ' ' +
        StarTime.toLocaleDateString(navigator.language, {
          month: '2-digit',
          day: '2-digit',
        });
    } else {
      if (this.skillList) {
        this.selectedValue = this.skillList[0];
        this.formModel.SkillId = this.skillList[0]?.Id;
      }
    }

    this.subscriptionDepartmentList =
      this.departementService.departementList.subscribe((list) => {
        this.departmentList = list as Departement[];
      });
    this.departementService.getDepartementList();

    this.subscriptionSkillList = this.skillService.EmployeeSkillList.subscribe(
      (list) => {
        // check if the skill list is empty or null then set the skill list to the list
        if (!this.skillList || this.skillList.length === 0) {
          this.skillList = list as Skill[];
        }

        this.skillList = list as Skill[];
        if (!this.data?.workShift?.SkillId) {
          this.selectedValue = this.skillList.find(
            (x) => x.Id === this.data.workShift.SkillId
          );
        } else {
          this.selectedValue = this.skillList.find(
            (x) => x.Id === this.data.workShift.SkillId
          );
          // this.formModel.SkillId = this.data.workShift.SkillId;
        }
      }
    );
    this.skillService.getEmployeeSkillList(this.data.workShift.EmployeeId);

    this._subscriptionEmployeeCategory =
      this.employeeService.employeeNameListByDate.subscribe((list) => {
        this.employeeCategory = list as EmployeeListByDateDto[];
        this.filteredCategory.next(this.employeeCategory.slice());
        // get employee name from employee id in employeeCategory by employeeId
        this.employeeName = this.employeeCategory.find(
          (x) => x.Id === this.data.workShift.EmployeeId
        )?.Name;
      });

    // if starTime and endTime is equal then set endTime +24 -1 minute
    if (this.data.workShift.StarTime === this.data.workShift.EndTime) {
      this.data.workShift.EndTime = dayjs(this.data.workShift.EndTime)
        .add(1, 'day')
        .subtract(1, 'minute')
        .format('YYYY-MM-DD HH:mm:ss');
    }
    this.employeeService.getEmployeeNameListByDate({
      StarTime: dayjs(this.data.workShift.StarTime).utcOffset(0, true).format(),
      EndTime: dayjs(this.data.workShift.EndTime).utcOffset(0, true).format(),
      SkillId: this.data.workShift.SkillId,
      EmployeeId: this.data.workShift.EmployeeId,
      DepartmentId: this.data.workShift.DepartementId,
    });

    this.categoryFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterCategory();
      });
  }

  getDateHours(dateString: string): string {
    const date = new Date(dateString);
    return date.toLocaleString([], { hour: '2-digit', minute: '2-digit' });
  }

  onTimeset(event): void {
    const eventTime = event.split(':');
    this.minEndTime = dayjs()
      .startOf('day')
      .set('h', eventTime[0])
      .set('m', eventTime[1])
      .add(5, 'minutes')
      .format('HH:mm');
  }

  onTimeChange(event): void {
    this.getEmployeeList();
    this.isBreakTimeInsufficient(); // Update the break time validation
  }

  onInputChange(event: any) {
    const inputValue = event.target.value.toLowerCase();
    this.filteredSuggestions = this.suggestions.filter((suggestion) =>
      suggestion.toLowerCase().includes(inputValue)
    );
  }

  selectSuggestion(event: MatAutocompleteSelectedEvent, suggestion: number) {
    this.formModel.Break = suggestion;
    this.isBreakTimeInsufficient(); // Update the break time validation
  }

  isOverDailyWorkingHours(): boolean {
    const start = dayjs(this.hour.StarTime, 'HH:mm');
    const end = dayjs(this.hour.EndTime, 'HH:mm');
    const duration = dayjs.duration(end.diff(start));
    const hours = duration.asHours();
    this.dailyWorkingHoursText = FormatString(
      this.translate.instant('WORKING TIME EXCEEDS DAILY LIMIT'),
      [this.companySetting.CompanySettings.TimeTrackingSettings.MaxDailyWorkingHours]
    );
    return (
      hours >
      this.companySetting.CompanySettings.TimeTrackingSettings.MaxDailyWorkingHours
    );
  }

  isBreakTimeInsufficient(): boolean {
    const start = dayjs(this.hour.StarTime, 'HH:mm');
    const end = dayjs(this.hour.EndTime, 'HH:mm');
    const duration = dayjs.duration(end.diff(start));
    const hours = duration.asHours();
    let requiredBreakTime =
      this.companySetting.CompanySettings.TimeTrackingSettings.BreakTime;

    if (hours > 9) {
      requiredBreakTime =
        this.companySetting.CompanySettings.TimeTrackingSettings.LongBreakTime;
    }

    this.requiredBreakTimeText = FormatString(
      this.translate.instant('BREAK TIME IS LESS THAN REQUIRED'),
      [requiredBreakTime]
    );
    return this.formModel.Break < requiredBreakTime;
  }

  onSubmit(form: NgForm): void {
    const startTime = this.hour.StarTime.split(':');
    const endTime = this.hour.EndTime.split(':');
    const startTimeCalendar = this.data.workShift.StarTime;
    this.data.workShift.StarTime = new Date(startTimeCalendar);
    this.data.workShift.StarTime.setHours(startTime[0], startTime[1]);

    this.data.workShift.EndTime = new Date(startTimeCalendar);
    this.data.workShift.EndTime.setHours(endTime[0], endTime[1]);

    if (form.value) {
      form.value.StarTime = this.data.workShift.StarTime;
      form.value.EndTime = this.data.workShift.EndTime;
      form.value.DepartmentId = form.value.DepartementID;
      form.value.EmployeeId = form.value.EmployeeID;
      if (
        this.data.workShift.addWorkShift &&
        !this.data.workShift.isOpenShift
      ) {
        this.addWorkShiftEvent(form);
      }

      if (
        !this.data.workShift.addWorkShift &&
        !this.data.workShift.isOpenShift
      ) {
        this.updateWorkShiftEvent(form);
      }

      if (this.data.workShift.isOpenShift && this.data.workShift.addWorkShift) {
        this.addOpenShiftEvent(form);
      }
      if (
        this.data.workShift.isOpenShift &&
        !this.data.workShift.addWorkShift
      ) {
        if (this.assignEmployeeForOpenShift) {
          this.adoptOpenShiftInWorkShift(
            this.data.workShift.EventId,
            form.value.EmployeeId
          );
        }
        this.updateOpenShiftEvent(form);
      }

      this.onClose();
    }
  }

  adoptOpenShiftInWorkShift(openShiftId, employeeId): void {
    this.openShiftService.adoptOpenShiftInWorkShift(openShiftId, employeeId);
  }

  updateAssignEmployeeForOpenShift() {
    if (!this.assignEmployeeForOpenShift) {
      this.formModel.EmployeeId = 0;
    } else {
      this.formModel.Break =
        this.companySetting.CompanySettings.TimeTrackingSettings.BreakTime;
    }
  }

  updateWorkShiftEvent(form: NgForm): void {
    form.value.EventId = this.data.workShift.EventId;
    form.value.EmployeeId = this.data.workShift.EmployeeId;
    this.service.updateEvent(
      form.value,
      true,
      this.data.workShift.calendarViewDay
    );
  }

  updateOpenShiftEvent(form: NgForm): void {
    form.value.EventId = this.data.workShift.EventId;
    this.openShiftService.updateEvent(form.value);
  }

  addWorkShiftEvent(form: NgForm): void {
    form.value.EventId = uuidv4();
    this.service.addEvent(
      form.value,
      this.data.workShift.DepartementId > 0,
      this.data.workShift.calendarViewDay
    );
  }

  addOpenShiftEvent(form: NgForm): void {
    form.value.EventId = uuidv4();
    this.openShiftService.addEvent(form.value);
  }

  onIconChange(event): void {
    this.selectedValue = this.skillList.find((x) => x.Id === event.value);
    this.getEmployeeList();
  }

  getEmployeeList() {
    const startTime = this.hour.StarTime.split(':');
    const endTime = this.hour.EndTime.split(':');
    const startTimeCalendar = this.formModel.StarTime;
    let StartTimeDraf = new Date(startTimeCalendar);
    StartTimeDraf.setHours(Number(startTime[0]), Number(startTime[1]));

    let EndTimeDraf = new Date(startTimeCalendar);
    EndTimeDraf.setHours(Number(endTime[0]), Number(endTime[1]));

    this.employeeService.getEmployeeNameListByDate({
      StarTime: dayjs(StartTimeDraf).utcOffset(0, true).format(),
      EndTime: dayjs(EndTimeDraf).utcOffset(0, true).format(),
      SkillId: this.formModel.SkillId,
      EmployeeId: 0,
      DepartmentId: this.formModel.DepartementId,
    });
  }

  onDepartmentChange(event): void {
    this.subscriptionSkillList = this.skillService.SkillList.subscribe(
      (list) => {
        this.skillList = list as Skill[];
      }
    );
    this.skillService.getSkillList(event.value);
  }

  onClose(): void {
    this.dialogRef.close();
  }

  onEmployeeIdChange(form: any): void {
    form.DepartmentId = this.employeeCategory.find(
      (x) => x.Id === form.EmployeeId
    ).DepartmentId;
    this.GetEmployeeAvailability(form.EmployeeId, form.StarTime);
  }

  ngOnDestroy(): void {
    this.subscriptionDepartmentList.unsubscribe();
    this.subscriptionSkillList.unsubscribe();
    this._subscriptionEmployeeCategory.unsubscribe();
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  GetEmployeeAvailability(employeeId: number, startTime: Date): void {
    this.service
      .GetEmployeeAvailability(employeeId, startTime)
      .subscribe((res) => {
        this.employeeAvailability = res as EmployeeAvailability;

        if (this.employeeAvailability.AvailableStart) {
          this.employeeAvailability.AvailableStart = new Date(
            this.employeeAvailability.AvailableStart
          );
          this.employeeAvailability.AvailableEnd = new Date(
            this.employeeAvailability.AvailableEnd
          );
          this.employeeName = this.employeeAvailability.EmployeeName;
        }
      });
  }

  convertDateToString(date: Date): string {
    return dayjs(date).format('HH:mm');
  }

  ///////////////////// Filter Employee Section ///////////////////
  protected filterCategory(): void {
    if (!this.employeeCategory) {
      return;
    }
    // get the search keyword
    let search = this.categoryFilterCtrl.value;
    if (!search) {
      this.filteredCategory.next(this.employeeCategory.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the Category
    this.filteredCategory.next(
      this.employeeCategory.filter(
        (category) => category.Name.toLowerCase().indexOf(search) > -1
      )
    );
  }

  updateIncomeType(ctrl): void {
    if (ctrl.selectedIndex === 0) {
      this.IncomeTypeId = '';
    } else {
      this.IncomeTypeId = this.employeeCategory[ctrl.selectedIndex - 1]
        .Id as '';
    }
  }

  ngAfterViewInit() {
    if (!this.data.workShift.isOpenShift) {
      this.setInitialValue();
    }
  }

  protected setInitialValue() {
    this.filteredCategory
      .pipe(take(1), takeUntil(this._onDestroy))
      .subscribe(() => {
        if (this.categorySelect) {
          this.categorySelect.compareWith = (
            a: EmployeeListDto,
            b: EmployeeListDto
          ) => a && b && a === b;
        }
      });
  }
  ///////////////////// End Filter Income Section ///////////////////
}
