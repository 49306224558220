<div class="modal-dialog" role="document" style="margin: 10px; min-width: 450px;">
  <div class="modal-content">
    <div class="modal-header text-center">
      <h5 class="modal-title w-100" id="exampleModalCenterTitle">{{'ADD THE TEMPLATE IN THE PLAN'| translate}}</h5>
    </div>
    <div class="modal-body" style="overflow-y: auto;">
      <div class="col-lg-12">
        <div *ngFor="let template of planTemplateList">
          <app-item-list [Title]="template.Name" [Content]="getTime(formModel.StarTime, formModel.EndTime)"
            [Icon]="'fa-light fa-calendars'" [buttonClass]="'btn btn-outline-success'"
            (onClick)="acceptRequest(request)" [BadgeContent]="'Plan Template' | translate"
            [secondButtonIcon]="'bi-trash'" [secondButtonClass]="'btn btn-outline-danger btn-sm '"
            [isSecondButton]="true" [ContentColor]="'#34568B'" (onClick)="onSubmitPlan(template.Id)"
            (onSecondClick)="onDelete(template.Id,template.ImageId)"></app-item-list>
        </div>
      </div>
      <div *ngIf="!planTemplateList?.length">
        <div class="alert alert-warning" role="alert">
          {{'CURRENTLY THERE ARE NO TEMPLATES AVAILABLE'| translate}}
        </div>
      </div>
    </div>
    <div class="modal-footer d-flex justify-content-between">
      <button class="btn btn-outline-secondary" (click)="onClose()" type="button" data-dismiss="modal">
        {{'Close'| translate}}</button>
    </div>
  </div>
</div>