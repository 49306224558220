import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import * as dayjs from 'dayjs';
import { Subscription } from 'rxjs';
import { FormatString } from 'src/app/core/_helpers';
import { Conflicts } from '../../../admin/model/Conflicts.model';
import { AbsenceService } from '../../services/absence.service';

@Component({
  selector: 'app-accept-absence',
  templateUrl: './accept-absence.component.html',
  styleUrls: ['./accept-absence.component.css'],
})
export class AcceptAbsenceComponent implements OnInit, OnDestroy {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<AcceptAbsenceComponent>,
    private service: AbsenceService,

    private translate: TranslateService
  ) {}
  private holidaysConflicts: Subscription;
  holidaysConflictsList: Conflicts[];
  setAsOpenShift = true;
  conflictsAsOpenShift = [];
  Title = '';
  alertMessage = '';

  openShiftNumber = 0;
  deletedShifNumber = 0;

  openShiftNumberText = '';
  deletedShifNumberText = '';
  Comment = null;

  ngOnInit(): void {
    if (this.data && this.data.Id) {
      this.service.getConflictsWithWorkingShift(this.data.Id);

      this.alertMessage = this.getAlertMessage(
        this.data.Start,
        this.data.End,
        this.data.AllDay
      );

      if (this.data.Comment) {
        this.Comment = this.data.Comment;
      }

      this.Title =
        this.data.AbsenceType === 'Sick'
          ? this.translate.instant('SICKNESS REQUEST FOR')
          : (this.Title = this.translate.instant('VACATION REQUEST FOR'));
    }
    this.holidaysConflicts =
      this.service.holidaysConflictsWithWorkingShift.subscribe(
        (list: Conflicts[]) => {
          this.holidaysConflictsList = list as Conflicts[];
          this.conflictsAsOpenShift.fill(this.holidaysConflictsList.length);
          this.conflictsAsOpenShift = new Array(
            this.holidaysConflictsList.length
          );
          this.conflictsAsOpenShift.fill(true);
          this.getShiftNumber();
        }
      );
  }

  getAlertMessage(startTime: Date, endTime: Date, isAllDay: boolean): string {
    const options: any = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    };

    const startDateDay = startTime.getDate();
    const endDateDay = endTime.getDate();
    const starTimeString = new Date(startTime).toLocaleDateString(
      navigator.language,
      options
    );

    if (startDateDay === endDateDay) {
      let allDayString =
        "<strong style='white-space: nowrap;'>" +
        this.translate.instant('ALLDAY') +
        '</strong>';
      if (!isAllDay) {
        // Eintrag mit Uhrzeit
        const startTimeM = dayjs(startTime).format('HH:mm');
        const endTimeM = dayjs(endTime).format('HH:mm');
        allDayString =
          " <strong style='white-space: nowrap;'>" +
          startTimeM +
          '-' +
          endTimeM +
          '</strong>';
      }
      if (this.data.AbsenceType === 'Sick') {
        return (
          FormatString(this.translate.instant('ILLNESS REQUEST FOR X'), [
            this.data.Title,
            starTimeString,
          ]) +
          ' ' +
          allDayString
        );
      } else {
        return (
          FormatString(this.translate.instant('VACATION REQUEST X'), [
            this.data.Title,
            starTimeString,
          ]) +
          ' ' +
          allDayString
        );
      }
    } else {
      const endTimeString = dayjs(endTime)
        .subtract(1, 'minute')
        .toDate()
        .toLocaleDateString(navigator.language, options);

      if (this.data.AbsenceType === 'Sick') {
        return FormatString(this.translate.instant('ILLNESS REQUEST FOR XN'), [
          this.data.Title,
          starTimeString,
          endTimeString,
        ]);
      } else {
        return FormatString(this.translate.instant('VACATION REQUEST FOR XN'), [
          this.data.Title,
          starTimeString,
          endTimeString,
        ]);
      }
    }
  }
  onSubmit(): void {
    const workShift = [];
    for (let index = 0; index < this.conflictsAsOpenShift.length; index++) {
      if (this.conflictsAsOpenShift[index]) {
        workShift.push(this.holidaysConflictsList[index].WorkShiftId);
      }
    }

    this.service.addOpenShiftByAbsenceIdCommand(workShift, this.data.Id);
    this.service.acceptRequestAbsence(this.data.Id, this.data.Start);

    this.onClose();
  }

  onReject(): void {
    this.service.rejectRequestAbsence(this.data.Id, this.data.Start);
    this.onClose();
  }

  onClose(): void {
    this.dialogRef.close();
  }
  getDate(starTime): string {
    const options: any = {
      weekday: 'short',
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      dateFormat: 'EEE d.MM.yyyy',
    };
    const StarTime = new Date(starTime);
    return StarTime.toLocaleDateString(navigator.language, options);
  }

  getTime(startTime, endTime): string {
    const StarTime = new Date(startTime);
    let returnString = StarTime.toLocaleTimeString(navigator.language, {
      hour: '2-digit',
      minute: '2-digit',
    });
    const EndTime = new Date(endTime);
    return (
      returnString +
      ' - ' +
      EndTime.toLocaleTimeString(navigator.language, {
        hour: '2-digit',
        minute: '2-digit',
      })
    );
  }

  ngOnDestroy(): void {
    this.holidaysConflicts.unsubscribe();
  }

  acceptEvent(index): void {
    this.conflictsAsOpenShift[index] = true;
    this.getShiftNumber();
  }

  rejectEvent(index): void {
    this.conflictsAsOpenShift[index] = false;
    this.getShiftNumber();
  }

  getShiftNumber() {
    this.openShiftNumber = 0;
    this.deletedShifNumber = 0;
    for (let index = 0; index < this.conflictsAsOpenShift.length; index++) {
      if (this.conflictsAsOpenShift[index]) {
        this.openShiftNumber++;
      } else {
        this.deletedShifNumber++;
      }
    }
    this.openShiftNumberText = FormatString(
      this.translate.instant('SHIFTS ARE MARKED AS OPEN SHIFTS'),
      [this.openShiftNumber]
    );

    this.deletedShifNumberText = FormatString(
      this.translate.instant('SHIFTS ARE MARKED AS DELETED SHIFTS'),
      [this.deletedShifNumber]
    );
  }
}
