import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { EChartsOption } from 'echarts';
import { TimeChart } from '../../model/time-chart';

@Component({
  selector: 'app-time-recording-donut-chart',
  templateUrl: './time-recording-donut-chart.component.html',
  styleUrls: ['./time-recording-donut-chart.component.css'],
})
export class TimeRecordingDonutChartComponent implements OnInit, OnChanges {
  chartOption: EChartsOption;
  @Input() timeChart: TimeChart;
  constructor() {}

  ngOnInit(): void {
    this.updateChartOptions();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.timeChart) {
      this.updateChartOptions();
    }
  }

  updateChartOptions(): void {
    const gaugeData = [];
    const colorPalette = [];
    const primaryColor = getComputedStyle(document.documentElement)
      .getPropertyValue('--primary-color-blue')
      .trim();

    if (this.timeChart.Percentage >= 0) {
      gaugeData.push({
        value: this.timeChart.Percentage,
        detail: {
          valueAnimation: true,
          animationDuration: 16000,
          animationEasing: 'cubicOut',
          offsetCenter: ['0%', '-15%'],
        },
      });
      colorPalette.push(primaryColor);
    }

    this.chartOption = {
      color: colorPalette,
      grid: {
        left: 0,
        top: 30,
        right: 0,
        bottom: 0,
      },
      series: [
        {
          radius: '100%',
          type: 'gauge',
          startAngle: 90,
          endAngle: -270,
          pointer: {
            show: false,
          },
          progress: {
            show: true,
            overlap: false,
            roundCap: true,
            clip: false,
            itemStyle: {
              borderWidth: 1,
              borderColor: '#fff',
            },
          },
          axisLine: {
            lineStyle: {
              width: 8,
            },
          },
          splitLine: {
            show: false,
            distance: 0,
            length: 10,
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            show: false,
            distance: 50,
          },
          data: gaugeData,
          detail: {
            width: 50,
            height: 10,
            fontSize: 12,
            color: '#000',
            borderColor: 'auto',
            formatter: () => {
              return `${this.timeChart.LabelText}\n${this.timeChart.SecondLabelText}`;
            },
            lineHeight: 19,
          },
        },
      ],
    };
  }
}
