export class TimeEvent {
  StarTime: string | Date;
  EndTime: string | Date;
  EmployeeId: number;
  EventId: string;
  DepartementId: number;
  OpenShift: boolean;
  SkillId: number;
  RequiredStaff: number;
  Break: number;
}
