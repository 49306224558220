<div class="col-12" style="padding-top: 25px">
  <h3 class="page-title">
    {{ "Employee" | translate }}
  </h3>
</div>
<div class="row">
  <div class="row mb-4">
    <div class="col-sm-6">
      <button (click)="addEmployee()" class="btn btn-primary text-start">
        <span class="icon">
          <i class="bi bi-person-plus fa-lg"></i>
        </span>
        <span class="text"> {{ "Add Employer" | translate }}</span>
      </button>
    </div>
    <div class="col-sm-6 pull-right">
      <app-search-employee></app-search-employee>
    </div>
  </div>
  <app-employee-list></app-employee-list>
</div>
