import { Component, Inject, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { CalendarService } from '../../services/calendar.service';

@Component({
  selector: 'app-add-plan-template',
  templateUrl: './add-plan-template.component.html',
  styleUrls: ['./add-plan-template.component.css'],
})
export class AddPlanTemplateComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private service: CalendarService,
    public dialogRef: MatDialogRef<AddPlanTemplateComponent>,
    private spinner: NgxSpinnerService
  ) {}

  formModel = {
    StarTime: null,
    EndTime: null,
    Name: null,
  };
  confirmMessage: string;

  ngOnInit(): void {
    if (this.data && this.data.timeSpan) {
      this.formModel = {
        StarTime: this.data.timeSpan.starTime,
        EndTime: this.data.timeSpan.endTime,
        Name: null,
      };
      const options: any = {
        month: '2-digit',
        day: '2-digit',
      };

      this.data.timeSpan.endTime.setSeconds(
        this.data.timeSpan.endTime.getSeconds() - 1
      );

      this.confirmMessage =
        'Wollen sie den Plan vom <b>' +
        new Date(this.data.timeSpan.starTime).toLocaleDateString(
          navigator.language,
          options
        ) +
        '</b> bis zum <b>' +
        new Date(this.data.timeSpan.endTime).toLocaleDateString(
          navigator.language,
          options
        ) +
        '</b> als Vorlage speichern?';
    }
  }

  async onSubmit(form: NgForm): Promise<void> {
    if (form.value) {
      await this.spinner.show();
      this.service.addPlanTemplate(form.value).subscribe((res: number) => {
        if (res > 0) {
          this.dialogRef.close(res);
        }
      });
    }
  }

  onClose(): void {
    this.dialogRef.close();
  }
}
