import {
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { ClockEventType } from 'src/app/core/services/const';
import { Departement } from 'src/app/modules/departement/model/departement';
import {
  DepartmentDto,
  EmployeeDetailsDto,
  SkillDto,
} from 'src/app/modules/employee/model/employee-details-dto.model';
import { EmployeeService } from 'src/app/modules/employee/services/employee.service';
import { ClockEventOneDayDto } from '../../model/clock-event-dto.model';
import { TimeRecordingService } from '../../services/time-recording.service';

@Component({
  selector: 'app-time-dialog',
  templateUrl: './time-dialog.component.html',
  styleUrls: ['./time-dialog.component.css'],
})
export class TimeDialogComponent implements AfterViewInit, OnDestroy {
  @ViewChild('slider', { static: true }) slider: ElementRef;
  @ViewChild('handle', { static: true }) handle: ElementRef;
  @ViewChild('sliderTrackGreen', { static: true }) sliderTrackGreen: ElementRef;
  @ViewChild('sliderTrackRed', { static: true }) sliderTrackRed: ElementRef;
  @ViewChild('sliderTextStart', { static: false }) sliderTextStart: ElementRef;
  @ViewChild('sliderTextStop', { static: false }) sliderTextStop: ElementRef;

  panelOpenState = false;
  currentTimeStatus: ClockEventType = ClockEventType.Nothing;
  LastEventTime: Date = new Date();
  TotalSecondsWorkedToday: number = 0;
  workedTimeDisplay: string = '00:00:00';
  timer: any;
  LastEventTimeString: string;
  eventListAccordion: ClockEventOneDayDto[];
  timeIconBadgeColor = '#e3326133';
  timeIconColor = '#e33261ff';

  private subscriptions: Subscription[] = [];
  showDepartmentSelector: boolean = false;
  showSkillSelector: boolean = false;
  skillList: any[];
  skillPlaceholderText: any;
  departmentList: Departement[];

  departmentValue = new UntypedFormControl();
  skillValue = new UntypedFormControl();
  employeeDepartment: DepartmentDto[] = [];
  employeeSkillList: SkillDto[] = [];
  employeeSkillListTemp: SkillDto[] = [];

  departmentSelectionId: number = 0;
  skillSelectionId: number = 0;
  sliderDisabled: boolean = true;
  ComplianceMessage: [];

  constructor(
    public dialogRef: MatDialogRef<TimeDialogComponent>,
    private renderer: Renderer2,
    private timeRecordingService: TimeRecordingService,
    private employeeService: EmployeeService,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    const employeeStatusSub =
      this.timeRecordingService.employeeStatus.subscribe((status) => {
        if (navigator.onLine && status) {
          this.currentTimeStatus = status.LastEventType;
          this.updateIconColors();
          this.LastEventTime = new Date(status.LastEventTime);
          this.updateLastEventTimeString();
          this.TotalSecondsWorkedToday = status.TotalSecondsWorkedToday;
          this.ComplianceMessage = status.ComplianceMessage;
          this.eventListAccordion = status.ClockEventOneDayDto
            ? [status.ClockEventOneDayDto]
            : [];
          if (this.currentTimeStatus == ClockEventType.ClockIn) {
            this.skillSelectionId = status.SkillId;
            this.departmentSelectionId = status.DepartmentId;
            this.startTimer();
          } else {
            this.workedTimeDisplay = this.formatTime(
              this.TotalSecondsWorkedToday
            );
          }
          this.updateFormControls();
        }
      });

    this.subscriptions.push(employeeStatusSub);

    if (navigator.onLine) {
      this.timeRecordingService.getEmployeeLastStatus();
    }

    this.employeeService.GetEmployeeDetails();

    this.employeeService.getEmployeeDetails.subscribe(
      (res: EmployeeDetailsDto) => {
        if (res.DepartmentList && res.DepartmentList.length > 1) {
          this.showDepartmentSelector = true;
          this.employeeDepartment = res.DepartmentList;
          this.skillValue.disable();
        } else if (res.DepartmentList && res.DepartmentList.length == 1) {
          this.departmentSelectionId = res.DepartmentList[0].Id;
          this.showDepartmentSelector = false;
        }

        if (res.SkillList && res.SkillList.length > 1) {
          this.showSkillSelector = true;
          this.employeeSkillListTemp = res.SkillList;
          this.employeeSkillList = res.SkillList;
        } else if (res.SkillList && res.SkillList.length == 1) {
          this.skillSelectionId = res.SkillList[0].Id;
          this.showSkillSelector = false;
        }

        this.updateSliderDisabled();
      }
    );
  }

  handleClockEvent(event) {
    if (navigator.onLine) {
      this.timeRecordingService.getEmployeeLastStatus();
    }
  }

  ngAfterViewInit() {}

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    this.stopTimer();
  }

  startTimer() {
    if (this.timer) {
      clearInterval(this.timer);
    }

    if (this.currentTimeStatus === ClockEventType.ClockIn) {
      this.timer = setInterval(() => {
        this.TotalSecondsWorkedToday++;
        this.workedTimeDisplay = this.formatTime(this.TotalSecondsWorkedToday);
      }, 1000);
    }
  }

  stopTimer() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  }

  formatTime(seconds: number): string {
    const hrs = Math.floor(seconds / 3600);
    const mins = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;
    return `${hrs.toString().padStart(2, '0')}:${mins
      .toString()
      .padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
  }

  onClose(): void {
    this.stopTimer();
    this.dialogRef.close();
  }

  onDepartmentChange(event) {
    this.employeeSkillList = this.employeeSkillListTemp.filter(
      (skill) => skill.DepartmentId === event.value
    );
    this.skillValue.enable();
  }

  onBackdropClick(): void {
    this.dialogRef.close();
  }

  startDrag(event: MouseEvent | TouchEvent) {
    if (this.sliderDisabled) {
      return;
    }

    event.preventDefault();
    const handle = this.handle.nativeElement;
    const slider = this.slider.nativeElement;
    const sliderTrackGreen = this.sliderTrackGreen.nativeElement;
    const sliderTrackRed = this.sliderTrackRed.nativeElement;
    this.renderer.addClass(handle, 'dragging'); // Remove transition effect during drag

    const onMouseMove = (e: MouseEvent | TouchEvent) => {
      const clientX =
        (e as MouseEvent).clientX || (e as TouchEvent).touches[0].clientX;
      const newLeft = Math.min(
        Math.max(0, clientX - slider.getBoundingClientRect().left),
        slider.offsetWidth - handle.offsetWidth
      );
      handle.style.left = `${newLeft}px`;

      // Update track widths
      sliderTrackGreen.style.width = `${newLeft}px`;
      sliderTrackRed.style.width = `${
        slider.offsetWidth - newLeft - handle.offsetWidth
      }px`;

      if (newLeft >= slider.offsetWidth - handle.offsetWidth) {
        this.startTime();
        stopDrag();
      }
    };

    const stopDrag = () => {
      document.removeEventListener('mousemove', onMouseMove);
      document.removeEventListener('touchmove', onMouseMove);
      document.removeEventListener('mouseup', stopDrag);
      document.removeEventListener('touchend', stopDrag);

      // Setze den Slider sanft zurück zur Startposition
      this.renderer.removeClass(handle, 'dragging'); // Re-enable transition effect
      this.renderer.setStyle(handle, 'left', '0px'); // Reset handle position
      this.renderer.setStyle(sliderTrackGreen, 'width', '0%'); // Reset green track width
      this.renderer.setStyle(sliderTrackRed, 'width', '0%'); // Reset red track width
    };

    document.addEventListener('mousemove', onMouseMove);
    document.addEventListener('touchmove', onMouseMove);
    document.addEventListener('mouseup', stopDrag);
    document.addEventListener('touchend', stopDrag);
  }

  startTime() {
    console.log('Time started!');

    const currentTimeUtc = new Date(new Date().toISOString()); // Convert to UTC

    if (this.currentTimeStatus === ClockEventType.ClockIn) {
      this.stopTimer();
    }

    // Call the service to start or stop the time
    const newEventType =
      this.currentTimeStatus === ClockEventType.ClockIn
        ? ClockEventType.ClockOut
        : ClockEventType.ClockIn;

    this.timeRecordingService
      .addClockEvent({
        EventType: newEventType,
        EventTime: currentTimeUtc,
        DepartmentId: this.departmentSelectionId,
        SkillId: this.skillSelectionId,
      })
      .then(() => {
        this.handleClockEvent(null);
      });

    this.currentTimeStatus = newEventType;
    this.timeIconBadgeColor =
      this.currentTimeStatus === ClockEventType.ClockOut
        ? '#e3326133'
        : '#4bcc8141'; // Update the icon badge color

    this.timeIconColor =
      this.currentTimeStatus === ClockEventType.ClockOut
        ? '#e33261ff'
        : '#4bcc81d7'; // Update the icon color

    // Update the slider handle and text color
    const handle = this.handle.nativeElement;
    const sliderTextStart = this.sliderTextStart.nativeElement;
    const sliderTextStop = this.sliderTextStop.nativeElement;

    if (this.currentTimeStatus === ClockEventType.ClockIn) {
      this.renderer.removeClass(handle, 'clock-out');
      this.renderer.addClass(handle, 'clock-in');
      this.renderer.addClass(sliderTextStart, 'clock-in');
      this.renderer.removeClass(sliderTextStop, 'clock-out');
      this.startTimer();
    } else {
      this.renderer.removeClass(handle, 'clock-in');
      this.renderer.addClass(handle, 'clock-out');
      this.renderer.removeClass(sliderTextStart, 'clock-in');
      this.renderer.addClass(sliderTextStop, 'clock-out');
    }
  }

  updateSliderDisabled() {
    this.sliderDisabled =
      this.departmentSelectionId === 0 || this.skillSelectionId === 0;
  }

  updateIconColors() {
    this.timeIconBadgeColor =
      this.currentTimeStatus === ClockEventType.Nothing ||
      this.currentTimeStatus === ClockEventType.ClockOut
        ? '#e3326133'
        : '#4bcc8141';
    this.timeIconColor =
      this.currentTimeStatus === ClockEventType.Nothing ||
      this.currentTimeStatus === ClockEventType.ClockOut
        ? '#e33261ff'
        : '#4bcc81d7';
  }

  updateLastEventTimeString() {
    const userLanguage = navigator.language;
    this.LastEventTimeString = this.LastEventTime.toLocaleString(userLanguage, {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    });
  }

  updateFormControls() {
    if (this.currentTimeStatus === ClockEventType.ClockIn) {
      this.skillValue.disable();
      this.departmentValue.disable();
    } else {
      this.skillValue.enable();
      this.departmentValue.enable();
    }
  }
}
