import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as dayjs from 'dayjs';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Observable } from 'rxjs';
import { ApiEndPoints, ApiMethod } from '../../../core/services/const';
import { HttpService } from '../../../core/services/http/http.service';
import { AuthService } from '../../auth/services/auth/auth.service';
import { TimeEvent } from '../model/time-event.model';
import { CalendarService } from './calendar.service';

@Injectable({
  providedIn: 'root',
})
export class OpenShiftsService {
  constructor(
    private httpClient: HttpService,
    public authService: AuthService,
    private toastr: ToastrService,
    private calenderService: CalendarService,
    private translate: TranslateService
  ) {}

  private employeeOpenShiftSource: BehaviorSubject<any> = new BehaviorSubject(
    []
  );
  employeeOpenShiftList = this.employeeOpenShiftSource.asObservable();

  private openShiftDetailsSource: BehaviorSubject<any> = new BehaviorSubject(
    []
  );
  openShiftDetails = this.openShiftDetailsSource.asObservable();

  getOpenShiftsForEmployee(): Observable<any[]> {
    return this.httpClient
      .requestCall(ApiEndPoints.GetShiftsForEmployee, ApiMethod.GET)
      .subscribe((list: any) => {
        const clonedList = list.map((x) => Object.assign({}, x));
        clonedList.forEach((entry) => {
          entry.StarTime = new Date(entry.StarTime);
          entry.EndTime = new Date(entry.EndTime);
        });
        this.employeeOpenShiftSource.next(clonedList as any[]);
      });
  }

  getOpenShiftListForAdmin(): void {
    this.httpClient
      .requestCall(ApiEndPoints.GetOpenShiftListForAdmin, ApiMethod.GET)
      .subscribe((list: any) => {
        const clonedList = list.map((x) => Object.assign({}, x));
        clonedList.forEach((entry) => {
          entry.StarTime = new Date(entry.StarTime);
          entry.EndTime = new Date(entry.EndTime);
        });
        this.employeeOpenShiftSource.next(clonedList as any[]);
      });
  }
  addEmployeeToOpenShift(openShitId): void {
    this.httpClient
      .requestCall(ApiEndPoints.AddEmployeeToOpenShift, ApiMethod.POST, {
        OpenShiftId: openShitId,
      })
      .subscribe((response: any) => {
        if (response > 0) {
          this.toastr.success(
            this.translate.instant(
              'YOU HAVE SUCCESSFULLY APPLIED FOR AN OPEN SHIFT'
            ),
            this.translate.instant('OPEN SHIFT APPLICATION')
          );
        }
        this.getOpenShiftsForEmployee();
      });
  }

  adoptOpenShiftInWorkShift(openShitId, employeeId): void {
    this.httpClient
      .requestCall(ApiEndPoints.AdoptOpenShiftInWorkShift, ApiMethod.POST, {
        EventId: openShitId,
        EmployeeId: employeeId,
      })
      .subscribe((response: any) => {
        if (response > 0) {
          this.toastr.success(
            'The Shift was adopted successfully',
            'Adopt Shift'
          );
        } else {
          this.toastr.error(
            'The Shift was not apple to be adopted successfully',
            'Adopt Shift'
          );
        }
        this.getOpenShiftDetails(openShitId);
        this.getOpenShiftsForEmployee();
        this.calenderService.getTimelineEvent();
        this.calenderService.GetCalendarWeekList();
      });
  }

  getOpenShiftDetails(eventId): void {
    this.httpClient
      .requestCall(
        ApiEndPoints.GetOpenShiftDetails + '/' + eventId,
        ApiMethod.GET
      )
      .subscribe((details: any) => {
        this.openShiftDetailsSource.next(details);
      });
  }

  addEvent(event: TimeEvent): HttpService {
    event.StarTime = dayjs(event.StarTime).utcOffset(0, true).format();
    if (event.EndTime) {
      event.EndTime = dayjs(event.EndTime).utcOffset(0, true).format();
    }
    return this.httpClient
      .requestCall(ApiEndPoints.AddOpenShift, ApiMethod.POST, event)
      .subscribe((res: number) => {
        if (res === 0) {
          this.toastr.error(
            'Add Neu Shift',
            'The Employee has vacation on This Day'
          );
        } else {
          const startTime = dayjs(this.calenderService.calendarCurrentStart)
            .utcOffset(0, true)
            .format();
          this.calenderService.getTimelineEvent(true, startTime);
          this.calenderService.GetCalendarWeekList();
          this.toastr.success(
            'Add Neu Shift',
            'The Shift was added successfully'
          );
        }
      });
  }

  updateEvent(event: TimeEvent): HttpService {
    event.StarTime = dayjs(event.StarTime).utcOffset(0, true).format();
    if (event.EndTime) {
      event.EndTime = dayjs(event.EndTime).utcOffset(0, true).format();
    }

    return this.httpClient
      .requestCall(ApiEndPoints.UpdateOpenShift, ApiMethod.POST, event)
      .subscribe((res: number) => {
        if (res === 0) {
          this.toastr.error(
            'update Shift',
            'The Employee has vacation on This Day'
          );
        }
        const startTime = dayjs(this.calenderService.calendarCurrentStart)
          .utcOffset(0, true)
          .format();
        this.calenderService.getTimelineEvent(true, startTime);
        this.calenderService.GetCalendarWeekList();
        this.toastr.success(
          'update Shift',
          'The Shift was updated successfully'
        );
      });
  }
}
