<div class="modal-dialog modal-lg custom-modal" role="document">
  <div class="modal-content">
    <div class="modal-header text-center">
      <h5 class="modal-title w-100" id="exampleModalCenterTitle">
        {{ "ADD NEW QUALIFICATIONS" | translate }}
      </h5>

      <app-toggle-button
        [isActive]="isOnboardingActive"
        (toggled)="toggleOnboarding()"
        *ngIf="!showOnboarding"
      ></app-toggle-button>
    </div>
    <div class="modal-body">
      <form
        class="user"
        id="addSkill"
        #addSkillForm="ngForm"
        autocomplete="off"
        (submit)="onSubmit(addSkillForm)"
      >
        <input type="hidden" name="Id" [(ngModel)]="formModel.Id" />

        <div class="row">
          <div class="form-group col-md-6 mb-4">
            <label for="Name" class="required-label">{{
              "ABILITY/COMPETENCE" | translate
            }}</label>
            <input
              autocomplete="off"
              type="text"
              class="form-control"
              id="Name"
              name="Name"
              [(ngModel)]="formModel.Name"
              required
              placeholder="{{ 'ENTER QUALIFICATION NAME' | translate }}"
              onboardingAnchor="name-field"
            />
          </div>
        </div>
        <div class="row">
          <div class="form-group col-md-6 mb-4">
            <label for="iconType" class="required-label">{{
              "ICON CATEGORIES" | translate
            }}</label>
            <mat-select
              (selectionChange)="onIconTypeChange($event)"
              id="iconType"
              class="form-control"
              placeholder="{{ 'SELECT CATEGORIES FOR SYMBOLS' | translate }}"
              [formControl]="iconValue"
              [(ngModel)]="formModel.IconType"
              name="IconType"
              multiple
              required
              onboardingAnchor="icon-type-field"
            >
              <mat-option
                *ngFor="let iconType of iconTypeList"
                [value]="iconType.Id"
              >
                {{ iconType.Name }}
              </mat-option>
            </mat-select>
          </div>
          <div class="form-group col-md-6 mb-4">
            <label for="Icon" class="required-label">{{
              "ICON" | translate
            }}</label>
            <mat-select
              #Icon="ngModel"
              name="Icon"
              id="Icon"
              class="form-control"
              [(ngModel)]="formModel.Icon"
              placeholder="{{
                iconList.length === 1
                  ? ('SELECT CATEGORIES FOR SYMBOLS' | translate)
                  : ''
              }}"
              (selectionChange)="onIconChange($event)"
              required
              onboardingAnchor="icon-field"
            >
              <mat-select-trigger>
                <span *ngIf="selectedValue">
                  <i [ngClass]="selectedValue.IconCode + ' fa-lg'"></i>
                  {{ selectedValue.Name | translate }}
                </span>
              </mat-select-trigger>
              <mat-option *ngFor="let icon of iconList" [value]="icon.Id">
                <i [ngClass]="icon.IconCode + ' fa-lg'"></i>
                {{ icon.Name | translate }}
              </mat-option>
            </mat-select>
          </div>
        </div>
      </form>
    </div>
    <div
      class="modal-footer d-flex justify-content-between"
      *ngIf="showButtons"
    >
      <button
        class="btn btn-outline-secondary"
        (click)="onClose()"
        type="button"
        data-dismiss="modal"
      >
        {{ "Close" | translate }}
      </button>
      <button
        class="btn btn-primary active"
        form="addSkill"
        type="submit"
        [disabled]="!addSkillForm.valid"
      >
        {{ "Save" | translate }}
      </button>
    </div>
  </div>
</div>
