import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ShiftSwapService } from '../../services/shift-swap.service';

@Component({
  selector: 'app-show-targeted-swap-shift',
  templateUrl: './show-targeted-swap-shift.component.html',
  styleUrls: ['./show-targeted-swap-shift.component.css'],
})
export class ShowTargetedSwapShiftComponent {
  public swapShiftList = [];
  selectedShiftId: number;
  swapShiftId: number;
  swapShiftIsAccepted: boolean;
  hideEmployeeAcceptButton: boolean;

  constructor(
    public dialogRef: MatDialogRef<ShowTargetedSwapShiftComponent>,
    private shiftSwapService: ShiftSwapService,
    @Inject(MAT_DIALOG_DATA) public data
  ) {}

  ngOnInit() {
    if (this.data?.swapShiftId) {
      this.swapShiftId = this.data.swapShiftId;
      this.hideEmployeeAcceptButton = this.data.hideEmployeeAcceptButton;
    }
  }

  onClose() {
    this.dialogRef.close();
  }
  onSubmit() {
    this.shiftSwapService.EmployeeAcceptShiftSwapCommand(this.swapShiftId);

    this.dialogRef.close();
  }

  ngOnDestroy(): void {}

  employeeAccepted(value: boolean) {
    this.swapShiftIsAccepted = value;
  }
}
