export class TimeTrackingSettings {
  MaxDailyWorkingHours: number = 10;
  MaxWeeklyWorkingHours: number = 48;
  BreakTime: number = 30;
  LongBreakTime: number = 45;
  AfterHours: number = 2;
  RestPeriods: RestPeriods = new RestPeriods();
  Flextime: Flextime = new Flextime();
  Overtime: Overtime = new Overtime();
  MinDailyWorkingHours: number = 4;
  MinWeeklyWorkingHours: number = 20;
  WorkTimeWindow: WorkTimeWindow = new WorkTimeWindow();
  MaxConsecutiveWorkDays: number = 6;
  Notifications: Notifications = new Notifications();
  MobileTimeTracking: MobileTimeTracking = new MobileTimeTracking();
  EmployeeSettings: EmployeeSettings = new EmployeeSettings();
  AutoStopTimeOnMaxHours: boolean = false;
}

export class RestPeriods {
  DailyRestPeriod: number = 11;
  WeeklyRestPeriod: number = 35;
  HolidayRestPeriod: number = 24;
}

export class Flextime {
  IsFlextime: boolean = true;
  CoreTimeStart: string = '09:00';
  CoreTimeEnd: string = '15:00';
}

export class Overtime {
  IsOvertime: boolean = true;
  MaxOvertimeHours: number = 20;
}

export class WorkTimeWindow {
  Start: string = '06:00';
  End: string = '22:00';
}

export class Notifications {
  Enable: boolean = true;
  Email: boolean = true;
}

export class MobileTimeTracking {
  Enable: boolean = true;
  GPSTracking: boolean = false;
}

export class EmployeeSettings {
  CanAddTimeRecord: boolean = false;
  CanEditTimeRecord: boolean = false;
  CanDeleteTimeRecord: boolean = false;
}
