import { Injectable } from '@angular/core';
import { ApiEndPoints, ApiMethod } from '../const';
import { HttpService } from '../http/http.service';

@Injectable({
  providedIn: 'root',
})
export class ImageService {
  constructor(private httpClient: HttpService) {}

  public uploadFile = (files): Promise<string> => {
    if (files.length === 0) {
      return;
    }
    const fileToUpload = files[0] as File;
    const formData = new FormData();
    formData.append('file', fileToUpload, fileToUpload.name);

    return new Promise((resolve, reject) => {
      this.httpClient
        .requestCall(ApiEndPoints.ImageUpload, ApiMethod.POST, formData)
        .subscribe((event) => {
          if (event.fileId) {
            resolve(event.fileId);
          }
        });
    });
  };

  public deleteFile = (fileId: string): Promise<boolean> => {
    return new Promise((resolve, reject) => {
      this.httpClient
        .requestCall(`${ApiEndPoints.ImageDelete}/${fileId}`, ApiMethod.DELETE)
        .subscribe((event) => {
          if (event) {
            resolve(true);
          }
        });
    });
  };
}
