import { Injectable } from '@angular/core';
import { MatPaginatorIntl, PageEvent } from '@angular/material/paginator';
import { Pagination } from '../../../admin/model/pagination.model';

@Injectable({
  providedIn: 'root',
})
export class EmployeePaginationService {
  public paginationModel: Pagination;

  setPaginationModel(paging: Pagination) {
    this.paginationModel.AllItemsLength = paging.AllItemsLength;
    this.paginationModel.PageSize = paging.PageSize;
    this.paginationModel.PageIndex = paging.PageIndex;
  }
  get page(): number {
    return this.paginationModel.PageIndex;
  }

  get totalCount(): number {
    return this.paginationModel.AllItemsLength;
  }

  get pageCount(): number {
    return this.paginationModel.PageSize;
  }

  constructor() {
    this.paginationModel = new Pagination();
  }

  change(pageEvent: PageEvent) {
    this.paginationModel.PageIndex = pageEvent.pageIndex + 1;
    this.paginationModel.PageSize = pageEvent.pageSize;
    this.paginationModel.AllItemsLength = pageEvent.length;
  }
  static getDutchPaginatorIntl(): MatPaginatorIntl {
    const paginatorIntl = new MatPaginatorIntl();

    paginatorIntl.itemsPerPageLabel = 'Elemente pro Seite:';
    paginatorIntl.nextPageLabel = 'Volgende pagina';
    paginatorIntl.previousPageLabel = 'Vorige pagina';

    return paginatorIntl;
  }
}
