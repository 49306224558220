<div class="modal-dialog modal-lg" role="document" style="margin-left: 8px; margin-right: 8px;">
  <div class="modal-content">
    <div class="modal-header text-center">
      <h5 class="modal-title w-100" id="exampleModalCenterTitle">{{'ASSIGNED SHIFTS'| translate}}
      </h5>
    </div>
    <div class="modal-body" style="padding: 0px;">
      <table mat-table [dataSource]="dataSource" class="table table-bordered table-hover dataTable ng-star-inserted">
        <!-- Checkbox Column -->
        <ng-container matColumnDef="select">
          <th style="width: 10px; padding-left: 2px;" mat-header-cell *matHeaderCellDef>
          </th>
          <td style="width: 10px; padding-left: 2px;" mat-cell *matCellDef="let row">
            <mat-slide-toggle class="example-margin" (click)="$event.stopPropagation()"
              (change)="$event ? selection.toggle(row) : null" [checked]="selection.isSelected(row)">
            </mat-slide-toggle>
          </td>
        </ng-container>

        <!-- Position Column -->
        <ng-container matColumnDef="skill">
          <th mat-header-cell *matHeaderCellDef> Skill </th>
          <td style="text-align:center" mat-cell *matCellDef="let element">
            <i [ngClass]="element.skillIcons"></i>
          </td>

        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="hour">
          <th style="text-align:center" mat-header-cell *matHeaderCellDef>Zeit</th>
          <td style="text-align:center" mat-cell *matCellDef="let element">{{element.hour}}</td>
        </ng-container>

        <!-- Weight Column -->
        <ng-container matColumnDef="date">
          <th style="text-align:center" mat-header-cell *matHeaderCellDef> Datum </th>
          <td style="text-align:center; padding-left: 2px; padding-right: 2px;" mat-cell *matCellDef="let element">
            {{element.date}} </td>
        </ng-container>

        <!-- Symbol Column -->
        <ng-container matColumnDef="timeRange">
          <th style="text-align:center" mat-header-cell *matHeaderCellDef> Zeitspanne </th>
          <td style="text-align:center; padding-left: 2px; padding-right: 2px;" mat-cell *matCellDef="let element">
            {{element.timeRange}}
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;">
        </tr>
      </table>
    </div>
    <div class="modal-footer d-flex justify-content-between">
      <button class="btn btn-outline-secondary" (click)="onClose()" type="button"
        data-dismiss="modal">{{'Close'|translate}}</button>
      <button class="btn btn-primary active" (click)="onSubmit()" type="submit" [disabled]="false">{{'ACCEPT'|
        translate}}</button>
    </div>
  </div>
</div>