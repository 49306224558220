import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'src/app/modules/auth/services/auth/auth.service';

@Component({
  selector: 'app-email-confirmation',
  templateUrl: './email-confirmation.component.html',
  styleUrls: ['./email-confirmation.component.css'],
})
export class EmailConfirmationComponent implements OnInit {
  message: string = 'Please wait...';
  errorStatus = { showError: false, errorMessage: '' };
  constructor(
    private route: ActivatedRoute,
    private authService: AuthService,
    private translate: TranslateService
  ) {
    const token = this.route.snapshot.queryParams.token;
    const email = this.route.snapshot.queryParams.email;

    this.authService.confirmEmail(token, email).subscribe(
      (response) => {
        if (response) {
          this.message = this.translate.instant('REGISTRATION SUCCESSFUL');
        } else {
          this.errorStatus.showError = true;
          this.errorStatus.errorMessage = this.translate.instant(
            'THERE WAS AN ERROR CONFIRMING YOUR EMAIL'
          );
          this.message = '';
        }
      },
      (error) => {
        this.errorStatus.showError = true;
        this.errorStatus.errorMessage = this.translate.instant(
          'THERE WAS AN ERROR CONFIRMING YOUR EMAIL'
        );
        this.message = '';
      }
    );
  }

  ngOnInit(): void {}

  // onSubmit(form: NgForm): void {
  //   if (form.value) {
  //     this.service.submitEmployer(form.value);
  //     this.onClose();
  //   }
  // }

  // onClose(): void {
  //   this.dialogRef.close();
  // }
}
