import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
} from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { Departement } from '../../model/departement';
import { DepartementService } from '../../services/departement.service';
import { AddDepartementComponent } from '../add-departement/add-departement.component';
import { ConfirmationDialogComponent } from 'src/app/core/component/confirmation-dialog/confirmation-dialog.component';

import { MatTableDataSource } from '@angular/material/table';
import { DepartmentType, ProjectStatus } from '../../model/add-departement';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';

@Component({
  selector: 'app-departement-list',
  templateUrl: './departement-list.component.html',
  styleUrls: ['./departement-list.component.css'],
  animations: [
    trigger('detailExpand', [
      state(
        'collapsed',
        style({ height: '0px', minHeight: '0', opacity: 0, display: 'none' })
      ),
      state('expanded', style({ height: '*', opacity: 1 })),
      transition(
        'expanded <=> collapsed',
        animate('300ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
    ]),
  ],
})
export class DepartementListComponent implements OnInit, OnDestroy {
  private subscriptionDepartementList: Subscription;
  DepartmentType = DepartmentType;
  dataSource = new MatTableDataSource<DepartementFlatNode>();
  displayedColumns: string[] = [
    'expand',
    'Number',
    'Name',
    'Type',
    'EmployeeCount',
    'Actions',
  ];

  @ViewChild('table') table: any;

  constructor(
    private service: DepartementService,
    private dialog: MatDialog,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.subscriptionDepartementList =
      this.service.DepartementListWithEmployeeCount.subscribe((list) => {
        const data = this.buildFlatData(list as Departement[]);
        this.dataSource.data = data;
      });
    this.service.getDepartementListWithEmployeeCount();
  }

  ngOnDestroy(): void {
    this.subscriptionDepartementList.unsubscribe();
  }

  /** Aufbau der flachen Datenliste für die Tabelle */
  buildFlatData(
    departments: Departement[],
    level: number = 0,
    parentNumber: string = '',
    parentId: number = 0
  ): DepartementFlatNode[] {
    let flatData: DepartementFlatNode[] = [];
    departments.forEach((dept, index) => {
      const number = parentNumber
        ? `${parentNumber}.${index + 1}`
        : `${index + 1}`;
      const flatNode: DepartementFlatNode = {
        Id: dept.Id,
        Name: dept.Name,
        EmployeeCount: dept.EmployeeCount,
        Level: level,
        Expandable: !!dept.SubDepartments && dept.SubDepartments.length > 0,
        ParentId: parentId,
        Visible: level === 0, // Hauptabteilungen sind sichtbar
        Expanded: false,
        Number: number,
        Description: dept.Description,
        Type: dept.Type,
        ProjectStatus: dept.ProjectStatus,
      };
      flatData.push(flatNode);
      if (dept.SubDepartments && dept.SubDepartments.length > 0) {
        flatData = flatData.concat(
          this.buildFlatData(dept.SubDepartments, level + 1, number, dept.Id)
        );
      }
    });
    return flatData;
  }

  /** Ein- und Ausklappen der Zeilen */
  toggleRow(node: DepartementFlatNode): void {
    node.Expanded = !node.Expanded;
    this.updateToggle(node);
  }

  updateToggle(node: DepartementFlatNode): void {
    const data = this.dataSource.data;
    const nodeIndex = data.findIndex((d) => d.Id === node.Id);
    if (nodeIndex >= 0) {
      for (let i = nodeIndex + 1; i < data.length; i++) {
        if (data[i].Level <= node.Level) {
          break;
        }
        if (data[i].Level === node.Level + 1) {
          data[i].Visible = node.Expanded;
          if (!node.Expanded) {
            data[i].Expanded = false;
            this.collapseDescendants(data[i]);
          }
        }
      }
    }
    // Datenquelle aktualisieren
    this.dataSource.data = [...data];
  }

  collapseDescendants(node: DepartementFlatNode): void {
    const data = this.dataSource.data;
    const nodeIndex = data.findIndex((d) => d.Id === node.Id);
    if (nodeIndex >= 0) {
      for (let i = nodeIndex + 1; i < data.length; i++) {
        if (data[i].Level <= node.Level) {
          break;
        }
        data[i].Visible = false;
        data[i].Expanded = false;
      }
    }
  }

  /** Aktionen */
  onDepartementUpdate(departementId: number): void {
    const departement = this.service.getDepartementById(departementId);
    this.updateDepartement(departement);
  }

  updateDepartement(departement): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.data = { departement };

    dialogConfig.panelClass = 'custom-dialog-container';
    this.dialog.open(AddDepartementComponent, dialogConfig);
  }

  onDepartementDelete(id: number, name: string): void {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      disableClose: false,
      panelClass: 'custom-dialog-container',
    });
    const textDialog =
      this.translate.instant(
        'Are you sure you want to delete this Departement'
      ) +
      '?<br/><b>' +
      name +
      '</b> ';
    dialogRef.componentInstance.confirmMessage = textDialog;

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.service.deleteDepartment(id);
      }
    });
  }
}

interface DepartementFlatNode {
  Id: number;
  Name: string;
  EmployeeCount: number;
  Level: number;
  Expandable: boolean;
  Expanded: boolean;
  ParentId: number;
  Visible: boolean;
  Number: string;
  Description: string;
  Type: DepartmentType;
  ProjectStatus?: ProjectStatus;
}
