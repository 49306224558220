<ion-fab vertical="bottom" horizontal="end" slot="fixed">
  <ion-fab-button (click)="handleClick()">
    <i
      *ngIf="workedTimeDisplay"
      class="fa-light fa-stopwatch fa-lg"
      style="font-size: 25px; color: #b6ff59"
    ></i>
    <i
      *ngIf="!workedTimeDisplay"
      class="fa-light fa-stopwatch fa-lg"
      style="font-size: 25px; color: #ff59b4"
    ></i>
    <!-- Warnhinweis Symbol -->
  </ion-fab-button>
  <span
    *ngIf="isComplianceMessages"
    class="warning-icon"
    (click)="handleClick()"
  >
    <i class="fa fa-exclamation-triangle" style="color: yellow"></i>
  </span>
</ion-fab>
