import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import * as echarts from 'echarts';
import { PieChartDto } from '../../model/pie-chart-dto.model';
import { ReportsService } from '../../services/reports.service';

@Component({
  selector: 'app-pie-chart',
  templateUrl: './pie-chart.component.html',
  styleUrls: ['./pie-chart.component.css'],
})
export class PieChartComponent implements OnInit, OnChanges {
  @Input() startDate: string;
  @Input() endDate: string;
  @Input() employeeId: number;
  @Input() departmentIds: number[];
  chartId = 'pieChart';
  private chartInstance: any;
  private colorsDepartment = ['#B71C1C', '#880E4F', '#4A148C'];

  private colorsSkill = [
    '#01589bd6',
    '#1a227ec7',
    '#E65100',
    '#0d48a1d3',
    '#c51162c6',
    '#00695CS',
    '#37474F',
    '#0097A7',
    '#C51162',
    '#BF360C',
  ];

  constructor(private service: ReportsService) {}

  ngOnInit(): void {
    // Initialize chart after view is initialized
    setTimeout(() => {
      this.initChart();
    }, 300);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.startDate ||
      changes.endDate ||
      changes.employeeId ||
      changes.departmentIds
    ) {
      this.service.getPieChartQuery(
        this.startDate,
        this.endDate,
        this.employeeId,
        this.departmentIds
      );
    }
  }

  initChart(): void {
    const chartDom = document.getElementById(this.chartId)!;
    this.chartInstance = echarts.init(chartDom);

    this.service.getPieChartData.subscribe((data: PieChartDto) => {
      if (data) {
        this.updateChart(data);
      }
    });
  }

  updateChart(data: PieChartDto): void {
    const convertMinutesToHours = (minutes: number) =>
      (minutes / 60).toFixed(2);

    const departmentData = data.DepartmentArray.map((item, index) => ({
      ...item,
      value: convertMinutesToHours(item.value),
      itemStyle: {
        color: this.colorsDepartment[index % this.colorsDepartment.length], // Farben rotieren durch die Liste
      },
    }));

    const skillData = data.SkillArray.map((item, index) => ({
      ...item,
      value: convertMinutesToHours(item.value),
      itemStyle: {
        color: this.colorsSkill[index % this.colorsSkill.length], // Farben rotieren durch die Liste
      },
    }));

    const option = {
      tooltip: {
        trigger: 'item',
        formatter: '{a} <br/>{b}: {c} Stunden ({d}%)',
      },
      legend: {
        data: departmentData
          .map((item) => item.name)
          .concat(skillData.map((item) => item.name)),
      },
      series: [
        {
          name: 'Departments',
          type: 'pie',
          selectedMode: 'single',
          radius: [0, '30%'],
          label: {
            position: 'inner',
            fontSize: 14,
          },
          labelLine: {
            show: false,
          },
          data: departmentData,
        },
        {
          name: 'Skills',
          type: 'pie',
          radius: ['45%', '60%'],
          labelLine: {
            length: 30,
          },
          label: {
            formatter:
              '{a|{a}}{abg|}\n{hr|}\n  {b|{b}：}{c} Stunden  {per|{d}%}  ',
            backgroundColor: '#F6F8FC',
            borderColor: '#8C8D8E',
            borderWidth: 1,
            borderRadius: 4,
            rich: {
              a: {
                color: '#6E7079',
                lineHeight: 22,
                align: 'center',
              },
              hr: {
                borderColor: '#8C8D8E',
                width: '100%',
                borderWidth: 1,
                height: 0,
              },
              b: {
                color: '#4C5058',
                fontSize: 14,
                fontWeight: 'bold',
                lineHeight: 33,
              },
              per: {
                color: '#fff',
                backgroundColor: '#4C5058',
                padding: [3, 4],
                borderRadius: 4,
              },
            },
          },
          data: skillData,
        },
      ],
    };

    this.chartInstance.setOption(option);
  }
}
