import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.css'],
})
export class ConfirmationDialogComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
    private translate: TranslateService
  ) {}
  public confirmMessage: string;
  public confirmHeader = this.translate.instant('CONFIRMATION');
  public confirmButtonText = this.translate.instant('CONFIRM');
  public cancelButtonText = this.translate.instant('Close');
  ngOnInit(): void {}
}
