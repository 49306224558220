import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  forwardRef,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-item-list',
  templateUrl: './item-list.component.html',
  styleUrls: ['./item-list.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ItemListComponent),
      multi: true,
    },
  ],
  animations: [
    trigger('fadeOutRight', [
      state(
        'hiddenToRight',
        style({
          opacity: 0,
          transform: 'translateX(100%)',
          background: 'green',
        })
      ),
      state(
        'visible',
        style({
          opacity: 1,
          transform: 'translateX(0)',
        })
      ),
      state(
        'hiddenToLeft',
        style({
          opacity: 0,
          transform: 'translateX(-100%)',
          background: 'red',
        })
      ),
      transition('visible => hiddenToRight', [animate('1.5s ease')]),
      transition('visible => hiddenToLeft', [animate('1.5s ease')]),
    ]),
  ],
})
export class ItemListComponent implements OnInit {
  onChange: (val) => void;
  onTouched: () => void;

  @Output() onClick = new EventEmitter<boolean>();

  // scrondbutton
  @Output() onSecondClick = new EventEmitter<boolean>();

  @Input() Title: string = '';
  @Input() Content: string = '';
  @Input() BadgeContent: string = '';
  @Input() Image: string = null;
  @Input() Icon: string = null;
  @Input() ContentColor: string = '#f5379ccb';
  @Input() buttonText: string = null;
  @Input() isButton: boolean = true;
  @Input() buttonColor: string = null;
  @Input() isButtoDisabled: boolean = false;
  @Input() buttonClass: string = null;
  @Input() buttonIcon: string = 'bi-calendar-check';
  @Input() isSecondButton: boolean = false;
  @Input() secondButtonIcon: string = null;
  @Input() secondButtonColor: string = null;
  @Input() secondButtonClass: string = null;
  @Input() showAnimation: string = 'visible';

  iconStyle = this.Image != null ? 'padding-top: 30px' : 'font-size: 35px;';

  value: any;
  constructor() {}

  ngOnInit(): void {}

  writeValue(value: any): void {
    this.value =
      value || (typeof value === 'number' && value >= 0) ? value : '';
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  buttonClick(): void {
    this.onClick.emit(true);
  }

  secondButtonClick(): void {
    this.onSecondClick.emit(true);
  }
}
