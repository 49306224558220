import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as dayjs from 'dayjs';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject } from 'rxjs';
import { ApiEndPoints, ApiMethod } from 'src/app/core/services/const';
import { HttpService } from 'src/app/core/services/http/http.service';
import { AuthService } from '../../auth/services/auth/auth.service';
import { Availability } from '../model/availability';
import { DailyAvailability } from '../model/daily-availability';

@Injectable({
  providedIn: 'root',
})
export class AvailabilityService {
  private availabilityEventListSource: BehaviorSubject<any> =
    new BehaviorSubject([]);
  availabilityEventList = this.availabilityEventListSource.asObservable();

  private availabilityTimelineEventListSource: BehaviorSubject<any> =
    new BehaviorSubject(null);
  availabilityTimelineEventList =
    this.availabilityTimelineEventListSource.asObservable();

  constructor(
    private httpClient: HttpService,
    private toastr: ToastrService,
    private translate: TranslateService,
    private authService: AuthService
  ) {}

  addAvailability(event: Availability): HttpService {
    event.StarTime = new Date(event.StarTime);
    if (event.EndTime) {
      event.EndTime = new Date(event.EndTime);
    }
    return this.httpClient
      .requestCall(ApiEndPoints.AddAvailability, ApiMethod.POST, event)
      .subscribe((res: number) => {
        if (res > 0) {
          this.toastr.success(
            this.translate.instant(
              'YOU HAVE SUCCESSFULLY ADDED AN AVAILABILITY'
            ),
            this.translate.instant('ADD AVAILABILITY')
          );
        }
        this.getAvailabilityEvent(false);
      });
  }
  addWeekdayAvailability(event: DailyAvailability): HttpService {
    let route = this.authService.isManegeOrTeamLeader
      ? ApiEndPoints.AddDailyAvailabilityAdmin
      : ApiEndPoints.AddDailyAvailability;
    if (event.Id) {
      route = this.authService.isManegeOrTeamLeader
        ? ApiEndPoints.UpdateDailyAvailabilityAdmin
        : ApiEndPoints.UpdateDailyAvailability;
    }

    return this.httpClient
      .requestCall(route, ApiMethod.POST, event)
      .subscribe((res: number) => {
        if (res > 0) {
          this.toastr.success(
            this.translate.instant(
              'YOU HAVE SUCCESSFULLY ADDED AN AVAILABILITY'
            ),
            this.translate.instant('ADD AVAILABILITY')
          );
        }
        if (this.authService.isManegeOrTeamLeader) {
          this.getAvailabilityTimelineEvent();
        } else {
          this.getAvailabilityEvent(false);
        }
      });
  }


  getAvailabilityEvent(withAbsence = false, employeeId = 0): void {
    const endPoint =
      ApiEndPoints.GetAvailability + '/' + withAbsence + '/' + employeeId;
    // if (!this.authService.isManegeOrTeamLeader) {
    //   endPoint = ApiEndPoints.GetEmployeeEvents;
    // }

    this.httpClient
      .requestCall(endPoint, ApiMethod.GET)
      .subscribe((list: any) => {
        const clonedList = list.map((x) => Object.assign({}, x));
        clonedList.forEach((entry) => {
          entry.start = new Date(entry.start);
          entry.end = new Date(entry.end);
          entry.startTime = entry.start.toLocaleTimeString(navigator.language, {
            hour: '2-digit',
            minute: '2-digit',
          });
          entry.endTime = entry.end.toLocaleTimeString(navigator.language, {
            hour: '2-digit',
            minute: '2-digit',
          });
        });

        if (clonedList.length) {
          this.availabilityEventListSource.next(clonedList as any[]);
        }
      });
  }

  getAvailabilityPositionId(withAbsence = false, positionId = 0): void {
    const endPoint =
      ApiEndPoints.GetAvailabilityPositionId +
      '/' +
      withAbsence +
      '/' +
      positionId;

    this.httpClient
      .requestCall(endPoint, ApiMethod.GET)
      .subscribe((list: any) => {
        const clonedList = list.map((x) => Object.assign({}, x));

        clonedList.forEach((entry) => {
          entry.start = new Date(entry.start);
          entry.end = new Date(entry.end);
          entry.startTime = entry.start.toLocaleTimeString(navigator.language, {
            hour: '2-digit',
            minute: '2-digit',
          });
          entry.endTime = entry.end.toLocaleTimeString(navigator.language, {
            hour: '2-digit',
            minute: '2-digit',
          });
        });

        if (clonedList.length) {
          this.availabilityEventListSource.next(clonedList as any[]);
        }
      });
  }

  deleteAvailability(Id, EmployeeId = 0): HttpService {
    let route = EmployeeId
      ? ApiEndPoints.DeleteAvailabilityAdmin
      : ApiEndPoints.DeleteAvailability;

    return this.httpClient
      .requestCall(route, ApiMethod.POST, {
        AvailabilityId: Id,
        EmployeeId: EmployeeId,
      })
      .subscribe((details: any) => {
        if (details) {
          this.toastr.success(
            this.translate.instant(
              'YOU HAVE SUCCESSFULLY DELETED THEIR AVAILABILITY'
            ),
            this.translate.instant('DELETE AVAILABILITY')
          );
        }
        if (this.authService.isManegeOrTeamLeader) {
          this.getAvailabilityTimelineEvent();
        } else {
          this.getAvailabilityEvent(false);
        }
      });
  }

  getAvailabilityTimelineEvent(searchKeywords = '*'): void {
    searchKeywords = searchKeywords ? searchKeywords : '*';

    let endPoint =
      ApiEndPoints.GetAvailabilityTimelineEvents + '/' + searchKeywords;

    this.httpClient
      .requestCall(endPoint, ApiMethod.GET)
      .subscribe((result: any) => {
        result.CalenderEvents = result.CalenderEvents.map((entry) => {
          entry.start = new Date(entry.start);
          entry.end = new Date(entry.end);
          this.setColorAndClassForEvent(entry);
          return entry;
        });

        this.availabilityTimelineEventListSource.next({
          calenderEvents: result.CalenderEvents,
          calenderResources: result.CalenderResources,
        } as any);
      });
  }

  setColorAndClassForEvent(event: any): void {
    event.className = 'vertical-line-availability ' + 'event-animation-scale';
    event.color = '#00695c20';
    event.textColor = '#000000';
  }
}
