<div class="holiday-management">
  <div class="row">
    <div class="col">
      <h6>
        {{ "HOLIDAYS IN" | translate }}
        {{
          locationService.getRegionNameByIsoRegionCode(
            companySetting.CompanySettings.IsoRegionCode
          ) | async
        }}
        {{ "FOR THE YEAR" | translate }}
      </h6>
    </div>
    <div class="col">
      <mat-select
        name="Role"
        id="Role"
        class="form-control"
        required
        [(value)]="year"
        (selectionChange)="onYearChange()"
        ><mat-option *ngFor="let y of years" [value]="y">{{
          y
        }}</mat-option></mat-select
      >
    </div>
  </div>
  <hr class="mt-2 mb-3" />

  <table *ngIf="holidays.length > 0" class="holiday-table">
    <thead style="background-color: white">
      <tr>
        <th>Aktiv</th>
        <th>Feiertag</th>
        <th>Datum</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let holiday of holidays">
        <td>
          <div class="toggle-container">
            <label class="status-label">
              {{
                holiday.IsSelected ? ("YES" | translate) : ("NO" | translate)
              }}
            </label>
            <mat-slide-toggle
              color="primary"
              [checked]="holiday.IsSelected"
              (change)="onToggleChange(holiday)"
              name="Flextime"
              [(ngModel)]="holiday.IsSelected"
            >
            </mat-slide-toggle>
          </div>
        </td>
        <td>{{ getHolidayName(holiday) }}</td>
        <td>
          {{ getFormattedDate(holiday.StartDate) }}
        </td>
        <td style="width: 10px; padding: 0px">
          <mat-menu #holidayMenu="matMenu">
            <button
              mat-menu-item
              (click)="openEditCustomHolidayDialog(holiday)"
            >
              <mat-icon>edit</mat-icon>
              {{ "EDIT" | translate }}
            </button>
            <button mat-menu-item (click)="deleteCustomHoliday(holiday)">
              <mat-icon>delete</mat-icon>
              {{ "DELETE" | translate }}
            </button>
          </mat-menu>
          <button
            *ngIf="holiday.IsCustom"
            mat-icon-button
            [matMenuTriggerFor]="holidayMenu"
            aria-label="Options"
          >
            <mat-icon>more_vert</mat-icon>
          </button>
        </td>
      </tr>
    </tbody>
  </table>
</div>
