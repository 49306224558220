<div class="row" style="padding-top: 25px">
  <div class="col-12">
    <h3 class="page-title">
      {{ "EMPLOYEE AND DEPARTMENT REPORTS" | translate }}
    </h3>
  </div>

  <div class="card shadow">
    <div class="card-body" style="padding: 5px">
      <app-report-filter
        (filterApplied)="handleFilterApplied($event)"
      ></app-report-filter>
    </div>
  </div>

  <div class="row mt-3">
    <div class="col-lg-5">
      <div class="card shadow" style="position: inherit">
        <div class="card-body" style="padding: 5px">
          <app-pie-chart
            [employeeId]="employeeId"
            [departmentIds]="departmentIds"
            [startDate]="startDate"
            [endDate]="endDate"
          ></app-pie-chart>
        </div>
      </div>
    </div>
    <div class="col-lg-7">
      <div class="card shadow" style="position: inherit">
        <div class="card-body" style="padding: 5px">
          <app-total-work-hours-report
            [employeeId]="employeeId"
            [departmentIds]="departmentIds"
            [startDate]="startDate"
            [endDate]="endDate"
          ></app-total-work-hours-report>
        </div>
      </div>
      <div class="shadow mb-4" style="padding-top: 10px">
        <app-report-list
          [employeeId]="employeeId"
          [departmentIds]="departmentIds"
          [startDate]="startDate"
          [endDate]="endDate"
        ></app-report-list>
      </div>
    </div>
  </div>
</div>
