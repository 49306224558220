<div class="row shadow" [@fadeOutRight]="showAnimation">
  <div [ngClass]="Image !== null ? 'icon-container-big' : 'icon-container-small'">
    <img *ngIf="Image !== null" class="image-size" [src]="Image" alt="Avatar">
    <i *ngIf="Icon !== null"
      [ngStyle]="{'padding-top': Image !== null ? '26px' : '0px', 'font-size': Image === null ? '26px' : '19px'}"
      [ngClass]="Icon" class="fa-lg"></i>
  </div>
  <div [ngClass]="isSecondButton ? 'content-container-small' : 'content-container-big'">
    <div class="content-text">
      <p style="font-size: 15px; font-weight: 600; margin-top: 5px; margin-bottom: 10px;">{{Title}}</p>
      <span style="font-size: 12px;" [innerHTML]="Content"></span>
    </div>
    <div class="badge-container">
      <span class="badge badge-pill badge-primary align-middle" [style.backgroundColor]="ContentColor"
        style="font-size: 12px;">
        {{BadgeContent}}
      </span>
    </div>
  </div>
  <div [ngClass]="isSecondButton ? 'button-container-big' : 'button-container-small'">
    <button *ngIf="isButton" [ngClass]="buttonClass" (click)="buttonClick()" [disabled]="isButtoDisabled">
      <i [ngClass]="buttonIcon"></i>
    </button>
    <button *ngIf="isSecondButton" [ngClass]="secondButtonClass" (click)="secondButtonClick()">
      <i [ngClass]="secondButtonIcon"></i>
    </button>
  </div>
</div>