import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CalendarWeek } from 'src/app/modules/admin/model/CalendarWeek';
import { CalendarService } from 'src/app/modules/admin/services/calendar.service';

@Component({
  selector: 'app-calendar-week-horizontal-view',
  templateUrl: './calendar-week-horizontal-view.component.html',
  styleUrls: ['./calendar-week-horizontal-view.component.css'],
})
export class CalendarWeekHorizontalViewComponent implements OnInit {
  calenderWeekList = [];
  constructor(private service: CalendarService, private router: Router) {}

  ngOnInit(): void {
    this.service.calenderWeekList.subscribe((list: CalendarWeek[]) => {
      const cloned = list.map((x) => Object.assign({}, x));
      cloned.forEach((entry) => {
        // const StartTime = new Date(entry.StartTime);
        // entry.StartTime = StartTime.toLocaleTimeString(navigator.language);
        // const EndTime = new Date(entry.EndTime);
        // entry.EndTime = EndTime.toLocaleTimeString(navigator.language);
      });
      this.calenderWeekList = cloned;
    });
    this.service.GetCalendarWeekList();
  }

  calenderWeekClick(): void {
    this.router.navigateByUrl('/planner');
  }
}
