import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as dayjs from 'dayjs';
import { Duration } from 'dayjs/plugin/duration';
import { BehaviorSubject, Subscription } from 'rxjs';
import { ClockEventType } from 'src/app/core/services/const';
import { AuthService } from 'src/app/modules/auth/services/auth/auth.service';
import { CompanySettingsService } from 'src/app/modules/settings/services/company-settings.service';
import { ClockEventDto } from '../../model/clock-event-dto.model';
import { TimeRecordingService } from '../../services/time-recording.service';

@Component({
  selector: 'app-time-recording-table',
  templateUrl: './time-recording-table.component.html',
  styleUrls: ['./time-recording-table.component.css'],
})
export class TimeRecordingTableComponent implements OnInit, OnDestroy {
  @Input() clockEventList: ClockEventDto[];
  @Output() clockEvent = new EventEmitter<boolean>();
  @Input() employeeId: number;
  @Input() departmentId: number;
  @Input() skillId: number;
  @Input() startDate: string;

  private subscriptions: Subscription[] = [];
  newEvent: any = null;
  validationError$ = new BehaviorSubject<string>('');

  timeRecordes = [
    { id: ClockEventType.ClockIn, lable: 'Work' },
    { id: ClockEventType.BreakStart, lable: 'Break' },
  ];

  constructor(
    private timeRecordingService: TimeRecordingService,
    private translate: TranslateService,
    private cdr: ChangeDetectorRef,
    public companySettingsService: CompanySettingsService,
    public authService: AuthService
  ) {}

  ngOnInit() {
    this.updateFormattedTimes();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.clockEventList) {
      this.handleClockEventListChange();
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  private handleClockEventListChange(): void {
    this.clockEventList.forEach((event) => {
      event.editing = false;
      event.editStartTime = this.formatTime(event.EventStart);
      event.editEndTime = this.formatTime(event.EventEnd);
      event.formattedEventStart = this.formatToLocaleTimeString(
        event.EventStart,
        event.EventStartId
      );
      event.formattedEventEnd = this.formatToLocaleTimeString(
        event.EventEnd,
        event.EventEndId
      );
      event.eventTypeLabel = this.getEventType(event.EventType);
    });
  }

  private updateFormattedTimes(): void {
    if (this.clockEventList) {
      this.clockEventList.forEach((event) => {
        event.formattedEventStart = this.formatToLocaleTimeString(
          event.EventStart,
          event.EventStartId
        );
        event.formattedEventEnd = this.formatToLocaleTimeString(
          event.EventEnd,
          event.EventEndId
        );
        event.eventTypeLabel = this.getEventType(event.EventType);
      });
    }
  }

  loadClockEvents(): void {
    this.clockEvent.emit(true);
  }

  formatTime(dateString: string): string {
    return dayjs(dateString).format('HH:mm');
  }

  formatToLocaleTimeString(dateString: string, eventId): string {
    if (
      eventId === 0 &&
      new Date(dateString).toDateString() === new Date().toDateString()
    ) {
      return this.translate.instant('NOW');
    }
    const date = new Date(dateString);
    return date.toLocaleTimeString(navigator.language, {
      hour: '2-digit',
      minute: '2-digit',
    });
  }

  convertMinutesToTime(minutes: number): string {
    if (isNaN(minutes) || minutes < 0) {
      return '00:00';
    }

    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;

    const hoursStr = hours < 10 ? '0' + hours : hours;
    const minutesStr =
      remainingMinutes < 10 ? '0' + remainingMinutes : remainingMinutes;

    return `${hoursStr}:${minutesStr}`;
  }

  formatDuration(duration: Duration): string {
    const hours = Math.floor(duration.asHours());
    const minutes = Math.floor(duration.minutes());

    const formattedHours = hours < 10 ? '0' + hours : hours;
    const formattedMinutes = minutes < 10 ? '0' + minutes : minutes;

    return `${formattedHours}:${formattedMinutes}`;
  }

  getEventType(eventType: ClockEventType): string {
    switch (eventType) {
      case ClockEventType.ClockIn:
        return 'Work';
      case ClockEventType.ClockOut:
        return 'Works End';
      case ClockEventType.BreakStart:
        return 'Break';
      default:
        return 'Unknown';
    }
  }

  editEvent(index: number): void {
    this.clockEventList[index].editing = true;
  }

  stoptTimerForEmployee(): void {
    const currentTimeUtc = new Date(new Date().toISOString()); // Convert to UTC

    this.timeRecordingService
      .addClockEvent({
        EventType: ClockEventType.ClockOut,
        EventTime: currentTimeUtc,
        EmployeeId: this.employeeId,
        DepartmentId: this.departmentId,
        SkillId: this.skillId,
      })
      .then(() => {
        this.timeRecordingService.getAllClockEventsForDay(
          this.startDate,
          this.employeeId,
          this.departmentId
        );
      });
  }

  async saveEvent(index: number): Promise<void> {
    const event = this.clockEventList[index];
    event.editing = false;

    try {
      await this.timeRecordingService.updateClockEvent({
        EmployeeId: this.employeeId,
        Id: event.EventStartId,
        EventTime: new Date(
          this.formatTimeToISO(event.editStartTime, event.EventStart)
        ),
        EventType: event.EventType,
      });

      await this.timeRecordingService.updateClockEvent({
        EmployeeId: this.employeeId,
        Id: event.EventEndId,
        EventTime: new Date(
          this.formatTimeToISO(event.editEndTime, event.EventEnd)
        ),
        EventType:
          event.EventType === ClockEventType.ClockIn
            ? ClockEventType.ClockOut
            : ClockEventType.BreakEnd,
      });

      this.loadClockEvents();
    } catch (error) {
      // Fehlerbehandlung hier hinzufügen, falls erforderlich
      console.error('Fehler beim Aktualisieren der Ereignisse:', error);
    }
  }

  formatTimeToISO(timeString: string, baseTime: string = null): string {
    if (!timeString) {
      throw new Error('Invalid time format');
    }
    const [hours, minutes] = timeString.split(':');
    const date = baseTime ? new Date(baseTime) : new Date();
    date.setHours(parseInt(hours, 10), parseInt(minutes, 10), 0, 0);
    return date.toISOString();
  }

  cancelEditEvent(index: number): void {
    const event = this.clockEventList[index];
    event.editing = false;
    event.editStartTime = this.formatTime(event.EventStart);
    event.editEndTime = this.formatTime(event.EventEnd);
    this.validationError$.next('');
    this.cdr.markForCheck(); // Manuelle Änderungserkennung
  }

  openPicker(event: any, index: number, type: string): void {
    event.stopPropagation();
    const picker = document.createElement('input');
    picker.type = 'time';
    picker.style.display = 'none';
    document.body.appendChild(picker);
    picker.click();
    picker.addEventListener('change', (e: Event) => {
      const input = e.target as HTMLInputElement;
      if (type === 'start') {
        this.clockEventList[index].editStartTime = input.value;
      } else {
        this.clockEventList[index].editEndTime = input.value;
      }
      document.body.removeChild(picker);
      this.validateEventForUpdate(index); // Add this line
    });
  }

  addNewEvent(): void {
    this.newEvent = {
      EventStartId: null,
      EventEndId: null,
      EventStart: '',
      EventEnd: '',
      EventType: ClockEventType.ClockIn,
      editStartTime: '',
      editEndTime: '',
      editing: true,
    };
  }

  validateNewEvent(): void {
    if (this.newEvent) {
      try {
        if (!this.newEvent.editStartTime || !this.newEvent.editEndTime) {
          this.validationError$.next(
            this.translate.instant('BOTH START AND END TIMES ARE REQUIRED')
          );
          return;
        }
        const newStart = this.formatTimeToISO(
          this.newEvent.editStartTime,
          this.startDate
        );
        const newEnd = this.formatTimeToISO(
          this.newEvent.editEndTime,
          this.startDate
        );
        const newStartDate = new Date(newStart);
        const newEndDate = new Date(newEnd);

        if (this.newEvent.editStartTime >= this.newEvent.editEndTime) {
          this.validationError$.next(
            this.translate.instant('END TIME MUST BE AFTER START TIME')
          );
          return;
        }
        if (this.isTimeOverlap(newStart, newEnd)) {
          this.validationError$.next(
            this.translate.instant('TIME PERIODS CANNOT OVERLAP')
          );
          return;
        }
        if (!this.isWithinWorkTimeWindow(newStartDate, newEndDate)) {
          this.validationError$.next(
            this.translate.instant(
              'WORK TIME MUST BE WITHIN COMPANY TIME WINDOW'
            )
          );
          return;
        }
        if (this.exceedsMaxDailyWorkingHours(newStartDate, newEndDate)) {
          this.validationError$.next(
            this.translate.instant(
              'EXCEEDS MAXIMUM ALLOWED DAILY WORKING HOURS'
            )
          );
          return;
        }
        this.validationError$.next('');
      } catch (error) {
        this.validationError$.next(
          this.translate.instant('INVALID TIME FORMAT')
        );
      }
    }
  }

  validateEventForUpdate(index: number): void {
    const event = this.clockEventList[index];
    if (event) {
      try {
        if (!event.editStartTime || !event.editEndTime) {
          this.validationError$.next(
            this.translate.instant('BOTH START AND END TIMES ARE REQUIRED')
          );
          return;
        }
        const newStart = this.formatTimeToISO(
          event.editStartTime,
          event.EventStart
        );
        const newEnd = this.formatTimeToISO(event.editEndTime, event.EventEnd);
        const newStartDate = new Date(newStart);
        const newEndDate = new Date(newEnd);

        if (event.editStartTime >= event.editEndTime) {
          this.validationError$.next(
            this.translate.instant('END TIME MUST BE AFTER START TIME')
          );
          return;
        }
        if (this.isTimeOverlap(newStart, newEnd, event)) {
          this.validationError$.next(
            this.translate.instant('TIME PERIODS CANNOT OVERLAP')
          );
          return;
        }
        if (!this.isWithinWorkTimeWindow(newStartDate, newEndDate)) {
          this.validationError$.next(
            this.translate.instant(
              'WORK TIME MUST BE WITHIN COMPANY TIME WINDOW'
            )
          );
          return;
        }
        if (this.exceedsMaxDailyWorkingHours(newStartDate, newEndDate)) {
          this.validationError$.next(
            this.translate.instant(
              'EXCEEDS MAXIMUM ALLOWED DAILY WORKING HOURS'
            )
          );
          return;
        }
        this.validationError$.next('');
      } catch (error) {
        this.validationError$.next(
          this.translate.instant('INVALID TIME FORMAT')
        );
      }
    }
  }

  checkEventValidityForUpdate(event: any): boolean {
    if (event) {
      try {
        if (!event.editStartTime || !event.editEndTime) {
          this.validationError$.next(
            this.translate.instant('BOTH START AND END TIMES ARE REQUIRED')
          );
          return false;
        }
        const newStart = this.formatTimeToISO(
          event.editStartTime,
          event.EventStart
        );
        const newEnd = this.formatTimeToISO(event.editEndTime, event.EventEnd);
        const newStartDate = new Date(newStart);
        const newEndDate = new Date(newEnd);

        if (event.editStartTime >= event.editEndTime) {
          this.validationError$.next(
            this.translate.instant('END TIME MUST BE AFTER START TIME')
          );
          return false;
        }
        if (this.isTimeOverlap(newStart, newEnd, event)) {
          this.validationError$.next(
            this.translate.instant('TIME PERIODS CANNOT OVERLAP')
          );
          return false;
        }
        if (!this.isWithinWorkTimeWindow(newStartDate, newEndDate)) {
          this.validationError$.next(
            this.translate.instant(
              'WORK TIME MUST BE WITHIN COMPANY TIME WINDOW'
            )
          );
          return false;
        }
        if (this.exceedsMaxDailyWorkingHours(newStartDate, newEndDate)) {
          this.validationError$.next(
            this.translate.instant(
              'EXCEEDS MAXIMUM ALLOWED DAILY WORKING HOURS'
            )
          );
          return false;
        }
        this.validationError$.next('');
        return true;
      } catch (error) {
        this.validationError$.next(
          this.translate.instant('INVALID TIME FORMAT')
        );
        return false;
      }
    }
    return false;
  }

  isTimeOverlap(
    startTime: string,
    endTime: string,
    currentEvent: any = null
  ): boolean {
    const newStart = new Date(startTime).getTime();
    const newEnd = new Date(endTime).getTime();

    return this.clockEventList.some((event) => {
      const existingStart = new Date(event.EventStart).getTime();
      const existingEnd = new Date(event.EventEnd).getTime();

      if (currentEvent && event === currentEvent) {
        return false;
      }

      return (
        (newStart >= existingStart && newStart < existingEnd) ||
        (newEnd > existingStart && newEnd <= existingEnd) ||
        (existingStart >= newStart && existingStart < newEnd) ||
        (existingEnd > newStart && existingEnd <= newEnd)
      );
    });
  }

  isWithinWorkTimeWindow(start: Date, end: Date): boolean {
    const workTimeWindowStart = this.parseTime(
      this.companySettingsService.CompanySettings.TimeTrackingSettings
        .WorkTimeWindow.Start
    );
    const workTimeWindowEnd = this.parseTime(
      this.companySettingsService.CompanySettings.TimeTrackingSettings
        .WorkTimeWindow.End
    );

    const startMinutes = start.getHours() * 60 + start.getMinutes();
    const endMinutes = end.getHours() * 60 + end.getMinutes();

    return (
      startMinutes >= workTimeWindowStart && endMinutes <= workTimeWindowEnd
    );
  }

  exceedsMaxDailyWorkingHours(start: Date, end: Date): boolean {
    const maxDailyWorkingHours =
      this.companySettingsService.CompanySettings.TimeTrackingSettings
        .MaxDailyWorkingHours *
      60 *
      60 *
      1000; // in milliseconds
    const newStart = new Date(start).getTime();
    const newEnd = new Date(end).getTime();
    const newDuration = newEnd - newStart;

    let totalWorkedTime = newDuration;

    for (const event of this.clockEventList) {
      // ignore the event being edited
      if (event.editing) {
        continue;
      }
      const eventStart = new Date(event.EventStart).getTime();
      const eventEnd = new Date(event.EventEnd).getTime();
      totalWorkedTime += eventEnd - eventStart;
    }

    return totalWorkedTime > maxDailyWorkingHours;
  }

  parseTime(time: string): number {
    const [hours, minutes] = time.split(':').map(Number);
    return hours * 60 + minutes;
  }

  async saveNewEvent(): Promise<void> {
    if (this.newEvent && this.validationError$.value === '') {
      const newEventCopy = { ...this.newEvent };
      newEventCopy.EventStart = this.formatTimeToISO(
        newEventCopy.editStartTime,
        this.startDate
      );
      newEventCopy.EventEnd = this.formatTimeToISO(
        newEventCopy.editEndTime,
        this.startDate
      );
      this.clockEventList.push(newEventCopy);
      this.newEvent = null;

      try {
        await this.timeRecordingService.addClockEvent({
          EventType: newEventCopy.EventType,
          EventTime: new Date(newEventCopy.EventStart),
          EmployeeId: this.employeeId,
          DepartmentId: this.departmentId,
          SkillId: this.skillId,
        });

        await this.timeRecordingService.addClockEvent({
          EventType:
            newEventCopy.EventType === ClockEventType.ClockIn
              ? ClockEventType.ClockOut
              : ClockEventType.BreakEnd,
          EventTime: new Date(newEventCopy.EventEnd),
          EmployeeId: this.employeeId,
          DepartmentId: this.departmentId,
          SkillId: this.skillId,
        });

        this.loadClockEvents();
      } catch (error) {
        // Fehlerbehandlung hier hinzufügen, falls erforderlich
        console.error('Fehler beim Hinzufügen der Ereignisse:', error);
      }
    } else {
      this.validationError$.next(
        this.translate.instant('TIME PERIODS CANNOT OVERLAP')
      );
    }
  }

  cancelNewEvent(): void {
    this.newEvent = null;
    this.validationError$.next('');
    this.cdr.markForCheck(); // Manuelle Änderungserkennung
  }

  async showDeleteDialog(index: number): Promise<void> {
    const event = this.clockEventList[index];

    try {
      await this.timeRecordingService.deleteClockEvent(
        event.EventStartId,
        true
      );

      await this.timeRecordingService.deleteClockEvent(event.EventEndId, true);

      this.loadClockEvents();
    } catch (error) {
      // Fehlerbehandlung hier hinzufügen, falls erforderlich
      console.error('Fehler beim Löschen der Ereignisse:', error);
    }
  }
}
