import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'src/app/modules/auth/services/auth/auth.service';
import { SubscriptionService } from 'src/app/modules/feature-toggle/services/subscription.service';
import { LoaderService } from '../../core/services/loader/loader.service';

@Component({
  selector: 'app-page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.css'],
})
export class PageHeaderComponent implements OnInit {
  currentRoute: string;
  constructor(
    public authService: AuthService,
    public router: Router,
    public loaderService: LoaderService,
    private changeDetectorRef: ChangeDetectorRef,
    private translate: TranslateService,
    public subscriptionService: SubscriptionService
  ) {}
  public loading = false;
  public isMenuCollapsed = true;
  openShiftNumber = 0;
  lang;
  ngOnInit(): void {
    this.changeDetectorRef.detach();
    this.loaderService.isLoading.subscribe((res) => {
      this.loading = res > 0;
      this.changeDetectorRef.detectChanges();
    });

    this.lang = this.translate.currentLang;

    // Lade die Features, sobald die App gestartet wird
    this.subscriptionService.loadCompanyFeatures().subscribe();
  }

  changeLocale(lang: string): void {
    localStorage.setItem('lang', lang);
    window.location.reload();
    this.isMenuCollapsed = true;
  }
  isOpen(): void {
    this.isMenuCollapsed = !this.isMenuCollapsed;
    this.changeDetectorRef.detectChanges();
  }

  openPersonProfile(): void {
    this.router.navigateByUrl('/profile');
    this.isMenuCollapsed = true;
  }

  onDashboardClick(): void {
    this.router.navigate(['/dashboard']);
    this.isMenuCollapsed = true;
  }

  onEmployeeDashboardClick(): void {
    this.router.navigate(['/employeedashboard']);
    this.isMenuCollapsed = true;
  }

  onEmployeeClick(): void {
    this.router.navigateByUrl('/employee');
    this.isMenuCollapsed = true;
  }

  onTimeRecordingClick(): void {
    this.router.navigateByUrl('/time-recording');
    this.isMenuCollapsed = true;
  }

  onCompanyClick(): void {
    this.router.navigateByUrl('/company');
    this.isMenuCollapsed = true;
  }

  onAbsenceClick(): void {
    this.router.navigateByUrl('/absence');
    this.isMenuCollapsed = true;
  }

  onPlannerClick(): void {
    this.router.navigateByUrl('/planner');
    this.isMenuCollapsed = true;
  }

  onAvailabilityClick(): void {
    this.router.navigateByUrl('/availability');
    this.isMenuCollapsed = true;
  }

  onResourcePlanClick(): void {
    this.router.navigateByUrl('/resource-plan');
    this.isMenuCollapsed = true;
  }

  onReportsClick(): void {
    this.router.navigateByUrl('/reports');
    this.isMenuCollapsed = true;
  }

  onSettingsClick(): void {
    this.router.navigateByUrl('/settings');
    this.isMenuCollapsed = true;
  }
  onScheduleRequirementClick(): void {
    this.router.navigateByUrl('/requirement-planer');
    this.isMenuCollapsed = true;
  }

  onAdminSettingClick(): void {
    this.router.navigateByUrl('/admin-settings');
    this.isMenuCollapsed = true;
  }
  onLogoutClick(): void {
    this.authService.goToLogin();
    this.isMenuCollapsed = true;
  }

  onChangePassword(): void {
    this.router.navigateByUrl('/change-password');
    this.isMenuCollapsed = true;
  }

  onDashboardClickImage(): void {
    if (this.authService.isManegeOrTeamLeader) {
      this.router.navigateByUrl('/dashboard');
    } else {
      this.router.navigateByUrl('/employeedashboard');
    }
    this.isMenuCollapsed = true;
  }
}
