<div class="modal-dialog modal-lg" role="document">
  <div class="modal-content">
    <div class="modal-header text-center" style="text-align: center;">
      <h5 class="modal-title w-100" *ngIf="formModel.Id>0" id="exampleModalCenterTitle">{{'EMPLOYEE ACCOUNT'|translate}}
      </h5>
      <h5 class="modal-title w-100" *ngIf="formModel.Id===0" id="exampleModalCenterTitle">{{'EMPLOYEE
        ACCOUNT'|translate}}
      </h5>
    </div>
    <div class="modal-body">
      <form class="user" id="addEmployee" #form='ngForm' autocomplete="off" (submit)="onSubmit(formModel)">
        <input type="hidden" #Id="ngModel" name="Id" [(ngModel)]="formModel.Id" />
        <div class="row">
          <div class="form-group col-md-6 mb-4" style="min-width: 330px;">
            <label for="Email">{{'Email'|translate}}</label>
            <div class="input-group mb-3">
              <div class="input-group-prepend">
                <span class="input-group-text" id="star-time-icon" style="height: 40px;"><i
                    class="fa-light fa-envelope"></i></span>
              </div>
              <input type="email" class="form-control form-control-user" id="Email"
                placeholder="{{'Enter Email'|translate}}" #Email="ngModel" name="Email" [(ngModel)]="formModel.Email"
                [required]="formModel.Role === 3">
              <div *ngIf="Email.invalid" class="text-danger">
                <div *ngIf="Email.errors?.required">
                  {{'TO SELECT A TEAM LEADER YOU MUST HAVE AN EMAIL ADDRESS'|translate}}
                </div>
                <div *ngIf="Email.errors?.email">
                  {{'Please enter a valid email address.'|translate}}
                </div>
              </div>
            </div>
          </div>

          <div class="form-group col-md-6 mb-4" style="min-width: 330px;">
            <label for="Role">{{'Role'|translate}}</label>
            <div class="input-group mb-3">
              <div class="input-group-prepend">
                <span class="input-group-text" id="star-time-icon" style="height: 40px;"><i
                    class="fa-light fa-user"></i></span>
              </div>
              <mat-select *ngIf="formModel.Role!==1" #Role="ngModel" name="Role" id="Role" class="form-control"
                [(ngModel)]="formModel.Role" [disabled]="authService.myRole !== 1">
                <mat-option *ngFor="let role of userRole" [value]="role.id"> {{ role.label }}</mat-option>
              </mat-select>
              <ng-container *ngIf="formModel.Role === 1">
                <br>
                <label for="Role">{{'MANAGER'|translate}}</label>
              </ng-container>
            </div>
          </div>
        </div>
      </form>
    </div>
    <div class="modal-footer d-flex justify-content-between">
      <button class="btn btn-outline-secondary" (click)="onClose()" type="button"
        data-dismiss="modal">{{'Close'|translate}}</button>
      <button class="btn btn-primary active" form="addEmployee" type="submit" [disabled]="form.invalid">{{'SAVE AND
        INVITE'|translate}}</button>
    </div>
  </div>
</div>