import { Location } from '@angular/common';
import { Directive, ElementRef, HostListener, Optional } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatomoTracker } from '@ngx-matomo/tracker';

@Directive({
  selector: 'button',
})
export class TrackButtonDirective {
  constructor(
    private matomoTracker: MatomoTracker,
    private el: ElementRef,
    private location: Location,
    @Optional() private dialogRef: MatDialogRef<any> // Optional Injection of MatDialogRef
  ) {}

  @HostListener('click', ['$event'])
  onClick(event: Event) {
    const buttonText = this.el.nativeElement.innerText.trim();
    const currentUrl = this.location.path();
    const dialogName = this.getDialogName();

    this.matomoTracker.trackEvent(
      'Button',
      'Click',
      `${buttonText} in ${dialogName} on ${currentUrl}`
    );

    console.log(
      `Button "${buttonText}" clicked in ${dialogName} on ${currentUrl}`
    );
  }

  private getDialogName(): string {
    return this.dialogRef
      ? this.dialogRef.componentInstance?.constructor?.name || 'UnknownDialog'
      : 'NotInDialog';
  }
}
