import { Component } from '@angular/core';

@Component({
  selector: 'app-availability-page',
  templateUrl: './availability-page.component.html',
  styleUrls: ['./availability-page.component.css']
})
export class AvailabilityPageComponent {

}
