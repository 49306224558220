import { Component, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { FormatString } from 'src/app/core/_helpers';
import { AuthService } from 'src/app/modules/auth/services/auth/auth.service';
import { NewPassword } from '../../../admin/model/new-password.model';

@Component({
  selector: 'app-accept-invitation',
  templateUrl: './accept-invitation.component.html',
  styleUrls: ['./accept-invitation.component.css'],
})
export class AcceptInvitationComponent implements OnInit {
  formModel: NewPassword = {
    Email: null,
    OldPassword: null,
    NewPassword: null,
  };
  EmployeeName = '';
  newPasswordForm: UntypedFormGroup;
  newPassword: string;
  emailText: string;
  token: string;
  email: string;
  oldPass: string = '';
  iid: string;

  constructor(
    private route: ActivatedRoute,
    private authService: AuthService,
    private fb: UntypedFormBuilder,
    private translate: TranslateService
  ) {
    this.token = this.route.snapshot.queryParams.token;
    this.email = this.route.snapshot.queryParams.email;
    this.oldPass = this.route.snapshot.queryParams.pass;
    this.iid = this.route.snapshot.queryParams.iid;

    this.emailText = FormatString(
      this.translate.instant(
        'YOUR EMPLOYER HAS REGISTERED YOU WITH THE EMAIL ADDRESS'
      ),
      [this.email]
    );

    this.newPasswordForm = this.fb.group(
      {
        password: ['', [Validators.required, Validators.minLength(6)]],
        confirmPassword: [''],
      },
      { validator: this.checkPasswords }
    );
  }

  onSubmit(): void {
    if (this.newPasswordForm.valid) {
      this.authService.EmployeeAccountConfirmation({
        Email: this.email,
        NewPassword: this.newPasswordForm.get('password').value,
        OldPassword: this.oldPass,
        Token: this.token,
        InvitationId: this.iid,
      });
    }
  }

  ngOnInit(): void {
    this.newPasswordForm = this.fb.group(
      {
        password: ['', [Validators.required, Validators.minLength(4)]],
        confirmPassword: [''],
      },
      { validator: this.checkPasswords }
    );
  }

  checkPasswords(group: UntypedFormGroup): any {
    const pass = group.get('password').value;
    const confirmPass = group.get('confirmPassword').value;

    return pass === confirmPass ? null : { notSame: true };
  }
}
