import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { Subscription } from 'rxjs';
import { TotalWorkHoursReportDto } from '../../model/total-work-hours-report-dto.model';
import { ReportsService } from '../../services/reports.service';

@Component({
  selector: 'app-total-work-hours-report',
  templateUrl: './total-work-hours-report.component.html',
  styleUrls: ['./total-work-hours-report.component.css'],
})
export class TotalWorkHoursReportComponent implements OnInit {
  @Input() startDate: string;
  @Input() endDate: string;
  @Input() employeeId: number;
  @Input() departmentIds: number[];

  chartId = 'totalWorkHoursReportChart';
  totalWorkHoursReport: TotalWorkHoursReportDto = new TotalWorkHoursReportDto();
  private subscriptionEmployeeList: Subscription;
  constructor(public service: ReportsService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.startDate ||
      changes.endDate ||
      changes.employeeId ||
      changes.departmentIds
    ) {
      this.service.getTotalWorkHoursReportQuery(
        this.startDate,
        this.endDate,
        this.employeeId,
        this.departmentIds
      );
    }
  }

  ngOnInit(): void {
    this.subscriptionEmployeeList =
      this.service.totalWorkHoursReportList.subscribe(
        (report: TotalWorkHoursReportDto) => {
          this.totalWorkHoursReport = report;
        }
      );
  }
}
