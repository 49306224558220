import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as echarts from 'echarts';
import { AbsenceSummaryDto } from '../../model/absence-summary-dto.model';
import { AbsenceService } from '../../services/absence.service';

@Component({
  selector: 'app-absence-summary-chart',
  templateUrl: './absence-summary-chart.component.html',
  styleUrls: ['./absence-summary-chart.component.css'],
})
export class AbsenceSummaryChartComponent implements OnInit, OnChanges {
  @Input() startDate: string;
  @Input() endDate: string;
  @Input() companyId: number;

  chartId = 'absenceSummaryChart';
  private chartInstance: any;

  constructor(
    private service: AbsenceService,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    // Set the start and end date to the first and last day of the current year
    this.startDate = `${new Date().getFullYear()}-01-01`;
    this.endDate = `${new Date().getFullYear()}-12-31`;

    this.service.getAbsenceSummary(this.startDate, this.endDate);
    // Initialize chart after view is initialized
    setTimeout(() => {
      this.initChart();
    }, 300);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.startDate || changes.endDate || changes.companyId) {
      this.service.getAbsenceSummary(this.startDate, this.endDate);
    }
  }

  initChart(): void {
    const chartDom = document.getElementById(this.chartId)!;
    this.chartInstance = echarts.init(chartDom);

    this.service.getAbsenceSummaryData.subscribe((data: AbsenceSummaryDto) => {
      if (data && data.MonthlyData) {
        this.updateChart(data);
      }
    });
  }

  updateChart(data: AbsenceSummaryDto): void {
    // Sort MonthlyData by the actual month to ensure chronological order
    data.MonthlyData.sort((a, b) => {
      const monthA = new Date(a.Month).getTime();
      const monthB = new Date(b.Month).getTime();
      return monthA - monthB;
    });

    const months = data.MonthlyData.map((item) => {
      const [year, month] = item.Month.split('-').map(Number);
      const date = new Date(year, month - 1); // Monat in Date-Objekt umwandeln
      return date.toLocaleString('default', { month: 'short' });
    });

    const confirmedVacationDays = data.MonthlyData.map(
      (item) => item.TotalConfirmedVacationDays
    );
    const pendingVacationDays = data.MonthlyData.map(
      (item) => item.TotalPendingVacationDays
    );
    const confirmedSickDays = data.MonthlyData.map(
      (item) => item.TotalConfirmedSickDays
    );
    const pendingSickDays = data.MonthlyData.map(
      (item) => item.TotalPendingSickDays
    );

    const option = {
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow',
        },
        formatter: (params) => {
          let tooltipText = `${params[0].name}<br/>`;
          params.forEach((param) => {
            tooltipText += `${param.marker} ${param.seriesName}: ${
              param.value
            } ${this.translate.instant('DAYS')}<br/>`;
          });
          return tooltipText;
        },
      },
      legend: {
        data: [
          this.translate.instant('CONFIRMED_VACATION_DAYS'),
          this.translate.instant('PENDING_VACATION_DAYS'),
          this.translate.instant('CONFIRMED_SICK_DAYS'),
          this.translate.instant('PENDING_SICK_DAYS'),
        ],
      },
      xAxis: {
        type: 'category',
        data: months,
        axisLabel: {
          interval: 0, // Show all labels
          rotate: 45, // Rotate labels to save space
        },
      },
      yAxis: {
        type: 'value',
        name: this.translate.instant('DAYS'),
      },
      series: [
        {
          name: this.translate.instant('CONFIRMED_VACATION_DAYS'),
          type: 'bar',
          stack: this.translate.instant('VACATION'),
          data: confirmedVacationDays,
          itemStyle: {
            color: this.service.eventVacationColor, // Green for confirmed vacation days
          },
        },
        {
          name: this.translate.instant('PENDING_VACATION_DAYS'),
          type: 'bar',
          stack: this.translate.instant('VACATION'),
          data: pendingVacationDays,
          itemStyle: {
            color: this.service.eventVacationColor + '82', // Light green for pending vacation days
          },
        },
        {
          name: this.translate.instant('CONFIRMED_SICK_DAYS'),
          type: 'bar',
          stack: this.translate.instant('SICKNESS'),
          data: confirmedSickDays,
          itemStyle: {
            color: this.service.eventSickColor, // Orange for confirmed sick days
          },
        },
        {
          name: this.translate.instant('PENDING_SICK_DAYS'),
          type: 'bar',
          stack: this.translate.instant('SICKNESS'),
          data: pendingSickDays,
          itemStyle: {
            color: this.service.eventSickColor + '82', // Light orange for pending sick days
          },
        },
      ],
    };

    this.chartInstance.setOption(option);
  }
}
