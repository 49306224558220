import { Component, OnInit } from '@angular/core';
@Component({
  selector: 'app-shifts-planner',
  templateUrl: './shifts-planner.component.html',
  styleUrls: ['./shifts-planner.component.css'],
})
export class ShiftsPlannerComponent implements OnInit {
  constructor() {}
  showTimeline = false;
  ngOnInit(): void {}
  clickPayOption(selectedChoice: boolean) {
    this.showTimeline = selectedChoice;
  }
}
