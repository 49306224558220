<div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
        <div class="modal-header text-center" style="padding-bottom: 0px; margin-bottom: 0px;">
            <h5 *ngIf="data.eventType === 3" class="modal-title w-100" id="exampleModalCenterTitle">{{'DETAILS OF THE OPEN SHIFT'| translate}} <b>{{dialogTitle}}</b>
            </h5>
            <h5 *ngIf="data.eventType === 1" class="modal-title w-100" id="exampleModalCenterTitle">{{'WORK SHIFT DETAILS'| translate}} <b>{{dialogTitle}}</b></h5>
        </div>
        <hr class="mt-2 mb-3" />
        <div *ngIf="!employeeDetails" class="row" style="padding-top: 15px;">
            <div class="col-12">
                <h6>{{shiftDetails.Department}} - {{shiftDetails.EmployeeName}} </h6>
            </div>
        </div>
        <hr class="mt-2 mb-3" />
        <div class="modal-body" style="min-width: 330px;">
            <div class="row">
                <div class="col-8">
                    <h4> <i [ngClass]="shiftDetails.IconCode"></i> &nbsp; {{shiftTime.StarTime}} - {{shiftTime.EndTime}}
                    </h4>
                </div>
                <div class="col-4">
                    <h5><i class="fa-solid fa-clock"></i> {{duration}}</h5>
                    <h5><i class="fa-light fa-mug-hot"></i> {{break}}</h5>
                </div>
            </div>

            <hr class="mt-2 mb-3" />
            <div *ngIf="employeeDetails" class="row">
                <div class="col-4">
                </div>
                <hr class="mt-2 mb-3" />
                <h5 *ngIf="employeeDetails.length>0"> {{'Application list'|translate}} </h5>
                <table class="table table-bordered table-hover dataTable" style="padding-left: 0px;padding-right: 0px;">
                    <tbody>
                        <tr *ngFor="let employee of employeeDetails">
                            <td>
                                <div style="position: relative;display: flex;justify-content: center;align-items: center;">
                                    <div id="middle-circle">{{employee.TimeAvailablePercent}} %</div>
                                    <div id="progress-spinner" [ngStyle]="{'background': 'conic-gradient('+ employee.Color + ' '+employee.TimeAvailablePercent+'%, rgb(242, 242, 242) '+employee.TimeAvailablePercent+'%)'}">
                                    </div>
                                </div>
                            </td>
                            <td style="text-align: left; vertical-align: middle;">
                                <h6>{{employee.Name}}</h6>
                                <div class="badge badge-pill badge-primary" style="font-size: small;" [style.background-color]="employee.Color">
                                    {{employee.TimeAvailable}} </div>
                            </td>
                            <td style="text-align: left; vertical-align: middle; font-size: 20px;">
                                <i *ngFor="let icon of employee.SkillIcons" [ngClass]="icon"></i>
                            </td>
                            <td style="text-align: center; vertical-align: middle;">
                                <button class="btn btn-outline-success" (click)="adoptOpenShiftInWorkShift(shiftDetails.EventId,employee.EmployeeId)">
                  <i slot="font-size: 30px;" class="bi bi-person-check fa-lg"></i></button>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <h6 *ngIf="employeeDetails.length===0"> {{'THE APPLICATION LIST IS STILL EMPTY'|translate}} </h6>
            </div>
        </div>
        <div class="modal-footer d-flex justify-content-between">
            <button class="btn btn-outline-secondary" (click)="onClose()" type="button" data-dismiss="modal">
        {{'Close'|translate}}</button>
        </div>
    </div>
</div>