export class EmployeeDetailsDto {
  public DepartmentList: DepartmentDto[];
  public SkillList: SkillDto[];
}

export class DepartmentDto {
  public Id: number;
  public Name: string;
}

export class SkillDto {
  public Id: number;
  public Name: string;
  public IconCode: string;
  public DepartmentId: number;
}
