<div>
  <div *ngIf="skillList && skillList.length === 0" class="alert alert-info" role="alert">
    {{alertMessage}}
  </div>
  <table class="table">
    <tbody cdkDropList (cdkDropListDropped)="onDrop($event)">
      <tr *ngFor="let skill of skillList" cdkDrag cdkDragLockAxis="y">
        <th class="col-xs">
          <div class="drag-handle">
            <ng-container [ngTemplateOutlet]="dragHandleTmpl">
            </ng-container>
            {{ skill.Weighting }}
          </div>
        </th>
        <td style="float: left" class="col-md">
          <span class="badge badge-pill badge-primary"
            style="font-size: small; background-color:var(--primary-color-blue)">
            <i [ngClass]="skill.IconCode + ' fa-lg'"></i>
          </span>

        </td>
        <td style="float: left; width: auto;" class="col-md">
          {{skill.Name}}
        </td>
        <td style="float: right" class="col-md">
          {{skill.EmployeeCount}}
        </td>
        <td class="col-md">
          <button style="float: right" mat-icon-button [matMenuTriggerFor]="menu"
            aria-label="Example icon-button with a menu">
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="onSkillUpdate(skill.Id)">
              <mat-icon>edit</mat-icon>
              <span>{{'EDIT'| translate}}</span>
            </button>
            <button mat-menu-item (click)="onSkillDelete(skill.Id, skill.Name)">
              <mat-icon>delete</mat-icon>
              <span>{{'DELETE'| translate}}</span>
            </button>
          </mat-menu>
        </td>
      </tr>
    </tbody>
  </table>
</div>
<ng-template #dragHandleTmpl>
  <svg class="bi bi-list handle" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor"
    xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd"
      d="M2.5 11.5A.5.5 0 013 11h10a.5.5 0 010 1H3a.5.5 0 01-.5-.5zm0-4A.5.5 0 013 7h10a.5.5 0 010 1H3a.5.5 0 01-.5-.5zm0-4A.5.5 0 013 3h10a.5.5 0 010 1H3a.5.5 0 01-.5-.5z"
      clip-rule="evenodd" />
  </svg>
</ng-template>