import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CompanySettings } from '../../model/company-settings.model';
import { CompanySettingsService } from '../../services/company-settings.service';

@Component({
  selector: 'app-shift-swap-settings',
  templateUrl: './shift-swap-settings.component.html',
  styleUrls: ['./shift-swap-settings.component.css'],
})
export class ShiftSwapSettingsComponent implements OnInit {
  companySettings: CompanySettings = new CompanySettings();
  daysInAdvance = [];

  constructor(
    private companySetting: CompanySettingsService,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.companySetting.CompanySettingsObservable.subscribe((settings) => {
      if (settings) {
        this.companySettings = settings;
      }
    });
    this.companySetting.getCompanySettings();

    for (let i = 4; i <= 30; i++) {
      this.daysInAdvance.push({
        key: i,
        value: i + ' ' + this.translate.instant('DAYS'),
      });
    }
  }
  onUpdate(): void {
    this.companySetting.updateCompanySettings(this.companySettings);
  }
}
