import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { CompanySubscriptionDetailsDto } from '../../model/company-subscription-details-dto.model';
import { SubscriptionService } from '../../services/subscription.service';
import { SubscriptionOptionsComponent } from '../change-subscription/subscription-options.component';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import 'dayjs/locale/de';
import 'dayjs/locale/en';
import { TranslateService } from '@ngx-translate/core';
dayjs.extend(localizedFormat);
@Component({
  selector: 'app-current-subscription',
  templateUrl: './current-subscription.component.html',
  styleUrls: ['./current-subscription.component.css'],
})
export class CurrentSubscriptionComponent implements OnInit, OnDestroy {
  subscriptionDetails: CompanySubscriptionDetailsDto;
  showWarning: boolean = false;
  currentLocale: string;

  constructor(
    private subscriptionService: SubscriptionService,
    private dialog: MatDialog,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    // Get current language ('en' or 'de') from translate service
    this.currentLocale = this.translate.currentLang || 'en';

    // Set dayjs locale
    dayjs.locale(this.currentLocale);
    this.subscriptionService.loadCompanyFeatures().subscribe();

    this.subscriptionService.companySubscriptionDetails.subscribe((data) => {
      this.subscriptionDetails = data;

      // Überprüfe, ob das Abo gekündigt wurde und bald abläuft
      if (data.CancelAtPeriodEnd && data.CurrentPeriodEnd) {
        this.showWarning = true;
      } else {
        this.showWarning = false;
      }
    });
  }

  changeSubscription(): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;

    dialogConfig.hasBackdrop = true;
    dialogConfig.panelClass = 'custom-dialog-container';
    this.dialog.open(SubscriptionOptionsComponent, dialogConfig);
  }

  formatTrialEndDate(date: Date): string {
    return dayjs(date).locale(this.currentLocale).format('LL');
  }

  ngOnDestroy(): void {}
}
