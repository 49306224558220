// holiday-management.component.ts

import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { HolidayDto, HolidayService } from '../../services/holiday.service';
import { AddCustomHolidayComponent } from '../add-custom-holiday/add-custom-holiday.component';
import { ConfirmationDialogComponent } from 'src/app/core/component/confirmation-dialog/confirmation-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import dayjs from 'dayjs';
import { CompanySettingsService } from 'src/app/modules/settings/services/company-settings.service';
import { LocationService } from 'src/app/modules/settings/services/location.service';

@Component({
  selector: 'app-holiday-management',
  templateUrl: './holiday-management.component.html',
  styleUrls: ['./holiday-management.component.css'],
})
export class HolidayManagementComponent implements OnInit {
  holidays: HolidayDto[] = [];
  years: number[] = [];
  year: number = new Date().getFullYear();
  isLoading: boolean = false;
  errorMessage: string = '';

  constructor(
    private holidayService: HolidayService,
    private dialog: MatDialog,
    private translate: TranslateService,
    public companySetting: CompanySettingsService,
    public locationService: LocationService
  ) {}

  ngOnInit(): void {
    this.generateYears();
    this.loadHolidays();
  }

  // Generate years for selection
  generateYears(): void {
    const currentYear = new Date().getFullYear();
    const startYear = currentYear - 4; // Last 4 years
    const endYear = currentYear + 5; // Next 5 years
    this.years = [];
    for (let y = startYear; y <= endYear; y++) {
      this.years.push(y);
    }
  }

  loadHolidays(): void {
    this.isLoading = true;
    this.holidayService.getCompanyHolidays(this.year);
    this.holidayService.holidays$.subscribe({
      next: (data) => {
        this.holidays = data;
        this.isLoading = false;
      },
      error: (error) => {
        this.errorMessage = 'Fehler beim Laden der Feiertage.';
        console.error(error);
        this.isLoading = false;
      },
    });
  }

  // Toggle holiday activation
  onToggleChange(holiday: HolidayDto): void {
    this.isLoading = true;
    this.holidayService.saveSingleHoliday(holiday).subscribe({
      next: () => {
        this.isLoading = false;
        // Optional: Show success message
      },
      error: (error) => {
        this.errorMessage = 'Fehler beim Speichern des Feiertags.';
        console.error(error);
        this.isLoading = false;
      },
    });
  }

  onYearChange(): void {
    this.loadHolidays();
  }

  getHolidayName(holiday: HolidayDto): string {
    const preferredLanguage = 'DE';
    const nameEntry = holiday.Name.find(
      (n) => n.Language === preferredLanguage
    );
    return nameEntry ? nameEntry.Text : holiday.Name[0]?.Text || '';
  }

  // Open dialog to add a new custom holiday
  openAddCustomHolidayDialog(): void {
    const dialogRef = this.dialog.open(AddCustomHolidayComponent, {
      width: '600px',
      data: {},
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.loadHolidays();
      }
    });
  }

  // Open dialog to edit an existing custom holiday
  openEditCustomHolidayDialog(holiday: HolidayDto): void {
    const dialogRef = this.dialog.open(AddCustomHolidayComponent, {
      width: '600px',
      data: { holiday: holiday },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.loadHolidays();
      }
    });
  }

  // Delete a custom holiday
  deleteCustomHoliday(holiday: HolidayDto): void {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      disableClose: false,
      panelClass: 'custom-dialog-container',
    });
    const textDialog = this.translate.instant(
      'Are you sure you want to delete this custom holiday'
    );
    dialogRef.componentInstance.confirmMessage = textDialog;

    dialogRef.afterClosed().subscribe((confirmed) => {
      if (confirmed) {
        this.isLoading = true;
        this.holidayService.deleteCustomHoliday(holiday.Id).subscribe({
          next: () => {
            this.isLoading = false;
            this.loadHolidays();
          },
          error: (error) => {
            this.errorMessage = 'Fehler beim Löschen des Feiertags.';
            console.error(error);
            this.isLoading = false;
          },
        });
      }
    });
  }

  getFormattedDate(date: Date): string {
    // Aktuelle Sprache aus TranslateService abrufen
    const currentLang =
      this.translate.currentLang || this.translate.getDefaultLang();

    // dayjs auf die aktuelle Sprache einstellen
    dayjs.locale(currentLang);

    // Datum formatieren
    return dayjs(date).format('ddd, DD.MM.YYYY');
  }
}
