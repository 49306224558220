<div
  class="modal-dialog modal-lg"
  role="document"
  style="margin: 15px"
  (click)="onBackdropClick()"
>
  <div
    class="modal-content"
    (click)="$event.stopPropagation()"
    style="width: fit-content; min-width: 280px; max-width: 500px"
  >
    <div class="modal-header">
      <div class="modal-title w-100 row">
        <div class="col-2">
          <span
            class="badge badge-pill badge-primary align-middle"
            style="border-radius: 10px; padding-top: 15px; padding-bottom: 15px"
            [style.backgroundColor]="timeIconBadgeColor"
          >
            <i
              class="fa-light fa-clock fa-2xl"
              [style.color]="timeIconColor"
            ></i>
          </span>
        </div>
        <div style="padding-left: 15px" class="col">
          <p style="margin: 0px; font-size: small">{{ "TODAY" | translate }}</p>
          <h5>{{ workedTimeDisplay }}</h5>
        </div>
      </div>
    </div>
    <!-- Fehlernachrichten als Alerts -->
    <div
      *ngIf="ComplianceMessage && ComplianceMessage.length > 0"
      style="margin: 5px"
    >
      <div
        *ngFor="let message of ComplianceMessage"
        class="alert alert-warning d-flex align-items-center"
        role="alert"
        style="padding: 5px"
      >
        <i
          class="fa fa-exclamation-triangle fa-xl mr-2"
          style="padding-right: 10px"
        ></i>
        <span>{{
          message[0] | translate : { value1: message[1], value2: message[2] }
        }}</span>
      </div>
    </div>

    <div class="modal-body">
      <div
        *ngIf="showDepartmentSelector"
        style="width: 350px"
        class="form-group col-md-6 mb-4"
      >
        <label for="Department">{{ "Department" | translate }}</label>
        <mat-select
          id="Department"
          class="form-control"
          placeholder="{{ 'Select Department' | translate }}"
          (selectionChange)="onDepartmentChange($event)"
          [formControl]="departmentValue"
          [(ngModel)]="departmentSelectionId"
          (selectionChange)="updateSliderDisabled()"
          required
        >
          <mat-option
            *ngFor="let department of employeeDepartment"
            [value]="department.Id"
            >{{ department.Name }}</mat-option
          >
        </mat-select>
      </div>
      <div
        *ngIf="showSkillSelector"
        style="width: 350px"
        class="form-group col-md-6 mb-4"
      >
        <label for="Department">{{ "QUALIFICATION" | translate }}</label>
        <mat-select
          id="Department"
          class="form-control"
          placeholder="{{ skillPlaceholderText }}"
          [formControl]="skillValue"
          [(ngModel)]="skillSelectionId"
          (selectionChange)="updateSliderDisabled()"
        >
          <mat-option
            *ngFor="let skill of employeeSkillList"
            [value]="skill.Id"
          >
            <i [ngClass]="skill.IconCode"></i> {{ skill.Name }}
          </mat-option>
        </mat-select>
      </div>
      <!-- Slider Button -->
      <div class="slider-container" #slider>
        <div class="slider-track-green" #sliderTrackGreen></div>
        <div class="slider-track-red" #sliderTrackRed></div>
        <div
          class="slider-handle"
          #handle
          (mousedown)="startDrag($event)"
          (touchstart)="startDrag($event)"
        >
          <i
            *ngIf="currentTimeStatus === 0 || currentTimeStatus === 2"
            class="fa-light fa-circle-play"
          ></i>
          <i *ngIf="currentTimeStatus === 1" class="fa-light fa-play-pause"></i>
        </div>
        <span class="slider-text" #sliderTextStart>
          <p *ngIf="sliderDisabled">
            {{ "SELECT DEPARTMENT AND SKILL FIRST" | translate }}
          </p>
          <p
            *ngIf="
              !sliderDisabled &&
              (currentTimeStatus === 0 || currentTimeStatus === 2)
            "
          >
            {{ "SWIPE TO CLOCK IN" | translate }}
          </p>
        </span>
        <span class="slider-text" #sliderTextStop>
          <p *ngIf="currentTimeStatus === 1">{{ "STOP TIME" | translate }}</p>
        </span>
      </div>
      <div
        class="scrollable-accordion-container"
        *ngIf="eventListAccordion.length"
      >
        <app-time-recording-accordion
          (clockEvent)="handleClockEvent($event)"
          [eventListAccordion]="eventListAccordion"
        >
        </app-time-recording-accordion>
      </div>
    </div>
    <div class="row" style="padding-top: 20px">
      <app-day-week-month-chart [showMonth]="false"></app-day-week-month-chart>
    </div>
  </div>
</div>
