import { Injectable } from '@angular/core';
import dayjs from 'dayjs';
import { BehaviorSubject, map, Observable } from 'rxjs';
import { ApiEndPoints, ApiMethod } from 'src/app/core/services/const';
import { HttpService } from 'src/app/core/services/http/http.service';

@Injectable({
  providedIn: 'root',
})
export class HolidayService {
  private holidaysSource = new BehaviorSubject<HolidayDto[]>([]);
  holidays$ = this.holidaysSource.asObservable();
  currentYear: number = new Date().getFullYear();

  constructor(private httpClient: HttpService) {}

  getCompanyHolidays(year: number): void {
    this.currentYear = year;
    this.httpClient
      .requestCall(
        `${ApiEndPoints.GetCompanyHolidays}?year=${year}`,
        ApiMethod.GET
      )
      .subscribe({
        next: (holidays: HolidayDto[]) => {
          this.holidaysSource.next(holidays);
        },
        error: (error) => {
          console.error('Fehler beim Abrufen der Feiertage:', error);
          // Optional: Fehlerbehandlung oder weitere Aktionen
        },
      });
  }

  // Neue Methode zum Speichern eines einzelnen Feiertags
  saveSingleHoliday(holiday: HolidayDto): Observable<boolean> {
    return this.httpClient.requestCall(
      ApiEndPoints.SaveSingleCompanyHoliday,
      ApiMethod.POST,
      { holiday: holiday }
    );
  }

  // Die bisherige Methode zum Speichern aller Feiertage bleibt optional erhalten
  saveCompanyHolidays(holidays: HolidayDto[]): Observable<boolean> {
    return this.httpClient.requestCall(
      ApiEndPoints.SaveCompanyHolidays,
      ApiMethod.POST,
      { holidays: holidays }
    );
  }

  // Neue Methode zum Speichern eines benutzerdefinierten Feiertags
  addCustomHoliday(holiday: CustomHolidayDto): Observable<boolean> {
    // Formatieren des Datums
    const formattedHoliday = {
      ...holiday,
      Date: dayjs(holiday.Date).format('YYYY-MM-DD'),
    };

    return this.httpClient
      .requestCall(
        ApiEndPoints.AddCustomCompanyHoliday,
        ApiMethod.POST,
        formattedHoliday
      )
      .pipe(
        map(() => {
          // Optional: Show success message
          this.getCompanyHolidays(this.currentYear);
        })
      );
  }

  updateCustomHoliday(holiday: CustomHolidayDto): Observable<boolean> {
    console.log(holiday);
    // Formatieren des Datums
    const formattedHoliday = {
      ...holiday,
      Date: dayjs(holiday.Date).format('YYYY-MM-DD'),
    };
    console.log(formattedHoliday);
    return this.httpClient
      .requestCall(
        ApiEndPoints.UpdateCustomCompanyHoliday,
        ApiMethod.PUT,
        formattedHoliday
      )
      .pipe(
        map(() => {
          // Optional: Show success message
          this.getCompanyHolidays(this.currentYear);
        })
      );
  }

  deleteCustomHoliday(holidayId: string): Observable<boolean> {
    return this.httpClient.requestCall(
      `${ApiEndPoints.DeleteCustomCompanyHoliday}/${holidayId}`,
      ApiMethod.DELETE
    );
  }
}

export interface HolidayDto {
  Id: string;
  StartDate: Date;
  EndDate: Date;
  Type: string;
  Name: HolidayName[];
  Nationwide: boolean;
  Subdivisions: Subdivision[];
  IsSelected: boolean;
  IsCustom: boolean;
  IsRecurring: boolean;
  RecurrencePattern: string;
  DurationInDays: number;
}

export interface CustomHolidayDto {
  Date: Date;
  HolidayName: string;
  IsRecurring: boolean;
  RecurrencePattern?: string;
  IsHalfDay: boolean;
  HolidayId: string;
}

export interface HolidayName {
  Language: string;
  Text: string;
}

export interface Subdivision {
  Code: string;
  ShortName: string;
}
