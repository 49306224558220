import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';

import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { ConfirmationDialogInputComponent } from 'src/app/core/component/confirmation-dialog-input/confirmation-dialog-input.component';
import { ConfirmationDialogComponent } from 'src/app/core/component/confirmation-dialog/confirmation-dialog.component';
import { ReportsService } from '../../services/reports.service';
import { ReportPaginationService } from './report-pagination.service';

@Component({
  selector: 'app-report-list',
  templateUrl: './report-list.component.html',
  styleUrls: ['./report-list.component.css'],
})
export class ReportListComponent implements OnInit {
  @Input() startDate: string;
  @Input() endDate: string;

  @Input() employeeId: number;
  @Input() departmentIds: number[];
  private subscriptionEmployeeList: Subscription;
  dialogRef: MatDialogRef<ConfirmationDialogComponent>;
  dialogRefInput: MatDialogRef<ConfirmationDialogInputComponent>;
  constructor(
    public paginationService: ReportPaginationService,
    public service: ReportsService,
    private translate: TranslateService
  ) {}

  listData: MatTableDataSource<any>;
  displayedColumns: string[] = [
    'EmployeeName',
    'Department',
    'Diagram',
    'TotalWorkTime',
    'TotalOvertimeTime',
    'Actions',
  ];

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.startDate ||
      changes.endDate ||
      changes.employeeId ||
      changes.departmentIds
    ) {
      this.service.getEmployeeWorkHoursReport(
        this.startDate,
        this.endDate,
        this.employeeId,
        this.departmentIds
      );
    }
  }
  ngOnInit(): void {
    this.subscriptionEmployeeList = this.service.employeeList.subscribe(
      (list) => {
        const cloned = list.map((x) => Object.assign({}, x));
        this.listData = new MatTableDataSource(cloned);
      }
    );
    // // start of current month
    // const startDate = new Date();
    // startDate.setDate(1);

    // // end of current month
    // const endDate = new Date();
    // endDate.setMonth(endDate.getMonth() + 1);
    // endDate.setDate(0);

    // this.service.getEmployeeWorkHoursReport(
    //   startDate.toString(),
    //   endDate.toString()
    // );
  }

  onPageSwitch(event: PageEvent): void {
    this.paginationService.change(event);

    this.service.getEmployeeWorkHoursReport(
      this.startDate,
      this.endDate,
      this.employeeId,
      this.departmentIds
    );
  }

  sortEmployeeData(event): void {
    this.paginationService.change(event);

    this.service.getEmployeeWorkHoursReport(
      this.startDate,
      this.endDate,
      this.employeeId,
      this.departmentIds
    );
  }

  getEmployeeWorkHoursReportPdfQuery(
    employeeId: number,
    departmentId: number
  ): void {
    this.service.GetEmployeeWorkHoursReportPdfQuery(
      this.startDate,
      this.endDate,
      employeeId,
      [departmentId]
    );
  }
}
