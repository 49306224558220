<!-- add-custom-holiday.component.html -->

<div class="modal-dialog modal-lg custom-modal" role="document">
  <div class="modal-content">
    <div class="modal-header text-center">
      <h5 class="modal-title w-100">
        {{ "ADD CUSTOM HOLIDAY" | translate }}
      </h5>
    </div>
    <div class="modal-body">
      <form
        class="user"
        id="addCustomHolidayForm"
        #addCustomHolidayForm="ngForm"
        autocomplete="off"
        (submit)="onSubmit(addCustomHolidayForm)"
      >
        <!-- Holiday Name -->
        <div class="form-group mb-4">
          <label for="HolidayName" class="required-label">{{
            "HOLIDAY NAME" | translate
          }}</label>
          <input
            type="text"
            class="form-control"
            id="HolidayName"
            placeholder="{{ 'ENTER HOLIDAY NAME' | translate }}"
            name="HolidayName"
            [(ngModel)]="formModel.HolidayName"
            required
          />
        </div>

        <!-- Date Picker -->
        <div class="form-group mb-4">
          <label for="HolidayDate" class="required-label">{{
            "DATE" | translate
          }}</label>
          <input
            matInput
            id="HolidayDate"
            class="form-control"
            [matDatepicker]="datePicker"
            (click)="datePicker.open()"
            [(ngModel)]="formModel.Date"
            name="Date"
            required
          />
          <mat-datepicker #datePicker></mat-datepicker>
        </div>

        <!-- Is Recurring -->
        <div class="form-group mb-4">
          <mat-checkbox
            name="IsRecurring"
            [(ngModel)]="formModel.IsRecurring"
            >{{ "RECURRING HOLIDAY" | translate }}</mat-checkbox
          >
        </div>

        <!-- Is Half Day -->
        <div class="form-group mb-4">
          <mat-checkbox name="IsHalfDay" [(ngModel)]="formModel.IsHalfDay">{{
            "HALF DAY HOLIDAY" | translate
          }}</mat-checkbox>
        </div>
      </form>
    </div>
    <div
      class="modal-footer d-flex justify-content-between"
      *ngIf="showButtons"
    >
      <button
        class="btn btn-outline-secondary"
        (click)="onClose()"
        type="button"
        data-dismiss="modal"
      >
        {{ "Close" | translate }}
      </button>
      <button
        class="btn btn-primary active"
        form="addCustomHolidayForm"
        type="submit"
        [disabled]="!addCustomHolidayForm.valid"
      >
        {{ "Save changes" | translate }}
      </button>
    </div>
  </div>
</div>
