<div class="status-container">
  <!-- Bereich für Status "Aktiv" -->
  <div
    class="status-card aktiv"
    (mouseenter)="showList(MitarbeiterStatus.Aktiv)"
    (mouseleave)="hideList()"
  >
    <mat-icon>{{ getStatusIcon(MitarbeiterStatus.Aktiv) }}</mat-icon>
    <p>{{ statusGruppen[MitarbeiterStatus.Aktiv].length }} Aktiv</p>
    <ul>
      <li *ngFor="let skill of getSkillsByStatus(MitarbeiterStatus.Aktiv)">
        <span [innerHTML]="getSkillIcon(skill, MitarbeiterStatus.Aktiv)"></span>
        {{ getMitarbeiterAnzahlBySkill(MitarbeiterStatus.Aktiv, skill) }}
      </li>
    </ul>

    <div
      *ngIf="
        hoveredStatus === MitarbeiterStatus.Aktiv &&
        statusGruppen[MitarbeiterStatus.Aktiv].length
      "
      class="hover-list"
    >
      <table>
        <tbody>
          <tr
            *ngFor="
              let mitarbeiter of getMitarbeiterByStatus(MitarbeiterStatus.Aktiv)
            "
          >
            <!-- Fortschrittsanzeige -->
            <td
              style="padding: 5px; text-align: center; vertical-align: middle"
            >
              <div
                style="
                  display: flex;
                  justify-content: center;
                  align-items: center;
                "
              >
                <div id="middle-circle">{{ mitarbeiter.progress }}%</div>
                <div
                  id="progress-spinner"
                  style="background: conic-gradient(#1b5e1f8f {{
                    mitarbeiter.progress
                  }}%, rgb(242, 242, 242) {{ mitarbeiter.progress }}%);"
                ></div>
              </div>
            </td>

            <!-- Mitarbeiterinformationen -->
            <td style="padding: 5px">
              <span style="font-size: medium">{{ mitarbeiter.name }}</span
              ><br />
              <div
                class="badge badge-pill badge-primary"
                style="font-size: small; background-color: #4ccaf1"
              >
                {{ mitarbeiter.schichtStart }} - {{ mitarbeiter.schichtEnde }}
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <!-- Bereich für Status "Pause" -->
  <div
    class="status-card pause"
    (mouseenter)="showList(MitarbeiterStatus.Pause)"
    (mouseleave)="hideList()"
  >
    <mat-icon>{{ getStatusIcon(MitarbeiterStatus.Pause) }}</mat-icon>
    <p>{{ statusGruppen[MitarbeiterStatus.Pause].length }} Pause</p>

    <div
      *ngIf="
        hoveredStatus === MitarbeiterStatus.Pause &&
        statusGruppen[MitarbeiterStatus.Pause].length
      "
      class="hover-list"
    >
      <table>
        <tbody>
          <tr
            *ngFor="
              let mitarbeiter of getMitarbeiterByStatus(MitarbeiterStatus.Pause)
            "
          >
            <td
              style="padding: 5px; text-align: center; vertical-align: middle"
            ></td>
            <td style="padding: 5px">
              <span style="font-size: medium">{{ mitarbeiter.name }}</span
              ><br />
              <div
                class="badge badge-pill badge-primary"
                style="font-size: small; background-color: #ffb74d"
              >
                {{ mitarbeiter.schichtStart }} - {{ mitarbeiter.schichtEnde }}
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <!-- Bereich für Status "Fertig" -->
  <div
    class="status-card fertig"
    (mouseenter)="showList(MitarbeiterStatus.Fertig)"
    (mouseleave)="hideList()"
  >
    <mat-icon>{{ getStatusIcon(MitarbeiterStatus.Fertig) }}</mat-icon>
    <p>{{ statusGruppen[MitarbeiterStatus.Fertig].length }} Fertig</p>

    <div
      *ngIf="
        hoveredStatus === MitarbeiterStatus.Fertig &&
        statusGruppen[MitarbeiterStatus.Fertig].length
      "
      class="hover-list"
    >
      <table>
        <tbody>
          <tr
            *ngFor="
              let mitarbeiter of getMitarbeiterByStatus(
                MitarbeiterStatus.Fertig
              )
            "
          >
            <td
              style="padding: 5px; text-align: center; vertical-align: middle"
            ></td>
            <td style="padding: 5px">
              <span style="font-size: medium">{{ mitarbeiter.name }}</span
              ><br />
              <div
                class="badge badge-pill badge-primary"
                style="font-size: small; background-color: #66bb6a"
              >
                {{ mitarbeiter.schichtStart }} - {{ mitarbeiter.schichtEnde }}
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <!-- Bereich für Status "Nicht eingeloggt" -->
  <div
    class="status-card nicht-eingeloggt"
    (mouseenter)="showList(MitarbeiterStatus.NichtEingeloggt)"
    (mouseleave)="hideList()"
  >
    <mat-icon>{{ getStatusIcon(MitarbeiterStatus.NichtEingeloggt) }}</mat-icon>
    <p>
      {{ statusGruppen[MitarbeiterStatus.NichtEingeloggt].length }} Nicht
      eingeloggt
    </p>
    <ul
      class="custom-scrollbar"
      style="max-height: 70px; overflow-y: auto; padding: 0; margin: 0"
    >
      <li
        *ngFor="
          let skill of getSkillsByStatus(MitarbeiterStatus.NichtEingeloggt)
        "
      >
        <span
          [innerHTML]="getSkillIcon(skill, MitarbeiterStatus.NichtEingeloggt)"
        ></span>
        {{
          getMitarbeiterAnzahlBySkill(MitarbeiterStatus.NichtEingeloggt, skill)
        }}
      </li>
    </ul>
    <div
      *ngIf="
        hoveredStatus === MitarbeiterStatus.NichtEingeloggt &&
        statusGruppen[MitarbeiterStatus.NichtEingeloggt].length
      "
      class="hover-list custom-scrollbar"
      style="right: 0; left: auto"
    >
      <table>
        <tbody>
          <tr
            *ngFor="
              let mitarbeiter of getMitarbeiterByStatus(
                MitarbeiterStatus.NichtEingeloggt
              )
            "
          >
            <td
              style="padding: 5px; text-align: center; vertical-align: middle"
            ></td>
            <td style="padding: 5px">
              <span style="font-size: medium">{{ mitarbeiter.name }}</span
              ><br />
              <div
                class="badge badge-pill badge-primary"
                style="font-size: small; background-color: #e57373"
              >
                {{ mitarbeiter.schichtStart }} - {{ mitarbeiter.schichtEnde }}
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
