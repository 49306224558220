<div class="container content-center">
  <div class="row">
    <div class="col-sm-8 col-md-6 col-lg-4 mx-auto">
      <div class="card shadow-lg">
        <div class="card-body">
          <img class="center" style="padding-top: 30px;" src="../../../../../assets/img/Logo.PNG" alt="" width="152"
            height="80">
          <form #form='ngForm' (submit)="onSubmit(form)">
            <div class="form-group mb-3" style="padding-top: 30px;">
              <label for="InputEmail" style="color: var(--font-color)">E-Mail</label>
              <ion-input appAutofill autocomplete="email" type="email" class="form-control" id="InputEmail"
                placeholder="name@example.com" #Email="ngModel" name="Email" [(ngModel)]="formModel.Email"></ion-input>
            </div>
            <div class="form-group mb-3" style="padding-top: 25px;">
              <label for="floatingPassword" style="color: var(--font-color)">Password</label>
              <ion-input appAutofill autocomplete="current-password" type="password" class="form-control"
                id="floatingPassword" placeholder="Password" #Password="ngModel" name="Password"
                [(ngModel)]="formModel.Password"></ion-input>
            </div>

            <div class="mb-3 row" style="padding-top: 30px; font-size: small;">
              <div class="d-grid">
                <button class="btn btn-primary btn-lg btn-login bi bi-box-arrow-in-right fa-lg" type="submit"> {{'LOG
                  IN'|
                  translate}}</button>
              </div>
            </div>
            <div class="text-center">
              <a style="color: var(--font-color); font-size: small; text-align: center;"
                [routerLink]="['/forgotpassword']"> {{'FORGOT
                PASSWORD'| translate}}
              </a>

              <a style="color: var(--font-color); font-size: small; text-align: center; padding-left: 30px;"
                [routerLink]="['/registration']"> {{'REGISTER'| translate}}
              </a>
            </div>
            <div *ngIf="errorStatus.showError" class="alert alert-danger" role="alert">
              {{errorStatus.errorMessage}}
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>