<div class="payment-page">
  <div class="confirmation-box" *ngIf="subscriptionPlan && subscriptionPlan.SubscriptionPlanName">
    <h3 class="plan-name">{{ subscriptionPlan.SubscriptionPlanName }}</h3>
    <ul class="features-list">
      <li *ngFor="let feature of subscriptionPlan.Features">
        <i class="fa-light fa-check fa-lg feature-icon"></i> {{ feature.Name | translate }}
      </li>
    </ul>

    <div class="payment-intervals">
      <button class="interval-btn" [class.active]="paymentInterval === 'monthly'"
        (click)="setPaymentInterval('monthly')">
        {{ 'MONTHLY' | translate }}: {{ subscriptionPlan.PricePerUserPerMonth | currency }} / {{ 'USER' | translate }}
      </button>
      <button class="interval-btn" [class.active]="paymentInterval === 'yearly'" (click)="setPaymentInterval('yearly')">
        {{ 'YEARLY' | translate }}: {{ (subscriptionPlan.PricePerUserPerMonth * 12 * (1 - discountRate / 100)) |
        currency }} / {{ 'USER' | translate }} ({{ 'DISCOUNT' | translate:{value: discountRate} }})
      </button>
    </div>

    <p class="total-cost">
      {{ 'TOTAL_COST' | translate }} {{ employeeCount }} {{ 'EMPLOYEES' | translate }}: {{ getTotalCost() | currency }}
    </p>

    <div class="info-box">
      <button class="info-btn" mat-icon-button matTooltip="{{ 'TOOLTIP_PAYMENT_DETAILS' | translate }}">
        <mat-icon>info</mat-icon>
      </button>
      <p>{{ 'CHANGE_CANCEL_SUBSCRIPTION' | translate }}</p>
    </div>

    <div class="payment-methods">
      <h4>{{ 'CHOOSE_PAYMENT_METHOD' | translate }}</h4>
      <div class="payment-options">
        <div class="payment-option" *ngFor="let method of paymentMethods"
          [class.selected]="method === selectedPaymentMethod" (click)="selectPaymentMethod(method)">
          <img [src]="method.icon" alt="{{ method.name }}" style="width: 40px;" />
          <span>{{ method.name }}</span>
        </div>
      </div>
    </div>
    <div class="modal-footer d-flex justify-content-between">
      <button (click)="cancelPayment()" class="btn btn-outline-secondary">{{ 'CANCEL' | translate }}</button>
      <button (click)="confirmPayment()" class="btn btn-primary" [disabled]="!isPaymentMethodSelected()">
        {{ 'CONFIRM_PAYMENT' | translate }}
      </button>
    </div>
  </div>

  <div *ngIf="loading" class="loading-overlay">
    <mat-spinner></mat-spinner>
  </div>
</div>