import { SelectionModel } from '@angular/cdk/collections';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Subscription } from 'rxjs';
import { ShiftForSwap } from '../../models/shift-for-swap';
import { ShiftSwapService } from '../../services/shift-swap.service';

@Component({
  selector: 'app-show-possible-work-shift-for-swap',
  templateUrl: './show-possible-work-shift-for-swap.component.html',
  styleUrls: ['./show-possible-work-shift-for-swap.component.css'],
})
export class ShowPossibleWorkShiftForSwapComponent {
  private subscriptionListPossibleWorkShiftForSwap: Subscription;
  swapShiftList: any;
  displayedColumns: string[] = ['select', 'skill', 'date', 'timeRange', 'hour'];

  dataSource = new MatTableDataSource<ShiftForSwap>();
  selection = new SelectionModel<ShiftForSwap>(true, []);

  constructor(
    private shiftSwapService: ShiftSwapService,
    @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<ShowPossibleWorkShiftForSwapComponent>
  ) {}

  ngOnInit(): void {
    if (this.data?.shittId && this.data.EmployeeId) {
      this.shiftSwapService.GetEmployeeWorkShiftsForSwapingQuery(
        this.data.shittId,
        this.data.EmployeeId
      );
    }

    this.subscriptionListPossibleWorkShiftForSwap =
      this.shiftSwapService.shiftForPossibleWorkShift.subscribe((list: any) => {
        list.forEach((entry) => {
          entry.StartTime = new Date(entry.StartTime);
          entry.EndTime = new Date(entry.EndTime);
        });
        this.dataSource.data = list.map(
          (x) =>
            ({
              Id: x.Id,
              skillIcons: x.IconCode,
              hour: this.getHours(x.StartTime, x.EndTime),
              date: x.StartTime.toLocaleDateString(navigator.language, {
                month: 'long',
                day: '2-digit',
              }),
              timeRange:
                x.StartTime.toLocaleTimeString(navigator.language, {
                  hour: '2-digit',
                  minute: '2-digit',
                }) +
                ' -> ' +
                x.EndTime.toLocaleTimeString(navigator.language, {
                  hour: '2-digit',
                  minute: '2-digit',
                }),
            } as unknown as ShiftForSwap)
        );

        this.dataSource.data.forEach((row) => {
          if (
            this.data?.CandidateShiftListId &&
            (this.data.CandidateShiftListId as ShiftForSwap[]).some(
              (x) => x.ShiftId === row.Id
            )
          ) {
            this.selection.select(row);
          }
        });
      });
  }

  ngOnDestroy(): void {
    this.subscriptionListPossibleWorkShiftForSwap.unsubscribe();
  }

  isAllSelected(): boolean {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    const status = numSelected === numRows;
    return status;
  }

  onClose(): void {
    this.dialogRef.close();
  }

  onSubmit(): void {
    const acceptedList = this.dataSource.data.map((x) => ({
      workShiftId: x.Id,
      AcceptedStatus: this.selection.selected.includes(x),
    }));

    // fillter the list with just the selected items(true) and put it in int list
    const selectedList = acceptedList
      .filter((x) => x.AcceptedStatus)
      .map((x) => x.workShiftId);
    this.shiftSwapService.proposedOptionList(selectedList, this.data.shittId);
    this.onClose();
  }

  getHours(from: Date, to: Date): string {
    let diff = to.getTime() - from.getTime();

    const hours = Math.floor(diff / (1000 * 60 * 60));
    diff -= hours * (1000 * 60 * 60);

    const mins = Math.floor(diff / (1000 * 60));
    diff -= mins * (1000 * 60);

    return hours + ':' + mins;
  }
}
