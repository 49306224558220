<button
  [ngClass]="{ active: !isActive }"
  (click)="toggle()"
  class="help-button"
  matTooltip="{{
    isActive
      ? ('DEACTIVATE_TOOLTIP' | translate)
      : ('ACTIVATE_TOOLTIP' | translate)
  }}"
>
  <i class="fas fa-question"></i>
</button>
