<div>
  <div class="row">
    <app-item-list [Title]="Proposer.EmployeeFullName"
      [Content]="Proposer.DateString + ' ' + Proposer.StartTime + ' - ' + Proposer.EndTime"
      [ContentColor]="acceptButtonColor" [BadgeContent]="'CREATED' | translate" [Icon]="Proposer.IconCode"
      [isButton]="false"></app-item-list>
  </div>
  <div class="row justify-content-center">
    <div class="col-auto d-flex justify-content-center">
      <button *ngIf="!isEmployee" class="btn btn-outline-success" [ngClass]="{'active': buttonActive}"
        (click)="adminAcceptedButton()" [disabled]="AcceptButtonDisabled">
        <i class="fa-light fa-arrow-up-arrow-down" style="font-size: 1.4em;"></i>
      </button>
      <i *ngIf="isEmployee" class="fa-light fa-arrow-up-arrow-down" style="font-size: 1.4em;"></i>
    </div>
  </div>
  <div class="row">
    <app-item-list [Title]="RequestedShift.EmployeeFullName"
      [Content]="RequestedShift.DateString + ' ' + RequestedShift.StartTime + ' - ' + RequestedShift.EndTime"
      [ContentColor]="RequestedShiftBadgeContentColor" [BadgeContent]="RequestedShiftBadgeContentText"
      [Icon]="RequestedShift.IconCode" [isButton]="isEmployee && !hideEmployeeAcceptButton"
      [buttonClass]="{'btn btn-outline-success': true, 'active': employeeButtonActive}"
      [buttonIcon]="'fa-light fa-user-check'" (onClick)="employeeAcceptedButton()"></app-item-list>
  </div>
  <div class="alert-container">
    <div *ngIf="buttonActive" class="alert alert-info" role="alert" style="padding: 5px">
      {{'THE SHIFTS ARE BEING SWAPPED BETWEEN'| translate}}: <br>
      <b>{{Proposer.EmployeeFullName}}</b> {{'AND'| translate}} <b>{{RequestedShift.EmployeeFullName}}</b>
    </div>

    <div *ngIf="employeeButtonActive" class="alert alert-info" role="alert" style="padding: 5px">
      {{'YOU HAVE ACCEPTED THE SWAP REQUEST'| translate}}
    </div>
  </div>
</div>