<div class="row text-center" style="color: var(--font-color);">
  <div class="col-2">
    <p>{{'NUMBER OF EMPLOYEES' | translate}}</p>
    <h4>{{totalWorkHoursReport.TotalEmployees}}</h4>
  </div>
  <div class="col-2">
    <p>{{'NUMBER OF DEPARTMENTS' | translate}}</p>
    <h4>{{totalWorkHoursReport.TotalDepartments}}</h4>
  </div>
  <div *ngIf="totalWorkHoursReport.TimeLine && totalWorkHoursReport.TimeLine.WorkTime.length" class="col-4"
    style="height: 80px;">
    <app-employee-time-bar-chart [chartId]="chartId"
      [chartData]="totalWorkHoursReport.TimeLine"></app-employee-time-bar-chart>
  </div>
  <div class="col-2">
    <P>{{'WORKING HOURS' | translate}}</P>
    <h4>{{totalWorkHoursReport.totalWorkTime}}</h4>
  </div>
  <div class="col-2">
    <P>{{'OVERTIME' | translate}}</P>
    <h4>{{totalWorkHoursReport.totalOvertimeTime}}</h4>
  </div>
</div>