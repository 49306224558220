<div>
  <div
    *ngIf="dataSource.data.length === 0"
    class="alert alert-info"
    role="alert"
  >
    {{
      "THE LIST IS STILL EMPTY PLEASE ADD A DEPARTMENT USING THE OPTIONS AT THE TOP RIGHT"
        | translate
    }}
  </div>

  <table mat-table [dataSource]="dataSource" multiTemplateDataRows>
    <!-- Expand Column -->
    <ng-container matColumnDef="expand">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let node">
        <button
          mat-icon-button
          *ngIf="node.Expandable"
          (click)="toggleRow(node)"
        >
          <mat-icon>
            {{ node.Expanded ? "expand_more" : "chevron_right" }}
          </mat-icon>
        </button>
      </td>
    </ng-container>

    <!-- Number Column -->
    <ng-container matColumnDef="Number">
      <th mat-header-cell *matHeaderCellDef>{{ "No." | translate }}</th>
      <td mat-cell *matCellDef="let node">
        {{ node.Number }}
      </td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="Name">
      <th mat-header-cell *matHeaderCellDef>{{ "Name" | translate }}</th>
      <td mat-cell *matCellDef="let node" [style.padding-left.px]="node.Level">
        {{ node.Name }}
      </td>
    </ng-container>

    <!-- Type Column -->
    <ng-container matColumnDef="Type">
      <th mat-header-cell *matHeaderCellDef>{{ "Type" | translate }}</th>
      <td mat-cell *matCellDef="let node">
        {{
          node.Type === DepartmentType.Department
            ? ("Department" | translate)
            : ("Project" | translate)
        }}
      </td>
    </ng-container>

    <!-- Employee Count Column -->
    <ng-container matColumnDef="EmployeeCount">
      <th mat-header-cell *matHeaderCellDef>
        {{ "Employee" | translate }}
      </th>
      <td mat-cell *matCellDef="let node">
        {{ node.EmployeeCount }}
      </td>
    </ng-container>

    <!-- Actions Column -->
    <ng-container matColumnDef="Actions">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let node">
        <button
          mat-icon-button
          [matMenuTriggerFor]="menu"
          (click)="$event.stopPropagation()"
        >
          <mat-icon>more_vert</mat-icon>
        </button>
        <!-- Menü für Aktionen -->
        <mat-menu #menu="matMenu">
          <button mat-menu-item (click)="onDepartementUpdate(node.Id)">
            <mat-icon>edit</mat-icon>
            <span>{{ "EDIT" | translate }}</span>
          </button>
          <button
            mat-menu-item
            (click)="onDepartementDelete(node.Id, node.Name)"
          >
            <mat-icon>delete</mat-icon>
            <span>{{ "DELETE" | translate }}</span>
          </button>
        </mat-menu>
      </td>
    </ng-container>

    <!-- Header Row -->
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>

    <!-- Data Rows -->
    <tr
      mat-row
      *matRowDef="let row; columns: displayedColumns"
      [ngClass]="{ 'sub-department-row': row.Level > 0 }"
      [@detailExpand]="row.Visible ? 'expanded' : 'collapsed'"
      style="overflow: hidden"
    ></tr>
  </table>
</div>
