import { Component, EventEmitter, Output } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { BottomNavigationEmployeeService } from 'src/app/navigation/bottom-navigation-employee/bottom-navigation-employee.service';
import { EmployeeShiftSwap } from '../../models/employee-shift-swap';
import { ShiftForSwap } from '../../models/shift-for-swap';
import { ShiftSwapService } from '../../services/shift-swap.service';
import { ShowPossibleWorkShiftForSwapComponent } from '../show-possible-work-shift-for-swap/show-possible-work-shift-for-swap.component';
import { ShowTargetedSwapShiftComponent } from '../show-targeted-swap-shift/show-targeted-swap-shift.component';

@Component({
  selector: 'app-show-shift-for-swap',
  templateUrl: './show-shift-for-swap.component.html',
  styleUrls: ['./show-shift-for-swap.component.css'],
})
export class ShowShiftForSwapComponent {
  private subscriptionShiftsForSwapList: Subscription;
  swapShiftList: EmployeeShiftSwap[] = [];

  @Output() showListInDashbord = new EventEmitter<boolean>(false);

  constructor(
    private shiftSwapService: ShiftSwapService,
    private dialog: MatDialog,
    private bottomNavigationEmployeeService: BottomNavigationEmployeeService
  ) {}

  ngOnInit(): void {
    this.showListInDashbord.emit(false);
    this.subscriptionShiftsForSwapList =
      this.shiftSwapService.shiftForSwapList.subscribe(
        (list: EmployeeShiftSwap[]) => {
          // null check
          if (!list) {
            return;
          }
          list.forEach((entry) => {
            const StarTimeTitle = new Date(entry.StartTime);
            //entry.Title =
            entry.DateString = StarTimeTitle.toLocaleDateString(
              navigator.language,
              {
                month: 'long',
                day: '2-digit',
                weekday: 'short',
              }
            );
            const options: any = {
              hour: '2-digit',
              minute: '2-digit',
            };
            const StarTime = new Date(entry.StartTime);
            entry.StartTime = StarTime.toLocaleTimeString(
              navigator.language,
              options
            );

            const EndTime = new Date(entry.EndTime);
            entry.EndTime = EndTime.toLocaleTimeString(
              navigator.language,
              options
            );
          });

          this.swapShiftList = list;

          if (this.swapShiftList.length > 0) {
            this.showListInDashbord.emit(true);
          }
          this.bottomNavigationEmployeeService.setNotificationBadge(
            this.swapShiftList.length > 0
          );
        }
      );

    this.shiftSwapService.GetShiftsForSwapQuery();
  }

  ngOnDestroy(): void {
    this.subscriptionShiftsForSwapList.unsubscribe();
  }

  getEmployeeWorkShiftsForSwapingQuery(
    shittId: number,
    EmployeeId,
    CandidateShiftListId: ShiftForSwap[],
    RequestsShifts: ShiftForSwap
  ) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.panelClass = 'custom-dialog-container';
    if (RequestsShifts && RequestsShifts.Id > 0) {
      dialogConfig.data = { swapShiftId: shittId };
      this.dialog.open(ShowTargetedSwapShiftComponent, dialogConfig);
    } else {
      dialogConfig.data = { shittId, EmployeeId, CandidateShiftListId };
      this.dialog.open(ShowPossibleWorkShiftForSwapComponent, dialogConfig);
    }
  }
}
