import { Component, OnInit } from '@angular/core';
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
} from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { EmployeeService } from '../../../employee/services/employee.service';
import { EmployeePaginationService } from './employee-pagination.service';

import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { ConfirmationDialogInputComponent } from 'src/app/core/component/confirmation-dialog-input/confirmation-dialog-input.component';
import { ConfirmationDialogComponent } from 'src/app/core/component/confirmation-dialog/confirmation-dialog.component';
import { JobType } from 'src/app/core/services/const';
import { AddEmployeeComponent } from '../add-employee/add-employee.component';
import { InviteEmployeeComponent } from '../invite-employee/invite-employee.component';

@Component({
  selector: 'app-employee-list',
  templateUrl: './employee-list.component.html',
  styleUrls: ['./employee-list.component.css'],
})
export class EmployeeListComponent implements OnInit {
  private subscriptionEmployeeList: Subscription;
  dialogRef: MatDialogRef<ConfirmationDialogComponent>;
  dialogRefInput: MatDialogRef<ConfirmationDialogInputComponent>;
  constructor(
    private dialog: MatDialog,
    public paginationService: EmployeePaginationService,
    public service: EmployeeService,
    private translate: TranslateService
  ) {}

  listData: MatTableDataSource<any>;
  displayedColumns: string[] = [
    'FirstName',
    'LastName',
    'Department',
    'Skill',
    'JobType',
    'WorkingHours',
    'Email',
    'MobileNo',
    'HomePhoneNo',
    'Street1',
    'Actions',
  ];

  ngOnInit(): void {
    this.subscriptionEmployeeList = this.service.employeeList.subscribe(
      (list) => {
        const cloned = list.map((x) => Object.assign({}, x));
        this.listData = new MatTableDataSource(cloned);
      }
    );
    this.service.getEmployeeList();
  }

  onPageSwitch(event: PageEvent): void {
    this.paginationService.change(event);
    this.service.getEmployeeList();
  }

  onEmployeeUpdate(id): void {
    const employee = this.service.getEmployeeById(id);
    this.updateEmployee(employee);
  }

  onEmailUpdate(id): void {
    const employee = this.service.getEmployeeById(id);
    this.updateEmail(employee);
  }

  onEmployeeDelete(id, firstName, lastName): void {
    this.dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      disableClose: false,
      panelClass: 'custom-dialog-container',
    });
    var textDialog =
      this.translate.instant('Are you sure you want to delete this user') +
      '?<br/><b>' +
      firstName +
      ' ' +
      lastName +
      '</b> ';
    this.dialogRef.componentInstance.confirmMessage = textDialog;

    this.dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.service.deleteEmployer(id);
      }
      this.dialogRef = null;
    });
  }

  onActivateDeactivateEmployee(id, isActive, firstName, lastName): void {
    if (isActive) {
      this.dialogRefInput = this.dialog.open(ConfirmationDialogInputComponent, {
        disableClose: false,
        panelClass: 'custom-dialog-container',
      });
      this.dialogRefInput.componentInstance.confirmMessage =
        this.translate.instant(
          'ARE YOU SURE YOU WANT TO DEACTIVATE THIS USER'
        ) +
        '?<br/><b>' +
        firstName +
        ' ' +
        lastName +
        '</b> ';
      this.dialogRefInput.componentInstance.inputLabel =
        this.translate.instant('FROM THE');
      this.dialogRefInput.componentInstance.inputType = 'date';
      this.dialogRefInput.componentInstance.inputValue = new Date()
        .toISOString()
        .split('T')[0];

      this.dialogRefInput.afterClosed().subscribe((result) => {
        if (result) {
          this.service.activateDeactivateEmployee(
            id,
            !isActive,
            result.utcOffset(0, true).format()
          );
        }
        this.dialogRefInput = null;
      });
    } else {
      this.dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        disableClose: false,
        panelClass: 'custom-dialog-container',
      });
      this.dialogRef.componentInstance.confirmMessage =
        this.translate.instant('ARE YOU SURE YOU WANT TO ACTIVATE THIS USER') +
        '?<br/><b>' +
        firstName +
        ' ' +
        lastName +
        '</b> ';

      this.dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.service.activateDeactivateEmployee(id, !isActive, null);
        }
        this.dialogRefInput = null;
      });
    }
  }

  updateEmployee(employee): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = { employee };
    dialogConfig.panelClass = 'custom-dialog-container';
    this.dialog.open(AddEmployeeComponent, dialogConfig);
  }

  updateEmail(employee): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = { employee };

    dialogConfig.panelClass = 'custom-dialog-container';
    this.dialog.open(InviteEmployeeComponent, dialogConfig);
  }

  getJobTypeLabel(jobType: JobType): string {
    switch (jobType) {
      case JobType.FullTime:
        return this.translate.instant('FULL-TIME');
      case JobType.PartTime:
        return this.translate.instant('PART-TIME');
      case JobType.MiniJob:
        return this.translate.instant('MINI-JOB');
      default:
        return '';
    }
  }

  sortEmployeeData(event): void {
    this.service.getEmployeeList(null, event);
  }
}
