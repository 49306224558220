<div class="container-fluid">
  <div class="row">
    <div id="section-to-print" class="col-12">
      <div class="card shadow mb-4">
        <div class="card-body">
          <!-- <div class="row">
            <div class="col-md-10 mb-2">
            </div>
            <div class="col-md-2 mb-6">
              <div class="btn-group btn-group-toggle">
                <div><label [ngClass]="{'active': !showTimeline }" class="btn btn-secondary" for="male">
                    <input id="male" type="radio" value="male" name="calenderPlanner" (click)="clickPayOption(false)">
                    Calender</label>
                </div>
                <div><label [ngClass]="{'active': showTimeline }" class="btn btn-secondary" for="female">
                    <input id="female" type="radio" value="female" name="calenderPlanner"
                      (click)="clickPayOption(true)">
                    Timeline</label>
                </div>
              </div>
            </div>
          </div> -->
          <div class="row">
            <div class="col-md-12 mb-6">
              <app-schedule-requirement-planner-timeline></app-schedule-requirement-planner-timeline>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
