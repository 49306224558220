<div class="modal-dialog modal-lg" role="document">
  <div class="modal-content">
    <div class="modal-header text-center">
      <h5 class="modal-title w-100">{{confirmHeader}}</h5>
    </div>
    <div class="modal-body">
      <div [innerHTML]="confirmMessage"></div>
      <br>
      <mat-label>{{ inputLabel }}</mat-label>
      <mat-form-field *ngIf="inputType === 'date'">
        <input matInput [(ngModel)]="inputValue" [matDatepicker]="picker" required (focus)="picker.open()">
        <mat-datepicker #picker></mat-datepicker>
        <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
      </mat-form-field>
      <mat-form-field *ngIf="inputType === 'text'">
        <input matInput [(ngModel)]="inputValue" *ngIf="inputType === 'text'">
      </mat-form-field>
    </div>
    <div class="modal-footer d-flex justify-content-between">
      <button class="btn btn-outline-secondary" (click)="dialogRef.close(false)">{{cancelButtonText}}</button>
      <button class="btn btn-primary active" (click)="dialogRef.close(inputValue)">{{confirmButtonText}}</button>
    </div>
  </div>
</div>
