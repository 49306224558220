import { animate, keyframes, transition, trigger } from '@angular/animations';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  NgZone,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
} from '@angular/material/dialog';
import { FullCalendarComponent } from '@fullcalendar/angular';
import { CalendarOptions } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGrigPlugin from '@fullcalendar/timegrid';
import { TranslateService } from '@ngx-translate/core';
import * as dayjs from 'dayjs';
import { Subscription } from 'rxjs';
import { ConfirmationDialogComponent } from 'src/app/core/component/confirmation-dialog/confirmation-dialog.component';
import { CalendarService } from 'src/app/modules/admin/services/calendar.service';
import { CompanySettingsService } from 'src/app/modules/settings/services/company-settings.service';
import { AddSwapRequestComponent } from 'src/app/modules/swap-shift/component/add-swap-request/add-swap-request.component';
import tippy, {
  followCursor,
  hideAll,
  inlinePositioning,
  roundArrow,
} from 'tippy.js';
import * as kf from './keyframes';

@Component({
  selector: 'app-show-employee-work-shifts',
  templateUrl: './show-employee-work-shifts.component.html',
  styleUrls: ['./show-employee-work-shifts.component.css'],
  animations: [
    trigger('cardAnimator', [
      transition('* => wobble', animate(1000, keyframes(kf.wobble))),
      transition('* => swing', animate(1000, keyframes(kf.swing))),
      transition('* => jello', animate(1000, keyframes(kf.jello))),
      transition(
        '* => zoomOutRight',
        animate(1000, keyframes(kf.zoomOutRight))
      ),
      transition('* => slideOutLeft', animate(300, keyframes(kf.slideOutLeft))),
      transition(
        '* => slideOutRight',
        animate(300, keyframes(kf.slideOutRight))
      ),
      transition(
        '* => rotateOutUpRight',
        animate(1000, keyframes(kf.rotateOutUpRight))
      ),
      transition('* => flipOutY', animate(1000, keyframes(kf.flipOutY))),
    ]),
  ],
})
export class ShowEmployeeWorkShiftsComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  public innerWidth: any;
  animationState: string;
  calendarApi: any;
  currentCalendarData: any;
  HTMLElement: HTMLImageElement;

  calendarOptions: CalendarOptions = {
    plugins: [timeGrigPlugin, dayGridPlugin],
    schedulerLicenseKey: 'GPL-My-Project-Is-Open-Source',
    locale: this.translate.currentLang,
    firstDay: 1,
    height: 'auto',
    initialView: 'timeGrid',
    duration: this.setGrid(),
    views: {
      timeGrid: {
        type: 'timeGrid',
        slotLabelFormat: {
          hour: 'numeric',
          minute: '2-digit',
          hour12: false,
        },
        // top level of headerToolbar
        slotLabelInterval: '01:00:00',
        slotDuration: '00:30:00',
        slotLabelClassNames: 'slot-label-employee',
        dayHeaderClassNames: 'day-header-employee',
      },
    },
    allDaySlot: false,
    headerToolbar: {
      left: 'title',
      center: '',
      right: 'employeeTimeGridButton,employeeCustomMonthButton',
    },
    titleFormat: {
      day: 'numeric',
      month: 'short',
    },
    customButtons: {
      employeeCustomMonthButton: {
        click: () => this.selectCustomMonthButton(),
      },
      employeeTimeGridButton: {
        click: () => this.selectCustomTimeGridButton(),
      },
    },
    eventDisplay: 'block',
    eventOverlap: false,
    slotEventOverlap: false,
    weekNumbers: true,
    slotMinTime: '08:00:00',
    slotMaxTime: '23:00:00',
    businessHours: {
      startTime: '08:00', // a start time (10am in this example)
      endTime: '22:30', // an end time (6pm in this example)

      daysOfWeek: [0, 1, 2, 3, 4, 5, 6],
      // days of week. an array of zero-based day of week integers (0=Sunday)
      // (Monday-Thursday in this example)
    },
    displayEventTime: true,
    weekends: true,
    editable: false,
    selectable: false,
    selectMirror: true,
    handleWindowResize: true,
    dayMaxEvents: true,
    eventStartEditable: false,
    eventDurationEditable: false,
    eventContent: this.handleEventContent.bind(this),
    eventDidMount: this.handleEventDidMount.bind(this),
  };

  @ViewChild('calendar') calendarComponent: FullCalendarComponent;
  dialogRef: MatDialogRef<ConfirmationDialogComponent>;
  distanceX: number;
  lastStauts: string;
  startTimeStamp: number;

  constructor(
    private service: CalendarService,
    private el: ElementRef,
    private translate: TranslateService,
    private dialog: MatDialog,
    private elRef: ElementRef,
    private changeDetectorRef: ChangeDetectorRef,
    private zone: NgZone,
    private companySetting: CompanySettingsService
  ) {
    Window['ShowEmployeeWorkShiftsComponent'] = this;
  }
  private subscriptionEmployeeEventList: Subscription;

  private isDragging: boolean = false;
  private startX: number = 0;
  private startY: number = 0;
  private currentX: number = 0;
  private currentY: number = 0;

  onTouchStart(event: TouchEvent) {
    // event.preventDefault();

    // check if the event or event.touches is undefined
    if (!event || !event.touches) {
      return;
    }
    // Get the initial touch position
    this.startX = event.touches[0]?.pageX;
    this.startY = event.touches[0]?.pageY;

    // startTimeStamp
    this.startTimeStamp = event.timeStamp;

    // get screen width
    this.innerWidth = window.innerWidth;

    // Add event listeners for touchmove
    document.addEventListener('touchmove', this.onTouchMove.bind(this), {
      passive: false,
    });

    // Set isDragging flag
    this.isDragging = true;
  }

  onTouchMove(event: TouchEvent) {
    // event.preventDefault();
    if (!this.isDragging) {
      return;
    }
    // Get the current touch position
    this.currentX = event.touches[0].pageX;
    this.currentY = event.touches[0].pageY;

    // Calculate the distance moved along the x-axis
    this.distanceX = this.currentX - this.startX;

    // Calculate the distance moved along the y-axis
    const distanceY = this.currentY - this.startY;
    // Move the calendar by updating its CSS transform
    // get bool finger is moving in x direction or y direction
    const isMovingInXDirection = Math.abs(this.distanceX) > Math.abs(distanceY);

    // get bool finger is moving more on x or y axis based on scr
    if (this.isDragging && isMovingInXDirection) {
      const element = this.elRef.nativeElement.querySelector(
        '.fc-view-harness-passive'
      );
      element.style.transform = `translateX(${this.distanceX}px)`;

      element.style.transition = 'transform 0.2s ease';
      // Apply the fade-out effect
      if (this.distanceX > 70) {
        const opacity = Math.max(0, 1 - (this.distanceX - 100) / 180);
        element.style.opacity = opacity.toString();
      }
      if (this.distanceX < -70) {
        const opacity = Math.max(0, 1 + (this.distanceX + 100) / 180);
        element.style.opacity = opacity.toString();
      }

      // get bool distancex is 0,5 of screen width
      const isHalfOfScreenWidth =
        Math.abs(this.distanceX) > 0.3 * this.innerWidth;

      // get speed based on distance and time from startTimeStamp to now
      const speed =
        Math.abs(this.distanceX) / (event.timeStamp - this.startTimeStamp);

      const isSpeedHigherThan1000 = speed > 1;

      if (
        this.distanceX > 10 &&
        (isHalfOfScreenWidth || isSpeedHigherThan1000)
      ) {
        this.lastStauts = 'right';
        element.style.transition = 'transform 0.8s ease';
        this.onTouchEnd(true);
        element.style.opacity = '0';
        // transform to the right side
        element.style.transform = `translateX(100vw)`;
        this.selectPrevButton();
      }
      if (
        this.distanceX < -10 &&
        (isHalfOfScreenWidth || isSpeedHigherThan1000)
      ) {
        this.lastStauts = 'left';
        element.style.transition = 'transform 0.8s ease';
        this.onTouchEnd(true);
        element.style.opacity = '0';
        // transform to the left side
        element.style.transform = `translateX(-100vw)`;
        this.selectNextButton();
      }
    }
  }

  onTouchEnd(notTrasform = false) {
    if (!notTrasform && this.isDragging) {
      const element = this.elRef.nativeElement.querySelector(
        '.fc-view-harness-passive'
      );
      element.style.transform = 'translateX(0px)';
      element.style.opacity = '1';
    }

    this.startTimeStamp = 0;

    // Remove event listeners for touchmove
    document.removeEventListener('touchmove', this.onTouchMove.bind(this));

    // Reset variables and isDragging flag
    this.startX = 0;
    this.currentX = 0;
    this.isDragging = false;
  }

  setGrid(): any {
    this.innerWidth = window.innerWidth;
    if (this.innerWidth < 600) {
      return { days: 4 };
    }
    if (this.innerWidth >= 600 && this.innerWidth < 750) {
      return { days: 5 };
    }
    if (this.innerWidth >= 750) {
      return { days: 7 };
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event): void {
    const calender = this.calendarComponent
      .getApi()
      .setOption('duration', this.setGrid());
  }

  @ViewChild('fc-dom-1', { static: true })
  fcTitle!: ElementRef<HTMLHeadingElement>;

  ngOnInit(): void {
    this.subscriptionEmployeeEventList =
      this.service.employeeEventList.subscribe((list) => {
        if (this.calendarComponent) {
          const calendar = this.calendarComponent.getApi();
          if (
            calendar &&
            JSON.stringify(this.calendarOptions.events) !== JSON.stringify(list)
          ) {
            calendar.removeAllEvents();
          }
        }

        this.calendarOptions.events = list;
        if (this.HTMLElement?.textContent) {
          this.HTMLElement.textContent =
            ' ' + this.translate.instant('WORK SCHEDULE') + ' ';
        }
        const element = this.elRef.nativeElement.querySelector(
          '.fc-view-harness-passive'
        );
        if (this.lastStauts === 'right') {
          element.style.transform = `translateX(-100vw)`;
          element.style.opacity = '0';
        }
        if (this.lastStauts === 'left') {
          element.style.transform = `translateX(100vw)`;
          element.style.opacity = '0';
        }
        // put in timer 1000 to give element enough time to render
        setTimeout(() => {
          if (element) {
            element.style.transform = 'translateX(0px)';
            element.style.opacity = '1';
            // refresh calendar
            const calendar = this.calendarComponent.getApi();
            if (calendar) {
              calendar.render();
              // cange detaction
              this.changeDetectorRef.detectChanges();
            }
          }
        }, 200);
      });
  }

  ngOnDestroy(): void {
    this.subscriptionEmployeeEventList.unsubscribe();
  }

  selectCustomMonthButton(): void {
    const calendar = this.calendarComponent.getApi();
    calendar.changeView('dayGridMonth');
    if (calendar) {
      this.service.getEvent(
        false,
        this.currentCalendarData.viewApi.currentStart,
        this.currentCalendarData.viewApi.currentEnd
      );
      this.currentCalendarData = this.calendarApi.getCurrentData();
      calendar.render();
    }
  }

  selectCustomTimeGridButton(): void {
    const calendar = this.calendarComponent.getApi();
    calendar.changeView('timeGrid');
    if (calendar) {
      this.currentCalendarData = this.calendarApi.getCurrentData();
      this.service.getEvent(
        false,
        this.currentCalendarData.viewApi.currentStart,
        this.currentCalendarData.viewApi.currentEnd
      );
      calendar.render();
    }
  }

  ngAfterViewInit(): void {
    this.calendarApi = this.calendarComponent.getApi();
    this.currentCalendarData = this.calendarApi.getCurrentData();
    this.service.getEvent(
      false,
      this.currentCalendarData.viewApi.currentStart,
      this.currentCalendarData.viewApi.currentEnd
    );
    this.HTMLElement = this.el.nativeElement.querySelector('#fc-dom-1');
  }

  onSwipeLeft(evt): void {
    if (!this.animationState) {
      this.animationState = 'slideOutLeft';
    }
    this.selectNextButton();
  }
  onSwipeRight(evt): void {
    if (!this.animationState) {
      this.animationState = 'slideOutRight';
    }
    this.selectPrevButton();
  }

  selectPrevButton(): void {
    this.calendarApi = this.calendarComponent.getApi();
    this.calendarApi.prev();
    this.currentCalendarData = this.calendarApi.getCurrentData();
    this.service.getEvent(
      false,
      this.currentCalendarData.viewApi.currentStart,
      this.currentCalendarData.viewApi.currentEnd
    );
  }

  PutShiftForSwap(eventId): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;

    dialogConfig.data = {
      EventId: eventId,
    } as any;

    dialogConfig.panelClass = 'custom-dialog-container';
    this.zone.run(() => {
      this.dialog.open(AddSwapRequestComponent, dialogConfig);
    });

    setTimeout(() => {
      hideAll();
    }, 100);
  }

  handleEventDidMount(event): any {
    // calculate the time based of this.companySetting.CompanySettings?.ShiftSwapSettings?.DaysInAdvance from the event and current date
    const currentDate = dayjs();
    const eventDate = dayjs(event.event.start);
    const diff = eventDate.diff(currentDate, 'days');

    if (
      !this.companySetting.CompanySettings?.ShiftSwapSettings?.AllowShiftSwap ||
      diff <
        this.companySetting.CompanySettings?.ShiftSwapSettings?.DaysInAdvance
    ) {
      return;
    }

    tippy(event.el, {
      content:
        '<div>' +
        '<span onclick="Window.ShowEmployeeWorkShiftsComponent.PutShiftForSwap(\'' +
        event.event.extendedProps.eventId +
        '\')" ><i class="bi bi-repeat fa-lg fa-clickable">' +
        '</i></span>' +
        '</div>',
      allowHTML: true,
      plugins: [inlinePositioning, followCursor],
      theme: 'light',
      trigger: 'click',
      followCursor: 'initial',
      arrow: roundArrow,
      popperOptions: { strategy: 'fixed' },
      interactive: true,
      interactiveBorder: 30,
      interactiveDebounce: 0,
      appendTo: document.body,
      animation: 'scale-extreme',
      onMount(instance): void {
        const box = instance.popper.firstElementChild;
        requestAnimationFrame(() => {
          box.classList.add('animated');
          box.classList.add('wobble');
        });
      },
    });
  }

  handleEventContent(event): any {
    // get hours and minutes from the event start and end time and show in HH:MM format
    const start = dayjs(event.event.start);
    const end = dayjs(event.event.end);
    let hours = end.diff(start, 'hours');
    let minutes = end.diff(start, 'minutes') - hours * 60;

    let hoursString = hours.toString();
    let minutesString = minutes.toString();

    if (hours < 10) {
      hoursString = '0' + hours;
    }
    if (minutes < 10) {
      minutesString = '0' + minutes;
    }
    return {
      html:
        '<div style="font-size:15px; text-align: center;vertical-align: middle;" class="fc-event-time">' +
        ' <i class="' +
        event.event._def.extendedProps.icon +
        ' fa-lg' +
        '"></i> ' +
        '</div>' +
        '<div style="text-shadow: 2px 2px 3px #ffffff; font-weight: 600; font-size:13px; text-align: center;vertical-align: middle;" class="fc-event-time">' +
        dayjs(event.event.start).format('HH:mm') +
        '</div>' +
        '<div style="text-shadow: 2px 2px 4px #000000; font-size:18px; text-align: center;vertical-align: middle;" class="fc-event-time">' +
        ' <i class="bi bi-arrow-down"></i> ' +
        '</div>' +
        '<div style="text-shadow: 2px 2px 3px #ffffff; font-weight: 600; font-size:13px; text-align: center;vertical-align: middle;" class="fc-event-time">' +
        dayjs(event.event.end).format('HH:mm') +
        '</div>' +
        '<div style="font-size:13px; text-align: center;vertical-align: middle; color: #00468b;" class="fc-event-time">' +
        ' <i class="bi bi-clock fa-2x"></i> ' +
        '</div>' +
        '<div style="text-shadow: 2px 2px 3px #ffffff; font-weight: 600; font-size:12px; text-align: center;vertical-align: middle; color: #00468b;" class="fc-event-time">' +
        hoursString +
        ':' +
        minutesString +
        '</div>',
    };
  }

  selectNextButton(): void {
    this.calendarApi = this.calendarComponent.getApi();
    this.calendarApi.next();
    this.currentCalendarData = this.calendarApi.getCurrentData();
    this.service.getEvent(
      false,
      this.currentCalendarData.viewApi.currentStart,
      this.currentCalendarData.viewApi.currentEnd
    );
  }
  resetAnimationState(): void {
    this.animationState = '';
  }
}
