import { Injectable } from '@angular/core';
import { HttpService } from 'src/app/core/services/http/http.service';
import { ApiEndPoints, ApiMethod } from '../../../core/services/const';
import { Departement } from '../model/departement';
import { DepartementDto } from '../model/departement-dto';

import { TranslateService } from '@ngx-translate/core';
import * as dayjs from 'dayjs';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Observable } from 'rxjs';
import { AddDepartement } from '../model/add-departement';
import { promise } from 'protractor';

@Injectable({
  providedIn: 'root',
})
export class DepartementService {
  constructor(
    private httpClient: HttpService,
    private toastr: ToastrService,
    private translate: TranslateService
  ) {}

  private departementListSource: BehaviorSubject<Departement[]> =
    new BehaviorSubject([]);
  departementList = this.departementListSource.asObservable();

  private DepartementListWithEmployeeCountSource: BehaviorSubject<
    Departement[]
  > = new BehaviorSubject([]);
  DepartementListWithEmployeeCount =
    this.DepartementListWithEmployeeCountSource.asObservable();

  private departementEmployeeListSource: BehaviorSubject<any[]> =
    new BehaviorSubject([]);
  departementEmployeeList = this.departementEmployeeListSource.asObservable();

  public currentDateTime = Date.now();

  getDepartementList(): void {
    this.httpClient
      .requestCall(ApiEndPoints.DepartmentList, ApiMethod.GET)
      .subscribe((res) => {
        const DepartementList = res as Departement[];
        this.departementListSource.next(DepartementList);
      });
  }

  getDepartementById(id: number): Departement {
    const findInSubDepartments = (
      departments: Departement[]
    ): Departement | undefined => {
      for (const department of departments) {
        if (department.Id === id) {
          return department;
        }
        if (department.SubDepartments) {
          const found = findInSubDepartments(department.SubDepartments);
          if (found) {
            return found;
          }
        }
      }
      return undefined;
    };

    return findInSubDepartments(
      this.DepartementListWithEmployeeCountSource.value
    );
  }

  getDepartementListWithEmployeeCount(): void {
    this.httpClient
      .requestCall(ApiEndPoints.DepartmentListWithEmployeeCount, ApiMethod.GET)
      .subscribe((res) => {
        const DepartementList = res as Departement[];
        this.DepartementListWithEmployeeCountSource.next(DepartementList);
      });
  }

  getDepartementEmployeeList(dateTime, selectedDepartementList = []): void {
    if (dateTime) {
      this.currentDateTime = dateTime;
    }
    const departement = selectedDepartementList.length
      ? selectedDepartementList.join(',')
      : null;

    dateTime = dayjs(this.currentDateTime).format('YYYY-MM-DD');
    const endPoint =
      ApiEndPoints.DepartmentEmployeeList + '/' + dateTime + '/' + departement;

    this.httpClient.requestCall(endPoint, ApiMethod.GET).subscribe((res) => {
      const DepartementEmpList = res as DepartementDto[];
      this.departementEmployeeListSource.next(DepartementEmpList);
    });
  }

  submitDepartement(departement: AddDepartement): Observable<any> {
    return new Observable((observer) => {
      const endPoint =
        departement.Id > 0
          ? ApiEndPoints.UpdateDepartment
          : ApiEndPoints.AddDepartment;

      this.httpClient
        .requestCall(endPoint, ApiMethod.POST, departement)
        .subscribe((res: any) => {
          if (res > 0) {
            this.toastr.success(
              this.translate.instant('NEW DEPARTMENT ADDED SUCCESSFULLY'),
              this.translate.instant('SUCCESS')
            );
            this.getDepartementListWithEmployeeCount();
            observer.next(res);
          }
        });
    });
  }

  deleteDepartment(id: number): void {
    this.httpClient
      .requestCall(ApiEndPoints.DeleteDepartment, ApiMethod.POST, {
        DepartementId: id,
      })
      .subscribe((res: any) => {
        if (res) {
          this.getDepartementListWithEmployeeCount();
        }
      });
  }
}
