import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { EChartsOption } from 'echarts';
import { Subscription } from 'rxjs';
import { HolidaysChart } from '../../../admin/model/holidaysChart.model';
import { AbsenceService } from '../../services/absence.service';

@Component({
  selector: 'app-absence-holiday-chart',
  templateUrl: './absence-holiday-chart.component.html',
  styleUrls: ['./absence-holiday-chart.component.css'],
})
export class AbsenceHolidayChartComponent implements OnInit, OnDestroy {
  @Input() employeeId = 0;
  holidaysChart: HolidaysChart;

  constructor(
    private service: AbsenceService,
    private translate: TranslateService
  ) {
    // this.drewChart(new HolidaysChart());
  }
  private subscriptionHolidaysForEmployee: Subscription;
  chartOption: EChartsOption;
  ngOnInit(): void {
    this.subscriptionHolidaysForEmployee =
      this.service.holidaysForEmployee.subscribe(
        (holidaysChart: HolidaysChart) => {
          this.holidaysChart = holidaysChart;
          this.drewChart(holidaysChart);
        }
      );

    this.service.getHolidaysForEmployeeChart(this.employeeId);
  }

  drewChart(holidaysChart: HolidaysChart): void {
    const data = [];

    if (!holidaysChart) {
      return;
    }

    // Farbenliste für das Chart
    const colorList = ['#003f5c', '#58508d', '#bc5090', '#ff6361', '#ffa600'];

    // Daten für Krankentage
    if (holidaysChart.SickDays !== 0) {
      const sickDaysLabel =
        this.translate.instant('ILLNESS DAYS') +
        ' (' +
        holidaysChart.SickDays +
        ' ' +
        this.translate.instant('DAYS') +
        ')';
      data.push({
        value: holidaysChart.SickDays,
        name: sickDaysLabel,
        itemStyle: { color: colorList[0] },
      });
    }

    // Daten für geplante Urlaubstage
    if (holidaysChart.Planned != null) {
      const plannedLabel =
        this.translate.instant('PLANNED VACATION') +
        ' (' +
        holidaysChart.Planned +
        ' ' +
        this.translate.instant('DAYS') +
        ')';
      data.push({
        value: holidaysChart.Planned,
        name: plannedLabel,
        itemStyle: { color: colorList[1] },
      });
    }

    // Daten für genehmigte Urlaubstage
    if (holidaysChart.Approved) {
      const approvedLabel =
        this.translate.instant('APPROVED VACATION') +
        ' (' +
        holidaysChart.Approved +
        ' ' +
        this.translate.instant('DAYS') +
        ')';
      data.push({
        value: holidaysChart.Approved,
        name: approvedLabel,
        itemStyle: { color: colorList[2] },
      });
    }

    // Daten für verbleibende Urlaubstage
    if (holidaysChart.RemainingLeaveCurrentYear != null) {
      const remainingLabel =
        this.translate.instant('REMAINING VACATION DAYS') +
        ' (' +
        holidaysChart.RemainingLeaveCurrentYear +
        ' ' +
        this.translate.instant('DAYS') +
        ')';
      data.push({
        value: holidaysChart.RemainingLeaveCurrentYear,
        name: remainingLabel,
        itemStyle: { color: colorList[3] },
      });
    }

    this.chartOption = {
      title: {
        text: this.translate.instant('ILLNESS AND VACATION DAYS'),
        subtext: '',
        left: 'center',
        textStyle: {
          fontSize: 18,
          fontWeight: 'bold',
        },
      },
      tooltip: {
        trigger: 'item',
        formatter: '{b}: {c} ' + this.translate.instant('DAYS') + ' ({d}%)',
        position: 'top',
      },
      legend: {
        show: true,
        orient: 'horizontal',
        bottom: 'bottom',
        left: 'left',
        textStyle: {
          fontSize: 15,
        },
        data: data.map((item) => item.name),
      },
      series: [
        {
          name: 'Urlaub',
          type: 'pie',
          radius: '70%',
          data: data,
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)',
            },
          },
          itemStyle: {
            borderRadius: 10,
            borderColor: '#fff',
            borderWidth: 2,
            color: function (params) {
              const colorList = ['#003f5c', '#604373', '#c4465f', '#F57F17'];
              return colorList[params.dataIndex];
            },
          },
          label: {
            show: true,
            formatter: '{c} ' + this.translate.instant('DAYS'),
            fontSize: 13,
            position: 'inside',
            lineHeight: 60,
          },
          labelLine: {
            show: true,
          },
        },
      ],
      grid: {
        containLabel: true,
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
      },
    };
  }

  ngOnDestroy(): void {
    this.subscriptionHolidaysForEmployee.unsubscribe();
  }
}
