import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { ShiftSwapService } from '../../services/shift-swap.service';

@Component({
  selector: 'app-show-responsed-work-shift-for-swap',
  templateUrl: './show-responsed-work-shift-for-swap.component.html',
  styleUrls: ['./show-responsed-work-shift-for-swap.component.css'],
})
export class ShowResponsedWorkShiftForSwapComponent {
  public swapShiftList = [];
  selectedShiftId: number;
  private subscriptionShiftsSwapCandidateList: Subscription;

  constructor(
    public dialogRef: MatDialogRef<ShowResponsedWorkShiftForSwapComponent>,
    private shiftSwapService: ShiftSwapService,
    @Inject(MAT_DIALOG_DATA) public data
  ) {}

  ngOnInit() {
    if (this.data?.swapShiftId) {
      this.shiftSwapService.GetShiftsSwapCandidateQuery(this.data.swapShiftId);
    }

    this.subscriptionShiftsSwapCandidateList =
      this.shiftSwapService.shiftsSwapCandidate.subscribe((list: any) => {
        list.forEach((entry) => {
          const StarTimeTitle = new Date(entry.StartTime);

          entry.Date = StarTimeTitle.toLocaleDateString(navigator.language, {
            month: '2-digit',
            day: '2-digit',
          });
          const options: any = {
            hour: '2-digit',
            minute: '2-digit',
          };
          const StarTime = new Date(entry.StartTime);
          entry.StartTime = StarTime.toLocaleTimeString(
            navigator.language,
            options
          );

          const EndTime = new Date(entry.EndTime);
          entry.EndTime = EndTime.toLocaleTimeString(
            navigator.language,
            options
          );
          entry.checked = false;
        });

        this.swapShiftList = list;
      });
  }

  onClose() {
    this.dialogRef.close();
  }
  onSubmit() {
    const selectedShifts = this.swapShiftList.filter(
      (entry) => entry.Id == this.selectedShiftId
    );

    if (selectedShifts.length > 0 && this.data?.swapShiftId) {
      const selectedShift = selectedShifts[0];
      this.shiftSwapService.SwapEmployeeBetweenShifts(
        this.data.swapShiftId,
        selectedShift.Id
      );
    }

    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    this.subscriptionShiftsSwapCandidateList.unsubscribe();
  }
}
