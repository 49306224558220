import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { EmployeeService } from 'src/app/modules/employee/services/employee.service';
import { AvailableSubscriptionDto } from '../../model/available-subscription-dto.model';
import { PaymentMethod } from '../../model/payment-method.model';
import { SubscriptionService } from '../../services/subscription.service';

@Component({
  selector: 'app-payment-confirmation',
  templateUrl: './payment-confirmation.component.html',
  styleUrls: ['./payment-confirmation.component.css'],
})
export class PaymentConfirmationComponent implements OnInit {
  subscriptionPlan: AvailableSubscriptionDto | null = null;
  employeeCount: number = 0;
  paymentMethods: PaymentMethod[] = [];
  selectedPaymentMethod: PaymentMethod | null = null;
  paymentInterval: 'monthly' | 'yearly' = 'monthly'; // Standardmäßig monatlich
  discountRate: number = 2; // Rabatt in Prozent
  loading: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private subscriptionService: SubscriptionService,
    private dialog: MatDialog,
    public router: Router,
    private employeeService: EmployeeService
  ) {}

  ngOnInit(): void {
    const subscriptionId = +this.route.snapshot.params['id']; // Die ID des Abonnements abrufen und zu einer Zahl konvertieren

    // Verfügbare Abonnements laden und das entsprechende Abo anhand der ID finden
    this.subscriptionService
      .loadAvailableSubscriptions()
      .subscribe((subscriptions) => {
        this.subscriptionPlan = subscriptions.find(
          (sub) => sub.Id === subscriptionId
        );
      });

    this.paymentMethods = this.subscriptionService.getPaymentMethods();

    this.employeeService.employeeCount.subscribe((count) => {
      this.employeeCount = count;
    });

    this.employeeService.getEmployeeCount();
  }

  selectPaymentMethod(method: PaymentMethod): void {
    this.selectedPaymentMethod = method;
  }

  setPaymentInterval(interval: 'monthly' | 'yearly'): void {
    this.paymentInterval = interval;
  }

  getTotalCost(): number {
    if (!this.subscriptionPlan) {
      return 0;
    }

    const monthlyCost =
      this.subscriptionPlan.PricePerUserPerMonth * this.employeeCount;
    if (this.paymentInterval === 'yearly') {
      return monthlyCost * 12 * (1 - this.discountRate / 100); // Rabatt berücksichtigen
    }
    return monthlyCost;
  }

  confirmPayment(): void {
    if (!this.selectedPaymentMethod || !this.subscriptionPlan) {
      alert('Bitte wählen Sie eine Zahlungsmethode aus.');
      return;
    }

    this.loading = true;
    this.subscriptionService.createCheckoutSession({
      companySubscriptionId: this.subscriptionPlan.Id,
      successUrl: 'https://example.com/success',
      cancelUrl: 'https://example.com/cancel',
    });
  }

  isPaymentMethodSelected(): boolean {
    return this.selectedPaymentMethod !== null;
  }

  cancelPayment(): void {
    this.router.navigate(['/settings']);
    this.dialog.closeAll();
  }
}
