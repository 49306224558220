import { FeatureDto } from './feature-dto.model';

export class CompanySubscriptionDetailsDto {
  public SubscriptionPlanId: number;
  public SubscriptionPlanName: string;
  public PricePerUserPerMonth: number;
  public Features: FeatureDto[];
  public CancelAtPeriodEnd: boolean; // Neues Feld
  public CurrentPeriodEnd: Date; // Neues Feld
  public Status: string; // Neues Feld
  public TrialEnd: Date;
}
