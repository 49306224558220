import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ReplaySubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { EmployeeListByDateDto } from 'src/app/modules/admin/model/department-dto.model';
import { Departement } from 'src/app/modules/departement/model/departement';
import { DepartementService } from '../../../departement/services/departement.service';
import { EmployeeService } from '../../../employee/services/employee.service';

@Component({
  selector: 'app-report-filter',
  templateUrl: './report-filter.component.html',
  styleUrls: ['./report-filter.component.css'],
})
export class ReportFilterComponent implements OnInit, OnDestroy {
  @Output() filterApplied = new EventEmitter<any>();

  public filteredEmployees: ReplaySubject<EmployeeListByDateDto[]> =
    new ReplaySubject<EmployeeListByDateDto[]>(1);
  public employeeList: EmployeeListByDateDto[] = [];
  public departmentList: Departement[] = [];
  protected _onDestroy = new Subject<void>();

  public filterForm: FormGroup;
  public employeeFilterCtrl: FormControl = new FormControl();

  constructor(
    private employeeService: EmployeeService,
    private departementService: DepartementService
  ) {}

  ngOnInit(): void {
    const startDate = new Date();
    startDate.setDate(1);

    const endDate = new Date();
    endDate.setMonth(endDate.getMonth() + 1);
    endDate.setDate(0);

    this.filterForm = new FormGroup({
      employee: new FormControl(null),
      department: new FormControl([], Validators.required),
      startDate: new FormControl(startDate, Validators.required),
      endDate: new FormControl(endDate, Validators.required),
    });

    this.employeeService.employeeNameListByDate.subscribe((list) => {
      this.employeeList = list as EmployeeListByDateDto[];

      // remove the duplicate employee id
      this.employeeList = this.employeeList.filter(
        (v, i, a) => a.findIndex((t) => t.Id === v.Id) === i
      );

      this.filteredEmployees.next(this.employeeList.slice());
    });

    this.departementService.departementList.subscribe((list) => {
      this.departmentList = list as Departement[];
    });

    this.filterForm.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.validateDates();
      });

    this.employeeFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterEmployees();
      });

    this.employeeService.getEmployeeNameListByDate({
      StarTime: startDate,
      EndTime: endDate,
      SkillId: 0,
      EmployeeId: 0,
      DepartmentId: 0,
    });
    this.departementService.getDepartementList();
  }

  filterEmployees(): void {
    if (!this.employeeList) {
      return;
    }
    let search = this.employeeFilterCtrl.value;
    if (!search) {
      this.filteredEmployees.next(this.employeeList.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.filteredEmployees.next(
      this.employeeList.filter((employee) =>
        employee.Name.toLowerCase().includes(search)
      )
    );
  }

  onInputChange(field: string): void {
    this.applyFilter();
  }

  onDepartmentChange(): void {
    // Handle department change if necessary
  }

  applyFilter(): void {
    this.filterApplied.emit(this.filterForm.value);
  }

  validateDates(): void {
    if (
      this.filterForm.controls['startDate'].value &&
      this.filterForm.controls['endDate'].value
    ) {
      const startDate = new Date(this.filterForm.controls['startDate'].value);
      const endDate = new Date(this.filterForm.controls['endDate'].value);

      if (startDate > endDate) {
        this.filterForm.setErrors({ dates: true });
      } else {
        this.filterForm.setErrors(null);
      }
    }
  }

  ngOnDestroy(): void {
    this._onDestroy.next();
    this._onDestroy.complete();
  }
}
