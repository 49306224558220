import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { NgForm } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { CompanySettings } from '../../model/company-settings.model';
import { CompanySettingsService } from '../../services/company-settings.service';

@Component({
  selector: 'app-time-tracking-settings',
  templateUrl: './time-tracking-settings.component.html',
  styleUrls: ['./time-tracking-settings.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class TimeTrackingSettingsComponent implements OnInit {
  companySettings: CompanySettings = new CompanySettings();

  suggestions = ['10', '15', '20', '25', '30', '40', '45', '50', '60'];

  constructor(
    private companySetting: CompanySettingsService,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.companySetting.CompanySettingsObservable.subscribe((settings) => {
      if (settings) {
        this.companySettings = settings;
      }
    });
    this.companySetting.getCompanySettings();
  }

  onSubmit(form: NgForm): void {}

  onUpdate(): void {
    this.companySetting.updateCompanySettings(this.companySettings);
  }
}
