<div class="modal-dialog modal-lg" role="document">
  <div class="modal-content">
    <div class="modal-header text-center">
      <h5 class="modal-title w-100" id="exampleModalCenterTitle">{{'SAVE THE PLAN AS A TEMPLATE'|translate}}</h5>
    </div>
    <div class="modal-body">
      <form class="user" id="addEmployee" #form='ngForm' autocomplete="off" (submit)="onSubmit(form)">
        <input type="hidden" #StarTime="ngModel" name="StarTime" [(ngModel)]="formModel.StarTime" />
        <input type="hidden" #EndTime="ngModel" name="EndTime" [(ngModel)]="formModel.EndTime" />
        <div class="row mb-4">
          <div class="form-group col-12" [innerHTML]="confirmMessage"></div>
        </div>

        <div class="row">
          <div class="form-group col-12">
            <input type="text" class="form-control form-control-user" id="Name"
              placeholder="{{'Name of the Template'|translate}}" #Name="ngModel" name="Name"
              [(ngModel)]="formModel.Name">
          </div>
        </div>

      </form>
    </div>
    <div class="modal-footer d-flex justify-content-between">
      <button class="btn btn-outline-secondary" (click)="onClose()" type="button"
        data-dismiss="modal">{{'Close'|translate}}</button>
      <button class="btn btn-primary" form="addEmployee" type="submit"
        [disabled]="form.invalid">{{'Save'|translate}}</button>
    </div>
  </div>
</div>