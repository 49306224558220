<div class="container content-center">
  <div class="row">
    <div class="col-sm-8 col-md-6 col-lg-4 mx-auto">
      <div class="card">
        <div class="card-body">
          <img class="center" style="padding-top: 30px;" src="../../../../../assets/img/Logo.PNG" alt="" width="152"
            height="80">
          <form [formGroup]="newPasswordForm" autocomplete="off" (submit)="onSubmit()">
            <div class="alert alert-info" role="alert" style="padding: 5px;">
              {{emailText}}
            </div>
            <div class="form-group mb-3" style="padding-top: 10px;">
              <label for="newPassword" style="color: var(--font-color)">{{'PASSWORD'|translate}}</label>
              <input id="newPassword" type="password" class="form-control" formControlName="password" autocomplete="off"
                required>
              <mat-error
                *ngIf="newPasswordForm.get('password').errors?.required && newPasswordForm.get('password').dirty">
                {{'This field is required'|translate}}
              </mat-error>
              <mat-error *ngIf="newPasswordForm.get('password').errors?.minlength">
                {{'You have to enter at least 6 digits'|translate}}!</mat-error>
            </div>
            <div class="form-group mb-3" style="padding-top: 10px;">
              <label style="color: var(--font-color)">{{'CONFIRM PASSWORD'|translate}}</label>
              <input type="password" class="form-control" formControlName="confirmPassword" autocomplete="off" required>
              <mat-error *ngIf="newPasswordForm.errors?.notSame">{{'These passwords dont match'|translate}}</mat-error>
            </div>
            <div class="d-grid" style="padding-top: 30px; font-size: small;">
              <button type="submit" class="btn btn-primary btn-lg bi bi-key btn-login fa-lg"
                [disabled]="!newPasswordForm.valid">
                {{'SET PASSWORD'|translate}}
              </button>
            </div>

          </form>
        </div>
      </div>
    </div>
  </div>
</div>